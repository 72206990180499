import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpImageService {

    constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) { }

    getImage(src, type?) {
        return this.httpClient.post(`${environment.baseUrl + environment.version}filelibrary/platform/viewimage`, { token: src }, { responseType: 'blob', observe: 'response' }).pipe(map((result: HttpResponse<Blob>) => {
            return result.body;
        }));
    }

    parseHtml(htmlString: string) {        
        let html = new DOMParser().parseFromString(htmlString, 'text/html');
        var imgs = html.querySelectorAll('img[http-src]');
        for (let i = 0; i < imgs.length; i++) {
            let parent = imgs[i].parentNode;
            let newnode = document.createElement('img');
            if (imgs[i].hasAttribute('http-src')) {
                let attribute = imgs[i].getAttribute('http-src');
                newnode.setAttribute('data-src', attribute);
            }
            if (imgs[i].hasAttribute('width')) {
                let attribute = imgs[i].getAttribute('width');
                newnode.setAttribute('width', attribute);
            }
            if (imgs[i].hasAttribute('height')) {
                let attribute = imgs[i].getAttribute('height');
                newnode.setAttribute('height', attribute);
            }
            if (imgs[i].hasAttribute('http-src-type')) {
                let attribute = imgs[i].getAttribute('http-src-type');
                newnode.setAttribute('data-type', attribute);
            }
            if (imgs[i].hasAttribute('style')) {
                let attribute = imgs[i].getAttribute('style');
                newnode.setAttribute('style', attribute);
            }
            newnode.setAttribute('id', `image_src_${i}`);
            parent.removeChild(imgs[i]);
            parent.appendChild(newnode);
        }
        let div = document.createElement("div");
        div.appendChild(html.body.cloneNode(true));
        return div.innerHTML;
    }
}