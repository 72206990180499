import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'filterBy', pure: false })
export class FilterByPipe implements PipeTransform {

    transform(items: any[], field: string, value: string[]): any[] {
        
        if (!items) return [];
        if (!value || !field || value.length == 0) return items;

        return items.filter(function (item) {            
            if (item[field].length > 0) {
                var matchingCategoryIds = _.intersection(item[field], value);
                return matchingCategoryIds.length > 0;
            }
        });

    }
}