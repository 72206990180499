<div>
    <div class="calendar-contest-period bg-color2">
        <app-hub-cms [componentId]="communicationPage[0].Items[0].selectedItem.id" [culture]="selectedCulture"
            [payload]="communicationPage[0].Items[0]"
            *ngIf="communicationPage && arrayHasIndex(communicationPage, 0) && communicationPage[0].Items.length > 0">
        </app-hub-cms>
        <div class="flex flex-align-right bg-color2">
            <div class="contest-time">
                <div class="icon">
                    <img src="/assets/images/calendar.png" alt="" />
                </div>
                <div class="body">
                    <div>
                        <body-text class="block bold">Contest Period: </body-text>
                        <body-text class="block bold">{{promotion.startDate | localizedDate}} - {{promotion.endDate | localizedDate}}</body-text>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="padding-20 bg-color2">
        <app-hub-cms [componentId]="communicationPage[0].Items[1].selectedItem.id" [culture]="selectedCulture"
            [payload]="communicationPage[0].Items[1]"
            *ngIf="communicationPage && arrayHasIndex(communicationPage, 0) && communicationPage[0].Items.length > 1">
        </app-hub-cms>

        <app-hub-participant-goal-quest [showProgress]="true"
            *ngIf="communicationPage && arrayHasIndex(communicationPage, 1) && communicationPage[1].Items.length > 0"
            [componentId]="communicationPage[1].Items[0].selectedItem.id" [culture]="selectedCulture"
            [payload]="communicationPage[1].Items[0]">
        </app-hub-participant-goal-quest>
    </div>

</div>