<div [appIsLoading]="loadingService.isLoading(this)">
    <div class="claim-filter-section">

        <div class="custom-toggle margin-side">
            <div class="header">
                <div class="header-title">User Guide - 1</div>
                <button (click)="toggleShows()" class="btn btn-link accordion"> {{isShownDiv ? '-' : '+'}}</button>
            </div>
            <div class="open bg" [class]="{'newClass':isShownDiv}" *ngIf="isShownDiv">
                <div class="padding-10" [innerHtml]="instructions"></div>
            </div>
        </div>

        <p class="claimDescription">
            {{'InvoiceClaimPage.InvoiceClaimSubmission.InvoiceClaimSubmissionDescription' | translate}}
        </p>

        <div class="sub-Header-Text">
            <span class="sub-header-invoiceClaim">{{'InvoiceClaimPage.InvoiceClaimSubmission.ClaimyourInvoice'
                |
                translate}}</span>

        </div>

        <form [formGroup]="invoiceSubmissionForm" *ngIf="invoiceSubmissionForm" (ngSubmit)="onSubmit()">
            <div class="grid grid-gap-10 grid-col-md-50 grid-col-hd-50 grid-col-xs-100 grid-col-sm-100 grid-col-lt-100">
                <div class="addContactControls remove-right-margin col-100">
                    <label>{{'InvoiceClaimPage.InvoiceClaimSubmission.InvoiceNumber' |
                        translate}} <span class="mandatory">*</span></label><br>
                    <input formControlName="invoiceNumber" placeholder="Enter invoiceNumber" class="form-control" />
                    <small *ngIf="invoiceSubmissionForm.get('invoiceNumber').errors && submitted"
                        class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.InvoiceNumberRequired'
                        |
                        translate}}</small>
                </div>
                <div class="addContactControls remove-right-margin col-100">
                    <label>{{'InvoiceClaimPage.InvoiceClaimSubmission.DateofSales' | translate}} <span
                            class="mandatory">*</span></label><br>
                    <div class="input-group">
                        <input formControlName="dateOfSale" placeholder="Select Date" class="form-control datePickerCalander" ngbDatepicker
                            #dateOfSales="ngbDatepicker" [maxDate]="maxSaleDate" [minDate]="minSaleDate" readonly />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dateOfSales.toggle()"
                                type="button"></button>
                        </div>
                        <small *ngIf="invoiceSubmissionForm.get('dateOfSale').errors && submitted"
                            class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.DateofSaleRequired'
                            |
                            translate}}</small>
                    </div>
                </div>
                <div class="addContactControls remove-right-margin col-100" *ngFor="let attribute of attributesDetails">
                    <div *ngIf="attribute.destination !== 'Organisation' &&  (attribute.dataType.name == 'Text' || attribute.dataType.name == 'Integer' || attribute.dataType.name == 'Decimal')"
                        formGroupName="attributes">
                        <label>{{attribute.destination}} <span class="mandatory">*</span></label><br>
                        <input [formControlName]="attribute.destination" [placeholder]="attribute.destination"
                            type="text" class="form-control" [id]="attribute.destination" />
                        <small *ngIf="checkAttributesValidation(attribute.destination) && submitted"
                            class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.FieldRequired'
                            |
                            translate}}</small>
                    </div>
                    <div *ngIf="attribute.destination === 'Organisation'" formGroupName="attributes">
                        <label>{{attribute.destination}} <span class="mandatory">*</span></label><br>
                        <input [formControlName]="attribute.destination" [placeholder]="attribute.destination"
                            type="text" class="form-control" [id]="attribute.destination"
                            [ngbTypeahead]="organisationSearch" />
                        <small *ngIf="checkAttributesValidation(attribute.destination) && submitted"
                            class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.FieldRequired'
                            |
                            translate}}</small>
                    </div>
                    <div *ngIf="attribute.dataType.name == 'Email'" formGroupName="attributes">
                        <label>{{attribute.destination}}</label><br>
                        <input [formControlName]="attribute.destination" [placeholder]="attribute.destination"
                            type="email" class="form-control" [id]="attribute.destination" />
                        <small *ngIf="checkAttributesValidation(attribute.destination) && submitted"
                            class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.FieldRequired'
                            |
                            translate}}</small>
                    </div>
                    <div *ngIf="attribute.dataType.name == 'Date'" formGroupName="attributes" class="form-group item">
                        <label for="fromdate" class="datePlacement">{{attribute.destination}}</label><br />
                        <div class="input-group">
                            <input class="form-control datePickerCalander" [formControlName]="attribute.destination"
                                [placeholder]="attribute.destination" ngbDatepicker #dpFromDate="ngbDatepicker"
                                readonly />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="dpFromDate.toggle()"
                                    type="button"></button>
                            </div>
                            <small *ngIf="checkAttributesValidation(attribute.destination) && submitted"
                                class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.FieldRequired'
                                |
                                translate}}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="grid grid-gap-10 grid-col-md-50 grid-col-hd-50 grid-col-xs-100 grid-col-sm-100 grid-col-lt-100 custom-rule">
            </div>


            <div formArrayName="salesLineItems"
                class="grid grid-gap-10 grid-col-md-50-custom grid-col-hd-50-custom grid-col-xs-100 grid-col-sm-100 grid-col-lt-100 custom-grid-hd-50 salesLineItems-flex"
                *ngFor="let saleLineItem of invoiceSubmissionForm.get('salesLineItems')['controls']; index as i; first as isFirst;
                             last as isLast;count as isCount">
                <div class="addContactControls addContactControls-custom col-100" [formGroupName]="i">
                    <div class="margin-bottom-0">
                        <label
                            [ngClass]="{'label-hide-web': !isFirst}">{{'InvoiceClaimPage.InvoiceClaimSubmission.Product'
                            | translate}}
                            <span class="mandatory">*</span></label>
                        <div class="addContactControls remove-right-margin col-100 position-relative mt-0 margin-left-0">
                            <input type="text" formControlName="productName" class="form-control customField-prd"
                                [ngbTypeahead]="search">
                            <a (click)="openAdvanceFilterModal(i)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="position-absolute fa-filter-custom"
                                    width="20" height="13.022" viewBox="0 0 20 13.022">
                                    <g id="Group_13823" data-name="Group 13823" transform="translate(-1160)">
                                        <path id="icon-filter" fill="#545454"
                                            d="M157.231,161.766a.7.7,0,0,1,0-1.4h2.322a.7.7,0,0,1,0,1.4Zm-4.644-5.811a.7.7,0,1,1,0-1.4h11.622a.7.7,0,1,1,0,1.4Zm-3.489-5.811a.7.7,0,1,1,0-1.4h18.6a.7.7,0,1,1,0,1.4Z"
                                            transform="translate(1011.602 -148.744)" />
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <small *ngIf="saleLineItem['controls'].productName?.hasError('required') && submitted"
                        class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.ProductNameRequired'
                        | translate}}</small>
                    <small *ngIf="saleLineItem['controls'].productName?.errors &&
                                 !saleLineItem['controls'].productName?.hasError('required') && submitted"
                        class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.ProductNameInvalid'
                        | translate}}</small>
                </div>
                <div class="addContactControls mx-custom col-100" [formGroupName]="i">
                    <div class="flex justify-content-between grid-gap-10 salesLineItems-flex">
                        <div class="margin-bottom-10" *ngFor="let attribute of lineItemAttributesDetails">
                            <div *ngIf="attribute.dataType.name == 'Text' || attribute.dataType.name == 'Integer' || attribute.dataType.name == 'Decimal'"
                                formGroupName="additionalAttributes">
                                <label [ngClass]="{'label-hide-web': !isFirst}">{{attribute.destination}}
                                    <span class="mandatory">*</span></label>
                                <input [formControlName]="attribute.destination" type="text" class="form-control"
                                    [id]="attribute.destination" />
                                <small
                                    *ngIf="saleLineItem['controls']['additionalAttributes']['controls'][attribute.destination].errors  && submitted"
                                    class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.FieldRequired'
                                    |
                                    translate}}</small>
                            </div>
                            <div *ngIf="attribute.dataType.name == 'Email'" formGroupName="additionalAttributes">
                                <input [formControlName]="attribute.destination" type="email" class="form-control"
                                    [id]="attribute.destination" />
                            </div>
                            <div *ngIf="attribute.dataType.name == 'Date'" formGroupName="additionalAttributes"
                                class="form-group item">
                                <div class="input-group">
                                    <input class="form-control datePickerCalander"
                                        [formControlName]="attribute.destination" ngbDatepicker
                                        #dpFromDate="ngbDatepicker" readonly />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="dpFromDate.toggle()"
                                            type="button"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="margin-bottom-10" *ngIf="claimForm.isQuantityEnabled">
                            <div>
                                <label
                                    [ngClass]="{'label-hide-web': !isFirst}">{{'InvoiceClaimPage.InvoiceClaimSubmission.Quantity'
                                    |
                                    translate}} <span class="mandatory">*</span></label>
                                <input type="text" formControlName="quantity" class="form-control customField-qty"
                                    pattern="^[-+]?\d+$">
                            </div>
                            <small *ngIf="saleLineItem['controls'].quantity?.hasError('required') && submitted"
                                class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.QuantityRequired'
                                | translate}}</small>
                            <small *ngIf="saleLineItem['controls'].quantity?.errors &&
                                            !saleLineItem['controls'].quantity?.hasError('required') && submitted"
                                class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.QuantityInvalid'
                                | translate}}</small>
                        </div>
                        <div class="margin-bottom-10" *ngIf="claimForm.isValueEnabled">
                            <div>
                                <label
                                    [ngClass]="{'label-hide-web': !isFirst}">{{'InvoiceClaimPage.InvoiceClaimSubmission.Value'
                                    |
                                    translate}} <span class="mandatory">*</span></label>
                                <input type="text" formControlName="value" class="form-control customField-qty"
                                    pattern="^(?!0\d|$)\d*(\.\d{1,4})?$">
                            </div>
                            <small *ngIf="saleLineItem['controls'].value?.hasError('required') && submitted"
                                class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.ValueRequired'
                                | translate}}</small>
                            <small *ngIf="saleLineItem['controls'].value?.errors &&
                                            !saleLineItem['controls'].value?.hasError('required') && submitted"
                                class="error-message">{{'InvoiceClaimPage.InvoiceClaimSubmission.ValueInvalid'
                                | translate}}</small>
                        </div>
                        <div class="margin-bottom-10 position-bottom mt-0 justify-content-start"
                            [ngClass]="{'column-delete': isCount > 1, 'column-delete-first': i == 0 && isCount > 1}"
                            *ngIf="isCount!=1">
                            <fa-icon [icon]="faDelete" class="fa-delet-custom" (click)="deleteLineItem(i)"></fa-icon>
                        </div>

                    </div>

                </div>
            </div>
            <div class="flex justify-content-center grid-gap-10 mt-3">
                <div class="margin-bottom-10 position-bottom">
                    <input type="button" class="btn btn-primary custombtn" (click)="addNewLineItem()"
                        [value]="'InvoiceClaimPage.AddSKUBtn' | translate" />
                </div>
            </div>
            <div
                class="grid grid-gap-10 grid-col-md-50 grid-col-hd-50 grid-col-xs-100 grid-col-sm-100 grid-col-lt-100 custom-rule-bottom">
            </div>

            <div class="grid grid-gap-10 grid-col-md-50 grid-col-hd-50 grid-col-xs-100 grid-col-sm-100 grid-col-lt-100">

                <div class="upload-file-submission">
                    <span class="sub-header-invoiceClaim">{{'InvoiceClaimPage.Uploadyourinvoiceclaimfile' |
                        translate}}</span>

                    <br>

                    <label>{{'InvoiceClaimPage.Uploadfile' | translate}} ({{extensions}})<span
                            class="mandatory">*</span></label><br>
                    <div class="file-upload">
                        <div class="file-select">
                            <label for="chooseFile" class="file-select-name"> </label>
                            <label *ngIf="files.length>0" class="selected-file-name">{{files.length}}
                                {{'InvoiceClaimPage.FilesUploaded' | translate}} </label>
                            <input #fileUpload type="file" name="chooseFile" multiple name="chooseFile" id="chooseFile"
                                (change)="fileHandler($event.target.files)" />
                        </div>
                    </div>
                    <small *ngIf="files.length == 0 && submitted" class="error-message">
                        {{'InvoiceClaimPage.InvoiceClaimSubmission.InvoiceRequired' | translate}}
                    </small>
                    <small *ngIf="files.length != 0 && fileErrorMessage && submitted" class="error-message">
                        {{fileErrorMessage}}
                    </small>

                    <div class="upload-section-main" *ngFor="let file of files">
                        <div class="upload-status-icon"></div>
                        <div class="progress-section">
                            <div class="upload-label">
                                <label class="fileName">{{file.name}}</label>
                                <span (click)="removeFile(file)" class="removebtn"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 text-center btn-align">
                    <input type="submit" class="btn btn-primary customBtnSubmit"
                        [value]="'InvoiceClaimPage.SubmitInvoiceClaim' | translate" />
                </div>
            </div>

            <div *ngIf="success" class="mainPointSummaryNoData alert alert-success text-center">
                {{'InvoiceClaimPage.SubmitSuccessfully' | translate}}
            </div>
            <div *ngIf="duplicateError" class="mainPointSummaryNoData alert alert-danger text-center">
                {{'InvoiceClaimPage.DuplicateIssue' | translate}}
            </div>
            <div *ngIf="submitFailed" class="mainPointSummaryNoData alert alert-danger text-center">
              {{'InvoiceClaimPage.SubmitFailed' | translate}}
          </div>
        </form>
    </div>
</div>
