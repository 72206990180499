import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from '@core/startup';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(public router: Router, public authService: AuthenticationService) { }

    canActivate() {
        const token = this.authService.checkUser();
        if (token) {
            return true;
        }
        this.router.navigate(['/']);
        return false;
    }
}