import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import { HubPageService } from '@core/private/hubpage/services/hub-page.service';
import { Pagination } from '@core/shared/models/pagination';
import { SearchCriteria } from '@core/shared/models/search-criteria';
import { LoadingService, SharedService, ChallengeModalComponent, ResolveChallengeModalComponent, RejectClaimComponent, ClaimErrorComponent } from '@core/shared';

@UntilDestroy()
@Component({
    selector: 'app-hub-claim',
    templateUrl: 'claim.component.html',
    styleUrls: ['claim.component.scss'],
})

export class ClaimComponent implements OnChanges, OnInit {
    @Input() componentId: string;
    @Input() payload: any;
    @Input() culture: string;

    userId: string;
    username: string;
    content;
    claimFormId: string;
    userIsResolver: boolean = false;
    configuration: any;
    sales: any;
    attributeHeaders: any[] = [];
    paginationDetails: Pagination = new Pagination();
    searchCriteria: SearchCriteria = new SearchCriteria('Date of Sale');
    maxFromDate: any;
    minFromDate: any;
    maxToDate: any;
    minToDate: any;
    private onDestroy$ = new Subject<void>();
    claimStatus = {
        UNCLAIMED: "Unclaimed",
        CLAIMED: "Claimed",
        CHALLENGED: "Challenged",
        RESOLVED: "Resolved",
        APPROVED: "Approved",
        REJECTED: "Rejected"
    };

    constructor(private modalService: NgbModal, private hubPageService: HubPageService, public loadingService: LoadingService, private sharedService: SharedService,
        private store: Store<fromStore.PlatformState>) { }

    ngOnInit() {
        this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.userId = state.user.Id;
            this.username = state.user.Username;
        });
    }

    ngOnChanges() {
        this.claimFormId = this.payload.selectedItem.id;
        let data = { item: JSON.stringify(this.payload) };
        let component$ = this.hubPageService.getHubComponent(this.componentId, this.culture, data);
        let claimConfiguration$ = this.sharedService.getClaimConfiguration(this.claimFormId);
        let join = forkJoin([component$, claimConfiguration$]);

        this.loadingService.doLoading(join, this, "claim").pipe(untilDestroyed(this)).subscribe(response => {
            this.content = response[0];
            this.sales = this.content.items;
            this.paginationDetails.totalNumberOfItems = this.content.totalNumberOfItems;
            this.setAttributeHeaders();
            this.configuration = response[1];
        });
    }

    getSales() {
        this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
        this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
        this.searchCriteria.sortField = 'Date of Sale';
        this.searchCriteria.sortAscending = true;

        let filter = {};
        filter['organisationsListUserId'] = this.userId;
        this.searchCriteria.additionalData = filter;
        this.hubPageService.getSales(this.claimFormId, this.searchCriteria).subscribe({
            next: (response) => {
                this.sales = response.items;
                this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
                this.setAttributeHeaders();
            },
            error: (error) => {
                this.paginationDetails.totalNumberOfItems = 0
                this.openErrorModal(this.getErrorLabel(error.errorMessages[0]));
            }
        });
    }

    setAttributeHeaders() {
        if (this.sales.length > 0)
            this.attributeHeaders = this.sales[0].attributes
    }

    setResolvingUser() {
        this.sharedService.containsUser(this.configuration.resolvingAudienceId, this.userId).subscribe((response) => {
            this.userIsResolver = response
        });
    }

    claimActionText(sale) {
        if (sale.claimStatus.name === this.claimStatus.UNCLAIMED) {
            return "SalesSearch.SalesList.ActionButton.Claim";
        }
        if (sale.claimStatus.name === this.claimStatus.CLAIMED && sale.sellerId === this.userId) {
            return "SalesSearch.SalesList.ActionButton.Cancel";
        }
        return '';
    }

    challengeActionText(sale) {
        if (sale.claimStatus.name === this.claimStatus.CHALLENGED && sale.lastClaimById === this.userId) {
            return "SalesSearch.SalesList.ActionButton.Cancel";
        }
        if (sale.claimStatus.name === this.claimStatus.CLAIMED && sale.sellerId !== this.userId) {
            return "SalesSearch.SalesList.ActionButton.Challenge";
        }
        return '';
    }

    claim(sale, detail?: string) {
        this.hubPageService.claim(this.claimFormId, sale.saleId, detail).subscribe({
            next: (response) => {
                this.updateSaleWithClaimResult(sale, response);
            },
            error: (error) => {
                this.openErrorModal(this.getErrorLabel(error.errorMessages[0]));
            }
        });
    }

    challenge(sale) {
        if (sale.claimStatus.name === this.claimStatus.CHALLENGED) {
            this.claim(sale);
        }
        else {
            const modalRef = this.modalService.open(ChallengeModalComponent, { backdrop: 'static' });
            modalRef.result.then((result) => {
                this.claim(sale, result.reason);
            });
        }
    }

    openResolve(sale) {
        this.hubPageService.checkResolver(this.claimFormId, sale.saleId).subscribe(response => {
            const modalRef = this.modalService.open(ResolveChallengeModalComponent, { backdrop: 'static' });
            modalRef.componentInstance.resolveDto = response;
            modalRef.result.then((result) => {
                this.resolve(sale, result);
            }).catch((error) => {
                console.log(error);
            });
        });
    }

    resolve(sale, resolveDto) {
        this.hubPageService.resolve(this.claimFormId, sale.saleId, 
            { detail: resolveDto.resolveReason, claimedByUserId: resolveDto.winnerId }).subscribe({
                next: (response) => {
                    this.updateSaleWithClaimResult(sale, response);
                },
                error: (error) => {
                    this.openErrorModal(this.getErrorLabel(error.errorMessages[0]));
                }
            });
    }

    approve(sale) {
        this.hubPageService.approve(this.claimFormId, sale.saleId, 
            { claimedByUserId: sale.sellerId, claimConfigurationId: this.claimFormId, saleId: sale.saleId }).subscribe({
                next: (response) => {
                    this.updateSaleWithApproval(sale, response);
                },
                error: (error) => {
                    this.openErrorModal(this.getErrorLabel(error.errorMessages[0]));
                }
            });
    }

    rejectClaim(sale, rejectDto) {
        this.hubPageService.reject(this.claimFormId, sale.saleId, { detail: rejectDto.rejectionReason, 
            claimedByUserId: rejectDto.claimedByUserId, claimConfigurationId: this.claimFormId, saleId: sale.saleId }).subscribe({
                next: (response) => {
                    this.updateSaleWithApproval(sale, response);
                },
                error: (error) => {
                    this.openErrorModal(this.getErrorLabel(error.errorMessages[0]));
                }
            });
    }

    showRejectModal(sale) {
        this.hubPageService.checkResolver(this.claimFormId, sale.saleId).subscribe(response => {
            this.openRejectModal(sale, response);
        });
    }

    updateSaleWithClaimResult(sale, result) {
        sale.claimStatus = result.status;
        sale.dateOfStatusChange = result.dateOfClaim;
        sale.sellerId = result.sellerId;
        this.setSellingUser(sale, result.status, result.sellerUsername);
        if (sale.claimStatus.name === this.claimStatus.CHALLENGED) {
            sale.lastClaimById = this.userId;
            sale.canBeChallenged = false;
        }
    }

    updateSaleWithApproval(sale, result) {
        sale.claimStatus = result.status;
        sale.dateOfStatusChange = result.dateOfClaim;
        sale.sellerId = result.sellerId;
        this.setSellingUser(sale, result.status, result.sellerUsername);
    }

    openRejectModal(sale, rejectDto) {
        const modalRef = this.modalService.open(RejectClaimComponent, { backdrop: 'static' });
        modalRef.componentInstance.rejectDto = rejectDto;
        modalRef.result.then((result) => {
            this.rejectClaim(sale, result);
        }).catch((error) => {
            console.log(error);
        });
    }

    setSellingUser(sale, newClaimStatus, username) {
        var sellingUserAttribute = sale.attributes.find(attribute => attribute.name === "Selling User");

        if (sellingUserAttribute) {
            if (newClaimStatus.name === this.claimStatus.CLAIMED || newClaimStatus.name === this.claimStatus.RESOLVED) {
                sellingUserAttribute.valueAsString = username;
            } else {
                sellingUserAttribute.valueAsString = '';
            }
        }
    }

    openErrorModal(errorLabel) {
        const modalRef = this.modalService.open(ClaimErrorComponent);
        modalRef.componentInstance.errorLabel = errorLabel;
        modalRef.result.then((result) => {

        }).catch((error) => {
            console.log(error);
        });
    }

    getErrorLabel(error) {
        return this.toPascalCase(Object.keys(error)[0]);
    }

    toPascalCase(text) {
        var start = text.charAt(0);
        start = start.toUpperCase();
        text = text.substring(1, text.length);
        return start + text;
    }

    onPaginationChange(pagination: Pagination) {
        this.paginationDetails = pagination;
        this.getSales();
    }
}