import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpService } from '@core/shared';
import { map } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from '@env/environment';

import {
  UrlBuilder,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  VALIDATE,
  VALIDATE_OTP,
  ACTIVATE,
  RESET_PASSWORD,
  VERIFY_LINK,
  GET_SELF_REGISTRATION_STATUS,
  GET_SELF_REGISTRATION_CONFIG,
  //GET_USER_DETAILS_BYUSERID,
  SAVE_USER_LOGIN_ACTIVITY_RESOURCE,
  GET_PRELOGIN_POLICY_PAGE_CONTENT,
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES,
  GET_STATUSES,
  GET_REGISTER_CONTENT_PAGE
} from '@core/core-api-variables';


@Injectable({
  providedIn: 'root'
})
export class PreloginService {
  constructor(public http: HttpService, public httpClient: HttpClient) { }


  changepassword(data): Observable<any> {
    let url = CHANGE_PASSWORD;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }

  forgotPassword(data): Observable<any> {
    let url = FORGOT_PASSWORD;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }

  validate(userName: string, activationCode: string, type: string): Observable<any> {
    let data = {
      activationCode: activationCode,
      type: type,
      username: userName
    }
    let url = VALIDATE;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }
  validateOTP(userName: string, otp: string, referenceID: string): Observable<any> {
    let data = {
      otp: otp,
      referenceID: referenceID,
      username: userName
    }
    let url = VALIDATE_OTP;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }

  activate(data): Observable<any> {
    let url = ACTIVATE;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }

  getRegisterContentPage(contentPageId: string, culture: string): Observable<any> {

    let data = { contentPageId, culture }
    let url = UrlBuilder(GET_REGISTER_CONTENT_PAGE, data);

    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/html',
        'Content-Type': 'application/json'
      }),
      responseType: 'text'
    }

    return this.http.get(url, httpOptions).pipe(map((response: any) => {
      return response;
    }));
  }

  resetPassword(data): Observable<any> {
    let url = RESET_PASSWORD;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }
  verifyLink(data): Observable<any> {
    let url = VERIFY_LINK;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }

  getSelfRegistrationStatus(): Observable<any> {
    let url = GET_SELF_REGISTRATION_STATUS;
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  selfRegistrationConfig(): Observable<any> {
    let url = GET_SELF_REGISTRATION_CONFIG;
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  //This function no longer requried.
  // getUpdatedUserDetails(userId: any): Observable<any> {
  //   let data = { userId }
  //   let url = UrlBuilder(GET_USER_DETAILS_BYUSERID, data);
  //   return this.http.get(url).pipe(map((response: any) => {
  //     return response;
  //   }));
  // }
  selfRegistration(configs, locale): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('companyid', configs.companyId)
      .set('formid', configs.userRegisterFormId)
      .set('nexus-environment-key', environment.environmentKey)
      .set('regid', configs.regId)
      .set('orgcode', configs.orgCode)
      .set('locale', locale)

    return this.httpClient.get(configs.baseUrl, { headers: headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  //Account Activation with selfregistration
  accountActivationSelfRegistration(configs, userId: string, locale): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('companyid', configs.companyId)
      .set('formid', configs.activationFormId)
      .set('nexus-environment-key', environment.environmentKey)
      .set('regid', configs.regId)
      .set('orgcode', configs.orgCode)
      .set('editForm', 'true')
      .set('personId', userId)
      .set('locale', locale)

    return this.httpClient.get(configs.baseUrl, { headers: headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  saveUserLoginActivityResource(data): Observable<any> {
    let url = SAVE_USER_LOGIN_ACTIVITY_RESOURCE;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }

  getPolicyContentPage(policyType: string, culture: string) {
    let data = { policyType, culture };
    let url = UrlBuilder(GET_PRELOGIN_POLICY_PAGE_CONTENT, data);

    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/html',
        'Content-Type': 'application/json'
      }),
      responseType: 'text'
    }

    return this.http.get(url, httpOptions).pipe(map((response: any) => {
      return response;
    }));
  }



  getCountries(country): Promise<any> {
    let url = GET_COUNTRIES;
    return firstValueFrom(this.http.post(url, country));
  }

  getStates(states): Promise<any> {
    let url = GET_STATES;
    return firstValueFrom(this.http.post(url, states));
  }

  getCities(cities): Promise<any> {
    let url = GET_CITIES;
    return firstValueFrom(this.http.post(url, cities));
  }

  getStatuses(status): Observable<any> {
    let url = GET_STATUSES;
    return this.http.post(url, status).pipe(map((response: any) => {
      return response;
    }));
  }
}
