import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GET_POLICY_PAGE_CONTENT, UrlBuilder } from "@app/core/core-api-variables";
import { HttpService } from "@app/core/shared";
import { ParamsBuilderService } from "@app/core/shared/services/params-builder.service";
import { map } from "rxjs/operators";

@Injectable()

export class LegalPagesServices {

    constructor(public http: HttpService, public paramsBuilderService: ParamsBuilderService) { }

    getPolicyContentPage(policyType: string, culture: string) {
        let data = { policyType, culture };
        let url = UrlBuilder(GET_POLICY_PAGE_CONTENT, data);

        let httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'text/html',
                'Content-Type': 'application/json'
            }),
            responseType: 'text'
        }

        return this.http.get(url, httpOptions).pipe(map((response: any) => {
            return response;
        }));
    }
}