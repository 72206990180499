import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InvoiceClaimService } from '../../services/invoice-claim.service';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from '@app/core/shared';

@UntilDestroy()
@Component({
  selector: 'modal-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() productMasterId: string = "";
  @Input() claimFormId: string = "";
  tableData: Array<object> = [];
  public faDelete = faTrashAlt;
  public faCheck = faPlus;
  public ErrMessage: string = "";
  filterForm: FormGroup;
  productNameSearchSettings: IDropdownSettings = {};
  products: Array<string> = [];
  duplicateAttributes: Array<string> = [];
  form = this.fb.group({
    selectProduct: new FormControl({ value: "" ,disable: false}),
    params: this.fb.array([])
  });

  constructor(private fb: FormBuilder, private invoiceClaimService: InvoiceClaimService,
    public modal: NgbModal, public activeModal: NgbActiveModal, public loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.loadingService.doLoading(this.invoiceClaimService.getProductsHeader(this.productMasterId, this.claimFormId), this)
      .pipe(untilDestroyed(this)).subscribe({
        next: (response) => {
          this.products = response;
        }
      });
    this.selectProduct.valueChanges.subscribe(x => {
      if (x.length > 0) {
        this.ErrMessage = "";
      }
      else {
        this.ErrMessage = "SearchInvoice.WarningMessage";
      }
      this.addParams(x);
    });
  }
  get selectProduct(): AbstractControl {
    return this.form.get('selectProduct');
  }
  get params() {
    return this.form.controls["params"] as FormArray;
  }

  addParams(valArray: Array<string>) {
    for (var value of valArray) {
      if (this.duplicateAttributes.indexOf(value) == -1) {
        this.duplicateAttributes.push(value);
        const paramsForm = this.fb.group({
          key: [value, Validators.required],
          value: ["", Validators.required]
        });
        paramsForm.updateValueAndValidity();
        this.params.push(paramsForm);
        this.ErrMessage = "";
      }
    }
    this.removeAttributes();
  }

  removeAttributes() {
    var attributesToRemove = this.duplicateAttributes.filter(val => !this.selectProduct.value.includes(val));
    for (var value of attributesToRemove) {
      const index = this.duplicateAttributes.indexOf(value);
      this.duplicateAttributes.splice(index, 1);
      this.params.removeAt(index);
    }
    if (this.duplicateAttributes.length == 0) {
      this.ErrMessage = "SearchInvoice.WarningMessage";
    }
  }

  addProduct(item: object) {
    this.activeModal.close(item);
  }

  submit() {
    if (this.form.valid  && this.form?.value?.params?.length > 0) {
      this.ErrMessage = "";
      const payload = {
        "productMasterId": this.productMasterId,
        "claimConfigurationId": this.claimFormId,
        "attributes": {}
      }
      this.form.value.params.forEach((element : any) => {
        payload.attributes[element.key] = element.value
      });

      this.loadingService.doLoading(this.invoiceClaimService.getProductByAttributes(payload), this)
        .pipe(untilDestroyed(this)).subscribe({
          next: (response) => {
            this.tableData = response;
          }
        });
    }
    else {
      if (this.form?.value?.params?.length > 0)
        this.ErrMessage = "SearchInvoice.ErrMessageMandatory";
      else
        this.ErrMessage = "SearchInvoice.WarningMessage";
    }
  }
}
