import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterEvent, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { SystemSettings } from '@core/models';
import { Menu, MenuService } from '@core/private/_layout/menu/menu.service';
import { browserRefresh } from '@app/app.component';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-tab-view',
    templateUrl: 'tabview.component.html',
    styleUrls: ['tabview.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class TabViewComponent implements OnInit, OnDestroy {
    menuId: string;
    selectedCulture: string;
    defaultCulture: string;
    subMenu: Menu[] = [];
    currentUrl: string;
    browserRefresh: boolean;
    navigationStartSubscription: Subscription;
    languageChangeSubscription: Subscription;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private menuService: MenuService, private titleService: Title,
        private systemSettings: SystemSettings, private translateService: TranslateService) {
        this.navigationStartSubscription = this.router.events.pipe(
            filter((event) => event instanceof RouterEvent ),
            startWith('Initial call')
        ).subscribe((res : NavigationEnd) => {
            if (this.menuId != this.activatedRoute.snapshot.params.menuId) {
                this.currentUrl = this.activatedRoute.snapshot.firstChild.url.map(u => u.path).join('/');
                this.browserRefresh = browserRefresh;
                this.menuId = this.activatedRoute.snapshot.params.menuId;
                this.getMenu();
            }
        });

        this.languageChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            if (!this.browserRefresh)
                this.getMenu();
            else
                this.browserRefresh = false;
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
                this.getSubmenuClass();
            }
        });

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute.snapshot),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            })).subscribe((route: ActivatedRouteSnapshot) => {
                this.translateService.get(route.data.title).subscribe((res: string) => {
                    this.titleService.setTitle(res);
                });
            });
    }

    ngOnInit() {
        this.subMenu = this.activatedRoute.snapshot.data.subMenu[0].children;
        this.getSubmenuClass();
        this.menuId = this.activatedRoute.snapshot.paramMap.get("menuId");
        this.selectedCulture = localStorage.getItem("culture");
        this.currentUrl = this.activatedRoute.snapshot.firstChild.url.map(u => u.path).join('/');
        this.defaultCulture = this.systemSettings.defaultPlatformLanguage;
    }

    getMenu() {
        this.menuService.getMenubyId(this.menuId).subscribe((response) => {
            this.subMenu = response[0].children.filter(x => !x.displayNone);
            this.getSubmenuClass();
        });
    }

    activeSubmenu(url: string) {
        this.router.navigateByUrl(`multiview/${this.menuId}/${url.toLocaleLowerCase()}`);
    }

    getSubmenuClass() {
        for (let menu of this.subMenu) {
            menu['activeClass'] = this.currentUrl.endsWith(menu['url'].toLocaleLowerCase()) ? 'active' : '';
        }
    }

    ngOnDestroy(): void {
        this.navigationStartSubscription.unsubscribe();
        this.languageChangeSubscription.unsubscribe();
    }
}