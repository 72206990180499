<div [appIsLoading]="loadingService.isLoading(this)">
    <div class="claim-filter-section" *ngIf="goalQuest">

        <p class="gqParagraph" *ngIf="content" [innerHtml]="content | sanitizeHtml"></p>
        <div class="inner-goal-section" *ngIf="goalQuest.goalQuestPromotion.rewards.length>0 && isProgramStarted">
            <div class="gq-goalstatus-container">
                <div class="gq-goalstatus-wrapper" *ngIf="progressStyle">
                    <div class="item">
                        <div class="gq-btn-circle btn-md" [class]="progressStyle.stage1.active"></div>
                        <div class="currentGoalProgress" [class]="progressStyle.stage1.selected">
                            {{'GoalQuestPage.GoalQuestSelection.SelectYourGoal' | translate}} </div>
                    </div>
                    <div class="item">
                        <div class="gq-btn-circle btn-md" [class]="progressStyle.stage2.active"></div>
                        <div class="currentGoalProgress" [class]="progressStyle.stage2.selected">
                            {{'GoalQuestPage.GoalQuestSelection.GoalProgress' |
                            translate}} </div>
                    </div>
                    <div class="item">
                        <div class="gq-btn-circle btn-md" [class]="progressStyle.stage3.active"></div>
                        <div class="currentGoalProgress" [class]="progressStyle.stage3.selected">
                            {{'GoalQuestPage.GoalQuestSelection.GoalResult' |
                            translate}} </div>
                    </div>
                </div>
                <div class="progress-indicator-wrapper">
                    <div class="progress-indicator" [style.width]="progressStyle.width"></div>
                </div>
            </div>
            <cs-shared-goal-selection *ngIf="activeStage == 1" [rewards]="goalQuest.goalQuestPromotion.rewards"
                [selectedGoal]="selectedGoal" [isMinimumQualifierEnabled]="isMinimumQualifierEnabled"
                [minimumQualifier]="goalQuest.minimumQualifier"
                [startDate]="goalQuest.goalQuestPromotion.goalSelectionStartDate"
                [endDate]="goalQuest.goalQuestPromotion.goalSelectionEndDate"
                [isManualReward]="goalQuest.goalQuestPromotion.showDetailedRewards" [culture]="selectedCulture"
                (selectTargetEvent)="selectTarget($event)">
            </cs-shared-goal-selection>
            <cs-shared-goal-progress *ngIf="activeStage == 2" [goalAchieved]="goalQuest.goalQuestPromotion.achievement"
                [selectedGoal]="selectedGoal" [isMinimumQualifierEnabled]="isMinimumQualifierEnabled"
                [minimumQualifier]="goalQuest.minimumQualifier"
                [startDate]="goalQuest.goalQuestPromotion.promotionStartDate"
                [endDate]="goalQuest.goalQuestPromotion.promotionEndDate"
                [isManualReward]="goalQuest.goalQuestPromotion.showDetailedRewards" [culture]="selectedCulture">
            </cs-shared-goal-progress>
            <cs-shared-goal-result *ngIf="activeStage == 3" [goalAchieved]="goalQuest.goalQuestPromotion.achievement"
                [selectedGoal]="selectedGoal" [isMinimumQualifierEnabled]="isMinimumQualifierEnabled"
                [minimumQualifier]="goalQuest.minimumQualifier"
                [isManualReward]="goalQuest.goalQuestPromotion.showDetailedRewards" [culture]="selectedCulture">
            </cs-shared-goal-result>

        </div>
        <div class="inner-goal-section" *ngIf="goalQuest.goalQuestPromotion.rewards.length==0">
            <div class="alert alert-danger">
                {{'GoalQuestPage.NoTargets' | translate}}
            </div>
        </div>
        <div class="inner-goal-section" *ngIf="!isProgramStarted">
            <div class="alert alert-danger">
                {{'GoalQuestPage.ProgramNotYetStarted' | translate}}
            </div>
        </div>
    </div>
</div>