import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpImageService } from '@core/shared/services/http-img.service';

@Component({
  selector: 'cs-http-img',
  templateUrl: 'http-img.component.html',
})
export class HttpImageComponent implements OnInit {
  @Input() httpSrc: string;
  @Input() httpType: string;
  @Input() alternativeText: string;
  @Input() height: number;
  @Input() width: number;
  @Input() cssClass: string;

  imageBlobUrl: any;

  constructor(private imageService: HttpImageService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.height = this.height ? this.height : 100;
    this.width = this.width ? this.width : 150;
    this.getImage();
  }

  getImage(): void {
    this.imageService.getImage(this.httpSrc, this.httpType).subscribe({
      next: (val) => {
        this.createImageFromBlob(val);
      }
    });
  }

  createImageFromBlob(image: Blob) {
    this.imageBlobUrl = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(image)
    );
  }
}
