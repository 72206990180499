import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@core/shared/shared.module';
import { QuestionPageService } from './services/questionpage.service';
import { QuestionPageComponent } from './question-page/question-page.component';
import { QuestionArticleComponent } from './question-article/question-article.component';
import { FaqComponent } from './faq.component';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,        
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],   
    providers: [QuestionPageService], 
    declarations: [        
        FaqComponent,
        QuestionPageComponent,
        QuestionArticleComponent
    ]
})

export class QuestionPageModule { }
