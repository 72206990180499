import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { PointSummaryService } from '../services/pointsummary.service';
import { LoadingService } from '@core/shared';
import { SearchCriteria } from '@core/shared/models/search-criteria';
import { Pagination } from '@core/shared/models/pagination';
import { Sorter } from '@core/shared/models/sorter';

@UntilDestroy()
@Component({
  selector: 'app-points-statement-table-view',
  templateUrl: './points-statement-table-view.component.html',
  styleUrls: ['./points-statement-table-view.component.scss']
})
export class PointsStatementTableViewComponent implements OnChanges {
  @Input() payload;

  paginationDetails: Pagination = new Pagination();
  searchCriteria: SearchCriteria = new SearchCriteria('date');
  sorter: Sorter = new Sorter('date', false);
  pointsStatement: any;
  totalNoOfItems: number = 0;

  constructor(private pointsummaryService: PointSummaryService, private translate: TranslateService, public loadingService: LoadingService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.payload && changes.payload.previousValue != changes.payload.currentValue) {
      this.getPointsStatement();
    }
  }

  getPointsStatement() {
    this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
    this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
    this.searchCriteria.sortField = this.sorter.sortColumn;
    this.searchCriteria.additionalData = this.payload;
    this.searchCriteria.sortAscending = this.sorter.sortDirection;
    this.loadingService.doLoading(this.pointsummaryService.getPointsStatement(this.searchCriteria), this)
      .pipe(untilDestroyed(this)).subscribe(response => {
        if (response.items.length > 0) {
          this.totalNoOfItems = response.totalNumberOfItems;
        }
        else {
          this.totalNoOfItems = 0;
        }
        this.pointsStatement = response.items;
        this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
      });
  }

  onChange(pagination: Pagination) {
    this.paginationDetails = pagination;
    this.getPointsStatement();
  }

  onSort(sortField) {
    this.sorter.sort(sortField);
    this.getPointsStatement();
  }

  //Downloads
  downloadXLS(): void {
    let additionalData = Object.assign({}, this.searchCriteria.additionalData);
    additionalData['dateHeader'] = this.translate.instant('PointsSummaryPages.PointsSummaryTable.Date');
    additionalData['descriptionHeader'] = this.translate.instant('PointsSummaryPages.PointsSummaryTable.Description');
    additionalData['promotionHeader'] = this.translate.instant('PointsSummaryPages.PointsSummaryTable.PromotionName');
    additionalData['pointsDepositedHeader'] = this.translate.instant('PointsSummaryPages.PointsSummaryTable.Points');
    this.searchCriteria.additionalData = additionalData;
    this.pointsummaryService.downloadViewXLS(this.searchCriteria).subscribe((res: any) => {
      let header = res.headers;
      let cdh = header.get('content-disposition');
      let contentType = header.get('content-type');
      let fileName = undefined;
      if (cdh) {
        fileName = cdh.match(/filename="(.+)"/)[1];
      }
      let blob = new Blob([res.body], { type: contentType });

      let downloadLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute("href", url);
      downloadLink.setAttribute("download", fileName + ".xlsx");
      downloadLink.style.visibility = "hidden";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

}
