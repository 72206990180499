import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpHeaders } from '@angular/common/http';

import { HttpService } from '@core/shared';
import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

import {
  UrlBuilder,
  GET_CONTENT_PAGE,
  GET_CONTENT_PAGE_PREVIEW,
  GET_PREBUILT_TEMPLATE_CSS
} from '@core/core-api-variables';

@Injectable()
export class ContentPageService {

  constructor(public http: HttpService, public paramsBuilderService: ParamsBuilderService) { }
  
  getContentPage(contentPageId:string,culture:string): Observable<any> {            

    let data = { contentPageId,culture }
    let url = UrlBuilder(GET_CONTENT_PAGE, data);

    let httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'text/html',
          'Content-Type': 'application/json'
      }),
      responseType: 'text'
    }

    return this.http.get(url,httpOptions).pipe(map((response: any) => {
      return response;
    }));
  }  
  getContentPreview(contentPageId:string,culture:string): Observable<any> {            
    let data = { contentPageId,culture}
    let url = UrlBuilder(GET_CONTENT_PAGE_PREVIEW, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  } 

  getPreBuiltTemplateCss():Observable<any>{
    return this.http.get(GET_PREBUILT_TEMPLATE_CSS);
  }
}
