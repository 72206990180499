<div class="grid grid-col-sm-100 grid-col-xs-100 grid-col-hd-25-custom-home-sales-claim grid-gap-35">
    <div></div>
    <div>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 500 438" style="enable-background:new 0 0 500 438;" xml:space="preserve">
            <g>
                <path d="M227.1,427c-12.3-4.7-16.5-13.9-16.2-26.7c0.5-25.8,0.1-51.6,0.2-77.4c0-2.1-0.4-4.8,0.7-6.3c1.7-2.2,4.6-4.9,6.8-4.7
		c2.2,0.1,4.6,3.2,6.2,5.5c0.8,1.2,0.3,3.4,0.3,5.2c0,26.6,0,53.1,0,79.7c0,8.8,1.9,10.7,10.6,10.7c75.8,0,151.6,0,227.4,0
		c8.7,0,10.4-1.7,10.4-10.1c0-51.4,0-102.7,0-154.1c0-1.7-0.2-3.4-0.3-5.7c-2.4,0-4.5,0-6.6,0c-65.8,0-131.7,0-197.5-0.1
		c-4,0-6.9,1-9.8,3.9c-58.2,57.9-116.4,115.6-174.7,173.3c-2.6,2.6-5.7,4.5-8.6,6.8c-2.7,0-5.5,0-8.2,0c-3.5-3-7.3-5.8-10.6-9
		c-10.5-10.2-20.8-20.6-31.2-30.9c-9.7-9.7-9.8-16.1-0.2-25.7c7-7,14-13.9,21-20.9c-7.4-7.4-14.1-14.5-21.2-21.2
		c-6.1-5.7-11.2-11.8-13-20.2c0-1.6,0-3.1,0-4.7c2.3-14.8,11.8-21.7,25.7-24.7c13.5-2.9,26.8-6.8,40.1-10.7c2.8-0.8,5.2-3.4,7.4-5.5
		c4.1-3.8,7.5-8.5,12.1-11.6c3.2-2.2,4.9-4.6,5.8-8.1c3.9-14.9,7.9-29.8,12-44.6c5.7-20.6,28.4-26.9,43.7-11.9
		c8.6,8.5,16.8,17.4,25.8,26.9c8.1-8.5,15.2-16,22.5-23.3c7.9-8,15.4-8,23.3-0.1c11.7,11.5,23.4,22.9,34.8,34.7
		c3.4,3.5,5.6,8.1,9,13.2c5.7,0,13.1,0,21.6,0c-1.5-1.7-2.4-2.6-3.2-3.6c-7.7-9.6-3.1-24.4,9-25.1c14-0.8,21.7-6.5,27-19.4
		c4.9-11.8,22.2-11.6,27,0.3c5.2,12.9,13,18.2,26.6,19c12.2,0.7,17,15.4,9.4,25c-0.8,1-1.7,2-3.3,3.8c2.8,0.1,4.8,0.3,6.8,0.3
		c26.9,0,53.9-0.1,80.8,0.2c3.6,0,7.2,2.7,10.8,4.1c0,59.4,0,118.8,0,178.2c-3.1,7-8.3,12-15.2,15.2C390.5,427,308.8,427,227.1,427z
		 M219.4,189.1c-62.2,61.7-124.3,123.3-185.5,184c13.2,13.5,25.8,26.4,38.7,39.5c61.8-61.3,123.8-122.8,185.4-184
		C245,215.3,232.3,202.3,219.4,189.1z M143.8,243.9c10.9-11.1,20.8-21.2,30.7-31.2c-8.3-8.4-16.8-17.6-25.9-26.2
		c-6.7-6.4-16.1-3.5-18.9,5.5c-2.1,6.5-3.7,13.1-5.5,19.7c-2.2,8.2-4.3,16.5-6.5,24.8c4.5,1.1,8.4,2,12.7,3.1
		c2.5-9.2,5-18.2,7.5-27.2c1.7-5.9,5.1-8.5,9.5-6.8c5.1,2,5.3,5.9,3.9,10.7C148.7,225,146.5,233.9,143.8,243.9z M87,299.5
		c-9.4,2.5-18.8,5.1-28.2,7.4c-4.9,1.2-8.9-0.8-9.6-4.9c-0.8-4.7,1.8-7.3,6.1-8.5c7.7-2.1,15.5-4,23.2-6.1c1.9-0.5,3.7-1.2,5.3-1.8
		c-1.2-4.6-2.2-8.3-3.2-12.5c-15.6,4.2-30.9,8-46,12.4C27,287.8,24,297,29.2,302.6c8.7,9.6,18.3,18.5,27.4,27.5
		C66.9,319.7,76.8,309.7,87,299.5z M343.6,186.2c-0.7,0-1.3,0-2,0c-2.3,4.6-5.2,9.1-6.9,14c-2.7,7.8-7.9,10.8-15.8,11.1
		c-5.2,0.2-10.4,1.6-15.6,2.5c-0.3,0.7-0.5,1.4-0.8,2.1c3.1,2.6,6.5,5,9.2,8c3.5,4,7.4,5.5,12.8,5.3c11.7-0.4,23.5-0.8,35.2,0.1
		c11.9,0.9,15.7-8.5,23.8-15.4c-8.7-1.3-15.5-2.6-22.4-3.4c-4.2-0.5-6.7-2.5-8.4-6.2C349.8,198.2,346.7,192.3,343.6,186.2z
		 M129.7,256.8c-6.7-7.9-20.2-8.2-28.6-0.1c-7.7,7.5-8.7,22,0.3,28.9C110.8,276,120.2,266.5,129.7,256.8z" />
                <path d="M340.9,19c2.2,2.7,4.3,5.4,7.1,9c2.7-0.3,7.3-0.9,9.1,4.4c1.7,5.2-1.8,7.8-6,9.8c1.9,4.3,3.6,8.5-1.1,11.7
		c-4.4,3-7.7,0.7-10.8-3.2c-3.2,3.5-6.7,6.5-11.4,3c-4.1-3.1-3.3-7-0.6-11.2c-4.3-2.3-8.7-5.4-5.7-10.6c1.5-2.6,5.8-3.5,9-4.4
		c2.3-2.8,4.6-5.7,6.9-8.5C338.5,19,339.7,19,340.9,19z" />
                <path d="M277.4,93.6c-3.7,15.5-12.5,23.3-28.4,21c0,0.5,0,1-0.1,1.5c2.6,2.6,4.9,5.6,7.8,7.9c5.1,4,6.6,8.7,4.7,14.9
		c-1,3.3-1.1,6.8-2,12.4c12.8-10.9,24.6-10.2,36.9-0.9c-5.4-14.5-3.1-26,10.3-33c-0.1-1.3,0-1.8-0.2-2c-4.2-2.7-6.2-6.3-3-10.7
		c3.2-4.4,7.6-3.2,11.6-1c7.4,4.2,9.8,14.9,3.3,20.5c-8.3,7.1-11,14.2-8.4,25.1c2.4,10.3-9.6,18.9-18.7,13.6
		c-9.5-5.5-17.2-5.5-26.6,0c-9.1,5.2-20.7-2.9-18.7-13c2.1-10.9-0.2-18.3-8.5-25.8c-7.7-7-3.1-20.3,7.3-21.6
		c10.7-1.3,17.2-5.2,21.6-15.5c4.2-9.7,17.8-9.2,23.5-0.2c1.3,2.1,2.4,4.5,3.4,6.8c1.8,4,0.9,7.6-3,9.4c-3.8,1.8-7.3,0.7-9.4-3.2
		C280,98.3,279.1,96.8,277.4,93.6z" />
                <path d="M407.8,124.8c-3.5,3.7-7,6.2-11.4,2.8c-4-3.1-3.3-7.1-0.4-11.1c-4-2.3-9-4.8-6.1-10c1.6-2.8,5.9-4,9.6-5.2
		c1.4-3.9,3.1-8.7,8.6-7.6c3.1,0.6,5.5,4.5,7.7,7.7c3.9,0,8.3,0.1,10.1,5.3c1.8,5.4-2.3,7.6-6.3,9.7c2.6,4.2,3.7,8.3-0.7,11.5
		C414.6,130.9,411.1,128.7,407.8,124.8z" />
                <path d="M442.7,209.9c-1.8-2.6-4.7-5-5.1-7.7c-0.6-4.2,2.6-7,6.9-6.9c4.2,0.1,7.4,2.9,6.4,7.2c-0.6,2.8-3.6,5-5.5,7.4
		C444.5,210,443.6,210,442.7,209.9z" />
                <path
                    d="M445,52.3c6.4,0.4,9,2.8,9,7.1c-0.1,4-2.5,6.6-6.7,6.6c-4.4,0-7.4-2.6-6.7-6.8C441,56.4,443.8,54,445,52.3z" />
                <path
                    d="M340.1,88.5c-0.5,6.4-2.9,8.9-7.2,8.8c-4.1-0.1-6.6-2.7-6.6-6.8c0-4.4,2.8-7.4,7-6.7C336,84.3,338.4,87.3,340.1,88.5z" />
                <path d="M379.9,150.5c-2.5,1.9-4.7,4.8-7.4,5.4c-4.2,0.9-7-1.9-7.2-6.4c-0.2-4.5,2.7-7.4,6.8-6.9c2.8,0.4,5.2,3.3,7.8,5.1
		C379.9,148.7,379.9,149.6,379.9,150.5z" />
            </g>
        </svg>
    </div>
    <div>
        <p class="userInfo">{{'Shared.Hey' | translate}} {{currentUser}}</p>
        <p>{{'SalesClaim.SalesClaimMsg' | translate}}</p>
        <div class="btn-group mt-5">
            <button type="button" class="btn btn-primary btn-WhiteBg-BrandColor" (click)="redirectTo()">
                <strong>{{'SalesClaim.ClaimNow' | translate}}</strong>
                <span class="glyphicon glyphicon-menu-right pl-1"></span>
            </button>
        </div>
    </div>
    <div></div>
</div>