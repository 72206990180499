import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as fromStore from '@core/store';
import * as fromThemeAction from '@core/store/actions/theme.action';

import { style, animate, transition, trigger } from '@angular/animations';

@Component({
  selector: 'cs-custom-theme',
  templateUrl: 'theme.component.html',
  styleUrls: ['theme.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ width: '0', opacity: -1 }),
        animate(300, style({ width: '*', opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({ width: 0, opacity: -1 }))
      ])
    ])
  ]
})
export class ThemeComponent {
  @Input() theme: string;
  @Input() radius: string;
  @Input() isFullView: boolean;

  isAuthenticated: boolean;
  title;
  isShown: boolean = false;
  isShownDrawer: boolean = false;
  private onDestroy$ = new Subject<void>();

  constructor(private store: Store<fromStore.PlatformState>) {
    this.store.select(fromStore.themeState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
      this.isFullView = state.isExpand;
    });

    this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
      this.isAuthenticated = state.status;
    });

  }

  toggleView() {
    this.store.dispatch({ type: fromThemeAction.TOGGLE_PANEL, payload: !this.isFullView });
  }
  
  changeTheme(theme) {
    this.store.dispatch({ type: fromThemeAction.UPDATE_THEME, payload: theme });
  }

  changeRadius(radius) {
    this.store.dispatch({ type: fromThemeAction.UPDATE_RADIUS, payload: radius });
  }

  toggleShow() {
    this.isShown = !this.isShown;
  }

  toggleDrawer() {
    this.isShownDrawer = !this.isShownDrawer;
  }
}