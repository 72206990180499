import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class MessageCentreService {
    constructor(public http: HttpClient) { }

    getAllUnReadNotifications(): Observable<any> {
        return this.http.get(`${environment.baseUrl + environment.version}notifications/messagecentre/unread`)
            .pipe(
                map((response: any) => {
                    return response;
                })
            )
    }
}