<!-- <div class="d-flex mb-4 content">
    <div class="col-md-6">
        <div class="form-group">
            <label for="">File</label>
            <cs-file-picker [isDisabled]="!uploadButtonEnabled" [uploadStatus]="uploadStatus"
                (changeFileEvent)="changeFile($event)">
            </cs-file-picker>
        </div>
    </div>
    <div class="col-md-4 mt-2">
        <div class="btn-group mt-4">
            <button class="btn-primary" (click)="uploadFile()" type="button"
                [disabled]="!uploadButtonEnabled">Upload</button>
        </div>
    </div>
</div> -->

<div class="file-upload">
    <label>{{header}}</label>
<button type="button" class="btn btn-outline-secondary mytooltip"
    [ngbPopover]="popoverContent"
    triggers="mouseenter:mouseleave">
</button><br>
    <div class="file-select">
       <!--  <div id="noFile"></div> -->
        <label for="chooseFile"  class="file-select-name"></label>
        <label class="uploaded-file-name">{{fileName}}</label>
        <input #fileUpload type="file" name="chooseFile" (change)="changeFile($event.target.files)" accept=".csv" single
            [disabled]="!uploadButtonEnabled" name="chooseFile" id="chooseFile"/>
        <!-- <input type="file" name="chooseFile" id="chooseFile" /> -->
        <button type="button" class="file-select-button" (click)="uploadFile()" [disabled]="!uploadButtonEnabled"> {{'CouponClaimPage.BtnUpload' | translate}}</button>
    </div>
</div>
<small>only (.csv) files can be uploaded</small>

<div class="upload-section-main" *ngIf="false">
    <div class="upload-status-icon"></div>
    <div class="progress-section">
        <div class="upload-label"><label class="fileName">{{this.file.name}}</label><label
                class="fileSize">43.5k</label><span class="removebtn"></span></div>
        <div class="upload-progress">
            <div class="progrss-front"></div>
        </div>
        <div class="upload-percentage"><label>80</label>% <label>Done</label></div>
    </div>
</div>