import { Component, OnDestroy, OnInit } from '@angular/core';
import { WindowReferenceService, ICustomWindow, LoadingService } from '@core/shared';
import { PreloginService } from '@core/prelogin/services/prelogin.service';
import { Subscription } from 'rxjs';
import { browserRefresh } from '@app/app.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';
import { HttpImageService } from '@core/shared/services/http-img.service';


let windowObject = window as any;

@Component({
  selector: 'app-account-register',
  templateUrl: './account-register.component.html',
  styleUrls: ['./account-register.component.scss']
})
export class AccountRegisterComponent implements OnInit, OnDestroy {
  private _window: ICustomWindow;
  lstcomments: any;
  contentPageId: string;
  contentPageDetails: any;
  showLoginPageLink: boolean = false;
  selectedCulture: string;
  languageChangeSubscription: Subscription;
  navigationStartSubscription: Subscription;
  browserRefresh: boolean;
  constructor(public preloginService: PreloginService, public windowRef: WindowReferenceService,
    private translateService: TranslateService,
    private router: Router, public loadingService: LoadingService,
    private imgService: HttpImageService) {
    this._window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    windowObject.parent.preRegistrationCallBack = function (userDetails): Promise<any> {
      let success = {
        "success": true,
        "errorCode": "",
        "responseMessage": "",
        "customAttributes": {
          "CreateOrganisation": "{\"Name\":\"{{Your Organisation Name}}\",\"OrganisationCode\":\"{{Your Organisation Code}}\",\"Online\":true,\"HierarchyGroupId\":\"21058A49-6BF2-4163-8847-AC0600724F64\",\"Address1\":\"Line1\",\"Address2\":\"Line2\",\"Address3\":\"Line3\",\"Town\":\"Agra\",\"County\":\"IND\",\"PostCode\":\"451231\",\"CountryIsoCode\":\"IND\",\"Phone\":\"1543215432\",\"Email\":\"<Email Address>\",\"Attributes\":[{\"Name\":\"Attr1\",\"ValueAsString\":\"ValueAsString1\",\"DataType\":\"Text\",\"SortOrder\":\"1\"},{\"Name\":\"Attr2\",\"ValueAsString\":\"ValueAsString2\",\"DataType\":\"Text\",\"SortOrder\":\"2\"}],\"HierarchyGroupIds\":[\"567a3749-3cdd-415c-b9c8-ac8b0060fed5\"]}"
        }
      };
      return Promise.resolve(success);
    };

    windowObject.parent.addressCallback = async (val, data) => {
    
      let details = {
        "localeCode": this.selectedCulture,
        "code": data
      };
      switch (val) {
        case "COUNTRY":
          let countryPromise = await this.preloginService.getCountries(details);
          return countryPromise.country;
        case "STATE":
          let statesPromise = await this.preloginService.getStates(details);
          return statesPromise.state;
        case "CITY":
          let cityPromise = await this.preloginService.getCities(details);
          return cityPromise.city;        
        default:
          return Promise.reject();
      }
    }
  
    windowObject.parent.postRegistrationCallBack = (userDetails, message) => {
      this.showLoginPageLink = true;
    };

    this.navigationStartSubscription = this.router.events.pipe(
      filter((e) => e instanceof RouterEvent),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      this.browserRefresh = browserRefresh;
      this.getConfig();
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh) {
        window.location.reload();
        this.getContentPage(this.contentPageId);
      }
      else
        this.browserRefresh = false;
    });
  }

  getContentPage(contentPageId) {    
    this.selectedCulture = localStorage.getItem('culture');
    this.loadingService.doLoading(this.preloginService.getRegisterContentPage(contentPageId, this.selectedCulture), this)
      .subscribe(response => {
        this.contentPageDetails = this.imgService.parseHtml(response);
        setTimeout(() => {
          this.fetchBlobImages();
        });
      }); 
  }
    
  fetchBlobImages() {
    let images = document.querySelectorAll('img[data-src]');
    for(let image of Array.from(images)){
      let dataSrc = image.getAttribute('data-src');
      this.imgService.getImage(dataSrc).subscribe(val => {
        let type = val.type;
        let reader = new FileReader();
        reader.readAsDataURL(val);
        reader.onloadend = function () {
          let baseImage = <string>reader.result;
          image.setAttribute('src', `data:${type};base64,` + baseImage.split(',')[1]);
        }
      });
    }
  }

  getConfig() {
    this.selectedCulture = localStorage.getItem('culture');
    this.preloginService.selfRegistrationConfig().subscribe(data => {
      this.renderForm(data);
      this.contentPageId = data.contentUserRegisterFormId;
      this.getContentPage(this.contentPageId);
    })
  }

  renderForm(configs) {
    this.preloginService.selfRegistration(configs, this.selectedCulture).subscribe(data => {
      this._window.__selfRegistrationAppData__ = data;
      this._window.loadSelfRegistrationInDom('selfRegistrationRoot');
    });
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }

  isShownDivMaterial: boolean = false; // hidden by default
  toggleShowsMaterial() {
    this.isShownDivMaterial = !this.isShownDivMaterial;
  }
}
