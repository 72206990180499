import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { faClock } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'cs-view-notification-modal',
    templateUrl: './view-notification.component.html',
    styleUrls: ['./view-notification.component.scss']
})
export class ViewNotificationModalComponent {
    public faClock = faClock;
    constructor(private activeModal: NgbActiveModal) {

    }
    @Input() public notification;

    cancel() {
        this.activeModal.dismiss();
    }
}
