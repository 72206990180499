import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as fromThemeAction from '@core/store/actions/theme.action';

@Component({
    selector: 'cs-page-header',
    templateUrl: 'page-header.component.html',
    styleUrls: ['page-header.component.scss'],
    styles: [`h1{ display: inline-block; }`]
})

export class PageHeaderComponent {    
    @Input() title;
    @Input() isFullView: boolean;    

    constructor(private store: Store<fromStore.PlatformState>) { }

    toggleView() {
        this.store.dispatch({ type: fromThemeAction.TOGGLE_PANEL, payload: !this.isFullView });
    }      

}