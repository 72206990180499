import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@core/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CatalogueaccessComponent } from './catalogueaccess.component';

@NgModule({
    declarations: [CatalogueaccessComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
    ],
    imports: [
        CommonModule,
        TranslateModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ]
})
export class CatalogueAccessModule { }
