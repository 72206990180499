import { Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { LoadingService } from '@core/shared';
import { HubPageService } from '@core/private/hubpage/services/hub-page.service';
import { HttpImageService } from '@core/shared/services/http-img.service';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import { Subject } from 'rxjs';
import { PreBuiltTemplateStyleManagerService } from '@app/core/shared/services/prebuilt-template-style-manager';

@UntilDestroy()
@Component({
    selector: 'app-hub-cms',
    template: `<div class="content-page content-wrap" [ngClass]="{'custom-content-page': tierName}" [appIsLoading]="loadingService.isLoading(this, 'cms')">
      <a href="{{payload.redirectionLink}}" target="_blank"> <div *ngIf="content" [innerHtml]="content | sanitizeHtml"></div> </a>
    </div>`,
    styleUrls: ['./cms.component.scss']
})

export class CmsComponent implements OnChanges {
    @Input() componentId: string;
    @Input() payload: any;
    @Input() culture: string;
    content;
    style:any;
    private onDestroy$ = new Subject<void>();
    tierName = "";

    constructor(private hubPageService: HubPageService, public loadingService: LoadingService, private imgService: HttpImageService,private prebuiltTemplateService: PreBuiltTemplateStyleManagerService,
        private store: Store<fromStore.PlatformState>) { }

    ngOnChanges() {
        this.getPreBuiltTemplateCss();
        this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            if (state.user) {
                this.tierName = state.user.tier ? state.user.tier : "";
            }
        });
        let data = { item: JSON.stringify(this.payload) };
        this.loadingService.doLoading(this.hubPageService.getHubComponent(this.componentId, this.culture, data), this, "cms")
            .pipe(untilDestroyed(this)).subscribe(response => {
                if (response) {
                    this.content = this.imgService.parseHtml(response.cmsContent);
                    setTimeout(() => {
                        this.fetchBlobImages();
                    });
                }
            });
    }

    fetchBlobImages() {
        let images: any = document.querySelectorAll('img[data-src]');
        for (let image of images) {
            let dataSrc = image.getAttribute('data-src');
            this.imgService.getImage(dataSrc).subscribe(val => {
                let type = val.type;
                var reader = new FileReader();
                reader.readAsDataURL(val);
                reader.onloadend = function () {
                    let baseImage = <string>reader.result;
                    image.setAttribute('src', `data:${type};base64,` + baseImage.split(',')[1]);
                }
            });
        }
    }
    getPreBuiltTemplateCss() {
        this.hubPageService.getPreBuiltTemplateCss().subscribe((res: any) =>{
          if(res != null){
            this.style = res.map(x => x.css).join(); 
            this.prebuiltTemplateService.setStyle(this.componentId,  this.style);
          }
        }
        )}
}
