import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbDatepickerModule, NgbTypeaheadModule, NgbPaginationModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@core/shared/shared.module';
import { InvoiceClaimService } from './services/invoice-claim.service';
import { InvoiceClaimHistoryComponent } from './invoice-claim-history/invoice-claim-history.component';
import { InvoiceClaimSubmissionComponent } from './invoice-claim-submission/invoice-claim-submission.component';
import { InvoiceClaimApprovalComponent } from './invoice-claim-approval/invoice-claim-approval.component'
import { InvoiceChallengeModalComponent } from './invoice-claim-modal/challenge/invoice-challenge.component'
import { InvoiceFilesModalComponent } from './invoice-claim-modal/invoice-files/invoice-files.component'
import { InvoiceRejectClaimComponent } from './invoice-claim-modal/reject-claim/reject-claim.component'
import { InvoiceComponent } from './invoice.component'
import { InvoiceClaimResubmissionComponent } from './invoice-claim-resubmission/invoice-claim-resubmission.component';
import { SearchComponent } from './invoice-claim-modal/search/search.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    InvoiceClaimSubmissionComponent,
    InvoiceClaimApprovalComponent,
    InvoiceClaimHistoryComponent,
    InvoiceChallengeModalComponent,
    InvoiceFilesModalComponent,
    InvoiceRejectClaimComponent,
    SearchComponent,
    InvoiceClaimResubmissionComponent,
    InvoiceComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [InvoiceClaimService],
  imports: [
    CommonModule,
    NgbDatepickerModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    SharedModule,
    NgbModule,
    TranslateModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    FontAwesomeModule,
    NgbAccordionModule,
    NgMultiSelectDropDownModule.forRoot()
  ]
})
export class InvoiceclaimModule { }
