<ng-container>
    <div class="content-width security-layout">
<div  [appIsLoading]="loadingService.isLoading(this)">
    <form [formGroup]="formSecurityQuestion" class="common-prelogin-form round-card shadowed bg-color2"
        (ngSubmit)="save(formSecurityQuestion)">
        <form-input controlName="type" inputType="hidden"></form-input>
        <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.SecurityQuestion' | translate }}<span class="mandatory">*</span>
            </body-text>
            <form-button-select [value]="formSecurityQuestion.get('securityQuestion').value" theme="theme2"
                [options]="securityQuestion" placeHolder="{{ 'Shared.SelectLists.PleaseSelect' | translate}}"
                optionsLabelKey="label" optionstrackbyKey="value" [translateLabel]="true"
                (onChange)="securityQuestionChange($event)">
            </form-button-select>
            <div class="help-block text-danger"
                *ngIf="!formSecurityQuestion.controls.securityQuestion.valid && (formSecurityQuestion.controls.securityQuestion.touched || submitted)">
                <span [hidden]="!formSecurityQuestion.controls['securityQuestion'].hasError('required')">*
                    Security question is required.</span>
            </div>
        </div>

        <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.SecurityAnswer' | translate }}<span class="mandatory">*</span>
            </body-text>
            <form-input controlName="securityAnswer" theme="theme2"
                placeHolder="{{'ParticipantActivation.SecurityAnswer' | translate}}">
            </form-input>
            <div class="help-block text-danger"
                *ngIf="!formSecurityQuestion.controls.securityAnswer.valid && (formSecurityQuestion.controls.securityAnswer.touched || submitted)">
                <span [hidden]="!formSecurityQuestion.controls['securityAnswer'].hasError('required')"
                    translate="ParticipantActivation.SecurityAnswerRequired"></span>
            </div>
        </div>

        <div class="margin-bottom-10">                    
            <div class="margin-top-30">
                <form-button text="{{ 'ParticipantActivation.Submit' | translate }}" theme="theme5" size="stretch">
                </form-button>
            </div>
        </div>

    </form>
</div>
</div>
</ng-container>