<div>
    <!--   <h1 class="pageHeader">Power BI Report</h1> -->
    <div class="claim-filter-section">
        <div class="bottomMargin">
            <div class="container massageArticle">
                <div *ngIf="messageArticle">
                    <p></p>
                    <h1>{{messageArticle.title }} <small>{{ messageArticle.articleDate | localizedDate }}</small></h1>
                    <div class="massageArticleSection" [innerHTML]="messageArticleContent | sanitizeHtml"></div>
                </div>
            </div>
        </div>
    </div>
</div>