import { Component, Input } from '@angular/core';

@Component({
    selector: 'cs-dashboard-svg',
    templateUrl: 'dashboard-svg.component.html'
})

export class DashboardSvgComponent  { 
    @Input() tabName: any;    
    tabNameList = ['league','claimform', 'gamearcade', 'goalquest', 'promotion', 'message']; // Add the type in this list, when it has svg and present in 'dashboard-svg.component.html'    
}