import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpService } from '@core/shared';
import { map } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from '@env/environment';

import {
  UrlBuilder,
  CHANGE_PASSWORD,
  NOTIFICATION_PREFERENCE,
  NOTIFICATION_PREFERENCE_SUBMIT,
  UPDATE_USER,
  UPDATE_PROFILE_IMAGE,
  GET_PROFILE,
  GET_SELF_REGISTRATION_STATUS,
  GET_SELF_REGISTRATION_CONFIG,
  CHANGE_SECURITY_QUESTION,
  GET_SECURITY_QUESTION,
  GET_CONTENT_PAGE,
  GET_IS_PAN_MODIFY_ALLOWED,
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES
} from '@core/core-api-variables';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  constructor(public http: HttpService, public httpClient: HttpClient) { }

  changepassword(data): Observable<any> {
    let url = CHANGE_PASSWORD;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }
  notificationpreference(): Observable<object> {
    let url = NOTIFICATION_PREFERENCE;
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  updateNotificationpreference(data): Observable<any> {
    let url = NOTIFICATION_PREFERENCE_SUBMIT;
    return this.http.put(url, data).pipe(map((response: any) => {
      return response;
    }));
  }
  getContentPage(contentPageId: string, culture: string): Observable<any> {

    let data = { contentPageId, culture }
    let url = UrlBuilder(GET_CONTENT_PAGE, data);

    let httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/html',
        'Content-Type': 'application/json'
      }),
      responseType: 'text'
    }

    return this.http.get(url, httpOptions).pipe(map((response: any) => {
      return response;
    }));
  }
  updateUser(data): Observable<any> {
    let url = UPDATE_USER;
    return this.http.put(url, data).pipe(map((response: any) => {
      return response;
    }));
  }
  updateProfileImage(data): Observable<any> {
    let url = UPDATE_PROFILE_IMAGE;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }
  getProfile() {
    let url = GET_PROFILE;
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getCountries(country): Promise<any> {
    let url = GET_COUNTRIES;
    return firstValueFrom(this.http.post(url, country));
  }

  getStates(states): Promise<any> {
    let url = GET_STATES;
    return firstValueFrom(this.http.post(url, states));
  }

  getCities(cities): Promise<any> {
    let url = GET_CITIES;
    return firstValueFrom(this.http.post(url, cities));
  }
  
  getSecurityQuestion(id): Observable<object> {
    const data = { id };
    const url = UrlBuilder(GET_SECURITY_QUESTION, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  //self registration

  selfRegistrationStatus(): Observable<any> {
    let url = GET_SELF_REGISTRATION_STATUS;
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  selfRegistrationConfig(): Observable<any> {
    let url = GET_SELF_REGISTRATION_CONFIG;
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  editProfileSelfRegistration(configs, userId, locale): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.set('companyid', configs.companyId)
      .set('formid', configs.userProfileFormId)
      .set('nexus-environment-key', environment.environmentKey)
      .set('regid', configs.regId)
      .set('orgcode', configs.orgCode)
      .set('editForm', 'true')
      .set('personId', userId)
      .set('locale', locale)

    return this.httpClient.get(configs.baseUrl, { headers: headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  changeSecurityQuestion(data): Observable<any> {
    let url = CHANGE_SECURITY_QUESTION;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }

  isPanModifyAllowed(): Observable<any>  {
    let url = GET_IS_PAN_MODIFY_ALLOWED;
    return this.http.get(url).pipe(map((response: any) => {
        return response;
    }));
}
}
