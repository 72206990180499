import { Injectable } from '@angular/core';
import { DownloadService, HttpService } from '@core/shared';
import { map } from 'rxjs/operators';
import { SearchCriteria } from '@core/shared/models/search-criteria';
import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

import {
  UrlBuilder, GET_INVOICE_CLAIM_HISTORY, GET_INVOICES_FOR_APPROVAL, GET_INVOICE_CLAIM_FIELDS, GET_INVOICE_PRODUCTS, INVOICE_SUBMISSION
  , INVOICE_FILE_UPLOAD, DOWNLOAD_INVOICE_CLAIM_HISTORY, DOWNLOAD_INVOICE_APPROVAL, GET_INVOICECLAIM_UPLOAD, UPDATE_INVOICE_STATUS
  , GET_INVOICE_CLAIM_ULOADED_FILES, DOWNLOAD_INVOICE_FILES, GET_INVOICE_DETAILS, INVOICE_RESUBMISSION, INVOICE_FILE_REUPLOAD, DOWNLOAD_COUPON_UPLOADED_FILE, GET_INVOICE_PARENT_CHILD_CLAIM_HISTORY, GET_INVOICE_SUBMISSION_PRODUCTS, GET_ELIGIBLE_ORGANISATION, GET_INVOICE_PRODUCTS_HEADER,
  GET_INVOICE_PRODUCTS_BY_ATTRIBUTES
} from '@core/core-api-variables';

@Injectable()
export class InvoiceClaimService extends DownloadService {

  constructor(public http: HttpService, public paramsBuilderService: ParamsBuilderService) {
    super();
   }

  getInvoiceClaimHistory(searchCriteria: SearchCriteria) {
    let params = this.paramsBuilderService.buildParams(searchCriteria);
    let data = { params };
    let url = UrlBuilder(GET_INVOICE_CLAIM_HISTORY, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getInvoiceParentClaimHistory(searchCriteria: SearchCriteria) {
    let params = this.paramsBuilderService.buildParams(searchCriteria);
    let data = { params };
    let url = UrlBuilder(GET_INVOICE_PARENT_CHILD_CLAIM_HISTORY, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getInvoicesForApproval(searchCriteria: SearchCriteria) {
    let params = this.paramsBuilderService.buildParams(searchCriteria);
    let data = { params };
    let url = UrlBuilder(GET_INVOICES_FOR_APPROVAL, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getInvoiceClaimFields(claimId) {
    let data = { claimId };
    let url = UrlBuilder(GET_INVOICE_CLAIM_FIELDS, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getInvoiceDetails(invoiceId) {
    let data = { invoiceId };
    let url = UrlBuilder(GET_INVOICE_DETAILS, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getProducts(productMasterId, claimFormId) {
    let data = { productMasterId, claimFormId };
    let url = UrlBuilder(GET_INVOICE_PRODUCTS, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getProductsHeader(productMasterId, claimFormId) {
    let data = { productMasterId, claimFormId };
    let url = UrlBuilder(GET_INVOICE_PRODUCTS_HEADER, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getInvoiceSubmissionProducts(productMasterId, claimFormId) {
    let data = { productMasterId, claimFormId };
    let url = UrlBuilder(GET_INVOICE_SUBMISSION_PRODUCTS, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  invoiceSubmission(payload) {

    let url = INVOICE_SUBMISSION;
    return this.http.post(url, payload).pipe(map((response: any) => {
      return response;
    }));
  }

  invoiceReSubmission(payload) {
    let url = INVOICE_RESUBMISSION;
    return this.http.put(url, payload).pipe(map((response: any) => {
      return response;
    }));
  }

  getEligibleOrganisation(claimFormId){
    let data = { claimFormId };
    let url = UrlBuilder(GET_ELIGIBLE_ORGANISATION, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));  
  }
  

  uploadInvoices(invoiceId, files: any[]) {
    const frmData = new FormData();

    for (var i = 0; i < files.length; i++) {
      frmData.append(`file[${i}]`, files[i]);
    }

    let data = { invoiceId };
    let url = UrlBuilder(INVOICE_FILE_UPLOAD, data);
    return this.http.post(url, frmData).pipe(map((response: any) => {
      return response;
    }));
  }

  reuploadInvoices(invoiceId, files: any[], deletedFiles?: string[]) {
    const frmData = new FormData();

    for (let i = 0; i < files.length; i++) {
      frmData.append(`file[${i}]`, files[i]);
    }
    if (deletedFiles && deletedFiles.length > 0) {
      for (let i = 0; i < deletedFiles.length; i++)
        frmData.append(`deleted_${i}`, deletedFiles[i]);
    }

    let data = { invoiceId };
    let url = UrlBuilder(INVOICE_FILE_REUPLOAD, data);
    return this.http.post(url, frmData).pipe(map((response: any) => {
      return response;
    }));
  }

  getInvoiceClaimUploadContext() {

    let url = UrlBuilder(GET_INVOICECLAIM_UPLOAD, '');
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getProductByAttributes(payload) {
    let url = GET_INVOICE_PRODUCTS_BY_ATTRIBUTES;
    return this.http.post(url, payload).pipe(map((response: any) => {
      return response;
    }));
  }

  updateInvoiceStatus(invoiceId, lineItemId, status, message) {
    let url = UPDATE_INVOICE_STATUS;
    let payload = { invoiceId: invoiceId, lineItemId: lineItemId, status: status, message: message };
    return this.http.put(url, payload).pipe(map((response: any) => {
      return response;
    }));
  }

  getinvoiceClaimUloadedFiles(invoiceId) {

    let data = { invoiceId };
    let url = UrlBuilder(GET_INVOICE_CLAIM_ULOADED_FILES, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadViewCSV(searchCriteria: SearchCriteria) {
    let params = this.paramsBuilderService.buildParams(searchCriteria);
    let data = { params };
    let url = UrlBuilder(DOWNLOAD_INVOICE_CLAIM_HISTORY, data);

    let httpOptions = {
      responseType: 'arraybuffer', observe: 'response'
    }

    return this.http.get(url, httpOptions).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadInvoiceApprovalCSV(searchCriteria: SearchCriteria) {
    let params = this.paramsBuilderService.buildParams(searchCriteria);
    let data = { params };
    let url = UrlBuilder(DOWNLOAD_INVOICE_APPROVAL, data);

    let httpOptions = {
      responseType: 'arraybuffer', observe: 'response'
    }

    return this.http.get(url, httpOptions).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadInvoiceFiles(fileLocation, fileName, filePath, fileType, invoiceId) {
    let data = { fileLocation, fileName, filePath, fileType, invoiceId };
    let url = UrlBuilder(DOWNLOAD_INVOICE_FILES, data);

    let httpOptions = {
      responseType: 'arraybuffer', observe: 'response'
    }

    return this.http.get(url, httpOptions).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadByUploadId(uploadId) {    
    let data = { uploadId };
    let url = UrlBuilder(DOWNLOAD_COUPON_UPLOADED_FILE, data);
    return this.http.download(url).pipe(map((response: any) => {
      this.downloadFile(response, 'InvoiceApproval.csv');
      return true;
    }));
  }
}
