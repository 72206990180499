import { ROUTES } from './menu-routes';

export function createCatalogueMap(catalogueService) {
  return {
    merchandise: {
      verificationPath: ROUTES.VERIFICATION_MERCHANDISE,
      redirectMethod: () => catalogueService.redirectMerchandiseCatalogue(null, null)
    },
    experience: {
      verificationPath: ROUTES.VERIFICATION_EXPERIENCE,
      redirectMethod: () => catalogueService.redirectExperienceCatalogue(null, null)
    },
    mastery: {
      verificationPath: ROUTES.VERIFICATION_MASTERY,
      redirectMethod: () => catalogueService.redirectMasteryCatalogue(null, null)
    }
  };
}