import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@core/shared';
import { LeagueService } from './services/league.service';
import { LeagueComponent } from './league/league.component';
import { ContestComponent } from './contest.component';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        TranslateModule
    ],
    declarations: [
        ContestComponent,
        LeagueComponent        
    ],
    providers: [
        LeagueService
    ]
})
export class LeagueModule { }
