import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "@app/core/startup";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { SharedService } from "../..";
import { MessageService } from "../../services/message.service";
@Component({
  selector: "app-cookie-component",
  templateUrl: "./cookie-component.component.html",
  styleUrls: ["./cookie-component.component.scss"],
})
export class CookieComponentComponent implements OnInit {
  public faAddressCard = faAddressCard;
  selected: boolean = false;
  checked: boolean;
  functional: boolean;
  tracking: boolean = false;
  performance: boolean = false;
  personalization: boolean = false;
  payload: object = {};
  payload1: object = {};
  cookieValue: string = "";
  showPopup: boolean = false;
  modalRef: any;
  isCustomizePopupOpened: boolean = false;
  isCookieExists: boolean = false;
  constructor(
    private modalService: NgbModal,
    private cookieService: CookieService,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private messageService: MessageService,
    private translate: TranslateService
  ) {}
  ngOnInit(): void {
    if (this.authService.checkUser()) {
      this.sharedService.isCookieExists().subscribe((res: any) => {
        if (res) {
          this.showPopup = false;
        } else {
          this.showPopup = true;
        }
      });
    } else {
      this.showPopup = false;
    }
  }
  openModel(content) {
    this.isCustomizePopupOpened = true;
    this.modalRef = this.modalService.open(content, {
      centered: true,
      modalDialogClass: "cookie-modal",
    });
    this.showPopup = false;
  }
  closeModel() {
    this.tracking = false;
    this.performance = false;
    this.personalization = false;
    this.modalRef.close();
    this.showPopup = true;
    this.isCustomizePopupOpened = false;
  }
  toggle() {
    this.selected = this.checked;
  }
  submitCookie(modals) {
    this.payload1 = {
      functional: true,
      tracking: this.tracking,
      performance: this.performance,
      personalization: this.personalization,
    };
    this.sharedService.getCookies().subscribe((res: any) => {
      //if (res && res.AcceptedCookiePolicyDetails == null) {
      if(res == false) {
        this.sharedService
          .setCookies(this.payload1)
          .subscribe((res: any) => {});
      }
      this.showPopup = false;
      this.isCustomizePopupOpened = true;
      this.messageService.showSuccess(
        this.translate.instant("cookiePolicy.savedSuccessfully")
      );
      this.modalRef.close();
    });
  }
  acceptAllcookie() {
    this.payload = {
      functional: true,
      tracking: true,
      performance: true,
      personalization: true,
    };
    this.sharedService.getCookies().subscribe((res: any) => {
      if (res == false) {
        this.sharedService.setCookies(this.payload).subscribe((res: any) => {});
      }
      this.showPopup = false;
      this.messageService.showSuccess(
        this.translate.instant("cookiePolicy.savedSuccessfully")
      );
    });
  }
}
