import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { GoalQuestParticipantComponent } from './participant/goal-quest-participant.component';
import { GoalQuestManagerComponent } from './manager/goal-quest-manager/goal-quest.component';
import { GoalQuestComponent } from './goal-quest.component';


export const GoalQuestRoutes: Routes = [
    {
        path: 'goalquest',
        component: GoalQuestComponent,
        children: [
            {
                path: 'goalquestpage/view/:id',
                canActivate: [AuthGuard],
                component: GoalQuestParticipantComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.GoalQuestPage', isPreLogin: false }
            },
            {
                path: 'managergoalquestpage/view/:id',
                canActivate: [AuthGuard],
                component: GoalQuestManagerComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.GoalQuestPage', isPreLogin: false }
            }
        ]
    }
];