import { NgModule } from "@angular/core";
import { MyThresholdAwardsService } from "./services/my-threshold-awards.service";
import { MyThresholdAwardsComponent } from './my-threshold-awards-component/my-threshold-awards.component';
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@app/core/shared";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { HubPageModule } from "../hubpage";
import { RouterModule } from "@angular/router";
import { ThresholdComponent } from "./threshold.component";

@NgModule({
    imports: [
        RouterModule,
        TranslateModule,
        SharedModule,
        ReactiveFormsModule,
        NgbModule,
        CommonModule,
        HubPageModule
    ],
    declarations: [
        ThresholdComponent,
        MyThresholdAwardsComponent
    ],
    providers: [MyThresholdAwardsService]
})
export class MyThresholdAwardsModule { }