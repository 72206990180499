import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { RegexService } from '@core/startup';
import { PreloginService } from '@core/prelogin/services/prelogin.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/startup/services/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector:'app-expired-password',
    templateUrl:'./expired-password.component.html'
})
export class ExpiredPasswordComponent implements OnInit{
    public username: string;
    public formExpiredPassword:FormGroup;
    public submitted: boolean = false;
    public passwordChangeSuccess: boolean = false;

    constructor(public fb: FormBuilder, public PreloginService: PreloginService, public regexService: RegexService,public route: ActivatedRoute,public authenticationService: AuthenticationService,public router: Router) { }
    ngOnInit(){
        this.route.paramMap.subscribe(params => {
            this.username = params.get('username');
        });
        this.formExpiredPassword = this.fb.group({
            username: this.username,
            oldPassword: ['', [Validators.required]],
            newPassword: [null, [Validators.required, Validators.pattern(this.regexService.passwordRegex)]],
            confirmPassword: [null, [Validators.compose([Validators.required, this.validateAreEqual.bind(this)])]]
        });
    }

    changePassword(formExpiredPassword) {
        this.submitted = true;
        if (formExpiredPassword.valid) {
            this.PreloginService.changepassword(formExpiredPassword.value).subscribe(result => {
                this.passwordChangeSuccess = true;
                this.authenticationService.login(formExpiredPassword.value.username,formExpiredPassword.value.newPassword,'username','password','',false).subscribe(result=>{
                    this.router.navigate([`/home`]);
                });
            });
        }
    }

    validateAreEqual(fieldControl: FormControl) {
        if (!this.formExpiredPassword) {
            return null;
        }
        return fieldControl.value === this.formExpiredPassword.get("newPassword").value ? null : {
            notEqual: true
        };
    }
}