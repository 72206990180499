<div>
    <div class="filter-section-chart">
        <div *ngIf="isPromotionStarted && mytarget.targets">
            <cs-performance-details [achieved]="mytarget.achieved" [targets]="mytarget.targets"
                [endDate]="mytarget.endDate" [units]="mytarget.unit" [minTarget]="minTarget" [maxTarget]="maxTarget"
                [isPromotionStarted]="isPromotionStarted" [isPromotionEnded]="isPromotionEnded"
                [isMultiple]="isMultiple" [startDate]="mytarget.startDate" [isManualReward]="isManualReward">
            </cs-performance-details>
        </div>
        <div *ngIf="mytarget && mytarget.targets.length===0">{{'MyPerformance.NoTargets' | translate}}</div>
    </div>

</div>