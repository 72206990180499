import { Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { LoadingService } from '@core/shared';
import { HubPageService } from '@core/private/hubpage/services/hub-page.service';

@UntilDestroy()
@Component({
    selector: 'app-hub-myaward-page',
    templateUrl: 'my-award.component.html'
})
export class MyAwardComponent implements OnChanges {
    @Input() componentId: string;
    @Input() payload: any;
    @Input() culture: string;

    myawards: any;
    attributeHeaders

    constructor(private hubPageService: HubPageService, public loadingService: LoadingService) { }

    ngOnChanges() {
        let data = { item: JSON.stringify(this.payload) };
        this.loadingService.doLoading(this.hubPageService.getHubComponent(this.componentId, this.culture, data), this, "award")
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.myawards = response;
            });
    }

    setAttributeHeaders() {
        this.attributeHeaders = [];
        if (this.myawards.length > 0) {
            this.myawards[0].attributes.forEach(attribute => {
                this.attributeHeaders.push(attribute.name)
            });
        }
    }
}