import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
    selector: 'form-checkbox',
    templateUrl: './form-checkbox.component.html',
    styleUrls: ['./form-checkbox.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ]
})
export class FormCheckboxComponent implements OnInit {
    @Input() controlName: string;
    @Input() theme: string;
    @Input() placeHolder: string;
    @Input() value: boolean;

    public template: string;

    ngOnInit() {
        this.template = this.theme ? this.theme : 'theme1';
    }
}