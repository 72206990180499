import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { browserRefresh } from '@app/app.component';
import { SystemSettings } from '@core/models';
import { GoalQuestService } from '@core/private/goalquest/services/goal-quest.service';
import { HttpImageService } from '@core/shared/services/http-img.service';
import { LoadingService } from '@core/shared';

@UntilDestroy()
@Component({
  selector: 'app-goal-quest',
  templateUrl: 'goal-quest-participant.component.html',
  styleUrls: ['../scss/goal-quest-participant.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GoalQuestParticipantComponent implements OnInit, OnDestroy {
  goalQuestPageId: string;
  goalQuest: any;
  goalSelectionRemainingTime: number;
  goalProgressRemainingTime: number;
  isMinimumQualifierEnabled: boolean;
  isGoalSelected: boolean;
  selectedGoal: any;
  activeStage: number;
  progressStyle: any;
  content: string;
  browserRefresh: boolean;
  isProgramStarted: boolean;
  selectedCulture: string;

  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;  

  constructor(private activeRoute: ActivatedRoute, private router: Router, private goalQuestService: GoalQuestService,
    private systemSettings: SystemSettings, private translateService: TranslateService, private imgService: HttpImageService,
    public loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent ),      
      startWith('Initial call')      
    ).subscribe((res : NavigationEnd) => {
      if (this.goalQuestPageId != this.activeRoute.snapshot.params.id) {
        this.browserRefresh = browserRefresh;
        this.goalQuestPageId = this.activeRoute.snapshot.params.id;
        this.getGoalQuestPages();
      }
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe(() => {
      if (!this.browserRefresh)
        this.getGoalQuestPages();
      else
        this.browserRefresh = false;
    });
  }

  getGoalQuestPages() {
    this.selectedCulture = localStorage.getItem('culture');
    let defaultCulture = this.systemSettings.defaultPlatformLanguage;

    this.loadingService.doLoading(this.goalQuestService.getGoalQuestPages(this.goalQuestPageId, this.selectedCulture, defaultCulture), this)
    .pipe(untilDestroyed(this)).subscribe(response => {
      this.goalQuest = response;
      this.calculateGoalQuestProgress();
      this.content = this.imgService.parseHtml(response.goalQuestPromotion.htmlContent);
      setTimeout(() => {
        this.fetchBlobImages();
      });
    });   
  }

  calculateGoalQuestProgress() {
    if (this.goalQuest.goalQuestPromotion.rewards.length > 0) {      
      this.isProgramStarted = this.calculateDateDifference(new Date(this.goalQuest.goalQuestPromotion.goalSelectionStartDate), new Date()) > 0;
      this.goalSelectionRemainingTime = this.calculateDateDifference(new Date(), new Date(this.goalQuest.goalQuestPromotion.goalSelectionEndDate))
      this.goalProgressRemainingTime = this.calculateDateDifference(new Date(), new Date(this.goalQuest.goalQuestPromotion.promotionEndDate))
      this.isMinimumQualifierEnabled = this.goalQuest.goalQuestPromotion.enableMinimumQualifier;
      this.getSelectedReward();
      this.findStage();
    }
  }

  calculateDateDifference(startDate, endDate) {
    var startDateUtc = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
    var endDateUtc = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());

    return (endDateUtc - startDateUtc) / 1000;
  }

  getSelectedReward() {
    let selectedReward = this.goalQuest.goalQuestPromotion.rewards.find(reward => reward.selected);
    if (selectedReward)
      this.selectedGoal = selectedReward;
  }

  findStage() {
    this.progressStyle = {
      'stage1': { 'selected': 'selected', 'active': 'inactive' },
      'stage2': { 'selected': '', 'active': 'inactive' },
      'stage3': { 'selected': '', 'active': 'inactive' },
      'width': '0%'
    };

    if (this.goalSelectionRemainingTime > 0) {
      this.activeStage = 1;
      this.progressStyle.stage1.active = 'active';
    }
    else if (this.goalProgressRemainingTime > 0) {
      if (!this.selectedGoal)
        this.selectedGoal = this.goalQuest.goalQuestPromotion.rewards[0];

      this.activeStage = 2;
      this.progressStyle.stage1.active = 'active';
      this.progressStyle.stage2.active = 'active';
      this.progressStyle.stage2.selected = 'selected';
      this.progressStyle.width = '50%';
    }
    else {
      if (!this.selectedGoal)
        this.selectedGoal = this.goalQuest.goalQuestPromotion.rewards[0];

      this.activeStage = 3;
      this.progressStyle.stage1.active = 'active';
      this.progressStyle.stage2.active = 'active';
      this.progressStyle.stage3.active = 'active';
      this.progressStyle.stage2.selected = 'selected';
      this.progressStyle.stage3.selected = 'selected';
      this.progressStyle.width = '100%'
    }
  }

  selectTarget(target) {
    let payload = { goalQuestPageId: this.goalQuestPageId, targetLevel: target };
    this.goalQuestService.changeReward(payload).subscribe(response => {
      this.goalQuest.goalQuestPromotion.rewards.forEach(x => {
        x.selected = x.targetLevel === target ? true : false;
      });
      this.getSelectedReward();
      this.findStage();
    });
  }

  fetchBlobImages() {
    let images = document.querySelectorAll('img[data-src]');
    for (let i = 0; i < images.length; i++) {
      let dataSrc = images[i].getAttribute('data-src');
      this.imgService.getImage(dataSrc).subscribe(val => {
        let type = val.type;
        var reader = new FileReader();
        reader.readAsDataURL(val);
        reader.onloadend = function () {
          let baseImage = <string>reader.result;
          images[i].setAttribute('src', `data:${type};base64,` + baseImage.split(',')[1]);
        }
      });
    }
  }

  ngOnDestroy() {    
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe()
  }
}
