
    <div class="prelogin-body">
        <div class="content-width">
            <div class="expired-password-page">


                <div class="row">
                    <div *ngIf="passwordChangeSuccess" class="mx-auto mt-5">
                        <ngb-alert type="success" (close)="passwordChangeSuccess = false">
                            <strong>{{ 'ExpiredPassword.PasswordChangedSuccessfully.WellDone' | translate }}</strong>
                            {{ 'ExpiredPassword.PasswordChangedSuccessfully.YouSuccessfullyChangedYourPassword' |
                            translate }}
                        </ngb-alert>
                    </div>
                </div>
                <div class="row">
                    <div class="mx-auto">
                        <div class="card card-signin my-5">
                            <div class="card-body">
                                <h4 class="card-title text-left mb-5 sub-header-password">{{ 'ExpiredPassword.Title' |
                                    translate}}</h4>
                                <form [formGroup]="formExpiredPassword" class="form-signin expired-password"
                                    (ngSubmit)="changePassword(formExpiredPassword)">
                                    <div class="form-group row">
                                        <label for="name" class=" " translate="ExpiredPassword.Username"></label>
                                        <div class=" col-sm-12">
                                            <input type="text" value={{username}} class="form-control" disabled>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="oldPassword" class=" "
                                            translate="ExpiredPassword.OldPassword"></label>
                                        <div class=" col-sm-12">
                                            <input type="password" id="oldPassword" class="form-control"
                                                formControlName="oldPassword" />
                                            <div class="help-block text-danger"
                                                *ngIf="!formExpiredPassword.controls.oldPassword.valid && (formExpiredPassword.controls.oldPassword.touched || submitted)">
                                                <span
                                                    [hidden]="!formExpiredPassword.controls['oldPassword'].hasError('required')"
                                                    translate="ExpiredPassword.OldPasswordRequired"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="newPassword" class=" "
                                            translate="ExpiredPassword.NewPassword"></label>
                                        <div class=" col-sm-12">
                                            <input type="password" id="password" class="form-control"
                                                formControlName="newPassword" />
                                            <div class="help-block text-danger"
                                                *ngIf="!formExpiredPassword.controls.newPassword.valid && (formExpiredPassword.controls.newPassword.touched || submitted)">
                                                <span [hidden]="formExpiredPassword.controls.newPassword.valid">
                                                    {{
                                                    'ExpiredPassword.PasswordDoestNotMeetRequirements.PasswordDoesNotMeetMinimumRequirement'
                                                    | translate }}
                                                    <br />
                                                    {{ 'ExpiredPassword.PasswordDoestNotMeetRequirements.Must' |
                                                    translate }}
                                                    <ul>
                                                        <li>
                                                            {{
                                                            'ExpiredPassword.PasswordDoestNotMeetRequirements.BeAtleast10Characters'
                                                            | translate }}
                                                        </li>
                                                        <li>
                                                            {{
                                                            'ExpiredPassword.PasswordDoestNotMeetRequirements.IncludeAtLeastOneNumber'
                                                            | translate }}
                                                        </li>
                                                        <li>
                                                            {{
                                                            'ExpiredPassword.PasswordDoestNotMeetRequirements.IncludeAtLeastOneLowercaseLetter'
                                                            | translate }}
                                                        </li>
                                                        <li>
                                                            {{
                                                            'ExpiredPassword.PasswordDoestNotMeetRequirements.IncludeAtLeastOneUppercaseLetter'
                                                            | translate }}
                                                        </li>
                                                        <li>
                                                            {{
                                                            'ExpiredPassword.PasswordDoestNotMeetRequirements.IncludeAtLeastOneSpecialCharacter'
                                                            | translate }}
                                                        </li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="confirmPassword" class=" "
                                            translate="ExpiredPassword.ConfirmPassword"></label>
                                        <div class=" col-sm-12">
                                            <input type="password" id="confirmPassword" class="form-control"
                                                formControlName="confirmPassword" />
                                            <div class="help-block text-danger"
                                                *ngIf="!formExpiredPassword.controls.confirmPassword.valid && (formExpiredPassword.controls.confirmPassword.touched || submitted)">
                                                <span [hidden]="formExpiredPassword.controls.confirmPassword.valid"
                                                    translate="ExpiredPassword.ConfirmPasswordMustMatch"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="btn btn-lg btn-primary btn-block" type="submit">{{
                                        'ExpiredPassword.Submit' | translate }}</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
