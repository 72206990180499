<div>
    <div class="gq-goals-selected">
        <div class="grid grid-gap-10 grid-col-md-33 grid-col-hd-33 grid-col-xs-100 grid-col-sm-100 grid-col-lt-100">
            <div class="goal-selected-outer-wrapper" *ngFor="let goal of goalSelectedUsers">
                <div class="percentage-achieved">
                    <h5>{{'GoalQuestPage.Manager.GoalQuestSelection.Goal' | translate}} {{goal.selectedLevel}}</h5>
                    <h5>{{calculateSelectedPercentage(goal.noOfUsersSelected)}}</h5>
                </div>
                <div class="goalPercentage">
                    <div class="totalPer"></div>
                    <div class="goalAchieved" [style.width]="calculateSelectedPercentage(goal.noOfUsersSelected)"></div>
                </div>
                <div class="total-selection">
                    <p>{{'GoalQuestPage.Manager.GoalQuestSelection.ParticipantsSelectedGoal' | translate}}</p>
                    <p class="count"><span>{{goal.noOfUsersSelected}}</span>/{{userCount}}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-rule-bottom"></div>

    <div class="table-header-gq">
        <h3>{{'GoalQuestPage.Manager.GoalQuestSelection.GoalDetail' | translate}}</h3>
        <div class="downloadText pull-right" (click)="downloadGoalSelection()">
            {{'GoalQuestPage.Manager.GoalQuestSelection.BtnDownloadReport' | translate}} <span
                class="downloadIcons"></span>
        </div>
    </div>
    <div class="gq-table table-responsive">

        <table class="table table-striped" aria-describedby="Manager Goal Selection">
            <thead>
                <tr>
                    <th scope="col">{{'GoalQuestPage.Manager.GoalQuestSelection.GoalQuestSelectionTable.UserID' |
                        translate}}</th>
                    <th scope="col">{{'GoalQuestPage.Manager.GoalQuestSelection.GoalQuestSelectionTable.ParticipantName'
                        | translate}}</th>
                    <th scope="col">{{'GoalQuestPage.Manager.GoalQuestSelection.GoalQuestSelectionTable.Organization' |
                        translate}}</th>
                    <th scope="col">{{'GoalQuestPage.Manager.GoalQuestSelection.GoalQuestSelectionTable.SelectionStatus'
                        | translate}}</th>
                    <th scope="col">{{'GoalQuestPage.Manager.GoalQuestSelection.GoalQuestSelectionTable.GoalSelected' |
                        translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let goalSelection of goalSelectionDetails">
                    <td scope="row">{{goalSelection.userIdName}}</td>
                    <td>{{goalSelection.userName}}</td>
                    <td>{{goalSelection.organisation}}</td>
                    <td>
                        <div class="status selected" *ngIf="goalSelection.targetLevel">
                            {{'GoalQuestPage.Manager.GoalQuestSelection.GoalQuestSelectionTable.BtnSelected' |
                            translate}}
                        </div>
                        <div class="status not-selected" *ngIf="!goalSelection.targetLevel">
                            {{'GoalQuestPage.Manager.GoalQuestSelection.GoalQuestSelectionTable.BtnNotSelected' |
                            translate}}
                        </div>
                    </td>
                    <td>
                        <span *ngIf="goalSelection.targetLevel">{{'GoalQuestPage.Manager.GoalQuestSelection.Goal' |
                            translate}} {{goalSelection.targetLevel}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>