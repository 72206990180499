<div>
    <div class="timeLeft">
        <div class="icon-gq-calendar"></div>
        <div class="goalWrapper">
            <div class="daysLeft">
                <cs-timer [endDate]="endDate"></cs-timer>
            </div>
            | &nbsp; {{ "GoalQuestPage.GoalQuestSelection.Days" | translate }} |
            &nbsp;<span class="duration">{{ startDate | localizedDate }} -
                {{ endDate | localizedDate }}</span>
        </div>
    </div>

    <div class="gq-goal-container">
        <div class="">
            <div
                class="grid grid-gap-10 grid-col-md-33 grid-col-hd-33 grid-col-xs-100 grid-col-sm-100 grid-col-lt-50 .grid-col-md-100">
                <div class="outerCard" *ngFor="let reward of rewards | sortBy: 'asc':'targetLevel';let i = index">
                    <div class="innerCardHeader">
                        <div class="rewards-icons">
                            <img alt="stars" src="assets/images/{{ i + 1 }}star.png" />
                            <div class="innerCardDec">
                                <span class="innerCardValue">{{ i + 1 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="cardContent">
                        <div class="items">
                            <div class="items-icons">
                                <span class="icon-gq-target"></span>
                            </div>
                            <div class="items-contnet">
                                <div class="items-rewards">
                                    {{
                                    "GoalQuestPage.GoalQuestSelection.GoalTarget" | translate
                                    }}
                                </div>
                                <div class="reward-points">{{ reward.target }}</div>
                            </div>
                        </div>
                        <div class="items" *ngIf="isMinimumQualifierEnabled">
                            <div class="items-icons">
                                <span class="icon-gq-target"></span>
                            </div>
                            <div class="items-contnet">
                                <div class="items-rewards">Minimium Qualifer</div>
                                <div class="reward-points">{{ minimumQualifier.target }}</div>
                            </div>
                        </div>
                        <div class="items-target">
                            <div class="items-icons"><span class="icon-gq-hand"></span></div>
                            <div class="items-contnet">
                                <div class="items-rewards">
                                    {{
                                    "GoalQuestPage.GoalQuestSelection.RewardsPoints" | translate
                                    }}
                                </div>
                                <div class="items-points" *ngIf="!isManualReward">
                                    {{ reward.points }}
                                </div>
                                <div class="items-points" *ngIf="isManualReward && reward.translations">
                                    <cs-http-img [httpSrc]="reward.translations[culture].imageUrl" httpType="view"
                                        alternativeText="image" height="40" width="50">
                                    </cs-http-img>
                                </div>
                                <div class="items-points" *ngIf="isManualReward && reward.imageUrl">
                                    <cs-http-img [httpSrc]="reward.imageUrl" httpType="view" alternativeText="image"
                                        height="40" width="50">
                                    </cs-http-img>
                                </div>
                            </div>
                        </div>
                        <div class="gq-btn">
                            <button *ngIf="!reward.selected" type="button" (click)="selectTarget(reward.targetLevel)"
                                class="btn btn-primary btn-lg btn-block items-button">
                                {{
                                "GoalQuestPage.GoalQuestSelection.btnSelectGoal" | translate
                                }}
                            </button>
                            <button *ngIf="reward.selected" type="button"
                                class="btn btn-primary btn-lg btn-block items-button selected">
                                <span class="icon-gq-tick"></span>{{
                                "GoalQuestPage.GoalQuestSelection.btnSelectedGoal" | translate
                                }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>