import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'profile-image-with-progress',
    templateUrl: './profile-image-with-progress.html',
    styleUrls: ['./profile-image-with-progress.scss']
})
export class ProfileImageWithProgressComponent implements OnInit {
    @Input() trackColor: string;
    @Input() imgUrl: string;
    @Input() size: number;
    @Input() progressPercentage: number;
    @Input() fullName:string;
    @Input() changeSelection: any;
    @Input() isLoading: boolean;
    public dimensions: any;
    public range: any;
    public imgStyle: any;
    public faEdit = faEdit;

    constructor() { }

    ngOnInit() {        
        this.dimensions = {
            width: this.size,
            height: this.size,
            center: (this.size / 2),
            stroke: 5,
            circleRadius: ((this.size / 2) - (5 / 2)),
            dashOffset: 360
        }
        this.range = {
            full: 93,
            empty: 360
        }
        
        let empty = this.range.empty - this.range.full;

        let progress = (this.progressPercentage / 100) * empty;

        this.dimensions.dashOffset = (empty - progress) + this.range.full;

        this.imgStyle = {
            "background-image": `url('${this.imgUrl}')`,
            "border-width": `${this.dimensions.stroke}px`,
            "border-color": this.trackColor
        }
    }

    getShortName(fullName: string) {       
        return fullName.toUpperCase().split(' ').map(n => n[0]).join('');
    }


    getProfileImageStyles() {
        return {
            "background-image": `url('${this.imgUrl}')`,
            "border-width": `${this.dimensions.stroke}px`,
            "border-color": this.trackColor
        }
    }
}