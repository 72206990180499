import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-quiz-view',
    templateUrl: 'quiz.component.html'
})

export class QuizComponent { 
    public isWebInMobileView: boolean = false;
    constructor(private router: Router,private activatedRoute : ActivatedRoute, private translateService: TranslateService, private titleService: Title){
        this.isWebInMobileView = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token')).hideHeaderFooter ? true : false;
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute.snapshot),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            })).subscribe((route: ActivatedRouteSnapshot) => {
                this.translateService.get(route.data.title).subscribe((res: string) => {
                    this.titleService.setTitle(res);
                });
            });
    }
}