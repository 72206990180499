import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import * as fromStore from '@core/store';
import { browserRefresh } from '@app/app.component';
import { LoadingService } from '@core/shared';
import { LeagueService } from '../services/league.service';
import { Authentication } from '@core/models/authentication.model';
import { Pagination } from '@core/shared/models/pagination';

@UntilDestroy()
@Component({
    selector: 'app-league',
    templateUrl: 'league.component.html'
})
export class LeagueComponent implements OnInit, OnDestroy {
    leaguePageId: string;
    league: any;
    user: Authentication;
    users: any[];
    selectedusers: any[];
    textForAboveLeague: string;
    textForBelowLeague: string;
    atTheTop: boolean;
    atTheBottom: boolean;
    browserRefresh: boolean;
    attributes: string[];
    isPageChange: boolean;
    showPaging: boolean;

    navigationSubscription;
    languageChangeSubscription;
    paginationDetails: Pagination = new Pagination();
    public destroyed = new Subject<any>();

    constructor(private activeRoute: ActivatedRoute, private router: Router, private translateService: TranslateService,
        private store: Store<fromStore.PlatformState>, private leagueService: LeagueService,
        public loadingService: LoadingService) { }

    ngOnInit() {
        this.navigationSubscription = this.router.events.pipe(
            filter((event) => event instanceof RouterEvent ),
            startWith('Initial call')
        ).subscribe((res : NavigationEnd) => {
            if (this.leaguePageId != this.activeRoute.snapshot.params.id) {
                this.browserRefresh = browserRefresh;
                this.leaguePageId = this.activeRoute.snapshot.params.id;
                this.getLeaguePage();
            }
        });

        this.languageChangeSubscription = this.translateService.onLangChange.pipe(takeUntil(this.destroyed)).subscribe((event: LangChangeEvent) => {
            if (!this.browserRefresh)
                this.getLeaguePage();
            else
                this.browserRefresh = false;
        });

        this.store.select(fromStore.authenticationState).pipe(takeUntil(this.destroyed)).subscribe(state => {
            if (state.user && !this.user)
                this.user = state.user;
        });
    }

    getLeaguePage() {
        let selectedCulture = localStorage.getItem('culture');

        this.loadingService.doLoading(this.leagueService.getLeaguePage(this.leaguePageId, selectedCulture), this)
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.league = response;
                this.attributes = this.league.participantAttributes.split('##');
                this.textForAboveLeague = response.textForAboveLeague;
                this.textForBelowLeague = response.textForBelowLeague;
                this.processLeague();
            });
    }

    getLeaderBoard(type, pagenumber, callback) {
        if (!this.user)
            return;

        this.loadingService.doLoading(this.leagueService.getMainLeague(this.league.promotionId, this.leaguePageId, this.user.Id, pagenumber, type), this)
            .pipe(untilDestroyed(this)).subscribe(response => {
                callback(response);
            });
    }

    processLeague(pageNumber?: number) {
        this.isPageChange = pageNumber ? true : false;
        let page = pageNumber ? pageNumber : 1;
        this.paginationDetails.itemsPerPage = 10;
        this.paginationDetails.pageNumber = page;
        if (this.league.conditionForMetric === '%ofTargetAchieved') {
            this.getLeaderBoard(this.league.salesAchievementLevel, page, this.processAotPromotion.bind(this));
        }
        else {
            let type = this.league.conditionForMetric == 'TotalRewards' ? this.league.conditionForMetric : 'QOS';
            this.getLeaderBoard(type, page, this.processSellxyPromotion.bind(this));
        }
    }

    processAotPromotion(response) {
        this.users = this.updateAttributeDetails(response.items);
        this.showPaging = response.showPaging;
        this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
        let isUserExist = this.users.find(x => x.Username === this.user.Username);
        if (!isUserExist && this.league.salesAchievementLevel === 'Participant' && !this.isPageChange) {
            this.getLeaderBoard(this.league.salesAchievementLevel, 0, this.processRelativePositions.bind(this));
        }
        else if (this.league.salesAchievementLevel === 'Organisation') {

        }
    }

    processSellxyPromotion(response) {
        this.users = this.updateAttributeDetails(response.items);
        this.showPaging = response.showPaging;
        this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
        let isUserExist = this.users.find(x => x.Username === this.user.Username);
        if (!isUserExist && !this.isPageChange) {
            let type = this.league.conditionForMetric == 'TotalRewards' ? this.league.conditionForMetric : 'QOS';
            this.getLeaderBoard(type, 0, this.processRelativePositions.bind(this));
        }
    }

    processRelativePositions(response) {
        this.gridPosition();
        this.selectedusers = this.updateAttributeDetails(response.items);
    }

    updateAttributeDetails(users) {
        let attributeUsers = [];

        if (users.length > 0) {
            users.forEach(user => {
                let username = '';
                let participant = '';
                let organisationName = '';
                let organisationCode = '';
                let jobRole = '';
                let quantity = 0;
                let rank = 0;
                let isCurrentUser = false;

                for (let attribute of this.attributes) {
                    quantity = user.quantity;
                    rank = user.rank;
                    username = user.username;

                    if (attribute === 'Participant')
                        participant = user.firstName + ' ' + user.lastName;
                    else if (attribute === 'Organisation Name')
                        organisationName = user.organisationName;
                    else if (attribute === 'Organisation Code')
                        organisationCode = user.organisationCode;
                    else if (attribute === 'Job Role')
                        jobRole = user.jobRole;

                    if (user.organisationCode === this.user.PrimaryOrganisationCode && (user.username === null || user.username === this.user.Username)) {
                        isCurrentUser = true;
                    }
                }
                attributeUsers.push({ Position: rank, Username: username, Participant: participant, OrganisationName: organisationName, OrganisationCode: organisationCode, JobRole: jobRole, Result: quantity, IsCurrentUser: isCurrentUser });
            });
        }

        return attributeUsers;
    }

    gridPosition() {
        let position = this.league?.snapshot?.placement;      
        if (position === 'AtTheTop') {
            this.atTheTop = true;
            this.atTheBottom = false;
        }
        else if (position === 'AtTheBottom') {
            this.atTheTop = false;
            this.atTheBottom = true;
        } else {
            this.atTheTop = false;
            this.atTheBottom = false;
        }
    }

    onChange(page) {
        this.processLeague(page.pageNumber);
    }

    ngOnDestroy() {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
        if (this.languageChangeSubscription) {
            this.languageChangeSubscription.unsubscribe();
        }
        this.destroyed.next(1);
        this.destroyed.complete();
    }
}