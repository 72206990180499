import { Component, OnInit, OnDestroy } from '@angular/core';
import { WindowReferenceService, ICustomWindow, LoadingService } from '@core/shared';
import { AccountsService } from '../services/accounts.service';
import { AuthenticationService } from '@core/startup';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { browserRefresh } from '@app/app.component';
import { HttpImageService } from '@core/shared/services/http-img.service';
import { PanVerificationScope } from '@app/core/shared/constants/pan-verification-scope';
import { SelfRegistrationFields } from '@app/core/shared/constants/self-registration-fields';

let windowObject = window as any;

@Component({
  selector: 'app-edit-profile-selfregistration',
  templateUrl: './edit-profile-selfregistration.component.html'
})
export class EditProfileSelfRegistrationComponent implements OnInit, OnDestroy {

  private _window: ICustomWindow;
  public userId: string;
  selectedCulture: string;
  contentPageId: string;
  contentPageDetails: any;
  languageChangeSubscription: Subscription;
  navigationStartSubscription: Subscription;
  browserRefresh: boolean;

  constructor(public accountsService: AccountsService, public windowRef: WindowReferenceService, public authService: AuthenticationService,
    private translateService: TranslateService, private router: Router, public loadingService: LoadingService,
    private imgService: HttpImageService) {
    this._window = windowRef.nativeWindow;
  }
  ngOnInit(): void {
    windowObject.parent.accountsService = this.accountsService;
    windowObject.parent.translate = this.translateService;

    windowObject.parent.preRegistrationCallBack = async (userDetails): Promise<any> => {
      let pan: string;
      let firstName: string;
      let lastName: string;

      

      let fields = Object.values(userDetails);
      fields.forEach(parseUserDetails);

      function parseUserDetails(item: any) {
        if (item.fieldName) {
          if (item.fieldName.toLowerCase() == SelfRegistrationFields.Pan.toLowerCase()) {
            pan = item.fieldValue;
          }
          if (item.fieldName.toLowerCase() == SelfRegistrationFields.FirstName.toLowerCase()) {
            firstName = item.fieldValue;
          }
          if (item.fieldName.toLowerCase() == SelfRegistrationFields.LastName.toLowerCase()) {
            lastName = item.fieldValue;
          }
        }
      }

      let panPromise = await windowObject.parent.accountsService
        .isPanModifyAllowed()
        .toPromise();

      let success = this.checkPan(panPromise, pan, firstName, lastName);

      return Promise.resolve(success);
    };

    windowObject.parent.postRegistrationCallBack = (userDetails, message) => {
      location.reload();
    };

    windowObject.parent.addressCallback = async (val, data) => {

      let details = {
        "localeCode": this.selectedCulture,
        "code": data
      };
      switch (val) {
        case "COUNTRY":
          let countryPromise = await this.accountsService.getCountries(details);
          return countryPromise.country;
        case "STATE":
          let statesPromise = await this.accountsService.getStates(details);
          return statesPromise.state;
        case "CITY":
          let cityPromise = await this.accountsService.getCities(details);
          return cityPromise.city;
        default:
          return Promise.reject();
      }
    }

    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd ) => {
      this.browserRefresh = browserRefresh;
      this.getUser();
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh) {
        window.location.reload();
        this.getContentPage(this.contentPageId);
      }
      else
        this.browserRefresh = false;
    });
  }

  checkPan(panPromise, pan, firstName, lastName){
    let success = {
      success: true,
      errorCode: "",
      responseMessage: "",
    };
    if (panPromise && !panPromise.isPanModifyAllowed) {
      if (pan != panPromise.pan) {
        success.success = false;
        success.responseMessage = windowObject.parent.translate.instant(
          "Accounts.PanNotAllowedToModify"
        );
      } else if (
        (panPromise.firstName + panPromise.lastName).toLowerCase() !=
        (firstName + lastName).toLowerCase() &&
        panPromise.panVerificationScope ==
        PanVerificationScope.PanWithOwnersName
      ) {
        success.success = false;
        success.responseMessage = windowObject.parent.translate.instant(
          "Accounts.NameNotAllowedToModify"
        );
      }
    }
    return success;
  }

  getContentPage(contentPageId) {
    this.selectedCulture = localStorage.getItem('culture');
    this.loadingService.doLoading(this.accountsService.getContentPage(contentPageId, this.selectedCulture), this)
      .subscribe(response => {
        this.contentPageDetails = this.imgService.parseHtml(response);
        setTimeout(() => {
          this.fetchBlobImages();
        });
      });
  }

  fetchBlobImages() {
    let images = document.querySelectorAll('img[data-src]');
    for (let image of Array.from(images)) {
      let dataSrc = image.getAttribute('data-src');
      this.imgService.getImage(dataSrc).subscribe(val => {
        let type = val.type;
        let reader = new FileReader();
        reader.readAsDataURL(val);
        reader.onloadend = function () {
          let baseImage = <string>reader.result;
          image.setAttribute('src', `data:${type};base64,` + baseImage.split(',')[1]);
        }
      });
    }
  }

  getUser() {
    this.selectedCulture = localStorage.getItem('culture');
    this.authService.getCurrentUser().subscribe(response => {
      this.userId = response.id;
      this.accountsService.selfRegistrationConfig().subscribe(data => {
        this.renderForm(data);
        this.contentPageId = data.contentUserEditFormId;
        this.getContentPage(this.contentPageId);
      })
    });
  }

  renderForm(configs) {
    this.accountsService.editProfileSelfRegistration(configs, this.userId, this.selectedCulture).subscribe(data => {
      this._window.__selfRegistrationAppData__ = data;
      this._window.loadSelfRegistrationInDom('selfRegistrationRoot');
    });
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }

  isShownDivMaterial: boolean = false; // hidden by default
  toggleShowsMaterial() {
    this.isShownDivMaterial = !this.isShownDivMaterial;
  }
}
