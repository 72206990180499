import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class SelfRegistrationService{
    constructor(public http: HttpClient){}

    selfRegistrationStatus(): Observable<any> {
        return this.http.get(`${environment.baseUrl + environment.version}services/selfRegistration/SelfRegistrationStatus`)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }
}