import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  public static mobile: string ="mobile";
}
export class LoginType{
  public static mobile: string ="mobile";
  public static email: string="email";
  public static username: string="username";
  public static requestType: string="CS_Login";
  public static otpGrantType: string="otp";
  public static passwordGrantType: string="password";
  public static loginTypes: any =[
    {name:'User Name',value:'username'},
    {name:'Email Id',value:'email'},
    {name:'Mobile No',value:'mobile'}
];
public static otp: string ="Otp";
public static password: string ="Password";
}
export class RequestType{
  public static CS_Login: string="CS_Login";
  public static CS_Activation: string="CS_Activation";
}
