<div class="claim-filter-section margin-bottom-40 home-page-brand-font-white-bg-color" *ngIf="!hideCategory">

    <div *ngIf="articleDetails && articleDetails.length === 0"> {{'MessagePage.NoArticles' | translate}} </div>
    <div class="grid grid-gap-10 grid-col-md-25-custom grid-col-hd-25-custom grid-col-xs-100 grid-col-sm-100 grid-col-lt-100"
        *ngIf="articleDetails && articleDetails.length > 0">
        <div class="form-group">
            <label>{{'MessagePage.CategoryFilter' | translate}}</label>
            <select class="form-control select-css" name="category" [(ngModel)]="selectedCategory">
                <option value="">{{'MessagePage.CategoryDefaultValue' | translate}}</option>
                <option *ngFor="let category of categoryDetails" [value]="category.id">
                    {{category.name}}
                </option>
            </select>

        </div>
        <div class="form-group btn-bottom">
            <button type="button" class="btn btn-primary btn-redeem custombtn"
                (click)=onCategoryFilterClick()>{{'MessagePage.BtnGo' | translate}}</button>
        </div>
    </div>
</div>

<div class="bottomMargin">
    <div class="grid grid-col-md-50 grid grid-gap-15 grid-col-xs-100 grid-col-xxs-100 grid-col-lt-100 grid-col-sm-100 grid-col-hd-50 custom-grid-width"
        *ngIf="articleDetails && articleDetails.length > 0">
        <div class="margin-bottom-10 promoLists home-page-brand-font-white-bg-color"
            *ngFor="let article of updatedArticles | filterBy : filterByKey : selectedCategories | sortBy:'desc':'articleDate'">
            <div
                class="grid grid-col-md-50-custom-2 grid grid-gap-15 grid-col-xs-100 grid-col-xxs-100 grid-col-lt-50-custom-2 grid-col-sm-50-custom grid-col-hd-50-custom-2">
                <div class="image-section img-message-page">
                    <cs-http-img [httpSrc]="article.teaserImage" *ngIf="!isHubComponent" httpType="view"
                        [alternativeText]="article.teaserText" height="200" width="408">
                    </cs-http-img>
                    <cs-http-img [httpSrc]="article.teaserImageToken" *ngIf="isHubComponent && showThumbnailImage"
                        httpType="view" [alternativeText]="article.teaserText" height="200" width="408">
                    </cs-http-img>
                </div>
                <div class="text-section">
                    <div class="card-body-right mt-2">
                        <div class="mb-4">
                            <p class="news-title">
                                <a *ngIf="!isHubComponent" (click)="redirectArticle(article.messagePageId, article.id)">
                                    {{article.title}}
                                </a>
                                <span *ngIf="isHubComponent && showTitle"><strong>{{article.title}}</strong></span>
                            </p>
                            <p class="promoSubHeader margin-top-10" *ngIf="showTeaser">{{ article.teaserText }}</p>
                            <p class="promoSubHeaderDescription  margin-top-10" *ngIf="showArticleDate">{{
                                article.articleDate | localizedDate}}</p>
                        </div>

                        <span *ngIf="isHubComponent"
                            (click)="redirectArticle(article.messagePageId, article.id)">{{article?.attributeJson?.buttonText}}</span>
                        <fa-icon [icon]="arrowNext" class="fa-icon-arrow-next ml-3 mr-3" *ngIf="isHubComponent"
                            (click)="redirectArticle(article.messagePageId, article.id)"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>