<!-- Right side -->
<div>
    <div class="claim-filter-section">
        <p class="claimDescription">
            {{'CouponClaimPage.CouponClaimSubmissionDescription' | translate}}
        </p>
        <form>
            <div
                class="grid grid-gap-10 grid-col-md-33-custom grid-col-hd-33-custom grid-col-xs-100 grid-col-sm-100 grid-col-lt-100">

                <div class="addContactControls col-100" [appIsLoading]="loadingService.isLoading(this)">

                    <label> {{'CouponClaimPage.AddyourCouponcode' | translate}}</label>
                    <button type="button" class="btn btn-outline-secondary mytooltip"
                        ngbPopover=" Simply enter a coupon code manually and use the Add button to claim up to eight codes. Upon completion, click the Submit Coupon Claim button."
                        triggers="mouseenter:mouseleave"></button>
                    <br>
                    <div class="input-group">
                        <input #coupon placeholder="Enter Coupon Code" class="form-control customField" />
                        <button class="btn btn-primary custombtn"
                            (click)="addCoupon(coupon.value)">{{'CouponClaimPage.AddBtn' | translate}}</button>
                    </div>
                    <small>{{'CouponClaimPage.CouponCodeMannually' | translate}}</small>
                    <br />
                    <small class="text-danger" *ngIf="couponMore">{{'CouponClaimPage.CouponCountExceed' |
                        translate}}</small>
                    <br />
                    <table class="table tableHeader" aria-describedby="myTable">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                        <tbody>
                            <tr *ngFor="let coupon of coupons; index as i" class="tableBorder">
                                <td>{{coupon}}</td>
                                <td class="text-right"><span class="removebtn" (click)="removeCoupon(i)">
                                    </span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="dividerScale">
                    <div class="line">
                        <div class="ball">{{'CouponClaimPage.DivderOR' | translate}}</div>
                    </div>
                </div>

                <div class="addContactControls col-100">
                    <div [innerHTML]="('CouponClaimPage.Upload.UploadColumns' | translate)"></div>
                    <br />
                    <cs-file-uploader-without-mapping *ngIf="contextId" [lastUploadId]="lastUploadId"
                        uploadType="CouponClaim" [contextId]="contextId" [importBehaviors]="importBehaviors"
                        showPicker="true" uploadButtonEnabled="true" [userId]="currentUser.Id"
                        [header]="('CouponClaimPage.Upload.UploadHeader' | translate)"
                        [popoverContent]="('CouponClaimPage.Upload.PopoverContent' | translate)"
                        (onImportComplete)="importCompleted($event)">
                    </cs-file-uploader-without-mapping>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center btn-align">
                    <button class="btn btn-primary customBtnSubmit" (click)="onSubmit()" [disabled]="claimDisabled">
                        {{'CouponClaimPage.SubmitCouponClaimBtn' | translate}}
                    </button>
                </div>
            </div>
            <div class="table-width" *ngIf="claimResult.length > 0"
                [appIsLoading]="loadingService.isLoading(this, 'couponClaim')" aria-describedby="Coupon Claim">
                <table class="table table-striped" aria-describedby="claimResultList">
                    <thead>
                        <tr>
                            <th class="cm_12 cm_bold" role="button" scope="col">
                                {{'CouponClaimPage.CouponClaimHistory.CouponClaimTable.CouponCode' | translate}}
                            </th>

                            <th class=" cm_12 cm_bold" role="button" scope="col">
                                {{'CouponClaimPage.CouponClaimHistory.CouponClaimTable.Status' | translate}}
                            </th>

                            <th scope="col">
                                {{'CouponClaimPage.CouponClaimHistory.CouponClaimTable.Points' | translate}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let coupon of claimResult">
                            <td>{{coupon.couponId}}</td>
                            <td>{{coupon.status === 'Used' ? ('CouponClaimPage.ClaimMessage' | translate)  : coupon.status === 'MaxLength' ? ('CouponClaimPage.MaxLength' | translate)  : coupon.status }}</td>
                            <td>{{coupon.points | localizednumber}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </form>
    </div>
</div>