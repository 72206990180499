import { Component, Input, OnInit } from '@angular/core';
import { Menu } from '../menu.service';
import { CatalogueService } from "@core/shared/services/catalogue.service";
import { Router } from '@angular/router';

@Component({
    selector: 'sub-menu-component',
    templateUrl: './sub-menu.component.html',
    styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent implements OnInit {
    @Input() subMenu: Menu[];   
    @Input() selectedCulture: string;
    @Input() defaultCulture: string; 
    public menu: Menu[]
    constructor(private catalogueService: CatalogueService, private router: Router) {}

    ngOnInit() {
        this.menu = this.subMenu;
    }

    rewardCatalogue(menuType) {
        //if (menuType === 'merchandise')
            //this.catalogueService.redirectMerchandiseCatalogue();
        //else if (menuType === 'experience')
            //this.catalogueService.redirectExperianceCatalogue();
        //else if (menuType === 'mastery')
            //this.catalogueService.redirectMasteryCatalogue();
        this.router.navigate([`/catalogue/verification/${menuType}`]);
    }
}
