import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cs-shared-goal-selection',
  templateUrl: './goal-selection.component.html'
})
export class SharedParticipantGoalSelectionComponent {
  @Input() rewards: any[] = [];
  @Input() selectedGoal: any;
  @Input() isMinimumQualifierEnabled: boolean;
  @Input() minimumQualifier;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() isManualReward: boolean;
  @Input() culture: string;
  @Output() selectTargetEvent = new EventEmitter();  

  selectTarget(target) {
    this.selectTargetEvent.emit(target);
  }

}
