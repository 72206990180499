import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'cs-shared-goal-progress',
  templateUrl: './goal-progress.component.html'
})
export class SharedParticipantGoalProgressComponent implements OnInit, OnChanges {
  @Input() goalAchieved: number;
  @Input() selectedGoal: any;
  @Input() isMinimumQualifierEnabled: boolean;
  @Input() minimumQualifier;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() isManualReward: boolean;
  @Input() culture: string;

  goalAchived: number;
  minimumQualifierAchived: number;
  percentageValue: (value: number) => string;

  ngOnInit() {
    this.percentageValue = (value: any): string => {
      if (this.minimumQualifier && this.minimumQualifier.target && !Number.isNaN(value))
        return `${value % 1 != 0 ? value.toFixed(2) : value}%`;
      else
        return 'N/A';
    };
  }

  ngOnChanges() {
    let goalAchievedPercentage = (this.goalAchieved / this.selectedGoal.target) * 100;
    this.goalAchived = goalAchievedPercentage > 100 ? 100 : goalAchievedPercentage;
    if (this.isMinimumQualifierEnabled) {
      this.minimumQualifierAchived = 0;
      if (this.minimumQualifier.target) {
        let minimumQualifierAchivedPercentage = (this.minimumQualifier.achievement / this.minimumQualifier.target) * 100
        this.minimumQualifierAchived = minimumQualifierAchivedPercentage > 100 ? 100 : minimumQualifierAchivedPercentage;
      }
    }
  }

}