<h1 class="sub-pageHeader">{{'PointsSummaryPages.PointsSummaryTableHeader' | translate}} </h1>

<div class="tableDataDisplay" #dataLoading="appIsLoading" [appIsLoading]="loadingService.isLoading(this)">
  <div class="row" *ngIf="paginationDetails.totalNumberOfItems > 0">
    <div class="col-12 col-md-6">
      <div *ngIf="totalNoOfItems>0">
        <p>{{'TableRecords.FoundTotalOf' | translate}}{{totalNoOfItems}}{{'TableRecords.matches' |
          translate}}</p>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="grid grid-col-xs-100 grid-col-hd-50 grid-gap-10">
        <cs-items-per-page class="" [paginationDetails]="paginationDetails" (onChangeEvent)="onChange($event)">
        </cs-items-per-page>
        <div class="pull-right">
          <div class="downloadText" (click)="downloadXLS()">
            Download Report <span class="downloadIcons"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mainPointSummary">

    <table class="table table-striped" *ngIf="paginationDetails.totalNumberOfItems!==0" aria-describedby="Points Statement">
      <thead>
        <tr>
          <th class="cm_12 cm_bold" role="button" (click)="onSort('date')" scope="col">
            <span translate="PointsSummaryPages.PointsSummaryTable.Date"></span>
            <cs-sort-icons *ngIf="sorter.showSortIcons('date')" [sortAscending]="sorter.sortDirection"></cs-sort-icons>
          </th>
          <th class=" cm_12 cm_bold" role="button" (click)="onSort('description')" scope="col">
            <span translate="PointsSummaryPages.PointsSummaryTable.Description"></span>
            <cs-sort-icons *ngIf="sorter.showSortIcons('description')" [sortAscending]="sorter.sortDirection">
            </cs-sort-icons>
          </th>
          <th class=" cm_12 cm_bold" role="button" scope="col">
            <span translate="PointsSummaryPages.PointsSummaryTable.PromotionName"></span>
          </th>
          <th class=" cm_12 cm_bold" role="button" (click)="onSort('points')" scope="col">
            <span translate="PointsSummaryPages.PointsSummaryTable.Points"></span>
            <cs-sort-icons *ngIf="sorter.showSortIcons('points')" [sortAscending]="sorter.sortDirection">
            </cs-sort-icons>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let statement of pointsStatement">
          <td>{{statement.date | localizedDate}}</td>
          <td>{{statement.description}}</td>
          <td>{{statement.promotionName}}</td>
          <td>{{statement.points | localizednumber}}</td>
        </tr>
      </tbody>
    </table>
    <div class="mainPointSummaryNoData alert alert-danger"
      *ngIf="!dataLoading.appIsLoading && paginationDetails.totalNumberOfItems===0">
      {{'PointsSummaryPages.PointsStatement.NoRecordsFound' | translate}}
    </div>
  </div>
  <div class="row" *ngIf="paginationDetails.totalNumberOfItems > 0">
    <div class="col-lg-12 pagination-center-Bottom">
      <cs-custom-pagination [paginationDetails]="paginationDetails" (onChangeEvent)="onChange($event)">
      </cs-custom-pagination>
    </div>
  </div>
</div>