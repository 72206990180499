import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { InvoiceClaimApprovalComponent } from './invoice-claim-approval/invoice-claim-approval.component';
import { InvoiceClaimHistoryComponent } from './invoice-claim-history/invoice-claim-history.component';
import { InvoiceClaimResubmissionComponent } from './invoice-claim-resubmission/invoice-claim-resubmission.component';
import { InvoiceClaimSubmissionComponent } from './invoice-claim-submission/invoice-claim-submission.component';
import { InvoiceComponent } from './invoice.component';

export const InvoiceClaimRoutes: Routes = [
    {
        path: 'invoice',        
        component: InvoiceComponent,
        children: [
            {
                path: 'claims/invoiceclaimhistory/:id',                
                component: InvoiceClaimHistoryComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'InvoiceClaimPage.InvoiceClaimHistory.InvoiceClaimHistoryHeader', isPreLogin: false }
            },
            {
                path: 'claims/claimsubmission/:id',                
                component: InvoiceClaimSubmissionComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'InvoiceClaimPage.InvoiceClaimSubmission.InvoiceClaimSubmissionHeader', isPreLogin: false }
            },
            {
                path: 'claims/approveinvoiceclaim/:id',                
                component: InvoiceClaimApprovalComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'InvoiceClaimPage.InvoiceClaimApproval.InvoiceClaimsApprovalHeader', isPreLogin: false }
            },
            {
                path: 'claims/claimresubmission/:id/:invoiceId',                
                component: InvoiceClaimResubmissionComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'InvoiceClaimPage.InvoiceClaimSubmission.InvoiceClaimResubmissionHeader', isPreLogin: false }
            }
        ]
    }
];