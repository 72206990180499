<div class="main-navbar $branding-primary" ng-class="{'mobile-active' : mobileActive}">

    <div class="flex flex-space-between mobile-menu">
        <div>
            <body-text class="font-bold large-size color2 text-uppercase" ng-bind="navbar.getActiveTab().data.group">
            </body-text>
        </div>

        <button class="hamburger" ng-click="mobileActive = !mobileActive">
            <em class="icon ion-navicon"></em>
        </button>
    </div>

    <div class="content-width bar-container">
        <a class="bar-item" *ngFor="let item of navbar" (click)="mobileActive = false">
            <body-text [textContent]="item.label"></body-text>
        </a>
    </div>
</div>