import * as fromTheme from '../actions/theme.action';
 

export interface ThemeState {
    isExpand: boolean,
    theme: string,
    radius: string
}

export const initialState: ThemeState = {
    isExpand: localStorage.getItem('expand') ? localStorage.getItem('expand').toLowerCase() == 'true' : false,
    theme: localStorage.getItem('theme') ? localStorage.getItem('theme') : 'theme2',
    radius: localStorage.getItem('radius') ? localStorage.getItem('radius') : 'radius1'
}


export function reducer(state = initialState, action: fromTheme.ThemeAction): ThemeState {

    switch (action.type) {
        case fromTheme.TOGGLE_PANEL: {
            localStorage.setItem('expand', action.payload);
            return Object.assign({}, state, { isExpand: action.payload })
        }
        case fromTheme.UPDATE_THEME: {
            localStorage.setItem('theme', action.payload);
            return Object.assign({}, state, { theme: action.payload })
        }
        case fromTheme.UPDATE_RADIUS: {
            localStorage.setItem('radius', action.payload);
            return Object.assign({}, state, { radius: action.payload })
        }
        default: {
            return state;
        }
    }
}

export const getView = (state: ThemeState) => state.isExpand;
export const getTheme = (state: ThemeState) => state.theme;
export const getRadius = (state: ThemeState) => state.radius;