import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { browserRefresh } from '@app/app.component';
import { MessagePageService } from '../services/messagepage.service';
import { LoadingService } from '@core/shared';

@UntilDestroy()
@Component({
  selector: 'message-page',
  templateUrl: './message-page.component.html'
})
export class MessagePageComponent implements OnInit, OnDestroy {

  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;
  browserRefresh: boolean;
  selectedCulture: string;
  messagePageId: string;
  messagePageDetails: any;
  articleDetails: any;
  categoryDetails: any;
  public destroyed = new Subject<any>();

  constructor(private activeRoute: ActivatedRoute, private router: Router, private messagePageService: MessagePageService,
    private translateService: TranslateService, public loadingService: LoadingService) { }

  ngOnInit(): void {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent ),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      if (this.messagePageId != this.activeRoute.snapshot.params.id) {
        this.browserRefresh = browserRefresh;
        this.messagePageId = this.activeRoute.snapshot.params.id;
        this.getMessagePages();
      }
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh)
        this.getMessagePages();
      else
        this.browserRefresh = false;
    });
  }

  getMessagePages() {

    this.selectedCulture = localStorage.getItem('culture');

    let messagePages = this.messagePageService.getMessagePage(this.messagePageId, this.selectedCulture);
    let articles = this.messagePageService.getArticles(this.messagePageId, this.selectedCulture);
    let categories = this.messagePageService.getCategories(this.messagePageId, this.selectedCulture);

    this.loadingService.doLoading(forkJoin([messagePages, articles, categories]), this)
      .pipe(untilDestroyed(this)).subscribe(response => {
        this.messagePageDetails = response[0];
        this.articleDetails = response[1];
        this.categoryDetails = response[2];

        this.calculateUnAssignedCategories();
      });
  }

  calculateUnAssignedCategories() {
    let unAssignedCategoryExists = false;
    this.articleDetails.forEach(article => {
      if (article.categoryIds.length === 0) {
        unAssignedCategoryExists = true;
        article.categoryIds.push("-1");
      }
    });
    if (unAssignedCategoryExists) {
      this.categoryDetails.push({ 'id': "-1", 'name': this.translateService.instant('MessagePage.UnassignedCategoryName') });
    }
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe()
  }

}
