<div class="homepage-tab-body-view">
    <p class="sub-heading">{{promotionName}}</p>
    <p>{{'SharedMyRank.AppreciationText' | translate}}</p>
</div>
<div class="horizontal-rank-chart">
    <div class="rank-item" *ngFor="let rank of leagueData" [class]="{'active': rank.isCurrentUser}">
        <div class="tool-tip">
            <body-text *ngIf="headerParticipant && salesAchievementLevel !== 'Organisation'">{{rank.firstName}}
                {{rank.lastName}}
            </body-text>
        </div>
        <body-text class="color2">{{rank.rank}}</body-text>
    </div>
</div>
<div class="homepage-tab-body-view">
    <p>{{'SharedMyRank.YourRank' | translate}}</p>
    <p>{{'SharedMyRank.msg2' | translate}}</p>
</div>
<div class="btn-group homepage-tab-body-view mt-5 text-center">
    <button type="button" class="btn btn-primary btn-WhiteBg-BrandColor" (click)="redirectTo()">
        <strong>{{'Shared.Buttons.KnowMore' | translate}}</strong>
        <span class="glyphicon glyphicon-menu-right pl-1"></span>
    </button>
</div>