import { Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { forkJoin } from 'rxjs';

import { LoadingService } from '@core/shared';
import { HubPageService } from '@core/private/hubpage/services/hub-page.service';
import { PlatformSystemSettings } from '@app/core/models';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-hub-points-summary',
    templateUrl: 'points-summary.component.html',
    styleUrls: ['./points-summary.component.scss']
})
export class PointsSummaryComponent implements OnChanges {
    @Input() componentId: string;
    @Input() payload: any;
    @Input() culture: string;
    @Input() viewType: string = 'Tile';
    systemSettings: PlatformSystemSettings;

    summaryDetails: any;
    summary: any;
    isFIFOEnabled = false;
    constructor(private hubPageService: HubPageService, public loadingService: LoadingService, private activatedRoute: ActivatedRoute) {
        
    }

    ngOnChanges() {
        this.systemSettings = this.activatedRoute.snapshot.data.settings;
        this.isFIFOEnabled = this.systemSettings.isFIFOEnabled;
        let data = { item: JSON.stringify(this.payload) };

        let hubComponent = this.hubPageService.getHubComponent(this.componentId, this.culture, data);
        let pointsummary = this.hubPageService.getPointsummary();
        this.loadingService.doLoading(forkJoin([hubComponent, pointsummary]), this)
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.summaryDetails = response[0];
                this.summary = response[1];
                this.viewType = this.summaryDetails.pointsSummaryView == null || this.summaryDetails.pointsSummaryView == undefined ? 'Tile' : this.summaryDetails.pointsSummaryView;
            });       
    }
}