<div>
    <div class="claim-filter-section" #dataLoading="appIsLoading" [appIsLoading]="loadingService.isLoading(this)">
        <div *ngIf="textForAboveLeague" [innerHtml]="textForAboveLeague | sanitizeHtml"></div>
        <cs-shared-league-page *ngIf="selectedusers && atTheTop" [salesAchievementLevel]="league.salesAchievementLevel"
            [winningPosition]="league.winningPositions" [attributes]="attributes" [leagueData]="selectedusers">
        </cs-shared-league-page>

        <cs-shared-league-page *ngIf="users" [salesAchievementLevel]="league.salesAchievementLevel"
            [winningPosition]="league.winningPositions" [attributes]="attributes" [leagueData]="users">
        </cs-shared-league-page>

        <div *ngIf="showPaging" class="col-lg-12 pagination-center-Bottom">
            <cs-custom-pagination [paginationDetails]="paginationDetails" (onChangeEvent)="onChange($event)">
            </cs-custom-pagination>
        </div>

        <cs-shared-league-page *ngIf="selectedusers && atTheBottom"
            [salesAchievementLevel]="league.salesAchievementLevel" [winningPosition]="league.winningPositions"
            [attributes]="attributes" [leagueData]="selectedusers">
        </cs-shared-league-page>

        <div *ngIf="!dataLoading.appIsLoading && users && users.length == 0 && selectedusers && selectedusers.length ==0 "
            class="awardsNoData alert alert-danger">
            {{'MyAwardsPage.RecordNotFound' | translate}}
        </div>
        <div *ngIf="textForBelowLeague" [innerHtml]="textForBelowLeague | sanitizeHtml"></div>
    </div>
</div>