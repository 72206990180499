<div class="home-page">
    <div class="bg-color3 home-page-bg">
        <div class="content-width home-point-gallery-section">
            <div class="flex-1 home-page-slide rightPane">
                <router-outlet></router-outlet>
            </div>            
            <div class="home-left leftPane">
                <cs-profile-point-card theme="theme-white" buttonTheme="theme2"></cs-profile-point-card>
                <cs-most-redeem-products [singleView]="true"></cs-most-redeem-products>
            </div>
        </div>
    </div>
</div>