import { Component } from '@angular/core';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { AuthenticationService } from '@core/startup'

@Component({
    selector: 'welcome-component',
    templateUrl: './welcome.component.html',
    styles: [
        'a { cursor: pointer; }'
    ]
})
export class WelcomeComponent {
    public currentUser:any;
    public faUser = faUser;

    constructor(public authService: AuthenticationService) {
        this.getUser();
    }

    getUser() {
        this.authService.getCurrentUser().subscribe(response => {
            this.currentUser = response;
        });
    }

    logout(){
        this.authService.logout();
    }
}
