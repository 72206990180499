import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'cs-shared-message-page',
    templateUrl: 'shared-message-page.component.html',
    styleUrls: ['shared-message.page.component.scss']
})
export class SharedMessagePageComponent implements OnChanges {
    @Input() messagePageId;
    @Input() filterByKey;
    @Input() categoryDetails;
    @Input() articleDetails;
    @Input() hideCategory: boolean = false;
    @Input() showArticleDate: boolean = true;
    @Input() showTeaser: boolean = true;
    @Input() showThumbnailImage: boolean = true;
    @Input() showTitle: boolean = true;
    @Input() isHubComponent: boolean = false;

    selectedCategory: string = '';
    selectedCategories: string[] = [];
    arrowNext = faLongArrowAltRight;
    updatedArticles: any[];

    constructor(private router: Router) { }

    ngOnChanges(): void {
        this.updatedArticles = [];
        for (let article of this.articleDetails) {
            let updatedArticle = Object.assign({}, article);
            delete updatedArticle.attributeJson;

            updatedArticle['attributeJson'] = JSON.parse(article.attributeJson);
            this.updatedArticles.push(updatedArticle);
        }
    }

    onCategoryFilterClick() {
        this.selectedCategories = [];
        if (this.selectedCategory != '')
            this.selectedCategories.push(this.selectedCategory);
    }

    redirectArticle(messagePageId, articleId) {
        this.router.navigateByUrl(`/articles/messagearticle/${messagePageId}/View/${articleId}`);
    }
    truncate = (input) => input.length > 105 ? `${input.substring(0, 100)}...` : input
}