import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from "@angular/router";
import { browserRefresh } from "@app/app.component";
import { HttpImageService } from "@app/core/shared/services/http-img.service";
import { MessageService } from "@app/core/shared/services/message.service";
import { PreloginService } from '@core/prelogin/services/prelogin.service';
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { filter, pairwise, startWith } from "rxjs/operators";

@Component({
    selector: 'app-prelogin-legal-page',
    templateUrl: './prelogin-legal-page.component.html'
})

export class PreloginLegalPageComponent implements OnInit {

    languageChangeSubscription: Subscription;
    navigationStartSubscription: Subscription;
    browserRefresh: boolean;
    public page: string;
    public selectedCulture;
    public content;
    public legalPage: string;
    constructor(public route: ActivatedRoute, public preloginService: PreloginService, private imgService: HttpImageService,
        private messageService: MessageService,
        private translate: TranslateService,
        private router: Router) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.legalPage = params.get('page');
            this.renderPage(params.get('page'));
        });

        this.navigationStartSubscription = this.router.events
            .pipe(
                filter((e) => e instanceof RouterEvent),
                pairwise(),
                filter((events) => events[0] !== events[1]),
                startWith("Initial call")
            )
            .subscribe((res) => {
                this.browserRefresh = browserRefresh;
            });

        this.languageChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.selectedCulture = localStorage.getItem('culture');
            if (!this.browserRefresh)
                this.renderPage(this.legalPage);
            else
                this.browserRefresh = false;
        });
    }

    renderPage(page) {
        switch (page) {
            case "accessadvice": {
                this.page = "AccessAdvice";
                break;
            }
            case "cookies": {
                this.page = "Cookies";
                break;
            }
            case "privacypolicy": {
                this.page = "PrivacyPolicy";
                break;
            }
            case "termsofuse": {
                this.page = "TermsOfUse";
                break;
            }
            default: {
                //statements; 
                break;
            }
        }
        this.selectedCulture = localStorage.getItem('culture');
        if (this.page == 'Cookies' || this.page == 'PrivacyPolicy') {
            let policyType = this.page == 'Cookies' ? 'cookie' : 'privacy'
            this.preloginService.getPolicyContentPage(policyType, this.selectedCulture).subscribe(response => {
                if (response == "" || response == undefined) {
                    response = ""
                    this.messageService.showError(this.translate.instant('LegalPage.NoContentConfigured'));
                }
                this.content = this.imgService.parseHtml(response);
            })
        }
    }
}