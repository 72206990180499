import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PreloginService } from '@core/prelogin/services/prelogin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '@app/core/shared/services/message.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  public formResetPassword: FormGroup;
  public token: any = '';
  public userId: any = '';
  constructor(
    public fb: FormBuilder,
    public PreloginService: PreloginService,
    public router: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public route: Router
  ) {
    this.token = this.router.snapshot.params.token;
  }

  ngOnInit() {
    this.formResetPassword = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });
  }
  changePassword(formResetPassword) {
    var token = { token: this.token }
    this.PreloginService.verifyLink(token).subscribe(result => {
      this.userId = result.userId;
      var data = {
        confirmPassword: formResetPassword.value.password,
        password: formResetPassword.value.confirmPassword,
        token: this.token, userId: this.userId
      }
      this.PreloginService.resetPassword(data).subscribe(result => {
        this.messageService.showSuccess(this.translate.instant('ResetPassword.PasswordResetSuccessful.PasswordResetSuccessfulMessage'))
        this.route.navigate(['/login'])
      });
    });
  }
  varifyLink() {
    var data = { token: this.token }
    this.PreloginService.verifyLink(data).subscribe(result => {
      this.userId = result.userId
    });

  }
}