import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { QuestionPageComponent } from './question-page/question-page.component';
import { QuestionArticleComponent } from './question-article/question-article.component';
import { FaqComponent } from './faq.component';


export const QuestionPageRoutes: Routes = [
    {
        path: 'faq',
        component: FaqComponent,
        children: [
            {
                path: 'questionpage/view/:id',
                canActivate: [AuthGuard],
                component: QuestionPageComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.QuestionPage', isPreLogin: false }
            },
            {
                path: 'questionarticle/:questionPageId/view/:questionArticleId',
                canActivate: [AuthGuard],
                component: QuestionArticleComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.QuestionArticle', isPreLogin: false }
            }
        ]
    }
];