<!-- <marquee style="background-color: #223f5c; color: #f9a34b; font-size: 20px; padding: 10px;">
    We are currently testing our deployment. You might experience some downtime. We apologize for the inconvenience and appreciate your patience.
</marquee> -->
<div class="prelogin-body">
    <div class="content-width">
        <div class="login-body">
            <div class="welcome-texts">
                <div>
                    <body-text class="font-light super-heading1 block">Welcome to <config-label label="PROGRAM_NAME">
                        </config-label>HeroesHub!</body-text>

                    <body-text class="font-light sub-heading1 block margin-top-20">You've been doing a fantastic job and
                        it's time to reap the rewards for all your hard work! Enter a world of limitless contests,
                        rewards and laurels, tailor-made just for you.
                    </body-text>
                </div>
            </div>
            <div class="form-card-container">
                <form [formGroup]="formLogin" id="loginForm" name="loginForm"
                    class="login-form-container round-card shadowed bg-color2" (ngSubmit)="save(formLogin)">

                    <div class="margin-bottom-20">
                        <body-text class="medium-size-plus-4">Enter HeroesHub</body-text>
                    </div>
                    <div class="margin-bottom-20 login-align-option">
                        <ng-container *ngFor="let loginType of loginTypes">

                            <div class="items">
                                <input type="radio" formControlName="login" value="{{loginType.value}}" [id]="loginType.value"
                                    (change)="changeLable()"> <label [for]="loginType.value" class="login-align-label"> {{loginType.name}}
                                </label>

                            </div>
                        </ng-container>
                    </div>

                    <div class="margin-bottom-10">
                        <form-input controlName="username" placeHolder="{{label| translate}}"></form-input>
                        <div class="help-block text-danger" *ngIf="!formLogin.controls.username.valid && isSubmitted">
                            <span [hidden]="!formLogin.controls['username'].hasError('required')"
                                translate="{{validationError | translate}}"></span>
                        </div>
                        <div class="help-block text-danger" *ngIf="formLogin.controls.username.errors?.pattern &&  isMobileNumber && formLogin.controls.username.touched">
                            <span 
                                translate="{{validationMobileNumberError | translate}}"></span>
                        </div>

                    </div>

                    <div class="margin-bottom-20" *ngIf="systemSettings.otpBasedLogin">
                        <input type="checkbox" class="margin-right" formControlName="otp" value="OTP"
                            (change)="useOTPasPassword($event)"> {{'ParticipantLogin.UseOTPAsPassword' | translate}}
                    </div>
                    <div class="margin-bottom-20">
                        <!--  <form-input controlName="password"  inputType="password" placeHolder="{{passwordLable | translate}}" ></form-input> -->
                        <input [type]="show ? 'text' : 'password'" class="form-control passwords" id="password"
                            name="password" formControlName="password" placeholder="{{passwordLable | translate}}" />
                        <span (click)="password()" class="{{show ? 'openEye' : 'closeEye'}}"></span>
                        <div class="help-block text-danger" *ngIf="!formLogin.controls.password.valid && isSubmitted">
                            <span [hidden]="!formLogin.controls['password'].hasError('required')"
                                translate="{{passwordErrorMessage | translate}}"></span>
                        </div>
                    </div>
                    <div class="otp-display" *ngIf="this.isOTPEnabled && this.isResendOTP">
                        <div class="otp-display-message">{{'ParticipantLogin.OtpValidityMessage'| translate}} </div>
                        <cs-timer [endDate]="expiryTime" [showOnlyMinutes]="'true'"></cs-timer>
                    </div>
                    <div class="margin-bottom-20" *ngIf="this.isOTPEnabled && this.isResendOTP">
                        <a class="otp-link" href="javascript:void(0);"
                            (click)="sendOTP()">{{'ParticipantLogin.RegenerateOTP' | translate}}</a>
                    </div>
                    <div class="margin-bottom-10">
                        <form-button text="Login" size="stretch" buttonType="submit"></form-button>
                    </div>
                    <div class="margin-bottom-20 text-center">
                        <a class="link-no-decor" [routerLink]="['/forgot']">
                            <body-text class="primary-color medium-size">{{'ParticipantLogin.ForgottenPassword' |
                                translate}}</body-text>
                        </a>
                    </div>
                    <div class="margin-bottom-20 text-center" *ngIf="isSelfRegistrationEnabled">
                        <a class="link-no-decor" [routerLink]="['register']">
                            <body-text class="primary-color medium-size">{{'ParticipantLogin.Register' | translate}}
                            </body-text>
                        </a>
                    </div>
                    <div class="separator1 margin-bottom-15"></div>

                    <div class="margin-bottom-10">
                        <a [routerLink]="['/activate']">
                            <form-button theme="theme4" size="stretch"
                                text="{{'ParticipantLogin.AccountActivation' | translate}}">
                            </form-button>
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>