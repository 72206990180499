import { Component, Input, OnInit } from "@angular/core";
import { CatalogueService } from "@core/shared/services/catalogue.service";
import { LoadingService } from "@app/core/shared";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, finalize } from "rxjs/operators";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-catalogueaccess",
  templateUrl: "./catalogueaccess.component.html",
  styleUrls: ["./catalogueaccess.component.scss"],
})
export class CatalogueaccessComponent implements OnInit {
  constructor(
    private catalogueService: CatalogueService,
    public loadingService: LoadingService,
    public route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}
  menuType;
  referenceId: string;
  otp: string;
  sendingOtp: boolean = false;

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.menuType = params.get("menuType");      
    });
  }

  sendOtp() {
    this.sendingOtp = true;
    this.loadingService
      .doLoading(this.catalogueService.sendOtp(), this)
      .pipe(
        catchError((error) => {
          let errorMessage = "";
            if (error.status == 500) {errorMessage = "Failed to send otp. Please try again.";}
            else{errorMessage = error.error.message;};

            console.error(errorMessage, error);
            this.toastr.error(errorMessage, 'Failed', {
          });
          return of(null);
        }),
        finalize(() => {
          this.sendingOtp = false;
        })
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.referenceId = response.referenceID;
            this.toastr.success('Otp sent successfully!', 'Success', {
              timeOut: 3000
            });
          }
        },
        error: (error) => {
          console.error("exception occured while sending otp", error);
          this.toastr.success('Failed to send otp. Please try again.', 'Failed', {
            timeOut: 3000
          });
        },
      });
  }

  rewardCatalogue() {
    if (this.menuType === "merchandise")
      this.catalogueService.redirectMerchandiseCatalogue(
        this.otp,
        this.referenceId
      );
    else if (this.menuType === "experience")
      this.catalogueService.redirectExperianceCatalogue(
        this.otp,
        this.referenceId
      );
    else if (this.menuType === "mastery")
        this.catalogueService.redirectMasteryCatalogue(
          this.otp,
          this.referenceId
        );
    this.otp = "";
  }
}
