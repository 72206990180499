<ul ngbNav #nav="ngbNav" (navChange)="navbarChange($event)" class="nav-tabs" [(activeId)]="active">
  <li ngbNavItem [ngbNavItem]="1">
    <a ngbNavLink>User Details</a>
    <ng-template ngbNavContent>
      <div id="tab-content1" class="content">
        <div id="selfRegistrationRoot"></div>
      </div>
    </ng-template>
  </li>
  <li ngbNavItem [ngbNavItem]="2">
    <a ngbNavLink>Activation</a>
    <ng-template ngbNavContent>
      <div id="tab-content2" class="content">
        <form [formGroup]="formActivationSelfRegistration" class="common-prelogin-form round-card shadowed bg-color2"
          (ngSubmit)="activate(formActivationSelfRegistration)">
          <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">
              {{
              'ParticipantActivation.Password' | translate
              }}<span class="mandatory"> * </span>
            </body-text>
            <form-input controlName="password" theme="theme2" inputType="password"
              placeHolder="{{'ParticipantActivation.Password' | translate}}">
            </form-input>
            <div class="help-block text-danger"
              *ngIf="!formActivationSelfRegistration.controls.password.valid && (formActivationSelfRegistration.controls.password.touched || submitted)">
              <span [hidden]="formActivationSelfRegistration.controls.password.valid">
                {{
                'ParticipantActivation.PasswordDoestNotMeetRequirements.PasswordDoesNotMeetMinimumRequirement'
                | translate
                }}
                <br />
                {{ 'ParticipantActivation.PasswordDoestNotMeetRequirements.Must' | translate }}
                <ul>
                  <li>
                    {{
                    'ParticipantActivation.PasswordDoestNotMeetRequirements.BeAtleast10Characters' |
                    translate
                    }}
                  </li>
                  <li>
                    {{
                    'ParticipantActivation.PasswordDoestNotMeetRequirements.IncludeAtLeastOneNumber'
                    | translate
                    }}
                  </li>
                  <li>
                    {{
                    'ParticipantActivation.PasswordDoestNotMeetRequirements.IncludeAtLeastOneLowercaseLetter'
                    | translate
                    }}
                  </li>
                  <li>
                    {{
                    'ParticipantActivation.PasswordDoestNotMeetRequirements.IncludeAtLeastOneUppercaseLetter'
                    | translate
                    }}
                  </li>
                  <li>
                    {{
                    'ParticipantActivation.PasswordDoestNotMeetRequirements.IncludeAtLeastOneSpecialCharacter'
                    | translate
                    }}
                  </li>
                </ul>
              </span>
            </div>
          </div>

          <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">
              {{
              'ParticipantActivation.ConfirmPassword' |
              translate
              }}<span class="mandatory"> * </span>
            </body-text>
            <form-input controlName="confirmPassword" theme="theme2" inputType="password"
              placeHolder="{{'ParticipantActivation.ConfirmPassword' | translate}}">
            </form-input>
            <div class="help-block text-danger"
              *ngIf="!formActivationSelfRegistration.controls.confirmPassword.valid && (formActivationSelfRegistration.controls.confirmPassword.touched || submitted)">
              <span [hidden]="formActivationSelfRegistration.controls.confirmPassword.valid"
                translate="ParticipantActivation.ConfirmPasswordMustMatch"></span>
            </div>
          </div>

          <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">
              {{
              'ParticipantActivation.SecurityQuestion' |
              translate
              }}<span class="mandatory"> * </span>
            </body-text>
            <form-button-select [value]="formActivationSelfRegistration.get('securityQuestion').value" theme="theme2"
              [options]="securityQuestion" placeHolder="{{ 'Shared.SelectLists.PleaseSelect' | translate}}"
              optionsLabelKey="label" optionstrackbyKey="value" [translateLabel]="true"
              (onChange)="securityQuestionChange($event)">
            </form-button-select>
            <div class="help-block text-danger"
              *ngIf="!formActivationSelfRegistration.controls.securityQuestion.valid && (formActivationSelfRegistration.controls.securityQuestion.touched || submitted)">
              <span [hidden]="!formActivationSelfRegistration.controls['securityQuestion'].hasError('required')">
                *
                Security question is required.
              </span>
            </div>
          </div>

          <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">
              {{
              'ParticipantActivation.SecurityAnswer' |
              translate
              }}<span class="mandatory"> * </span>
            </body-text>
            <form-input controlName="securityAnswer" theme="theme2"
              placeHolder="{{'ParticipantActivation.SecurityAnswer' | translate}}">
            </form-input>
            <div class="help-block text-danger"
              *ngIf="!formActivationSelfRegistration.controls.securityAnswer.valid && (formActivationSelfRegistration.controls.securityAnswer.touched || submitted)">
              <span [hidden]="!formActivationSelfRegistration.controls['securityAnswer'].hasError('required')"
                translate="ParticipantActivation.SecurityAnswerRequired"></span>
            </div>
          </div>

          <div class="margin-bottom-10">
            <div class="mt-4 d-flex align-items-center">
              <form-checkbox controlName="termsAccepted" class="d-inline-block"
                [value]="formActivationSelfRegistration.get('termsAccepted').value"></form-checkbox>
              <a class="text-decoration-underline anchor-link" (click)="termsAndConditions(terms)">
                {{ 'ParticipantActivation.TermsAndConditions' |translate}}
              </a>
              <span class="mandatory"> * </span>
            </div>
            <div class="help-block text-danger"
              *ngIf="!formActivationSelfRegistration.controls.termsAccepted.valid && submitted">
              <span [hidden]="formActivationSelfRegistration.controls.termsAccepted.valid"
                translate="ParticipantActivation.YouMustAcceptTerms"></span>
            </div>
            <div class="mt-4 d-flex align-items-center">
              <form-checkbox controlName="preferenceAccepted" class="d-inline-block"
                [value]="formActivationSelfRegistration.get('preferenceAccepted').value"></form-checkbox>
              <a class="anchor-link">{{ 'ParticipantActivation.Notificationpreference' |translate }}</a>
            </div>
            <div class="mt-5">
              <form-button text="{{ 'ParticipantActivation.Submit' | translate }}" theme="theme5" size="stretch">
              </form-button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
<ng-template #terms let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'ParticipantActivation.TermsAndConditions' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div innerHtml="{{ 'ParticipantActivation.Terms' | translate }}"></div>
  </div>
</ng-template>