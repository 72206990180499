import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';
import { CashrewardsComponent } from './cashrewards.component';

export const CashRewardsRoute: Routes = [
    {
      path: 'cashrewards',
      canActivate: [AuthGuard],
      component: CashrewardsComponent,
      pathMatch: 'full',
      data: { title: 'PageTitles.CashRewards', isPreLogin: false }
    }
];
