<div  *ngIf="validationResultSummary">
    <div class="p-4 validation-msg">        
        <div>
            <h4>Pre-import results</h4>
            <p>{{validationResultSummary.numberOfRows}} Total rows</p>
            <p class="hasError" *ngIf="validationResultSummary.numberOfRecordsWithErrors>0">{{validationResultSummary.numberOfRecordsWithErrors}} Total invalid rows</p>
            <p *ngIf="validationResultSummary.appendNewRecords">{{validationResultSummary.numberOfRecordsToAdd}} Total records to add</p>
            <p *ngIf="validationResultSummary.updateExistingRecords">{{validationResultSummary.numberOfRecordsToUpdate}} Total records to update</p>
            <p *ngIf="validationResultSummary.deleteMissingRecords">{{validationResultSummary.numberOfRecordsUnchanged}} Total records unchanged</p>
            <p *ngIf="validationResultSummary.numberOfRecordsUnchanged">{{validationResultSummary.numberOfRecordsToRemove}} Total records to remove</p>
        </div>   
    </div>    
</div>