import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GoalQuestService } from '@core/private/goalquest/services/goal-quest.service';
import { LoadingService } from '@core/shared';

@UntilDestroy()
@Component({
    selector: 'app-goal-quest-manager',
    templateUrl: 'goal-quest.component.html',
    styleUrls: ['../../scss/goal-quest-manager.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GoalQuestManagerComponent implements OnInit {
    goalQuestPageId: string;
    goalQuest: any;
    mostRedeemedProducts: any;
    goalSelectionRemainingTime: number;
    goalProgressRemainingTime: number;
    isMinimumQualifierEnabled: boolean;
    activeStage: number;

    public destroyed = new Subject<any>();

    constructor(private activeRoute: ActivatedRoute, private router: Router, private goalQuestService: GoalQuestService,
        public loadingService: LoadingService) { }

    ngOnInit() {
        this.router.events.pipe(
            filter((event) => event instanceof RouterEvent ),
            startWith('Initial call'),
            takeUntil(this.destroyed)
        ).subscribe((res : NavigationEnd) => {
            if (this.goalQuestPageId != this.activeRoute.snapshot.params.id) {
                this.goalQuestPageId = this.activeRoute.snapshot.params.id;
                this.getGoalQuestPages();
            }
        });
    }

    getGoalQuestPages() {
        this.loadingService.doLoading(this.goalQuestService.getManagerGoalQuestPages(this.goalQuestPageId), this)
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.goalQuest = response;
                this.calculateGoalQuestProgress();
            });
    }

    calculateGoalQuestProgress() {
        if (this.goalQuest.goalsSelectedUsersCounts && this.goalQuest.goalsSelectedUsersCounts.length > 0) {
            this.goalSelectionRemainingTime = this.calculateDateDifference(new Date(), new Date(this.goalQuest.goalSelectionLastDate))
            this.goalProgressRemainingTime = this.calculateDateDifference(new Date(), new Date(this.goalQuest.promotionLastDate))
            this.isMinimumQualifierEnabled = this.goalQuest.isMinimumQualifierEnabled;
            this.findStage();
        }
    }

    calculateDateDifference(startDate, endDate) {
        var startDateUtc = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
        var endDateUtc = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());

        return (endDateUtc - startDateUtc) / 1000;
    }

    findStage() {
        if (this.goalSelectionRemainingTime > 0) {
            this.activeStage = 1;
        }
        else if (this.goalProgressRemainingTime > 0) {
            this.activeStage = 2;
        }
        else {
            this.activeStage = 3;
        }
    }

    downloadGoalProgress() {
        this.goalQuestService.downloadGoalProgress(this.goalQuestPageId).subscribe((res: any) => { });
    }

    downloadGoalSelection() {
        this.goalQuestService.downloadGoalSelection(this.goalQuestPageId).subscribe((res: any) => { });
    }
}