import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { GameArcadeComponent } from './game-arcade/game-arcade.component';
import { EngageComponent } from './engage.component';

export const GameRoutes: Routes = [    
{
        path: 'engage',
        component: EngageComponent,
        children: [
            {
                path: 'gamearcade/index/:id',
                canActivate: [AuthGuard],
                component: GameArcadeComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.Games', isPreLogin: false, isDashboard: true }
            }
        ]
    }
];