import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { NotFoundComponent } from './not-found/not-found.component';

export const ErrorRoutes: Routes = [
    {
        path: 'error/accessdenied',
        canActivate: [AuthGuard],
        component: AccessDeniedComponent,
        pathMatch: 'full',
        data: { title: 'Access Denied', isPreLogin: false, isDashboard: true }
    },
    {
        path: 'error/notfound',       
        component: NotFoundComponent,
        pathMatch: 'full',
        data: { title: 'Access Denied', isPreLogin: false, isDashboard: true }
    }
];