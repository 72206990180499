<div class="home-page">
    <div class="bg-color3 home-page-bg">
        <div class="content-width home-point-gallery-section">
            <!-- Right side -->
            <div class="flex-1 home-page-slide">
                <!--   <h1 class="pageHeader">Power BI Report</h1> -->

                <div class="claim-filter-section" [appIsLoading]="loadingService.isLoading(this)">

                    <div class="bottomMargin">

                        <div class="container questionArticle">
                            <div *ngIf="questionArticle">
                                <p></p>
                                <h1>{{'PageTitles.QuestionArticle' | translate}}</h1>
                                <div class="questionArticleSection" [innerHTML]="questionArticle.fullText"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- Left side -->
            <div class="home-left" id="divshow">
                <cs-profile-point-card theme="theme-white" buttonTheme="theme2"></cs-profile-point-card>
                <cs-most-redeem-products [singleView]="true"></cs-most-redeem-products>
            </div>
        </div>
    </div>
</div>