import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { DownloadService, HttpService } from '@core/shared';
import {
  UrlBuilder, GET_GOALQUEST_PAGES, GET_MANAGER_GOALQUEST_PAGES, GET_MOST_REDEEMED_PRODUCTS, CHANGE_REWARD,
  DOWNLOAD_GOAL_PROGRESS, DOWNLOAD_GOAL_SELECTION
} from '@core/core-api-variables';

@Injectable()
export class GoalQuestService extends DownloadService {
  constructor(public http: HttpService) {
    super();
  }

  getGoalQuestPages(goalQuestId, culture, fallBackCulture) {
    let data = { goalQuestId, culture, fallBackCulture };
    let url = UrlBuilder(GET_GOALQUEST_PAGES, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getManagerGoalQuestPages(goalQuestId) {
    let data = { goalQuestId };
    let url = UrlBuilder(GET_MANAGER_GOALQUEST_PAGES, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getMostRedeemedProducts() {
    return this.http.get(GET_MOST_REDEEMED_PRODUCTS).pipe(map((response: any) => {
      return response;
    }));
  }

  changeReward(payload) {
    let url = CHANGE_REWARD;
    return this.http.post(url, payload).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadGoalProgress(goalQuestId) {
    let data = { goalQuestId };
    let url = UrlBuilder(DOWNLOAD_GOAL_PROGRESS, data);
    return this.http.download(url).pipe(map((response: any) => {
      this.downloadFile(response, 'Users Goal Progress.xlsx');
      return true;
    }));
  }

  downloadGoalSelection(goalQuestId) {
    let data = { goalQuestId };
    let url = UrlBuilder(DOWNLOAD_GOAL_SELECTION, data);
    return this.http.download(url).pipe(map((response: any) => {
      this.downloadFile(response, 'Users Goal Selection.xlsx');
      return true;
    }));
  }
}
