import { Component } from '@angular/core';

@Component({
    selector: 'cs-main-navbar',
    templateUrl: './main-navbar.component.html',
    styleUrls: ['./main-navbar.component.scss']
})
export class MainNavbarComponent {
    public mobileActive: boolean = false;
    public navbar: any;

    constructor() {
        this.navbar = [
            { label: "Home", targetState: "main.home", group: "home" },
            { label: "My Benefits", targetState: "main.aboutProgram.benefits", group: "benefits" },
            { label: "My Contests", targetState: "main.promotions.aot", group: "promotions" },
            { label: "Claims", targetState: "main.myClaims", group: "Claims" },
            { label: "Rewards", targetState: "replace" },
            { label: "News", targetState: "main.news" }
        ]
    }
}