import { Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-toggle-switch-component",
  templateUrl: "./toggle-switch-component.component.html",
  styleUrls: ["./toggle-switch-component.component.scss"],
})
export class ToggleSwitchComponentComponent {
  @Output() changed = new EventEmitter<boolean>();
  @Input() selected;
  @Input() disabled = false;
  // check: boolean;
  
  toggle() {
    if (!this.disabled) {
      this.selected = !this.selected;
      this.changed.emit(this.selected);
    }
  }
}
