<div class="profile-status-charts">
    <div class="profile-status-grid-parent">
        <div>
            <div class="vertical-tabs parent">
                <button *ngFor="let tabItem of tabs;index as i" class="tab-item {{tabItem.css}}"
                [ngClass]="{'active':i==selectedTab}"
                    (click)="changeTab(i)">
                    <span [textContent]="tabItem.label"></span>                    
                </button>
            </div>
        </div>
        <div class="tab-body">
            <div class="content-width">
                <ng-template appProfileHost></ng-template>
            </div>
        </div>
    </div>
</div>