import { Component, Input, AfterViewChecked, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { HttpService } from "@app/core/shared/services/http.service";
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Pagination } from '@core/shared/models/pagination';
import { Sorter } from '@core/shared/models/sorter';
import { MyThresholdAwardsService } from '../services/my-threshold-awards.service';
import { LoadingService } from '@core/shared';
import { SearchCriteria } from '@app/core/shared/models/search-criteria';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { browserRefresh } from '@app/app.component';

@UntilDestroy()
@Component({
    selector: 'app-my-threshold-awards',
    templateUrl: './my-threshold-awards.component.html',
    styleUrls: ['./my-threshold-awards.component.scss']
})
export class MyThresholdAwardsComponent implements OnInit, AfterViewChecked {
    @Input() componentId: string;
    @Input() payload: any;
    maxFromDate: any;
    minFromDate: any;
    maxToDate: any;
    minToDate: any;
    myawards: any;
    pointsHistory = [];
    skuPointsStructure = [];
    skuChart = [];
    activeId = 0;
    public thresholdAwardsForm: FormGroup;
    paginationDetails: Pagination = new Pagination();
    searchCriteria: SearchCriteria = new SearchCriteria('calculatedDate');
    sorter: Sorter = new Sorter('calculatedDate', false);
    totalNoOfItems: number = 0;
    progressTargets = [];
    myThresholdAwardsPageId;
    promotionId;
    attributeJson;
    showProgressBar = false;
    userachievements = [];
    targetPoints: any = { "earnedPoints": 0, "earnedQty": 0, "achievePoints": 0 };
    tableHeadingDynamic: any = "SKU";
    targetPointsList = [];
    navigationStartSubscription: Subscription;
    languageChangeSubscription: Subscription;
    browserRefresh: boolean;
    constructor(public http: HttpService, public myThresholdAwardsService: MyThresholdAwardsService, public loadingService: LoadingService, private router: Router,
        private activeRoute: ActivatedRoute,  public translate: TranslateService) {
        this.thresholdAwardsForm = new FormGroup({
            invoiceNumber: new FormControl(''),
            fromDate: new FormControl(''),
            toDate: new FormControl('')
        });
        this.maxFromDate = { year: "2011", month: "01", day: "01" };
    }
    get invoiceNumber(): AbstractControl {
        return this.thresholdAwardsForm.get('invoiceNumber');
    }
    get fromDate(): AbstractControl {
        return this.thresholdAwardsForm.get('fromDate');
    }
    get toDate(): AbstractControl {
        return this.thresholdAwardsForm.get('toDate');
    }
    ngOnInit() {
        this.navigationStartSubscription = this.router.events.pipe(
            filter((event) => event instanceof RouterEvent ),
            startWith('Initial call')
        ).subscribe((res : NavigationEnd) => {
            if (this.myThresholdAwardsPageId != this.activeRoute.snapshot.params.id) {
                this.browserRefresh = browserRefresh;
                this.myThresholdAwardsPageId = this.activeRoute.snapshot.params.id;
                this.getThreshold();
            }
        });

        this.languageChangeSubscription = this.translate.onLangChange.subscribe(() => {
            if (!this.browserRefresh)
                this.getThreshold();
            else
                this.browserRefresh = false;
        });
    }

    ngAfterViewChecked() {
        this.fromDate.valueChanges.subscribe(value => {
            this.blockOldDate("from", value);
        });
        this.toDate.valueChanges.subscribe(value => {
            this.blockOldDate('to', value);
        });
    }

    getThreshold() {
        this.myThresholdAwardsService.getMyThresholdAwardsPageDetails(this.myThresholdAwardsPageId).pipe().subscribe(response => {
            let thresholdAwardDetails = response;
            this.promotionId = thresholdAwardDetails.promotionId;
            this.attributeJson = response.attributeJson ? JSON.parse(response.attributeJson) : '';
            const promotionID = { 'id': this.promotionId };
            this.myThresholdAwardsService.getSKUPointsStructure(promotionID).subscribe((thresholdResponse) => {
                this.skuPointsStructure = thresholdResponse;
                this.skuChart = [];
                let obj = { "product": "", "skuStructure": [], "tabSelect": 0 };
                let prevProductValue = '';
                for (let i of this.skuPointsStructure) {
                    if (prevProductValue && i.product != prevProductValue) {
                        if (this.skuChart.length == 0) {
                            obj.tabSelect = 1;
                        }
                        this.skuChart.push(obj);
                        obj = { "product": "", "skuStructure": [], "tabSelect": 0 };
                    }
                    prevProductValue = i.product;
                    obj.product = i.product;
                    const tempObj = { 'minQuantity': i.minQuantity, 'maxQuantity': i.maxQuantity, 'points': i.points, "qtyWidth": 0, 'pointsWidth': 0 };
                    obj.skuStructure.push(tempObj);
                }
                if (obj.product) {
                    this.skuChart.push(obj);
                    obj = { "product": "", "skuStructure": [], "tabSelect": 0 };
                }
                this.loadingService.doLoading(this.myThresholdAwardsService.getUserAchievement(promotionID), this)
                    .pipe(untilDestroyed(this)).subscribe(userAchievmentResponse => {
                        this.userachievements = userAchievmentResponse;
                        if (this.skuPointsStructure[0])
                            this.changeChartValue(this.skuPointsStructure[0].product);
                    });
            });
            this.getPointHistory(0);
            this.getPromotion();
        });
    }

    getPointHistory(filterApply) {
        this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
        this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
        this.searchCriteria.sortField = this.sorter.sortColumn;
        this.searchCriteria.additionalData = this.payload;
        this.searchCriteria.sortAscending = this.sorter.sortDirection;
        const data = {
            "itemsPerPage": this.paginationDetails.itemsPerPage, "pageNumber": this.paginationDetails.pageNumber,
            "sortField": this.sorter.sortColumn, "sortAscending": this.sorter.sortDirection,
            "fromSale": filterApply && this.fromDate.value != "" ? `${this.dateFormat(this.fromDate.value, true)}T00:00:00` : "",
            "toSale": filterApply && this.toDate.value != "" ? `${this.dateFormat(this.toDate.value, true)}T23:59:59.999` : "",
            "promotionID": this.promotionId,
            "searchTerm": "",
            "type": filterApply ? this.invoiceNumber.value : ""
        };
        this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
        this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
        this.searchCriteria.sortField = this.sorter.sortColumn;
        this.searchCriteria.additionalData = this.payload;
        this.searchCriteria.sortAscending = this.sorter.sortDirection;
        this.loadingService.doLoading(this.myThresholdAwardsService.getPointHistory(data), this)
            .pipe(untilDestroyed(this)).subscribe(response => {
                if (response.items.length > 0) {
                    this.totalNoOfItems = response.items.length;
                }
                else {
                    this.totalNoOfItems = 0;
                }
                this.pointsHistory = response.items;
                // this.pointsHistory = [{"description": "Value: description 1","calculatedDate": "from date", "points": "points 1" }, {"description": "Value: description 1","calculatedDate": "from date", "points": "points 1" }, {"description": "Value: description 1","calculatedDate": "from date", "points": "points 1" }, {"description": "Value: description 1","calculatedDate": "from date", "points": "points 1" }, {"description": "Value: description 1","calculatedDate": "from date", "points": "points 1" }]
                this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
                // this.paginationDetails.totalNumberOfItems = this.pointsHistory.length;
            });
    }
    getPromotion() {
        this.loadingService.doLoading(this.myThresholdAwardsService.getPromotion(this.promotionId), this)
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.tableHeadingDynamic = response.attributesJson && JSON.parse(response.attributesJson)['thresholdConfigurationOn'] ?
                    JSON.parse(response.attributesJson)['thresholdConfigurationOn'] : '';
            });
    }

    onChange(pagination: Pagination) {
        this.paginationDetails = pagination;
        this.getPointHistory(0);
    }

    onSort(sortField) {
        this.sorter.sort(sortField);
        this.getPointHistory(0);
    }
    changeChartValue(product) {
        let value = this.skuChart.filter(x => x.product == product);
        const userachievementsCurrentData: any = this.userachievements.filter(x => x.product == product);
        value = value[0].skuStructure;
        if (!value[0].isCalculated) {
            const obj: any = { "earnedPoints": 0, "earnedQty": 0, "qtyToReachNextLvl": 0, "product": product, "percentageBarColor": 0 };
            let qtyWidth = 100 / (value.length);
            let prevQtyWidth = 0;
            let percentageThemeColor = 101;
            if (value[0] && value[0].minQuantity != 0) {
                prevQtyWidth = 12;
                qtyWidth = 88 / (value.length);
            }
            if (userachievementsCurrentData[0]) {
                obj.earnedPoints = userachievementsCurrentData[0].points;
                obj.earnedQty = userachievementsCurrentData[0].quantity;
            }
            for (let i of value) {
                i.qtyWidth = ((1) * qtyWidth + prevQtyWidth) + '%';
                i.pointsWidth = ((qtyWidth) / 2 + prevQtyWidth - 2) + '%';
                i.isCalculated = true;
                if (userachievementsCurrentData[0] && i.maxQuantity && i.maxQuantity >= obj.earnedQty
                    && i.minQuantity <= obj.earnedQty) {
                    if (value[value.length - 1].maxQuantity != i.maxQuantity) {
                        obj.qtyToReachNextLvl = (i.maxQuantity - obj.earnedQty);
                    }
                    obj.percentageBarColor = (qtyWidth / (i.maxQuantity - i.minQuantity)) *
                        (obj.earnedQty - i.minQuantity + 1) + prevQtyWidth;
                } else if (userachievementsCurrentData[0] && !i.maxQuantity && i.minQuantity <= obj.earnedQty) {
                    obj.percentageBarColor = 100;
                }
                if (i.minQuantity)
                    i.minQuantity = i.minQuantity + ' Qty';
                if (i.maxQuantity)
                    i.maxQuantity = i.maxQuantity + ' Qty';
                prevQtyWidth = ((1) * qtyWidth + prevQtyWidth);
                i.bgTransparentDot = (prevQtyWidth > percentageThemeColor);
            }
            this.targetPointsList.push(obj);
        }
        this.targetPoints = this.targetPointsList.filter(x => x.product == product)[0];
        this.progressTargets = value;
        this.showProgressBar = true;
    }
    blockOldDate(label, value) {
        if (label === 'from') {
            let tempFromDate = new Date(this.convertToDateFormat(value));
            if (value !== null) {
                this.minToDate = { year: tempFromDate.getFullYear(), month: tempFromDate.getMonth() + 1, day: tempFromDate.getDate() };
            }
        }
        else {
            let tempToDate = new Date(this.convertToDateFormat(value));
            if (value !== null) {
                this.maxFromDate = { year: tempToDate.getFullYear(), month: tempToDate.getMonth() + 1, day: tempToDate.getDate() };
            }
        }
    }

    convertToDateFormat(date) {
        let splitDate1 = date.split('-');
        let day = splitDate1[0];
        let month = splitDate1[1];
        let year = splitDate1[2];
        return month + "-" + day + "-" + year;
    }
    dateFormat(date, flag) {
        if (flag) {
            date = date.split('-');
            return date[2] + '-' + date[1] + '-' + date[0];
        }
        date = new Date(date);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        const yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        return dd + '/' + mm + '/' + yyyy;
    }
    search() {
        if (this.invoiceNumber.value || (this.toDate.value && this.fromDate.value)) {
            this.getPointHistory(1);
        } else {
            return;
        }
    }
    reset() {
        this.thresholdAwardsForm.reset();
        this.getPointHistory(0);
        return
    }

}
