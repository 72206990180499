import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
    selector: 'form-toggle-checkbox',
    templateUrl: 'form-toggle-checkbox.component.html',
    styleUrls: ['form-toggle-checkbox.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ]
})

export class FormToggleCheckbox {
    @Input() groupName: string;
    @Input() controlName: string;
    @Input() onLabel: string;
    @Input() offLabel: string;
    @Output() onChangeEvent = new EventEmitter();

    changeValue() {        
        this.onChangeEvent.emit();
    }
}