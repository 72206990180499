<div class="aot" [appIsLoading]="loadingService.isLoading(this, 'aot') || loadingService.isLoading(this, 'hub')">
    <div class="bg-color2 calendar-contest-period">
        <app-hub-cms [componentId]="communicationPage[0].Items[0].selectedItem.id" [culture]="selectedCulture"
            [payload]="communicationPage[0].Items[0]"
            *ngIf="communicationPage && arrayHasIndex(communicationPage, 0) && communicationPage[0].Items.length > 0">
        </app-hub-cms>

        <div class="flex flex-align-right bg-color2" *ngIf="communicationPage && communicationPage.length>0">
            <div class="contest-time">
                <div class="icon">
                    <img src="/assets/images/calendar.png" alt="" />
                </div>
                <div class="body">
                    <div>
                        <body-text class="block bold">Contest Period: </body-text>
                        <body-text class="block bold">{{promotion.startDate | localizedDate }} - {{promotion.endDate
                            | localizedDate}}</body-text>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grid padding-20 bg-color2">
        <app-hub-cms [componentId]="communicationPage[0].Items[1].selectedItem.id" [culture]="selectedCulture"
            [payload]="communicationPage[0].Items[1]"
            *ngIf="communicationPage && arrayHasIndex(communicationPage, 0) && communicationPage[0].Items.length > 1">
        </app-hub-cms>
    </div>

    <div class="bg-color2" [class]="{'padding-20': index!=0}"
        *ngFor="let groups of communicationPage;let index = index">
        <div *ngIf="index != 0" class="grid grid-gap-35 grid-col-xs-100 grid-col-xxs-100 grid-col-lt-100 grid-col-sm-100 "
            [class]="{'grid-col-hd-100': groups.Items.length === 1, 'grid-col-md-100': groups.Items.length === 1,
            'grid-col-hd-50': groups.Items.length === 2, 'grid-col-md-50': groups.Items.length === 2,
            'grid-col-hd-33': groups.Items.length === 3, 'grid-col-md-33': groups.Items.length === 3}">
            <div *ngFor="let item of groups.Items">
                <div [ngSwitch]="item.type">
                    <app-hub-cms *ngSwitchCase="'cms'" [componentId]="item.selectedItem.id" [culture]="selectedCulture"
                        [payload]="item"></app-hub-cms>
                    <app-hub-performance-page *ngSwitchCase="'promotion'" [componentId]="item.selectedItem.id"
                        [culture]="selectedCulture" [payload]="item">
                    </app-hub-performance-page>
                    <app-hub-league-page *ngSwitchCase="'league'" [componentId]="item.selectedItem.id"
                        [culture]="selectedCulture" [payload]="item"></app-hub-league-page>
                    <app-hub-monthly-sales *ngSwitchCase="'monthlysales'" [componentId]="item.selectedItem.id"
                        [culture]="selectedCulture" [payload]="item" [promotion]="promotion">
                    </app-hub-monthly-sales>
                    <app-hub-message-quicktip *ngSwitchCase="'message'" [componentId]="item.selectedItem.id"
                        [culture]="selectedCulture" [payload]="item"></app-hub-message-quicktip>
                </div>
            </div>
        </div>
    </div>
</div>