<div [appIsLoading]="loadingService.isLoading(this, 'claim')">
    <div *ngIf="content && content.showInstructions">
        <div [innerHtml]="content.instructions | sanitizeHtml"></div>
    </div>
    <div *ngIf="paginationDetails.totalNumberOfItems > 0">
        <div class="coupon-claim-history-filter">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div *ngIf="paginationDetails.totalNumberOfItems>0">
                        <p>{{'TableRecords.FoundTotalOf' |
                            translate}}{{paginationDetails.totalNumberOfItems}}{{'TableRecords.matches'
                            | translate}}</p>
                    </div>
                </div>
                <cs-items-per-page class="col-sm-6 text-right" [paginationDetails]="paginationDetails"
                    (onChangeEvent)="onPaginationChange($event)">
                </cs-items-per-page>
            </div>
            <div class="table-width">
                <table class="table table-striped" aria-describedby="Sales Claim">
                    <thead>
                        <tr>
                            <th *ngFor="let attributeHeader of attributeHeaders" scope="col">
                                <span>{{attributeHeader.name}}</span>
                            </th>
                            <th scope="col">{{'SalesClaim.SalesClaimTable.ClaimDate' | translate}} </th>
                            <th scope="col">{{'SalesClaim.SalesClaimTable.ClaimStatus' | translate}}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let sale of sales">
                            <td *ngFor="let attribute of sale.attributes">
                                <span *ngIf="attribute.dataType.name !== 'Date'">{{attribute.valueAsString}}</span>
                                <span *ngIf="attribute.dataType.name === 'Date'">{{attribute.valueAsString |
                                    date}}</span>
                            </td>
                            <td> {{sale.dateOfStatusChange | localizedDate}}</td>
                            <td> {{sale.claimStatus.name}}</td>
                            <td>
                                <button class="btn btn-primary alert-default custombtn" (click)="claim(sale)"
                                    *ngIf="sale.canBeClaimed && claimActionText(sale) != ''">
                                    {{claimActionText(sale) | translate}}
                                </button>
                                <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Challenge">
                                    <button class="challenge" (click)="challenge(sale)"
                                        *ngIf="sale.canBeChallenged && challengeActionText(sale) != ''">
                                        <!--   {{challengeActionText(sale) | translate}} -->
                                    </button>
                                </span>

                                <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Resolve">
                                    <span class="resolve" (click)="openResolve(sale)"
                                        *ngIf="sale.canBeResolved && (sale.claimStatus.name === 'Challenged') && userIsResolver">
                                        <!--  {{'SalesSearch.SalesList.ActionButton.Resolve' | translate}} -->
                                    </span>
                                </span>

                                <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Approve">
                                    <button type="button" class="success" (click)="approve(sale)"
                                        *ngIf="sale.requiresApproval && (sale.claimStatus.name === 'Claimed') && userIsResolver"
                                        [disabled]="!sale.canBeApproved">
                                        <!--   {{'SalesSearch.SalesList.ActionButton.Approve' | translate}} -->
                                    </button>
                                </span>
                                <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Reject">
                                    <button class="reject" (click)="showRejectModal(sale)"
                                        *ngIf="sale.requiresApproval && (sale.claimStatus.name === 'Approved') && userIsResolver"
                                        [disabled]="!sale.canBeApproved">
                                        <!--  {{'SalesSearch.SalesList.ActionButton.UnApprove' | translate}} -->
                                    </button>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 pagination-center-Bottom">
                <cs-custom-pagination [paginationDetails]="paginationDetails"
                    (onChangeEvent)="onPaginationChange($event)">
                </cs-custom-pagination>
            </div>
        </div>
    </div>
</div>