import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { MyPerformanceService } from '../services/my-performance.service';
import { browserRefresh } from '@app/app.component';

@Component({
    selector: 'app-my-performance',
    templateUrl: 'my-performance.component.html',
    styleUrls: ['my-performance.component.scss']
})

export class MyPerformanceComponent implements OnInit, OnDestroy {
    promotionId: string;
    mytarget: any;
    minTarget: number;
    maxTarget: number;
    isPromotionStarted: boolean;
    isPromotionEnded: boolean;
    isMultiple: boolean;
    isManualReward: boolean;
    browserRefresh: boolean;
    navigationStartSubscription: Subscription;

    constructor(private activeRoute: ActivatedRoute, private router: Router, private performanceService: MyPerformanceService) { }

    ngOnInit() {
        this.navigationStartSubscription = this.router.events.pipe(
            filter((event) => event instanceof RouterEvent ),
            startWith('Initial call')
        ).subscribe((res : NavigationEnd) => {
            if (this.promotionId != this.activeRoute.snapshot.params.id) {
                this.browserRefresh = browserRefresh;
                this.promotionId = this.activeRoute.snapshot.params.id;
                this.getMyTarget();
            }
        });
    }

    getMyTarget() {
        this.performanceService.getMyTarget(this.promotionId).subscribe(response => {
            this.mytarget = response;
            this.isManualReward = response.currency === 'Manual Award' ? true : false;
            if (this.mytarget.targets.length > 0) {
                this.isPromotionStarted = this.calculateDateDifference(new Date(), new Date(this.mytarget.startDate)) < 0;
                this.isPromotionEnded = this.calculateDateDifference(new Date(), new Date(this.mytarget.endDate)) < 0;
                this.minTarget = this.mytarget.targets[0].target;
                this.maxTarget = this.mytarget.targets[this.mytarget.targets.length - 1].target;
                this.isMultiple = this.mytarget.targets.length > 1;
            }
        });
    }

    calculateDateDifference(startDate, endDate) {
        var startDateUtc = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
        var endDateUtc = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());

        return (endDateUtc - startDateUtc) / 1000;
    }

    ngOnDestroy(): void {
        if (this.navigationStartSubscription) {
            this.navigationStartSubscription.unsubscribe();
        }
    }
}