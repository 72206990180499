import { Component, ViewContainerRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { AuthenticationService } from '@core/startup'
import { browserRefresh } from '@app/app.component';
import { QuizAndQuitionnaireComponent } from '../quiz-questionnaire/quiz-questionnaire.component';
import { BriksDirective } from '../directives/briks.directive';

@Component({
  selector: 'quiz-quitionnaire-participants',
  templateUrl: './quiz-participant.component.html',
  styleUrls: ['./quiz-participant.component.scss']
})
export class QuizParticipantComponent {  
  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;
  browserRefresh: boolean;
  selectedCulture: any;

  promotionId: string;
  currentUser: any;
  enableContent: boolean = true;
  isAdmin: boolean = false;
  userTimeZone: string;
  @ViewChild(BriksDirective, { static: true }) briksHost!: BriksDirective;

  constructor(private route: ActivatedRoute, private router: Router, public authService: AuthenticationService,
    private translate: TranslateService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent ),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      this.selectedCulture = localStorage.getItem('culture');
      if (this.promotionId != this.route.snapshot.params.id) {
        this.browserRefresh = browserRefresh;
        this.promotionId = this.route.snapshot.params.id;
        this.getUser();
      }
    });

    this.languageChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedCulture = localStorage.getItem('culture');
      if (!this.browserRefresh) {
        this.promotionId = this.route.snapshot.params.id;
        this.getUser();
      }
      else
        this.browserRefresh = false;
    });
  }

  getUser() {
    this.authService.getCurrentUser().subscribe(response => {
      this.currentUser = response;
      this.userTimeZone = response.timeZone;
      this.renderBriks();
    });
  }

  renderBriks() {
    const viewContainerRef = this.briksHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(QuizAndQuitionnaireComponent);
    componentRef.instance.promotionId = this.promotionId;
    componentRef.instance.enableContent = this.enableContent;
    componentRef.instance.isAdmin = this.isAdmin;
    componentRef.instance.culture = this.selectedCulture;
    componentRef.instance.userTimeZone = this.userTimeZone;
  }
}