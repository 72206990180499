<div #dataLoading="appIsLoading" [appIsLoading]="loadingService.isLoading(this, 'participantgq')">
    <div *ngIf="goalQuest && goalQuest.rewards.length > 0 && isProgramStarted">
        <div class="gq-goalstatus-container">
            <div class="gq-goalstatus-wrapper" *ngIf="showProgress && progressStyle">
                <div class="item">
                    <div class="gq-btn-circle btn-md" [class]="progressStyle.stage1.active"></div>
                    <div class="currentGoalProgress" [class]="progressStyle.stage1.selected">
                        {{'GoalQuestPage.GoalQuestSelection.SelectYourGoal' | translate}} </div>
                </div>
                <div class="item">
                    <div class="gq-btn-circle btn-md" [class]="progressStyle.stage2.active"></div>
                    <div class="currentGoalProgress" [class]="progressStyle.stage2.selected">
                        {{'GoalQuestPage.GoalQuestSelection.GoalProgress' |
                        translate}} </div>
                </div>
                <div class="item">
                    <div class="gq-btn-circle btn-md" [class]="progressStyle.stage3.active"></div>
                    <div class="currentGoalProgress" [class]="progressStyle.stage3.selected">
                        {{'GoalQuestPage.GoalQuestSelection.GoalResult' |
                        translate}} </div>
                </div>
            </div>
            <div class="progress-indicator-wrapper" [ngClass]="{'custom-progress-indicator-wrapper': !(showProgress && progressStyle)}">
                <div class="progress-indicator" [style.width]="progressStyle.width"></div>
            </div>
        </div>
        <cs-shared-goal-selection *ngIf="activeStage == 1" [rewards]="goalQuest.rewards" [selectedGoal]="selectedGoal"
            [isMinimumQualifierEnabled]="isMinimumQualifierEnabled" [minimumQualifier]="goalQuest.minimumQualifier"
            [startDate]="goalQuest.goalSelectionStartDate" [endDate]="goalQuest.goalSelectionEndDate"
            [isManualReward]="goalQuest.showDetailedRewards" [culture]="selectedCulture"
            (selectTargetEvent)="selectTarget($event)">
        </cs-shared-goal-selection>
        <cs-shared-goal-progress *ngIf="activeStage == 2" [goalAchieved]="goalQuest.achievement"
            [selectedGoal]="selectedGoal" [isMinimumQualifierEnabled]="isMinimumQualifierEnabled"
            [minimumQualifier]="goalQuest.minimumQualifier" [startDate]="goalQuest.promotionStartDate"
            [endDate]="goalQuest.promotionEndDate" [isManualReward]="goalQuest.showDetailedRewards"
            [culture]="selectedCulture">
        </cs-shared-goal-progress>
        <cs-shared-goal-result *ngIf="activeStage == 3" [goalAchieved]="goalQuest.achievement"
            [selectedGoal]="selectedGoal" [isMinimumQualifierEnabled]="isMinimumQualifierEnabled"
            [minimumQualifier]="goalQuest.minimumQualifier" [isManualReward]="goalQuest.showDetailedRewards"
            [culture]="selectedCulture">
        </cs-shared-goal-result>
    </div>
    <div class="inner-goal-section" *ngIf="goalQuest && goalQuest.rewards.length==0">
        <div class="alert alert-danger">
            {{'GoalQuestPage.NoTargets' | translate}}
        </div>
    </div>
    <div class="inner-goal-section" *ngIf="!dataLoading.appIsLoading && !isProgramStarted">
        <div class="alert alert-danger">
            {{'GoalQuestPage.ProgramNotYetStarted' | translate}}
        </div>
    </div>
</div>