import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { InvoiceClaimService } from '../../services/invoice-claim.service';

@Component({
    selector: 'cs-invoice-files-modal',
    templateUrl: './invoice-files.component.html'
})
export class InvoiceFilesModalComponent implements OnInit {

    @Input() invoice;
    formChallengeReason: FormGroup;
    invoiceFiles: any;

    constructor(private invoiceClaimService: InvoiceClaimService, private activeModal: NgbActiveModal, private fb: FormBuilder) { }

    ngOnInit() {
        this.formChallengeReason = this.fb.group({
            reason: ['', [Validators.required, Validators.maxLength(1000)]]
        });
        this.getinvoiceClaimUloadedFiles();

    }

    cancel() {
        this.activeModal.dismiss();
    }
    getinvoiceClaimUloadedFiles() {
        this.invoiceClaimService.getinvoiceClaimUloadedFiles(this.invoice.invoiceId).subscribe(response => {
            this.invoiceFiles = response;
        });
    }
    downLoadFile(file) {
        this.invoiceClaimService.downloadInvoiceFiles(file.fileLocation, file.fileName, file.filePath, file.fileType, this.invoice.invoiceId).subscribe((res: any) => {           
            let header = res.headers;
            let contentType = header.get('content-type');
            let fileName = file.fileName;                           
            let blob = new Blob([res.body], { type: contentType });

            let downloadLink = document.createElement("a");
            let url = URL.createObjectURL(blob);
            downloadLink.setAttribute("href", url);
            downloadLink.setAttribute("download", fileName);
            downloadLink.style.visibility = "hidden";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);            
        });
    }
}
