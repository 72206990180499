import { Component, Input, OnChanges } from "@angular/core";

import { Router } from "@angular/router";

@Component({
  selector: "app-message-page-banner",
  templateUrl: "./message-page-banner.component.html",
  styleUrls: ["./message-page-banner.component.scss"],
})
export class MessagePageBannerComponent implements OnChanges {
  @Input() message: any;
  updatedArticles: any[];

  public slideConfigHub: any = { dots: false, infinite: true };
  constructor(private router: Router) { }

  ngOnChanges() {
    this.updatedArticles = [];
    for (let article of this.message.articles) {
      let updatedArticle = Object.assign({}, article);
      delete updatedArticle.attributeJson;

      updatedArticle['attributeJson'] = JSON.parse(article.attributeJson);
      this.updatedArticles.push(updatedArticle);
    }
  }

  redirect(messagePageId, articleId, link) {
    if (link)
      window.open(link, "_blank");
    else
      this.router.navigateByUrl(`/articles/messagearticle/${messagePageId}/View/${articleId}`);
  }
}
