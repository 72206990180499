import { Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { LoadingService } from '@core/shared';
import { HubPageService } from '@core/private/hubpage/services/hub-page.service';
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-hub-message-quicktip',
    templateUrl: 'message-quicktip.component.html'
})
export class MessageQuicktipComponent implements OnChanges {
    @Input() componentId: string;
    @Input() payload: any;
    @Input() culture: string;

    message: any;
    arrowNext = faLongArrowAltRight;
    attributeJson: any;
    constructor(private hubPageService: HubPageService, public loadingService: LoadingService, private router: Router) { }

    ngOnChanges() {
        let data = { item: JSON.stringify(this.payload) };
        this.loadingService.doLoading(this.hubPageService.getHubComponent(this.componentId, this.culture, data), this, "message")
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.message = response;
                this.attributeJson = JSON.parse(response.articles[0].attributeJson);
            });
    }

    redirect(messagePageId, articleId, link) {
        if (link)
          window.open(link, "_blank");
        else
          this.router.navigateByUrl(`/articles/messagearticle/${messagePageId}/View/${articleId}`);
      }
}