<div class="home-page">
  <div class="bg-color3 home-page-bg">
    <div class="content-width home-point-gallery-section">
      <div class="flex-1 home-page-slide rightPane">
        <div class="topSection bg-color2">
          <div class="notification-subscription">
            <div class="col-md-12 content-wrap">
              {{ 'Notificationpreference.Manage'| translate }}
            </div>
            <div class="col-md-12 table-responsive">
              <form *ngIf="formSubscription" [formGroup]="formSubscription">
                <table aria-describedby="subscription">
                  <thead>
                    <tr>
                      <th>{{ 'Notificationpreference.Activity'| translate }}</th>
                      <th>
                        <img src="/assets/images/email.png" alt="" />
                        {{ 'Notificationpreference.Email'| translate }}
                      </th>
                      <th>
                        <img src="/assets/images/sms.png" alt="" />
                        {{ 'Notificationpreference.Sms'| translate }}
                      </th>
                      <th>
                        <img src="/assets/images/mobile_push.png" alt="" />
                        {{ 'Notificationpreference.MobilePush'| translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of sampledata">                  
                      <td>{{changeLabel(data.key)}}</td>
                      <td>
                        <form-toggle-checkbox [groupName]="data.key" controlName="email"
                                              onLabel="On" offLabel="Off" (onChangeEvent)="changePreference(data.key,'Email','email')">
                        </form-toggle-checkbox>
                      </td>
                      <td>
                        <form-toggle-checkbox [groupName]="data.key" controlName="message"
                                              onLabel="On" offLabel="Off" (onChangeEvent)="changePreference(data.key,'Message','message')">
                        </form-toggle-checkbox>
                      </td>
                      <td>
                        <form-toggle-checkbox [groupName]="data.key" controlName="mobilePush"
                                              onLabel="On" offLabel="Off" (onChangeEvent)="changePreference(data.key,'MobilePush','mobilePush')">
                        </form-toggle-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="home-left leftPane">
        <cs-profile-point-card theme="theme-white" buttonTheme="theme2"></cs-profile-point-card>
        <cs-most-redeem-products [singleView]="true"></cs-most-redeem-products>
      </div>
    </div>
  </div>
</div>
