import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        AccessDeniedComponent,
        NotFoundComponent
    ]
})
export class ErrorModule { }
