<div>
  <div class="coupon-claim-history-filter">
    <div class="custom-toggle margin-top-5">
      <div class="header">
        <div class="header-title">User Guide</div>
        <button (click)="toggleShows()" class="btn btn-link accordion">
          {{ isShownDiv ? "-" : "+" }}
        </button>
      </div>
      <div
        class="open bg"
        [class]="{ newClass: isShownDiv }"
        *ngIf="isShownDiv"
      >
        <div class="padding-10" [innerHtml]="instructions"></div>
      </div>
    </div>

    <div ngbAccordion>
      <div
        ngbAccordionItem="claimfilter"
        #claimfilter="ngbAccordionItem"
        [collapsed]="false"
        class="padding-15"
      >
        <div ngbAccordionHeader>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="accordion-header">
              {{ "SalesClaim.SalesClaimHeader" | translate }}
            </h5>
            <button ngbAccordionToggle class="btn btn-link accordion">
              {{ !claimfilter.collapsed ? "-" : "+" }}
            </button>
          </div>
        </div>
        <div ngbAccordionCollapse>
          <div class="padding-left-0" ngbAccordionBody>
            <ng-template>
              <p class="claimDescription">
                {{ "SalesClaim.SalesClaimSearchDescription" | translate }}
              </p>
              <div class="grid grid-gap-40 grid-col-hd-50 grid-col-xs-100">
                <div class="margin-bottom-10">
                  <label>{{ "SalesClaim.FromDateofSale" | translate }}</label>

                  <br />
                  <div class="form-group item">
                    <div class="input-group">
                      <input
                        class="form-control datePickerCalander"
                        [(ngModel)]="filter.salesStartDate"
                        placeholder="dd-mm-yyyy"
                        name="salesStartDate"
                        ngbDatepicker
                        #dpFromDate="ngbDatepicker"
                        [maxDate]="maxFromDate"
                        [minDate]="minFromDate"
                        (ngModelChange)="blockOldDate('from')"
                        readonly
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          type="button"
                          (click)="dpFromDate.toggle()"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="margin-bottom-10">
                  <div class="margin-bottom-10">
                    <label>{{ "SalesClaim.ToDateofSale" | translate }}</label>
                    <br />
                    <div class="form-group item">
                      <div class="input-group">
                        <input
                          class="form-control datePickerCalander"
                          [(ngModel)]="filter.salesEndDate"
                          placeholder="dd-mm-yyyy"
                          name="salesEndDate"
                          ngbDatepicker
                          #dpToDate="ngbDatepicker"
                          [maxDate]="maxToDate"
                          [minDate]="minToDate"
                          (ngModelChange)="blockOldDate('to')"
                          readonly
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            type="button"
                            (click)="dpToDate.toggle()"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-gap-40 grid-col-hd-50 grid-col-xs-100">
                <div class="margin-bottom-10">
                  <label>{{ "SalesClaim.ClaimStatus" | translate }}</label>
                  <br />
                  <div class="form-group item">
                    <select
                      name="list"
                      class="form-control"
                      [(ngModel)]="filter.salesClaimStatus"
                    >
                      <option value="">
                        {{ "Shared.SelectLists.PleaseSelect" | translate }}
                      </option>
                      <option
                        *ngFor="let status of claimStatuses"
                        [value]="status.value"
                      >
                        {{ status.text | translate }}
                      </option>
                    </select>
                    <em class="fa fa-chevron-down" aria-hidden="true"></em>
                  </div>
                </div>
                <div class="margin-bottom-10">
                  <label>{{ "SalesClaim.SearchTerm" | translate }}</label>
                  <br />
                  <input
                    placeholder="Search Term"
                    class="customField"
                    [(ngModel)]="filter.searchTerm"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-center btn-align">
                  <button
                    class="btn btn-primary customBtnSearch"
                    (click)="getSales()"
                  >
                    {{ "SalesClaim.BtnSearch" | translate }}
                  </button>
                  <button
                    class="btn btn-primary customBtnReset"
                    (click)="reset()"
                  >
                    {{ "SalesClaim.BtnReset" | translate }}
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-width">
    <h1 class="sub-pageHeader">{{ "SalesClaim.ClaimForm" | translate }}</h1>
  </div>

  <div
    #dataLoading="appIsLoading"
    [appIsLoading]="
      loadingService.isLoading(this, 'sales') ||
      loadingService.isLoading(this, 'configuration')
    "
  >
    <div class="coupon-claim-history-filter">
      <div class="row" *ngIf="paginationDetails.totalNumberOfItems !== 0">
        <div class="col-12 col-md-6">
          <div *ngIf="paginationDetails.totalNumberOfItems > 0">
            <p>
              {{ "TableRecords.FoundTotalOf" | translate
              }}{{ paginationDetails.totalNumberOfItems
              }}{{ "TableRecords.matches" | translate }}
            </p>
          </div>
        </div>
        <cs-items-per-page
          class="col-sm-6 text-right"
          [paginationDetails]="paginationDetails"
          (onChangeEvent)="onPaginationChange($event)"
        >
        </cs-items-per-page>
      </div>
      <div class="table-width">
        <table
          class="table table-striped"
          aria-describedby="Sales Claim"
          *ngIf="paginationDetails.totalNumberOfItems !== 0"
        >
          <thead>
            <tr>
              <th scope="col" *ngFor="let attributeHeader of attributeHeaders">
                <span>{{ attributeHeader.name }}</span>
              </th>
              <th scope="col">
                {{ "SalesClaim.SalesClaimTable.ClaimDate" | translate }}
              </th>
              <th scope="col">
                {{ "SalesClaim.SalesClaimTable.ClaimStatus" | translate }}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sale of sales">
              <td *ngFor="let attribute of sale.attributes">
                <span *ngIf="attribute.dataType.name !== 'Date'">{{
                  attribute.valueAsString
                }}</span>
                <span *ngIf="attribute.dataType.name === 'Date'">{{
                  attribute.valueAsString | localizedDate
                }}</span>
              </td>
              <td>{{ sale.dateOfStatusChange | localizedDate }}</td>
              <td>{{ sale.claimStatus.name }}</td>
              <td>
                <button
                  class="btn btn-primary alert-default custombtn"
                  (click)="claim(sale)"
                  *ngIf="
                    sale.canBeClaimed &&
                    claimActionText(sale) != '' &&
                    !userIsResolver
                  "
                >
                  {{ claimActionText(sale) | translate }}
                </button>
                <span
                  class="d-inline-block"
                  tabindex="0"
                  data-toggle="tooltip"
                  title="Challenge"
                >
                  <button
                    class="challenge"
                    (click)="challenge(sale)"
                    *ngIf="
                      sale.canBeChallenged &&
                      challengeActionText(sale) != '' &&
                      !userIsResolver
                    "
                  >
                    <!--   {{challengeActionText(sale) | translate}} -->
                  </button>
                </span>

                <span
                  class="d-inline-block"
                  tabindex="0"
                  data-toggle="tooltip"
                  title="Resolve"
                >
                  <span
                    class="resolve"
                    (click)="openResolve(sale)"
                    *ngIf="
                      sale.canBeResolved &&
                      sale.claimStatus.name === 'Challenged' &&
                      userIsResolver
                    "
                  >
                    <!--  {{'SalesSearch.SalesList.ActionButton.Resolve' | translate}} -->
                  </span>
                </span>

                <span
                  class="d-inline-block"
                  tabindex="0"
                  data-toggle="tooltip"
                  title="Approve"
                >
                  <button
                    type="button"
                    class="success"
                    (click)="approve(sale)"
                    *ngIf="
                      sale.requiresApproval &&
                      sale.claimStatus.name === 'Claimed' &&
                      userIsResolver
                    "
                    [disabled]="!sale.canBeApproved"
                  >
                    <!--   {{'SalesSearch.SalesList.ActionButton.Approve' | translate}} -->
                  </button>
                </span>
                <span
                  class="d-inline-block"
                  tabindex="0"
                  data-toggle="tooltip"
                  title="Reject"
                >
                  <button
                    class="reject"
                    (click)="showRejectModal(sale)"
                    *ngIf="
                      sale.requiresApproval &&
                      sale.claimStatus.name === 'Approved' &&
                      userIsResolver
                    "
                    [disabled]="!sale.canBeApproved"
                  >
                    <!--  {{'SalesSearch.SalesList.ActionButton.UnApprove' | translate}} -->
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="SalesClaimNoData alert alert-danger"
          *ngIf="
            !dataLoading.appIsLoading &&
            paginationDetails.totalNumberOfItems === 0
          "
        >
          {{ "SalesClaim.RecordNotFound" | translate }}
        </div>
      </div>
    </div>
    <div class="row" *ngIf="paginationDetails.totalNumberOfItems !== 0">
      <div class="col-lg-12 pagination-center-Bottom">
        <cs-custom-pagination
          [paginationDetails]="paginationDetails"
          (onChangeEvent)="onPaginationChange($event)"
        >
        </cs-custom-pagination>
      </div>
    </div>
  </div>
</div>
