import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GaugeModule } from 'angular-gauge';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgChartsModule } from 'ng2-charts';

import { AuthGuard, SubMenuResolver, RouteGuard, SettingsResolver } from './guards';

import { LogoBarComponent, LeagueProfileImageComponent, FormToggleCheckbox } from './components';
import { PreloginFooterComponent } from './components';
import { ProfilePointCardComponent, ProfileImageWithProgressComponent } from './components';
import { MainNavbarComponent } from './components';
import { AnimatedDigitComponent } from './components';
import { CategoryCardComponent } from './components';
import { FormInputComponent, FormButtonComponent, FormButtonSelectComponent, FormCheckboxComponent } from './components';
import { GoalProgressBarChartComponent } from './components';
import { FileUploaderWithoutMappingComponent, FileUploaderComponent, FilePickerComponent, FileUploaderProgressComponent, FileUploaderValidationResultComponent } from './components';
import { CustomPaginationComponent, PaginationItemsPerPageComponent } from './components';
import { CustomSortIconsComponent } from './components';
import { TabDirective } from './components'
import { ProfileStatusTabsComponent, ProfileStatusTab1Component, ProfileStatusTab6Component } from './components';
import { RedeemNowComponent } from './components';
import { PageHeaderComponent } from './components';
import { TimerComponent } from './components';
import { PerformanceDetailsComponent } from './components'
import { HttpImageComponent } from './components'
import { ThemeComponent } from './components';
import { PointBalanceComponent } from './components';
import { MostRedeemProductsComponent } from './components';
import {
    SharedMessagePageComponent, SharedLeagueComponent, SharedParticipantGoalSelectionComponent,
    SharedParticipantGoalProgressComponent, SharedParticipantGoalResultComponent, SharedManagerGoalSelectionComponent,
    SharedManagerGoalProgressComponent, SharedManagerGoalResultComponent, SharedMyRankComponent
} from './components';

import { ChallengeModalComponent, ResolveChallengeModalComponent, RejectClaimComponent, ClaimErrorComponent } from './components';

import { FileUploadService } from './services/file-upload.service';
import { LoadingSpinnerComponent, LoadingDirective } from './components';

import { SortByPipe } from './pipes/sortby.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { FilterByPipe } from './pipes/filterby.pipe';
import { RoundOffPipe } from './pipes/round-off.pipe';

import { IsLoadingDirective } from './adapters';
import { CookieComponentComponent } from './components/cookie-component/cookie-component.component';
import { ToggleSwitchComponentComponent } from './components/toggle-switch-component/toggle-switch-component.component';
import { PointSummaryComponent } from './components/point-summary/point-summary.component';
import { DashboardSvgComponent } from './components/dashboard-svg/dashboard-svg.component';
import { LocalizedDatePipe } from './pipes/localizeddate.pipe';
import { LocalizednumberPipe } from './pipes/localizednumber.pipe';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        TranslateModule,
        FontAwesomeModule,
        GaugeModule,
        SlickCarouselModule,
        NgChartsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [AuthGuard, SubMenuResolver, RouteGuard, FileUploadService, SettingsResolver],
    declarations: [
        LogoBarComponent,
        PreloginFooterComponent,
        FormInputComponent,
        FormButtonComponent,
        FormButtonSelectComponent,
        FormCheckboxComponent,
        FormToggleCheckbox,
        MainNavbarComponent,
        ProfilePointCardComponent,
        AnimatedDigitComponent,
        ProfileImageWithProgressComponent,
        CategoryCardComponent,
        TabDirective,
        ProfileStatusTabsComponent,
        ProfileStatusTab1Component,
        ProfileStatusTab6Component,
        GoalProgressBarChartComponent,
        TimerComponent,
        CustomPaginationComponent,
        PaginationItemsPerPageComponent,
        CustomSortIconsComponent,
        FileUploaderWithoutMappingComponent,
        FileUploaderComponent,
        FilePickerComponent,
        FileUploaderProgressComponent,
        FileUploaderValidationResultComponent,
        RedeemNowComponent,
        PageHeaderComponent,
        PerformanceDetailsComponent,
        LoadingSpinnerComponent,
        LoadingDirective,
        HttpImageComponent,
        ThemeComponent,
        PointBalanceComponent,
        MostRedeemProductsComponent,
        SharedMessagePageComponent,
        SharedLeagueComponent,
        LeagueProfileImageComponent,
        SharedParticipantGoalSelectionComponent,
        SharedParticipantGoalProgressComponent,
        SharedParticipantGoalResultComponent,
        SharedManagerGoalSelectionComponent,
        SharedManagerGoalProgressComponent,
        SharedManagerGoalResultComponent,
        SharedMyRankComponent,
        ChallengeModalComponent,
        ResolveChallengeModalComponent,
        RejectClaimComponent,
        ClaimErrorComponent,
        SortByPipe,
        SanitizeHtmlPipe,
        FilterByPipe,
        RoundOffPipe,
        IsLoadingDirective,
        PointSummaryComponent,
        CookieComponentComponent,
        ToggleSwitchComponentComponent,
        DashboardSvgComponent,
        LocalizedDatePipe,
        LocalizednumberPipe
    ],
    exports: [
        LogoBarComponent,
        PreloginFooterComponent,
        FormInputComponent,
        FormButtonComponent,
        FormButtonSelectComponent,
        FormCheckboxComponent,
        FormToggleCheckbox,
        MainNavbarComponent,
        ProfilePointCardComponent,
        AnimatedDigitComponent,
        CategoryCardComponent,
        TabDirective,
        ProfileStatusTabsComponent,
        ProfileStatusTab1Component,
        ProfileStatusTab6Component,
        GoalProgressBarChartComponent,
        TimerComponent,
        CustomPaginationComponent,
        PaginationItemsPerPageComponent,
        CustomSortIconsComponent,
        FileUploaderWithoutMappingComponent,
        RedeemNowComponent,
        PageHeaderComponent,
        PerformanceDetailsComponent,
        LoadingSpinnerComponent,
        LoadingDirective,
        HttpImageComponent,
        ThemeComponent,
        PointBalanceComponent,
        MostRedeemProductsComponent,
        SharedMessagePageComponent,
        SharedLeagueComponent,        
        SharedParticipantGoalSelectionComponent,
        SharedParticipantGoalProgressComponent,
        SharedParticipantGoalResultComponent,
        SharedManagerGoalSelectionComponent,
        SharedManagerGoalProgressComponent,
        SharedManagerGoalResultComponent,
        SharedMyRankComponent,
        ChallengeModalComponent,
        ResolveChallengeModalComponent,
        RejectClaimComponent,
        ClaimErrorComponent,
        SortByPipe,
        SanitizeHtmlPipe,
        FilterByPipe,
        RoundOffPipe,
        IsLoadingDirective,
        PointSummaryComponent,
        CookieComponentComponent,
        DashboardSvgComponent,
        LocalizedDatePipe,
        LocalizednumberPipe
    ]
})
export class SharedModule { }