<div class="modal-header">
    <h4 class="modal-title">{{ 'InvoiceClaimPage.FilesModal.Title' | translate }}</h4>
</div>
<div class="table-width">
    <table class="table top-border" aria-describedby="Invoice Uploaded Files">
        <thead>
            <tr>
                <th class="cm_12 cm_bold" role="button" scope="col">
                    File Name
                </th>
                <th class="cm_12 cm_bold" role="button" scope="col">
                    DownLoad
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor ="let file of invoiceFiles">
                <td>{{file.fileName}}</td>                
                <span class="download" (click)="downLoadFile(file)"></span>
            </tr>
        </tbody>
    </table>
</div>
<div class="modal-footer">    
    <button class="btn btn-default" type="button" (click)="cancel()">{{'Shared.Confirmation.Cancel' | translate }}</button>
</div>