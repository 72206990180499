import { Component, OnInit } from '@angular/core';
import { MemberShipTieringService } from '../services/membership-tiering-services';
import { Pagination } from '@core/shared/models/pagination';
import { LoadingService } from '@core/shared';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-membership-tiering',
  templateUrl: './membership-tiering.component.html',
  styleUrls: ['./membership-tiering.component.scss']
})
export class MembershipTieringComponent implements OnInit {
  paginationDetails: Pagination = new Pagination();
  tierHistoryDetails = [];
  activeTiersDetails = [];
  progressTargets = [];
  icon = [];
  percentageBarColor = 0;
  userName: any = "";
  tierName = "Member";
  shortName = "";
  nextTier = "next Tier";
  earnedPoint: any = 0;
  pointLeftToNextLevel: any = 0;
  isMaxTierReached = false;

  constructor(public membserhiptService: MemberShipTieringService, public loadingService: LoadingService) { }

  ngOnInit(): void {
    this.userName = localStorage.getItem("token") && JSON.parse(localStorage.getItem('token'))['username'] ?
      JSON.parse(localStorage.getItem('token'))['username'] : "";
    this.loadingService.doLoading(this.membserhiptService.getActiveTiers(), this)
      .pipe(untilDestroyed(this)).subscribe(response => {
        this.activeTiersDetails = response;
        this.getEarnedPoints();
      });
    this.getTierHistory();
  }
  onChange(pagination: Pagination) {
    this.paginationDetails = pagination;
    this.getTierHistory();
  }
  getEarnedPoints() {
    this.loadingService.doLoading(this.membserhiptService.getEarnedPoints(), this)
      .pipe(untilDestroyed(this)).subscribe(response => {
        this.earnedPoint = response;
        this.progressBarInfo();
      });
  }
  progressBarInfo() {
    const arrFilter = this.activeTiersDetails;
    arrFilter.sort((a, b) => {
      return a.minimumThreshold - b.minimumThreshold;
    });
    const length = arrFilter.length ? (100 / (arrFilter.length - 1)) : 100;
    let prevLength = 0;
    let count = 0;
    for (let i of arrFilter) {
      count++;
      // if ((i.name).toLowerCase() != 'default') {
      if (arrFilter[0] && arrFilter[0].name != i.name) {
        prevLength += length;
      }
      if (i.maximumThreshold >= this.earnedPoint.value && i.minimumThreshold <= this.earnedPoint.value) {
        this.percentageBarColor = prevLength;
        this.tierName = i.name + " Member";
        if (arrFilter[count]) {
          this.pointLeftToNextLevel = (arrFilter[count].minimumThreshold - this.earnedPoint.value) + " " + this.earnedPoint.type;
          this.nextTier = "tier " + arrFilter[count].name;
        } else {
          this.pointLeftToNextLevel =  this.earnedPoint.value + " " + this.earnedPoint.type;
          this.isMaxTierReached = true;
        }
      } else if (this.earnedPoint.value > i.maximumThreshold) {
        this.percentageBarColor = prevLength;
        if (arrFilter.length === count) {
          this.pointLeftToNextLevel =  this.earnedPoint.value + " " + this.earnedPoint.type;
          this.isMaxTierReached = true;
        }
      } else if (count === 1 && arrFilter[0].minimumThreshold > this.earnedPoint.value) {
        this.pointLeftToNextLevel = (i.minimumThreshold - this.earnedPoint.value) + " " + this.earnedPoint.type;
        this.nextTier = "tier " + i.name;
      }
      const obj = { "maxQuantity": i.name, "qtyWidth": prevLength + '%', "color": i.color ? i.color : "#45ec2a" };
      this.progressTargets.push(obj);
      this.icon.push(obj);
      // }
    }
    if (!this.pointLeftToNextLevel) {
      this.pointLeftToNextLevel = this.earnedPoint.type ? "0 " + this.earnedPoint.type : '0 Points';
    }
  }
  getTierHistory() {
    const data = { "pageNumber": this.paginationDetails.pageNumber };
    this.loadingService.doLoading(this.membserhiptService.getTierHistory(data), this)
      .pipe(untilDestroyed(this)).subscribe(response => {
        this.tierHistoryDetails = response.items;
        this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
      });
  }
}
