<div>
  <div class="claim-filter-section">
    <div ngbAccordion>
      <div
        ngbAccordionItem="claimfilter"
        #claimfilter="ngbAccordionItem"
        [collapsed]="false"
        class="padding-15"
      >
        <div ngbAccordionHeader>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="accordion-header">Coupon History Filter</h5>
            <button ngbAccordionToggle class="btn btn-link accordion">
              {{ !claimfilter.collapsed ? "-" : "+" }}
            </button>
          </div>
        </div>
        <div ngbAccordionCollapse>
          <div class="padding-left-0" ngbAccordionBody>
            <ng-template ngbPanelContent>
              <p class="claimDescription">
                {{
                  "CouponClaimPage.CouponClaimHistory.CouponClaimHistoryDescription"
                    | translate
                }}
              </p>
              <div class="grid grid-gap-40 grid-col-hd-50 grid-col-xs-100">
                <div class="margin-bottom-10">
                  <label>{{
                    "CouponClaimPage.CouponClaimHistory.SearchbyCouponCode"
                      | translate
                  }}</label>
                  <br />
                  <input
                    #couponId
                    placeholder="Coupon Code"
                    [(ngModel)]="filter.couponCode"
                    class="customField"
                  />
                </div>
                <div class="margin-bottom-10">
                  <div class="margin-bottom-10">
                    <label>{{
                      "CouponClaimPage.CouponClaimHistory.DateofClaim"
                        | translate
                    }}</label>
                    <br />
                    <div class="form-group item">
                      <div class="input-group">
                        <input
                          class="form-control datePickerCalander"
                          [(ngModel)]="filter.claimedDate"
                          placeholder="dd/mm/yyyy"
                          name="claimedDate"
                          ngbDatepicker
                          #dpclaimedDate="ngbDatepicker"
                          readonly
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary calendar"
                            type="button"
                            (click)="dpclaimedDate.toggle()"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-gap-40 grid-col-hd-50 grid-col-xs-100">
                <div class="margin-bottom-10">
                  <label>{{
                    "CouponClaimPage.CouponClaimHistory.SearchbyProductId"
                      | translate
                  }}</label>
                  <br />
                  <input
                    #productid
                    placeholder="Product Id"
                    [(ngModel)]="filter.productId"
                    class="customField"
                  />
                </div>

                <div class="margin-bottom-10">
                  <label>{{
                    "CouponClaimPage.CouponClaimHistory.SearchbyStatus"
                      | translate
                  }}</label>
                  <br />
                  <select
                    name="list"
                    [(ngModel)]="filter.status"
                    class="form-control select-status"
                  >
                    <option value="">
                      {{
                        "CouponClaimPage.CouponClaimHistory.SearchbyStatus"
                          | translate
                      }}
                    </option>
                    <option value="Success">
                      {{
                        "CouponClaimPage.CouponClaimHistory.Success" | translate
                      }}
                    </option>
                    <option value="Failed">
                      {{
                        "CouponClaimPage.CouponClaimHistory.Failed" | translate
                      }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-center btn-align">
                  <button
                    class="btn btn-primary customBtnSearch"
                    (click)="onSearch()"
                  >
                    {{ "CouponClaimPage.SearchBtn" | translate }}
                  </button>
                  <button
                    class="btn btn-primary customBtnReset"
                    (click)="onClear()"
                  >
                    {{ "CouponClaimPage.ResetBtn" | translate }}
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-width">
    <h1 class="sub-pageHeader" id="myTable">
      {{ "CouponClaimPage.CouponClaimHistory.CouponCodeClaims" | translate }}
    </h1>
  </div>

  <div class="claim-filter-section">
    <div class="row" *ngIf="paginationDetails.totalNumberOfItems > 0">
      <div class="col-12 col-md-6">
        <p>
          {{ "TableRecords.FoundTotalOf" | translate }}{{ totalNoOfItems
          }}{{ "TableRecords.matches" | translate }}
        </p>
      </div>
      <div class="col-12 col-md-6">
        <div class="grid grid-col-xs-100 grid-col-hd-50 grid-gap-10">
          <cs-items-per-page
            class=" "
            [paginationDetails]="paginationDetails"
            (onChangeEvent)="onChange($event)"
          ></cs-items-per-page>
          <div class="pull-right">
            <div class="downloadText" (click)="downloadCSV()">
              Download Report <span class="downloadIcons"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      #dataLoading="appIsLoading"
      [appIsLoading]="loadingService.isLoading(this)"
    >
      <div class="table-width" *ngIf="paginationDetails.totalNumberOfItems > 0">
        <table class="table table-striped" aria-describedby="myTable">
          <thead>
            <tr>
              <th
                class="cm_12 cm_bold"
                role="button"
                scope="col"
                (click)="onSort('couponCode')"
              >
                {{
                  "CouponClaimPage.CouponClaimHistory.CouponClaimTable.CouponCode"
                    | translate
                }}
                <cs-sort-icons
                  *ngIf="sorter.showSortIcons('couponCode')"
                  [sortAscending]="sorter.sortDirection"
                ></cs-sort-icons>
              </th>
              <th
                class="cm_12 cm_bold"
                role="button"
                (click)="onSort('productId')"
                scope="col"
              >
                {{
                  "CouponClaimPage.CouponClaimHistory.CouponClaimTable.ProductId"
                    | translate
                }}
                <cs-sort-icons
                  *ngIf="sorter.showSortIcons('productId')"
                  [sortAscending]="sorter.sortDirection"
                ></cs-sort-icons>
              </th>
              <th
                class="cm_12 cm_bold"
                role="button"
                scope="col"
                (click)="onSort('status')"
              >
                {{
                  "CouponClaimPage.CouponClaimHistory.CouponClaimTable.Status"
                    | translate
                }}
                <cs-sort-icons
                  *ngIf="sorter.showSortIcons('status')"
                  [sortAscending]="sorter.sortDirection"
                ></cs-sort-icons>
              </th>
              <th
                class="cm_12 cm_bold"
                role="button"
                (click)="onSort('claimedDate')"
                scope="col"
              >
                {{
                  "CouponClaimPage.CouponClaimHistory.CouponClaimTable.ClaimedDate"
                    | translate
                }}
                <cs-sort-icons
                  *ngIf="sorter.showSortIcons('claimedDate')"
                  [sortAscending]="sorter.sortDirection"
                ></cs-sort-icons>
              </th>
              <th scope="col">
                {{
                  "CouponClaimPage.CouponClaimHistory.CouponClaimTable.ErrorReason"
                    | translate
                }}
              </th>
              <th scope="col" role="button" (click)="onSort('points')">
                {{
                  "CouponClaimPage.CouponClaimHistory.CouponClaimTable.Points"
                    | translate
                }}
                <cs-sort-icons
                  *ngIf="sorter.showSortIcons('points')"
                  [sortAscending]="sorter.sortDirection"
                ></cs-sort-icons>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let coupon of couponHistoryDetails">
              <td>{{ coupon.couponCode }}</td>
              <td>{{ coupon.productId }}</td>
              <td>{{ coupon.status }}</td>
              <td>{{ coupon.claimedDate | localizedDate }}</td>
              <td>{{ coupon.errorReason }}</td>
              <td>{{ coupon.points }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="couponSummaryNoData alert alert-danger"
        *ngIf="
          !dataLoading.appIsLoading &&
          paginationDetails.totalNumberOfItems === 0
        "
      >
        {{ "CouponClaimPage.RecordNotFound" | translate }}
      </div>
    </div>
    <div class="row" *ngIf="paginationDetails.totalNumberOfItems > 0">
      <div class="col-lg-12 pagination-center-Bottom">
        <cs-custom-pagination
          [paginationDetails]="paginationDetails"
          (onChangeEvent)="onChange($event)"
        >
        </cs-custom-pagination>
      </div>
    </div>
  </div>
</div>
