export class Pagination {
    totalNumberOfItems: number;
    pageNumber: number;
    itemsPerPage: number;

    constructor(_totalNumberOfItems?: number, _pageNumber?: number, _itemsPerPage?: number) {
        this.totalNumberOfItems = _totalNumberOfItems ? _totalNumberOfItems : 0;
        this.pageNumber = _pageNumber ? _pageNumber : 1;
        this.itemsPerPage = _itemsPerPage ? _itemsPerPage : 10;
    }
}