import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { GET_PROMOTION, GET_HUB_PAGE_COMPONENTS, UrlBuilder } from '@core/core-api-variables';
import { HttpService } from '@core/shared';

@Injectable()
export class CommunicationService {
    constructor(public http: HttpService) { }

    getPromotion(promotionId) {
        let data = { promotionId: promotionId };
        let url = UrlBuilder(GET_PROMOTION, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    getCommunicationComponents(hubPageId, culture) {
        let data = { hubPageId, culture }
        let url = UrlBuilder(GET_HUB_PAGE_COMPONENTS, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }
}