import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthenticationService } from '../services/authentication.service'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    public isRefreshing = false;
    public refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public router: Router, public authService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.authService.getToken()) {
            request = this.addToken(request, this.authService.getToken());
        }

        return next.handle(request).pipe(catchError(errorResponse => {
            if (errorResponse instanceof HttpErrorResponse) {
                if (errorResponse.status === 401)
                    return this.handle401Error(request, next, errorResponse);
                else if (errorResponse.status === 403)
                    this.router.navigate(['error/accessdenied']);
                else if (errorResponse.status === 400 && errorResponse.error && errorResponse.error.error === "invalid_grant") {
                    this.authService.logout();
                }
                else {
                    if (request.url.includes("oauth/token")) {
                        let userName;
                        const urlParams = new URLSearchParams(request.body);
                        userName = urlParams.get('username');
                        if (errorResponse.headers.has("Password-Expired")) {
                            this.router.navigate(['/account/expiredpassword/' + userName]);
                        }
                    }
                }
            }
            return throwError(() => errorResponse);
        }));
    }

    public addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`,
                'Nexus-Environment-Key': environment.environmentKey
            }
        });
    }

    public handle401Error(request: HttpRequest<any>, next: HttpHandler, error) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            let refreshToken = this.authService.getRefreshToken();
            if (refreshToken) {
                return this.authService.refreshToken(refreshToken).pipe(
                    switchMap((token: any) => {
                        this.isRefreshing = false;
                        localStorage.setItem('token', JSON.stringify(token));
                        this.refreshTokenSubject.next(token.access_token);
                        return next.handle(this.addToken(request, token.access_token));
                    }), catchError((error) => {
                        this.isRefreshing = false;
                        this.authService.logout();
                        return throwError(() => error);
                    }));
            }
            else {
                this.isRefreshing = false;
                this.authService.logout();
                return throwError(() => error);
            }
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }
}