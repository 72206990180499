import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@core/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { QuizQuestionnaireService } from './services/quiz-questionnaire.service';
import { QuizAndQuitionnaireComponent } from './quiz-questionnaire/quiz-questionnaire.component';
import { QuizParticipantComponent } from './quiz-participant/quiz-participant.component';
import { ParticipantsQuizAttemptsComponent } from './quiz-attempts/participants-quiz-attempts.component';
import { EvaluateQuizQuestionnaireComponent } from './quiz-modal/evaluate/evaluate-quiz-questionnaire.component';
import { BriksDirective } from './directives/briks.directive';
import { RouterModule } from '@angular/router';
import { QuizComponent } from './quiz.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        SharedModule,
        NgbModule,
        FormsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [QuizQuestionnaireService],
    declarations: [
        QuizComponent,
        QuizAndQuitionnaireComponent,
        QuizParticipantComponent,
        ParticipantsQuizAttemptsComponent,
        EvaluateQuizQuestionnaireComponent,
        BriksDirective
    ]
})
export class QuizQuestionnaireModule { }