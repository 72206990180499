import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@core/shared/shared.module';
import { CouponComponent } from './coupon.component';
import { CouponclaimService } from './services/couponclaim.service';
import { CouponclaimComponent } from './coupon-claim/coupon-claim.component';
import { CouponClaimHistoryComponent } from './coupon-claim-history/coupon-claim-history.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,        
        NgbModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        SharedModule
    ],   
    providers: [CouponclaimService],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ], 
    declarations: [
        CouponComponent,
        CouponclaimComponent,
        CouponClaimHistoryComponent
    ]
})

export class CouponclaimModule { }
