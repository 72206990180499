export class Sorter {
    sortColumn: string;
    sortDirection: boolean;

    constructor(_initalColumn?: string, _initialSortDirection?: boolean) {        
        this.sortColumn = _initalColumn;
        this.sortDirection = _initialSortDirection === undefined ? true : _initialSortDirection;
    }

    sort(column) {
        if (this.sortColumn === column)
            this.sortDirection = !this.sortDirection;
        else {
            this.sortColumn = column;
            this.sortDirection = true;
        }
    }

    showSortIcons(column) {
        return this.sortColumn === column;
    }
}