import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { CommunicationComponent } from './communication.component';
import { GoalQuestComponent } from './goalquest/goalquest.component';
import { AotComponent } from './aot/aot.component';
import { SellxyComponent } from './sellxy/sellxy.component';

export const CommunicationRoutes: Routes = [
    {
        path: 'promotion',        
        component: CommunicationComponent,
        children: [
            {
                path: 'goalquest/:id',
                component: GoalQuestComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'PageTitles.GoalQuestPage', isPreLogin: false }
            },
            {
                path: 'achievementoftarget/:id',
                component: AotComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'PageTitles.Aot', isPreLogin: false }
            },
            {
                path: 'sellxgety/:id',
                component: SellxyComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'PageTitles.SellXGetY', isPreLogin: false }
            }
        ]
    }
];