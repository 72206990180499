import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';
import { AwardsComponent } from './awards.component';
import { MyAwardsComponent } from './my-awards/my-awards.component';

export const MyAwardsRoutes: Routes = [
    {
        path: 'awards',
        component: AwardsComponent,
        children: [
            {
                path: 'myawards/page/:id',
                canActivate: [AuthGuard],
                component: MyAwardsComponent,
                runGuardsAndResolvers: 'always',
                pathMatch: 'full',
                data: { title: 'My Awards', isPreLogin: false }
            }
        ]
    }
];