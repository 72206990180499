<div>
    <!--   <h1 class="pageHeader">Power BI Report</h1> -->
    <div class="claim-filter-section">
        <div class="claim-filter-section margin-bottom-40 ">
            <div *ngIf="articleDetails.length === 0"> {{'QuestionPage.NoArticles' | translate}} </div>
            <div class="grid grid-gap-10 grid-col-md-25-custom grid-col-hd-25-custom grid-col-xs-100 grid-col-sm-100 grid-col-lt-100"
                *ngIf="articleDetails.length > 0">
                <div class="form-group">
                    <label>{{'QuestionPage.CategoryFilter' | translate}}</label>
                    <select class="form-control select-css" name="category" [(ngModel)]="selectedCategory">
                        <option value="">{{'QuestionPage.CategoryDefaultValue' | translate}}</option>
                        <option *ngFor="let category of categoryDetails" [value]="category.id">
                            {{category.name}}
                        </option>
                    </select>

                </div>

                <div class="form-group btn-bottom">
                    <button type="button" class="btn btn-primary btn-redeem custombtn"
                        (click)=onCategoryFilterClick()>{{'QuestionPage.BtnGo' | translate}}</button>
                </div>
            </div>
        </div>

        <div class="bottomMargin" [appIsLoading]="loadingService.isLoading(this)">
            <div class="grid grid-gap-20 grid-col-hd-100 grid-col-xs-100">
                <div class="margin-bottom-10 promoLists"
                    *ngFor="let article of articleDetails | filterBy : 'categoryIds' : selectedCategories | sortBy:'desc':'articleDate'">
                    <div class="grid grid-gap-10 grid-col-sm-100 grid-col-xs-100">
                        <div class="text-section">
                            <div class="card-body-right">
                                <h3 class="promoSubHeader">{{ article.teaserText }}</h3>
                                <p *ngIf="showDateToParticipant" class="promoSubHeaderDescription">{{
                                    article.articleDate | localizedDate }}</p>
                                <h4><a href="javascript:void(0);"
                                        (click)="article.showContent = !article.showContent;">{{article.title }}</a>
                                </h4>
                                <div [innerHTML]="article.htmlContent | sanitizeHtml" [hidden]="!article.showContent">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>