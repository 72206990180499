import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import * as fromStore from '@core/store';
import { UserRegistrationApprovalService } from '../services/user-registration-approval.service';
import { Authentication } from '@core/models/authentication.model';
import { ImportBehaviors } from '@core/shared/models/import-behaviors';

@Component({
    selector: 'cs-user-registration-bulk-approval',
    templateUrl: './bulk-approval.component.html'
})

export class BulkApprovalComponent implements OnInit {
    currentUser: Authentication;
    private onDestroy$ = new Subject<void>();
    contextId: string;
    lastUploadId: string;
    importBehaviors: ImportBehaviors;
    constructor(private activeModal: NgbActiveModal, private approvalService: UserRegistrationApprovalService,
        private store: Store<fromStore.PlatformState>) {
        this.importBehaviors = new ImportBehaviors(true, false, false);
    }

    ngOnInit() {
        this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            if (state.user && !this.currentUser) {
                this.currentUser = state.user;
                this.approvalService.getUserRegistrationApprovalContext().subscribe(response => {
                    this.contextId = response.contextId;
                    this.lastUploadId = response.lastUploadId;
                });
            }
        });
    }

    importCompleted(uploadId) {
        this.approvalService.downloadByUploadId(uploadId).subscribe(() => {
            this.activeModal.close();
        });
    }

    cancel() {
        this.activeModal.close();
    }
}