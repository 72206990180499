import { Injectable } from '@angular/core';
import { DownloadService, HttpService } from '@core/shared';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SearchCriteria } from '@core/shared/models/search-criteria';
import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

import {
  UrlBuilder, CLAIM_COUPONS, GET_COUPON_CLAIM_HISTORY, COUPON_CLAIM_UPLOAD, DOWNLOAD_COUPON_CLAIM_HISTORY, DOWNLOAD_COUPON_UPLOADED_FILE
} from '@core/core-api-variables';

@Injectable()
export class CouponclaimService extends DownloadService {

  constructor(public http: HttpService, public paramsBuilderService: ParamsBuilderService) { 
    super();
  }

  claimCoupons(payload): Observable<any> {
    let data = { 'coupons': payload, 'isWeb': 'true' };
    let url = CLAIM_COUPONS;
    return this.http.post(url, data).pipe(map((response: any) => {
      return response;
    }));
  }

  getCouponClaimHistory(searchCriteria: SearchCriteria) {
    let params = this.paramsBuilderService.buildParams(searchCriteria);
    let data = { params };
    let url = UrlBuilder(GET_COUPON_CLAIM_HISTORY, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadByUploadId(uploadId) {    
    let data = { uploadId };
    let url = UrlBuilder(DOWNLOAD_COUPON_UPLOADED_FILE, data);
    return this.http.download(url).pipe(map((response: any) => {
      this.downloadFile(response, 'CouponUpload.csv');
      return true;
    }));
  }

  getCouponContext() {
    let url = UrlBuilder(COUPON_CLAIM_UPLOAD, '');
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  downloadViewCSV(searchCriteria: SearchCriteria) {
    let params = this.paramsBuilderService.buildParams(searchCriteria);
    let data = { params };
    let url = UrlBuilder(DOWNLOAD_COUPON_CLAIM_HISTORY, data);

    let httpOptions = {
      responseType: 'arraybuffer', observe: 'response'
    }

    return this.http.get(url, httpOptions).pipe(map((response: any) => {
      return response;
    }));
  }
}
