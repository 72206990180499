export class ImportBehaviors {
    appendNewRecords: boolean;
    updateExistingRecords: boolean;
    deleteMissingRecords: boolean;

    constructor(_appendNewRecords: boolean, _updateExistingRecords: boolean, _deleteMissingRecords: boolean) {
        this.appendNewRecords = _appendNewRecords;
        this.updateExistingRecords = _updateExistingRecords;
        this.deleteMissingRecords = _deleteMissingRecords
    }
}