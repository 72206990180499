<div class="content-width">

    <!-- prelogin footer starts -->
    <div class="pre-login-footer" *ngIf="!isAuthenticated">
        <ul class="horizontal-list-link">
            <li *ngIf="footerAccess.accessAdvice">
                <a [routerLink]="['/legal/accessadvice']">
                    <body-text class="font-light medium-size"><span translate="LegalPage.AccessAdviceLink"></span>
                    </body-text>
                </a>
            </li>
            <li *ngIf="footerAccess.cookies">
                <a [routerLink]="['/legal/cookies']">
                    <body-text class="font-light medium-size"><span translate="LegalPage.CookiesLink"></span>
                    </body-text>
                </a>
            </li>
            <li *ngIf="footerAccess.privacyPolicy">
                <a [routerLink]="['/legal/privacypolicy']">
                    <body-text class="font-light medium-size"><span translate="LegalPage.PrivacyPolicyLink"></span>
                    </body-text>
                </a>
            </li>
            <li *ngIf="footerAccess.termsOfUse">
                <a [routerLink]="['/legal/termsofuse']">
                    <body-text class="font-light medium-size"><span translate="LegalPage.TermsOfUseLink"></span>
                    </body-text>
                </a>
            </li>
            <li *ngIf="footerAccess.contactUs">
                <a [routerLink]="['/contactus-public']"><body-text class="font-light medium-size"><span>Contact
                            Us</span></body-text></a>
            </li>
            <li>
                <body-text class="font-light medium-size">Best viewed in Google Chrome and Mozilla Firefox.</body-text>
            </li>
        </ul>

        <div>
            <body-text class="font-light">Powerd by&nbsp;</body-text>
            <body-text class="font-bold">BI WORLDWIDE</body-text>
        </div>
    </div>
    <!-- prelogin footer ends -->

    <!-- post login footer starts -->
    <div class="post-login-footer" *ngIf="isAuthenticated">
        <div class="content-width footer-inner">

            <div class="flex">
                <ul class="footer-link-lists">
                    <li *ngIf="footerAccess.privacyPolicy">
                        <a [routerLink]="['/legalpage/privacypolicy']" target="_blank">
                            <body-text class="medium-size"><span translate="LegalPage.PrivacyPolicyLink"></span>
                            </body-text>
                        </a>
                    </li>
                    <li *ngIf="footerAccess.termsOfUse">
                        <a [routerLink]="['/legalpage/termsofuse']" target="_blank">
                            <body-text class="medium-size"><span translate="LegalPage.TermsOfUseLink"></span>
                            </body-text>
                        </a>
                    </li>
                    <li *ngIf="footerAccess.accessAdvice">
                        <a [routerLink]="['/legalpage/accessadvice']" target="_blank">
                            <body-text class="medium-size"><span translate="LegalPage.AccessAdviceLink"></span>
                            </body-text>
                        </a>
                    </li>
                    <li *ngIf="footerAccess.cookies">
                        <a [routerLink]="['/legalpage/cookies']" target="_blank">
                            <body-text class="medium-size"><span translate="LegalPage.CookiesLink"></span>
                            </body-text>
                        </a>
                    </li>
                    <li *ngIf="footerAccess.contactUs">
                        <a [routerLink]="['/contactus-private']"><body-text class="medium-size"><span>Contact
                                    Us</span></body-text></a>
                    </li>
                </ul>

                <ul class="footer-link-lists">
                    <li>
                        <a href="mailto:connect@biworldwide.com"><strong>Email:</strong> connect&amp;biworldwide.com</a>
                    </li>
                    <li>
                        <a href="tel:1800-211-4718"><strong>Call:</strong> 1800-211-4718</a>
                    </li>
                </ul>


            </div>


            <div class="right-box">
                <div class="social-links">
                    <a href="javascript:void(0);">
                        <img src="assets/images/social-fb.png" alt="fb">
                    </a>
                    <a href="javascript:void(0);">
                        <img src="assets/images/social-instagram.png" alt="instagram">
                    </a>
                    <a href="javascript:void(0);">
                        <img src="assets/images/social-twitter.png" alt="twitter">
                    </a>
                    <a href="javascript:void(0);">
                        <img src="assets/images/social-youtube.png" alt="youtube">
                    </a>
                </div>
                <body-text class="medium-size">
                    BI WORLDWIDE Copyright 2022
                </body-text>
            </div>

        </div>
    </div>
    <!-- post login footer ends -->


</div>