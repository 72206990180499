import { Routes } from "@angular/router";
import { AuthGuard } from "@app/core/shared";
import { NotificationCornerComponent } from "./notifications/notification.component";

export const NotificationCornerRoutes: Routes = [
    {
        path: 'messagecentre',
        canActivate: [AuthGuard],
        component: NotificationCornerComponent,
        runGuardsAndResolvers: 'always',
        pathMatch: 'full',
        data: { title: 'Notifications', isPreLogin: false }
    }
]