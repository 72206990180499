import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { LegalPageComponent } from './legal-pages/legal-page.component';

export const LegalPageRoutes: Routes = [
    {
        path: 'legalpage/:page',        
        component: LegalPageComponent,
        pathMatch: 'full',
        data: { title: 'PageTitles.LegalPage', isPreLogin: false }
    }
];