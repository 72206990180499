import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromStore from '@core/store';
import { LoadingService } from '@core/shared';
import { CouponclaimService } from '../services/couponclaim.service';
import { ImportBehaviors } from '@core/shared/models/import-behaviors';
import { MessageService } from '@core/shared/services/message.service';
import { Authentication } from '@core/models/authentication.model';
import { Title } from '@angular/platform-browser';

@UntilDestroy()
@Component({
  selector: 'app-couponclaim',
  templateUrl: './coupon-claim.component.html',
  styleUrls: ['./coupon-claim.component.scss']
})
export class CouponclaimComponent implements OnInit {
  productForm: FormGroup;
  @ViewChild('coupon') coupon: ElementRef;
  couponMore: boolean = false;
  currentUser: Authentication;
  contextId: string;
  lastUploadId: string;
  importBehaviors: ImportBehaviors;
  coupons: string[] = [];
  claimResult: any[] = [];
  claimDisabled: boolean = false;
  private onDestroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute, private titleService: Title, private couponclaimService: CouponclaimService, private messageService: MessageService,
    public translate: TranslateService, public loadingService: LoadingService, private store: Store<fromStore.PlatformState>) {
    this.importBehaviors = new ImportBehaviors(true, false, false);

  }

  ngOnInit(): void {
    this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
      if (state.user && !this.currentUser) {
        this.currentUser = state.user;
        this.couponclaimService.getCouponContext().subscribe(response => {
          this.contextId = response.contextId;
          this.lastUploadId = response.lastUploadId;
        });
      }
    });
    this.route.url.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.translate.get(this.route.snapshot.data.title).subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
    })
  }

  addCoupon(couponid) {
    const index = this.coupons.indexOf(couponid);

    if (!couponid) {
      this.messageService.showInfo(this.translate.instant('CouponClaimPage.EnterACoupon'));
      return;
    }

    if (index != -1) {
      this.messageService.showInfo(this.translate.instant('CouponClaimPage.DuplicateCoupon'));
      return;
    }

    if (this.coupons.length < 8) {
      this.couponMore = false;
      if (couponid && index === -1) {
        this.coupons.push(couponid);
        this.coupon.nativeElement.focus();
        this.coupon.nativeElement.value = null;
      }

    }
    else {
      this.couponMore = true;
    }
  }

  removeCoupon(index) {
    if (this.coupons.length <= 8) {
      this.couponMore = false;
    }
    this.coupons.splice(index, 1);
  }

  onSubmit() {
    if (this.coupons.length > 0) {
      this.claimDisabled = true;
      this.loadingService.doLoading(this.couponclaimService.claimCoupons(this.coupons), this, 'couponClaim').pipe(untilDestroyed(this)).subscribe((response) => {
        this.claimDisabled = false;
        this.claimResult = response.claimResult;
        this.coupons = [];

      });

    }
    else {
      this.messageService.showInfo(this.translate.instant('CouponClaimPage.EnterACoupon'));
    }
  }

  importCompleted(uploadId) {
    this.couponclaimService.downloadByUploadId(uploadId).subscribe(response => { });
  }
}
