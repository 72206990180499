import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { LoadingService } from '@core/shared';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SelectOptions } from '@core/models';
import { AuthenticationService } from '@core/startup'
import { AccountsService } from '../services/accounts.service';
import { MessageService } from '@core/shared/services/message.service';
import * as fromStore from '@core/store';
import * as fromAuthentication from '@core/store/actions';

@UntilDestroy()
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  public formModels: any;
  public editProfile: FormGroup;
  public securityQuestion: Array<SelectOptions>;
  public currentUser: any;
  public file: any = "/assets/images/image_uploader_placeholder.jpg";
  public userProfile: any;
  public imgURL: any;
  public isProfileChanged: boolean = false;
  public userId: any;
  public isProfilePictureSizeInvalid :boolean = false;

  constructor(public router: Router, public formBuilder: FormBuilder, public authService: AuthenticationService,
    public accountsService: AccountsService, public httpClient: HttpClient, public domSanitizer: DomSanitizer,
    private messageService: MessageService, public translate: TranslateService, private store: Store<fromStore.PlatformState>
    , public loadingService: LoadingService) { }

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.getProfile()
    this.loadingService.doLoading(this.authService.getCurrentUser(), this).pipe(untilDestroyed(this))
      .subscribe(response => {
        this.userId = response.id;
        this.editProfile = this.formBuilder.group({
          imgUpload: [null],
          userName: [response.username, [Validators.required]],
          firstName: [response.firstName, [Validators.required]],
          lastName: [response.lastName, [Validators.required]],
          email: [response.email, [Validators.required]],
          phone: [response.mobileNumber ? response.mobileNumber : null, [Validators.required]],
          role: [response.primaryJobRoleDescription ? response.primaryJobRoleDescription : null, [Validators.required]],
          organisationName: [response.primaryOrganisation.name, [Validators.required]],
          address1: [response.primaryOrganisation.address1, [Validators.required]],
          town: [response.primaryOrganisation.town, [Validators.required]],
          telephone: [response.primaryOrganisation.phone ? response.primaryOrganisation.phone : null, [Validators.required]],
          termsAgree: [false, [Validators.required]],
        });
        this.editProfile.controls['userName'].disable();
        this.editProfile.controls['telephone'].disable();
        this.editProfile.controls['organisationName'].disable();
        this.editProfile.controls['address1'].disable();
        this.editProfile.controls['town'].disable();
        this.editProfile.controls['role'].disable();
        this.currentUser = response;
      });
  }

  save(editProfile) {
    if (editProfile.valid) {
      this.currentUser.firstName = this.editProfile.value.firstName;
      this.currentUser.lastName = this.editProfile.value.lastName;
      this.currentUser.email = this.editProfile.value.email;
      this.currentUser.mobileNumber = this.editProfile.value.phone;
      if (this.isProfileChanged) {
        this.saveProfile();
      }
      this.loadingService.doLoading(this.accountsService.updateUser(this.currentUser), this).pipe(untilDestroyed(this))
        .subscribe(result => {
          if(this.isProfilePictureSizeInvalid){
            this.messageService.showInfo(this.translate.instant('ParticipantActivation.ProfilePictureSizeInvalid'));
            this.isProfilePictureSizeInvalid = false;
          }
          else{
            this.messageService.showSuccess(this.translate.instant('ParticipantActivation.SaveSucessfully'));
          }
          let payload = { firstname: this.currentUser.firstName, lastname: this.currentUser.lastName };
          this.store.dispatch({ type: fromAuthentication.UPDATE_NAME, payload: payload });
        })
    }
  }

  onChange(event) {
    this.isProfileChanged = true;
    this.userProfile = event.target.files[0];
    this.file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.file = reader.result;
    }
  }

  saveProfile() {
    const formData = new FormData();
    formData.append('file', this.userProfile);
    this.accountsService.updateProfileImage(formData).subscribe({
     
      error:(error) =>{
        this.isProfilePictureSizeInvalid = true;
      }
    })
  }
  getProfile() {
    this.accountsService.getProfile().subscribe(result => {
      this.file = result.profileImage === null ? "/assets/images/image_uploader_placeholder.jpg" : this.domSanitizer.bypassSecurityTrustUrl(result.profileImage);
    })
  }
}
