import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class CatalogueService {

    constructor(public http: HttpClient, private toastr: ToastrService) { }

    merchandiseCateloge(otp :string, referenceId :string) {
        return this.http.get(`${environment.baseUrl + environment.version}sso/catalogue/MERCH?otp=${otp}&referenceId=${referenceId}`).pipe(map((response: any) => {
            return response;
        }));
    }

    masteryCateloge(otp :string, referenceId :string) {
        return this.http.get(`${environment.baseUrl + environment.version}sso/catalogue/MASTERY?otp=${otp}&referenceId=${referenceId}`).pipe(map((response: any) => {
            return response;
        }));
    }

    experianceCatelogue(otp: string, referenceId:string) {
    return this.http.get(`${environment.baseUrl + environment.version}sso/experienceCatalogue/EXPER?otp=${otp}&referenceId=${referenceId}`).pipe(map((response: any) => {
        return response;
    }));
    }

    sendOtp() {
        return this.http.post(`${environment.baseUrl + environment.version}sso/sendOtp`,{}).pipe(map((response: any) => {
            return response;
        }));

    }

    redirectMerchandiseCatalogue(otp, referenceId) {
        if (document.getElementById('catalogueForm') != null)
            document.getElementById('catalogueForm').remove();
        this.merchandiseCateloge(otp, referenceId).subscribe({
            next: (response) =>{
            let form = $('<form id="catalogueForm" target="_blank" action="' + response.redirectUrl + '" method="post">' +
                '<input type="hidden" name="token" value="' + response.sessionId + '" />' +
                '</form>');
            $('body').append(form);
            form.trigger('submit');
        },
        error: (error) => {
            let errorMessage = "";
            if (error.status == 500) {errorMessage = "Failed to redirect to experience catalogue. Please try again.";}
            else{errorMessage = error.error;};

            console.error(errorMessage, error);
            this.toastr.error(errorMessage, 'Failed', {
                timeOut: 3000
            });
        }
        });
    }

    redirectMasteryCatalogue(otp, referenceId) {
        if (document.getElementById('catalogueForm') != null)
            document.getElementById('catalogueForm').remove();
        this.masteryCateloge(otp, referenceId).subscribe({
            next: (response) =>{
            let form = $('<form id="catalogueForm" target="_blank" action="' + response.redirectUrl + '" method="post">' +
                '<input type="hidden" name="token" value="' + response.sessionId + '" />' +
                '</form>');
            $('body').append(form);
            form.trigger('submit');
        },
        error: (error) => {
            let errorMessage = "";
            if (error.status == 500) {errorMessage = "Failed to redirect to experience catalogue. Please try again.";}
            else{errorMessage = error.error;};

            console.error(errorMessage, error);
            this.toastr.error(errorMessage, 'Failed', {
                timeOut: 3000
            });
        }
        });
    }

    redirectExperianceCatalogue(otp, referenceId) {
        this.experianceCatelogue(otp, referenceId).subscribe({
            next: (response) =>{
                if (document.getElementById('experiencCatalogueForm') != null)
                    document.getElementById('experiencCatalogueForm').remove();
                let result = JSON.parse(response.postData);
                let bodyContent = ''
                for (let key in result) {
                    bodyContent = bodyContent + '<input type="hidden" name="' + key + '" value="' + result[key] + '" />';
                }
                bodyContent = bodyContent + '<input type="hidden" name="media_label" value="track points" />';
                bodyContent = bodyContent + '<input type="hidden" name="product_type" value="a" />';
                bodyContent = bodyContent + '<input type="hidden" name="action_type" value="A" />';
                let form = $('<form id="experiencCatalogueForm" target="_blank" action="' + response.url + '" method="post">' +
                    bodyContent +
                    '</form>');
                $('body').append(form);
                form.trigger('submit');
            },
            error: (error) => {
                let errorMessage = "";
                if (error.status == 500) {errorMessage = "Failed to redirect to experience catalogue. Please try again.";}
                else{errorMessage = error.error;};
   
                console.error(errorMessage, error);
                this.toastr.error(errorMessage, 'Failed', {
                    timeOut: 3000
                });
            }
        });
    }
}
