<div>
    <div class="claim-filter-section">
        <div class="module-liner" [appIsLoading]="loadingService.isLoading(this)">

            <input type="hidden" id="tokenId" name="tokenId" [value]="tokenId" />
            <input type="hidden" id="locale" name="locale" [value]="" />
            <input type="hidden" id="ssoEndpoint" name="ssoEndpoint" [value]="ssoUrl" />

            <div class="module-content">
                <h3 class="section-header">Arcade Dashboard</h3>
                <div id="arcade-widget-container"></div>
            </div>
        </div>
    </div>
</div>