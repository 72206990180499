import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { browserRefresh } from '@app/app.component';
import { HttpImageService } from '@core/shared/services/http-img.service';
import { MessagePageService } from '../services/messagepage.service';

@Component({
  selector: 'message-article',
  templateUrl: './message-article.component.html'
})
export class MessageArticleComponent implements OnInit, OnDestroy {

  messagePageId: string;
  messageArticleId: string;
  messageArticle: any;
  messageArticleContent: any;
  browserRefresh: boolean;
  selectedCulture: string;
  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private messagePageService: MessagePageService,
    private imgService: HttpImageService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent ),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      if (this.messageArticleId != this.activeRoute.snapshot.params.messageArticleId) {
        this.messagePageId = this.activeRoute.snapshot.params.messagePageId;
        this.messageArticleId = this.activeRoute.snapshot.params.messageArticleId;
        this.browserRefresh = browserRefresh;        
        this.getMessageArticlePage();
      }
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh)
        this.getMessageArticlePage();
      else
        this.browserRefresh = false;
    });
  }

  getMessageArticlePage() {
    this.selectedCulture = localStorage.getItem('culture');
    this.messagePageService.getArticle(this.messagePageId, this.selectedCulture, this.messageArticleId).subscribe(response => {
      this.messageArticle = response;
      this.messageArticleContent = this.imgService.parseHtml(response.fullText);
      setTimeout(() => {
        this.fetchBlobImages();
      });
    });
  }

  fetchBlobImages() {
    let images = document.querySelectorAll('img[data-src]');
    for (let i = 0; i < images.length; i++) {
      let dataSrc = images[i].getAttribute('data-src');
      this.imgService.getImage(dataSrc).subscribe(val => {
        let type = val.type;
        var reader = new FileReader();
        reader.readAsDataURL(val);
        reader.onloadend = function () {
          let baseImage = <string>reader.result;
          images[i].setAttribute('src', `data:${type};base64,` + baseImage.split(',')[1]);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe()
  }

}
