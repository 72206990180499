import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { HttpService } from '@core/shared';

import {
  UrlBuilder,
  GET_POINT_SUMMARY,
  GET_POINT_STATEMENT,
  GET_PROMOTIONS_BY_USERID,
  GET_POINT_STATEMENT_FOR_CHART,
  EXPORT_POINT_STATEMENT
} from '@core/core-api-variables';
import { SearchCriteria } from '@core/shared/models/search-criteria';
import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

@Injectable()
export class PointSummaryService {

  constructor(public http: HttpService, public paramsBuilderService: ParamsBuilderService) { }

  getPointsummary(pointType: string ): Observable<any> {
    let url = UrlBuilder(GET_POINT_SUMMARY, {pointType});
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getPromotions() {
    return this.http.get(GET_PROMOTIONS_BY_USERID).pipe(map((response: any) => {
      return response;
    }));
  }

  getPointsStatement(searchCriteria: SearchCriteria) {
    let params = this.paramsBuilderService.buildParams(searchCriteria);
    let data = { params }
    let url = UrlBuilder(GET_POINT_STATEMENT, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getPointsStatementForChart(fromDate, toDate, promotion, pointType): Observable<any> {
    let data = { fromDate, toDate, promotion, pointType };
    console.log(data)
    let url = UrlBuilder(GET_POINT_STATEMENT_FOR_CHART, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  //downlaod
  public downloadViewXLS(searchCriteria: SearchCriteria): Observable<any> {
    let params = this.paramsBuilderService.buildParams(searchCriteria);
    let data = { params };
    let url = UrlBuilder(EXPORT_POINT_STATEMENT, data);
    return this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
  }  
}
