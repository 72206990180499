<div #dataLoading="appIsLoading" [appIsLoading]="loadingService.isLoading(this, 'award')">
    <div class="table-width" *ngIf="myawards && myawards.items.length > 0">
        <table class="table table-striped" aria-describedby="My Awards">
            <thead>
                <tr>
                    <th *ngFor="let attributeHeader of myawards.includedColumns" scope="col">
                        <span>{{attributeHeader}}</span>
                    </th>
                    <th class="cm_12 cm_bold" scope="col">
                        {{'MyAwardsPage.MyAwardsTable.ClaimDate' | translate}}
                    </th>
                    <th class=" cm_12 cm_bold" scope="col">
                        {{'MyAwardsPage.MyAwardsTable.Award' | translate}}
                    </th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let award of myawards.items">
                    <td *ngFor="let attribute of award.attributes">
                        <span *ngIf="attribute.dataType.name === 'Integer'">{{attribute.valueAsString}}</span>
                        <span *ngIf="attribute.dataType.name === 'Date'">{{attribute.valueAsDate | localizedDate}}</span>
                        <span *ngIf="attribute.dataType.name === 'Decimal'">{{attribute.valueAsString}}</span>
                        <span
                            *ngIf="attribute.dataType.name === 'Text' || attribute.dataType.name == 'email'">{{attribute.valueAsString}}</span>
                    </td>
                    <td>{{award.dateOfClaim | localizedDate}}</td>
                    <td>{{award.points}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="awardsNoData alert alert-danger"
        *ngIf="!dataLoading.appIsLoading && myawards && myawards.items.length===0">
        {{'MyAwardsPage.RecordNotFound' | translate}}
    </div>
</div>