import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { QuizParticipantComponent } from './quiz-participant/quiz-participant.component';
import { ParticipantsQuizAttemptsComponent } from './quiz-attempts/participants-quiz-attempts.component';
import { QuizComponent } from './quiz.component';

export const QuizRoutes: Routes = [
    {
        path: 'quizsurvey',
        component: QuizComponent,
        children: [
            {
                path: 'quizandquestionnaire/index/:id',
                canActivate: [AuthGuard],
                component: QuizParticipantComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.QuizAttempt', isPreLogin: false, isDashboard: true }
            },
            {
                path: 'quizadmin/index/:id',
                canActivate: [AuthGuard],
                component: ParticipantsQuizAttemptsComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.QuizEvaluate', isPreLogin: false, isDashboard: true }
            }
        ]
    }
];