import { Component } from '@angular/core';

@Component({
    selector: 'app-access-denied',
    template: `
    <div>
        <h1>You are not authorized to access this page!!</h1>
    </div>
    `
})

export class AccessDeniedComponent {
    constructor() { }
}