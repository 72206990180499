import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'cs-timer',
  templateUrl: 'timer.component.html'
})
export class TimerComponent implements OnInit, OnChanges {
  @Input() endDate;
  @Input() showOnlyMinutes: boolean = false;

  daysSpan: any = '00';
  hoursSpan: any = '00';
  minutesSpan: any = '00';
  secondsSpan: any = '00';
  clock;
  constructor() { }

  ngOnInit() {
    this.clock = this.initializeClock(this.endDate);
  }

  ngOnChanges() {
    if(this.clock){
      clearInterval(this.clock);
      this.clock = this.initializeClock(this.endDate);
    }    
  }

  initializeClock(endtime) {
    this.updateClock(endtime);
    return setInterval(() => this.updateClock(endtime), 1000);
  }

  updateClock(endtime) {
    var t = this.getTimeRemaining(endtime);

    if (!this.showOnlyMinutes) {
      this.daysSpan = t.days;
      this.hoursSpan = ('0' + t.hours).slice(-2);
    }
    this.minutesSpan = ('0' + t.minutes).slice(-2);
    this.secondsSpan = ('0' + t.seconds).slice(-2);

    if (t.total <= 0) {
      clearInterval(this.clock);
    }
  }

  getTimeRemaining(endtime) {
    var t = Date.parse(endtime) - new Date().getTime();
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));

    if (days < 0 && hours < 0 && minutes < 0 && seconds < 0) {
      days = 0;
      hours = 0;
      minutes = 0;
      seconds = 0;
    }

    return {
      'total': t,
      'days': days,
      'hours': hours,
      'minutes': minutes,
      'seconds': seconds
    };
  }

}
