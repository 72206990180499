import { Component, Input } from '@angular/core';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'cs-sort-icons',
    templateUrl: 'sort-icons.component.html'
})

export class CustomSortIconsComponent {
    @Input() sortAscending: boolean;

    public faChevronUp = faChevronUp;
    public faChevronDown = faChevronDown;    
}