import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges, ElementRef, ViewChild } from '@angular/core';

import { FileUploadService } from '@core/shared/services/file-upload.service';
import { MessageService } from '@core/shared/services/message.service';
import { ImportBehaviors } from '@core/shared/models/import-behaviors';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'cs-file-uploader',
    templateUrl: 'file-uploader.component.html',
    styleUrls: ['file-uploader.component.scss']
})

export class FileUploaderComponent implements OnChanges {
    @Input() uploadType: string;
    @Input() contextId: string;
    @Input() userId: string;
    @Input() uploadStatus: number;
    @Input() importBehaviors: ImportBehaviors;
    @Input() uploadButtonEnabled: boolean;
    @Input() header: string;
    @Input() popoverContent: string;
    @Output() changeUploadId: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('fileUpload') fileUpload: ElementRef;
    file: any;
    fileName: string;

    constructor(private fileuploadService: FileUploadService, public translate: TranslateService, private messageService: MessageService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.uploadStatus && (changes.uploadStatus.previousValue != changes.uploadStatus.currentValue)) {
            if (this.uploadStatus <= 3 && this.fileUpload) {
                this.file = null;
                this.fileUpload.nativeElement.value = null;
                this.fileName = null;
            }
        }
    }

    changeFile(file) {
        if (file.length > 0) {
            this.file = file[0];
            this.fileName = this.file.name;
        }
    }

    uploadFile() {        
        if (this.file) {
            let extension = this.getExtension(this.file.name);
            if (extension.toLowerCase() === "csv") {
                this.fileuploadService.fileUpload(this.file, this.uploadType, this.userId, this.contextId, this.importBehaviors).subscribe((res: Response) => {
                    const uploadId = res.headers.get('Location').split('/').pop()
                    this.messageService.showSuccess('New file uploaded successfully');
                    this.changeUploadId.emit(uploadId);
                });
            }
            else {
                this.file = null;
                this.fileName = null;
                if (this.fileUpload)
                    this.fileUpload.nativeElement.value = null;
                this.messageService.showError('File type is not supported');
            }
        }
        else {
            this.messageService.showError('File is required');
        }
    }

    getExtension(filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }
}