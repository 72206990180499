import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { UrlBuilder, GET_HUB_PAGE_COMPONENTS, GET_HOME_CONFIG } from '@core/core-api-variables';
import { HttpService } from '@core/shared';

@Injectable()
export class HomeService {
  constructor(private http: HttpService) { }

  getHomeConfig() {
    return this.http.get(GET_HOME_CONFIG).pipe(map((response: any) => {
      return response;
    }));
  }

  getHubComponents(hubPageId, culture) {
    let data = { hubPageId, culture }
    let url = UrlBuilder(GET_HUB_PAGE_COMPONENTS, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
}
