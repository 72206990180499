import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectOptions } from '@core/models';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AccountsService } from '../services/accounts.service';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoadingService } from '@core/shared';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-security-question',
    templateUrl: './security-questions.component.html'
})
export class SecurityQuestionsComponent implements OnInit {
    public submitted: boolean = false;
    public formSecurityQuestion: FormGroup;
    public securityQuestion: Array<SelectOptions>;
    public userId;
    selectedSecurityQuestion: any;

    constructor(
        public formBuilder: FormBuilder,
        public accountsService: AccountsService,
        public toaster: ToastrService,
        public translate: TranslateService,
        public loadingService: LoadingService,
        private activeRoute: ActivatedRoute
    ) {
        this.setupSecurityQuestion();
        this.userId = this.activeRoute.snapshot.params.id;
    }

    ngOnInit() {
        this.loadForm();
        this.accountsService.getSecurityQuestion(this.userId)
            .subscribe(res => {
                this.selectedSecurityQuestion = res['securityQuestion'];
                this.loadForm();
            });
    }

    loadForm(): void {
        this.formSecurityQuestion = this.formBuilder.group({
            securityQuestion: [this.selectedSecurityQuestion == null ? null : this.selectedSecurityQuestion, [Validators.required]],
            securityAnswer: [this.selectedSecurityQuestion == null ? null : "********", [Validators.required]],
        });
    }

    save(formSecurityQuestion) {
        this.submitted = true;
        if (!this.formSecurityQuestion.value.securityAnswer.includes('*')) {
            if (formSecurityQuestion.valid) {
                this.loadingService.doLoading(this.accountsService.changeSecurityQuestion(formSecurityQuestion.value), this)
                    .pipe(untilDestroyed(this)).subscribe(result => {
                        this.toaster.success(this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestionUpdated'))
                        this.formSecurityQuestion.reset();
                        this.submitted = false;
                    })
            }
        }
    }

    setupSecurityQuestion() {
        this.securityQuestion = [
            { label: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.CityBorn'), value: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.CityBorn') },
            { label: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstPet'), value: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstPet') },
            { label: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.MothersMaidenName'), value: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.MothersMaidenName') },
            { label: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.DadBorn'), value: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.DadBorn') },
            { label: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstCar'), value: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstCar') },
        ];
    }

    securityQuestionChange(selectedValue) {
        this.formSecurityQuestion.patchValue({
            'securityAnswer': ""
        });
        this.formSecurityQuestion.controls['securityQuestion'].setValue(selectedValue.value);        
    }
}