import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashrewardsComponent } from './cashrewards.component';
import { CashrewardsService } from './services/cashrewards.service';
import { SharedModule } from '@app/core/shared';


@NgModule({
  declarations: [
    CashrewardsComponent
  ],
  providers:[CashrewardsService],
  imports: [
    SharedModule,
    CommonModule
  ]
})
export class CashrewardsModule { }
