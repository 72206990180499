import { Injectable } from "@angular/core";
import { HttpService } from "@app/core/shared";
import { SearchCriteria } from "@app/core/shared/models/search-criteria";
import { ParamsBuilderService } from "@app/core/shared/services/params-builder.service";
import { DELETE_NOTIFICATION, GET_ALL_NOTIFICATIONS, GET_NOTIFICATION_BY_ID, MAKE_NOTIFICATION_AS_READ, UrlBuilder } from '@core/core-api-variables';
import { map } from "rxjs/operators";

@Injectable()
export class NotificationCornerService {
    constructor(
        public http: HttpService,
        private paramsBuilderService: ParamsBuilderService
    ) {

    }

    getNotifications(searchCriteria: SearchCriteria) {
        let params = this.paramsBuilderService.buildParams(searchCriteria);
        let data = { params };
        let url = UrlBuilder(GET_ALL_NOTIFICATIONS, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }))
    }

    getNotificationById(id) {
        let data = { id };
        let url = UrlBuilder(GET_NOTIFICATION_BY_ID, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }))
    }

    makeMessageAsRead(id, payload) {
        let data = { id };
        let url = UrlBuilder(MAKE_NOTIFICATION_AS_READ, data);
        return this.http.put(url, payload).pipe(map((response: any) => {
            return response;
        }))
    }

    deleteMessage(id) {
        let data = { id };
        let url = UrlBuilder(DELETE_NOTIFICATION, data)
        return this.http.delete(url).pipe(map((response: any) => {
            return response;
        }))
    }
}