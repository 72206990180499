export class PointsSummaryFilter {
    fromDate: string;
    toDate: string;
    promotion: string;
    type: string;
    dateHeader: string;
    descriptionHeader: string;
    promotionHeader: string;
    pointsDepositedHeader: string;

    constructor(_fromDate?: string, _toDate?: string) {
        this.dateHeader = '';
        this.descriptionHeader = '';
        this.promotionHeader = '';
        this.pointsDepositedHeader = '';
        this.fromDate = _fromDate ? _fromDate : null;
        this.toDate = _toDate ? _toDate : null;
        this.promotion = '';
        this.type = '';
    }
}