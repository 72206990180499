import { Component, Input, Output, EventEmitter, OnChanges, ElementRef, ViewChild, SimpleChanges } from '@angular/core';

@Component({
    selector: 'cs-file-picker',
    templateUrl: 'file-picker.component.html'
})

export class FilePickerComponent implements OnChanges {
    @Input() isDisabled: boolean;
    @Input() uploadStatus: number;
    @Output() changeFileEvent: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('fileUpload') fileUpload: ElementRef;    

    ngOnChanges(changes: SimpleChanges) {
        if (changes.uploadStatus && (changes.uploadStatus.previousValue != changes.uploadStatus.currentValue)) {
            if (this.uploadStatus == 3 && this.fileUpload) {                
                this.fileUpload.nativeElement.value = null;
            }
        }
    }

    changeFile(file) {
        if (file.length > 0)
            this.changeFileEvent.emit(file[0]);
    }
}