import { NgModule } from '@angular/core';
import { RouterModule, UrlSerializer } from '@angular/router';

import { CustomUrlSerializer } from '@core/shared';
import { CoreRoutes } from '@core/core.routes';

let routes = [...CoreRoutes]

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
