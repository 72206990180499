import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'form-input',
  //template: `<html-outlet [html]="a"></html-outlet>`,
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class FormInputComponent implements OnInit {
  @Input() controlName: string;
  @Input() theme: string;
  @Input() inputType: string;
  @Input() placeHolder: string;
  @Input() rightIcon: string;
  @Input() disable: boolean;
  @Input() setFocus: boolean;

  public type: string;
  public template: string;  

  constructor() { }

  ngOnInit() {
    this.template = this.theme ? this.theme : 'theme1';
    this.type = this.inputType ? this.inputType : 'text';
  }
}