<div>
    <p class="side-header pt-0" *ngIf="attributeJson && attributeJson.displayPointsHistory">
        {{'MyThresholdAwardsPage.PointsHistory' | translate}}</p>
    <div class="pointHistory p-4 bg-color2" *ngIf="attributeJson && attributeJson.displayPointsHistory">
        <!-- <p class="mt-3 mb-3 pointsStatement">{{'MyThresholdAwardsPage.ReviewPointsStatement' |
                            translate}}</p> -->
        <div class="formDiv" [ngClass]="{'noBoderBottom': pointsHistory.length == 0}">
            <form [formGroup]="thresholdAwardsForm">
                <div class="row mb-5">
                    <div class="col-md-6 mt-5">
                        <label class="l1">{{'MyThresholdAwardsPage.SearchBy' | translate}}
                            {{tableHeadingDynamic}}</label>
                        <!-- <span class="glyphicon glyphicon-question-sign customGlyph"></span> -->
                        <input type="text" formControlName="invoiceNumber" placeholder="{{tableHeadingDynamic}}">
                    </div>
                    <div class="col-md-6 mt-5">
                        <label class="l1">{{'MyThresholdAwardsPage.DateOfCalculation' |
                            translate}}</label>
                        <!-- <span class="glyphicon glyphicon-question-sign customGlyph"></span> -->
                        <div class="grid grid-gap-0 grid-col-hd-50 grid-col-sm-100">
                            <div class="form-group item">
                                <label for="fromdate" class="datePlacement">{{'PointsSummaryPages.From'
                                    |
                                    translate}}</label>
                                <div class="input-group">
                                    <input class="form-control datePickerCalander" placeholder="mm-dd-yyyy"
                                        name="fromDate" ngbDatepicker #dpFromDate="ngbDatepicker"
                                        [maxDate]="maxFromDate" [minDate]="minFromDate" readonly
                                        formControlName="fromDate" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="dpFromDate.toggle()"
                                            type="button"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group item">
                                <label for="fromdate" class="datePlacement pl-4"><span
                                        translate="PointsSummaryPages.To"></span></label>
                                <div class="input-group">
                                    <input class="form-control datePickerCalander" placeholder="mm-dd-yyyy"
                                        name="toDate" ngbDatepicker #dpToDate="ngbDatepicker" [maxDate]="maxToDate"
                                        [minDate]="minToDate" readonly formControlName="toDate" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="dpToDate.toggle()"
                                            type="button"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 btnDiv pull-right mt-5">
                        <button class="btn btn-primary customBtnSearch"
                            (click)="search()">{{'MyThresholdAwardsPage.BtnSearch' |
                            translate}}</button>
                    </div>
                    <div class="col-md-6 btnDiv mt-5">
                        <button class="btn btn-primary customBtnSearch secondaryThemeBtn" (click)="reset()">
                            {{'MyThresholdAwardsPage.BtnReset' | translate}}</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="pointHistoryTable mt-5">
            <table class="mt-5" aria-describedby="Threshold Award History"
                *ngIf="pointsHistory && pointsHistory.length > 0">
                <tr>
                    <th (click)="onSort('categoryValue')" scope="col">
                        <span>{{tableHeadingDynamic}}</span>
                        <cs-sort-icons *ngIf="sorter.showSortIcons('categoryValue')"
                            [sortAscending]="sorter.sortDirection"></cs-sort-icons>
                    </th>
                    <th (click)="onSort('calculatedDate')" scope="col">
                        <span>{{'MyThresholdAwardsPage.DateOfCalculation' | translate}}</span>
                        <cs-sort-icons *ngIf="sorter.showSortIcons('calculatedDate')"
                            [sortAscending]="sorter.sortDirection"></cs-sort-icons>
                    </th>
                    <th (click)="onSort('fromThreshold')" scope="col">
                        <span>{{'MyThresholdAwardsPage.FromThreshold' | translate}}</span>
                        <cs-sort-icons *ngIf="sorter.showSortIcons('fromThreshold')"
                            [sortAscending]="sorter.sortDirection"></cs-sort-icons>
                    </th>
                    <th (click)="onSort('toThreshold')" scope="col">
                        <span>{{'MyThresholdAwardsPage.ToThreshold' | translate}}</span>
                        <cs-sort-icons *ngIf="sorter.showSortIcons('toThreshold')"
                            [sortAscending]="sorter.sortDirection"></cs-sort-icons>
                    </th>
                    <th (click)="onSort('points')" scope="col">
                        <span>{{'MyThresholdAwardsPage.Points' | translate}}</span>
                        <cs-sort-icons *ngIf="sorter.showSortIcons('points')" [sortAscending]="sorter.sortDirection">
                        </cs-sort-icons>
                    </th>
                </tr>
                <tr *ngFor="let item of pointsHistory">
                    <td>{{item.categoryValue}}</td>
                    <td>{{item.calculatedDate | localizedDate}}</td>
                    <td>{{item.fromThreshold}}</td>
                    <td>{{item.tothreshold}}</td>
                    <td>{{item.points}}</td>
                </tr>
            </table>
            <div class="alert alert-danger mt-5" *ngIf="pointsHistory && pointsHistory.length === 0">
                {{'MyThresholdAwardsPage.RecordNotFound' | translate}}
            </div>
        </div>
        <div class="row mt-4" *ngIf="paginationDetails && paginationDetails.totalNumberOfItems &&
                            paginationDetails.totalNumberOfItems > 10">
            <div class="col-lg-12 pagination-center-Bottom">
                <cs-custom-pagination [paginationDetails]="paginationDetails" (onChangeEvent)="onChange($event)">
                </cs-custom-pagination>
            </div>
        </div>
    </div>



    <p class="side-header" *ngIf="attributeJson && attributeJson.displaySKUTargetProgress">
        {{'MyThresholdAwardsPage.TargetProgress' | translate}}</p>
    <div class="skuTargetProgress p-4 bg-color2" *ngIf="attributeJson && attributeJson.displaySKUTargetProgress">
        <div class="mt-5">
            <div class="container-fluid magin-left-right" *ngIf="skuChart && skuChart.length > 0">
                <div>
                    <div class="tab-center">
                        <div class="content-width high">
                            <nav ngbNav #nav="ngbNav" class="nav-tabs nav-fill" [(activeId)]="activeId">
                                <ng-container ngbNavItem *ngFor="let item of skuChart; let i = index">
                                    <a ngbNavLink (click)="changeChartValue(item.product)" [ngbNavItem]="i">
                                        {{item.product}}</a>
                                </ng-container>
                            </nav>
                        </div>
                    </div>
                    <div class="container-fluid body-tab-content tabwidget-continer-color" *ngIf="showProgressBar">
                        <div class="content-width tab-continer-color">
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                            <div>
                                <cs-goal-progress-bar-chart class="block margin-top-20"
                                    [progressPercentage]="targetPoints.percentageBarColor" trackColor="#f0f0f0"
                                    progressedTrackColor="linear-gradient(to right, #53C56D, #53C56D)"
                                    progressIcon="/assets/images/target_goal_rank_car_icon.png"
                                    goalIcon="/assets/images/profile_target.png" [loadOnInit]="true"
                                    [targets]="progressTargets" [noPercentage]='true'>
                                </cs-goal-progress-bar-chart>
                            </div>
                            <p class="text-center progressStatus" *ngIf="targetPoints.qtyToReachNextLvl > 0">
                                {{'MyThresholdAwardsPage.Msg1' |
                                translate}}<span class="textGreen"> {{targetPoints.earnedPoints}} Points
                                </span> {{'MyThresholdAwardsPage.Msg2'
                                | translate}} {{targetPoints.qtyToReachNextLvl}}
                                {{'MyThresholdAwardsPage.Msg3' | translate}} </p>
                            <p class="text-center progressStatus" *ngIf="targetPoints.qtyToReachNextLvl == 0">
                                {{'MyThresholdAwardsPage.Msg1' |
                                translate}}<span class="textGreen"> {{targetPoints.earnedPoints}}
                                    Points. </span></p>
                            <p class="text-center info">{{'MyThresholdAwardsPage.ProgressBarMobInfo' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger" *ngIf="skuChart && skuChart.length === 0">
                {{'MyThresholdAwardsPage.RecordNotFound' | translate}}
            </div>
        </div>
    </div>


    <p class="side-header" *ngIf="attributeJson && attributeJson.displaySKUPointsStructure">
        {{'MyThresholdAwardsPage.pointsStructure' |
        translate}}</p>
    <div class="skuPointsStructure p-4 bg-color2" *ngIf="attributeJson && attributeJson.displaySKUPointsStructure">
        <table class="mt-5" aria-describedby="Threshold Award Rules"
            *ngIf="skuPointsStructure && skuPointsStructure.length > 0">
            <tr>
                <th scope="col">{{tableHeadingDynamic}}</th>
                <th scope="col">{{'MyThresholdAwardsPage.MinQty' | translate}}</th>
                <th scope="col">{{'MyThresholdAwardsPage.MaxQty' | translate}}</th>
                <th scope="col">{{'MyThresholdAwardsPage.Points' | translate}}</th>
            </tr>
            <tr *ngFor="let item of skuPointsStructure">
                <td>{{item.product}}</td>
                <td>{{item.minQuantity}}</td>
                <td>{{item.maxQuantity}}</td>
                <td>{{item.points}}</td>
            </tr>
        </table>
        <div class="alert alert-danger" *ngIf="skuPointsStructure && skuPointsStructure.length === 0">
            {{'MyThresholdAwardsPage.RecordNotFound' | translate}}
        </div>
    </div>
</div>