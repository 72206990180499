import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import moment from 'moment';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';

import { LoadingService } from '@core/shared';
import { PointSummaryService } from '../services/pointsummary.service';
import { PointsSummaryFilter } from '../view-models/points-summary.filter';
import { HubPageService } from '../../hubpage/services/hub-page.service';
import { PlatformSystemSettings } from '@app/core/models';
import { HomeService } from '../../home/services/home.service';
import { forkJoin } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-points-summary',
  templateUrl: './points-summary.component.html',
  styleUrls: ['./points-summary.component.scss']
})
export class PointSummaryComponent implements OnInit {
  filter: PointsSummaryFilter;
  payload: PointsSummaryFilter;
  promotions: any;
  summary: any;
  minFromDate: any;
  maxFromDate: any;
  minToDate: any;
  maxToDate: any;
  summaryDetails: any;
  showExpiredData: boolean;
  showchart = true;
  systemSettings: PlatformSystemSettings;
  pointType: string = 'rewards';
  showPointTypeTab: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private translateService: TranslateService, private titleService: Title
    , private pointSummaryService: PointSummaryService, public loadingService: LoadingService, private hubPageService: HubPageService, private homeService: HomeService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute.snapshot),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })).subscribe((route: ActivatedRouteSnapshot) => {
        this.translateService.get(route.data.title).subscribe((res: string) => {
          this.titleService.setTitle(res);
        });
      });
  }

  ngOnInit(): void {
    this.systemSettings = this.activatedRoute.snapshot.data.settings;
    this.showPointTypeTab = this.systemSettings.isWalletBankEnabled;
    let fromDate = new Date();
    fromDate.setFullYear(fromDate.getFullYear() - 1);
    let toDate = new Date();
    this.maxFromDate = { year: toDate.getFullYear(), month: toDate.getMonth() + 1, day: toDate.getDate() };
    this.minToDate = { year: fromDate.getFullYear(), month: fromDate.getMonth() + 1, day: fromDate.getDate() };
    
    this.filter = new PointsSummaryFilter(moment(fromDate).format("DD-MM-YYYY"), moment(toDate).format("DD-MM-YYYY"));

    this.pointSummaryService.getPromotions().subscribe(data => {
      this.promotions = data;
    });
    
    this.showExpiredData = this.systemSettings.isFIFOEnabled && this.systemSettings.showExpiredData;
    this.loadPointSummary();
    this.onSubmit();
  }

  onPointTypeChange(pointType): void {
    this.pointType=pointType;
    this.summary=undefined;
    this.summaryDetails=undefined;
    this.loadPointSummary();
    this.onSubmit();
  }

  loadPointSummary() {
    this.loadingService.doLoading(this.homeService.getHomeConfig(), this, 'config')
      .pipe(untilDestroyed(this)).subscribe(res => {
        let pointSummaryId = res?.pointSummaryPageId;
        if (pointSummaryId) {
          let culture = localStorage.getItem("culture");
          let payload = { typeId: 8 };
          let data = { item: JSON.stringify(payload) };

          let summaryConfig = this.hubPageService.getHubComponent(pointSummaryId, culture, data);
          let summaryData = this.pointSummaryService.getPointsummary(this.pointType);
          let join = forkJoin([summaryConfig, summaryData]);

          this.loadingService.doLoading(join, this, "data")
            .pipe(untilDestroyed(this)).subscribe(response => {
              this.summaryDetails = response[0];
              this.summary = response[1];
            });
        }
      });
  }

  onSubmit() {
    let fromDate = this.filter.fromDate ? moment(this.filter.fromDate, "DD-MM-YYYY").format("YYYY-MM-DD") + "T00:00:00" : null;
    let toDate = this.filter.toDate ? moment(this.filter.toDate, "DD-MM-YYYY").format("YYYY-MM-DD") + "T23:59:59.999" : null;
    this.payload = Object.assign({}, this.filter, { fromDate: fromDate, toDate: toDate, pointType:this.pointType});
  }
  blockOldDate(value) {
    let tempFromDate = new Date(this.convertToDateFormat(this.filter.fromDate));
    let tempToDate = new Date(this.convertToDateFormat(this.filter.toDate));

    if (value === 'from') {
      if (this.filter.fromDate !== null) {
        this.minToDate = { year: tempFromDate.getFullYear(), month: tempFromDate.getMonth() + 1, day: tempFromDate.getDate() };
      }
    }
    else {
      if (this.filter.toDate !== null) {
        this.maxFromDate = { year: tempToDate.getFullYear(), month: tempToDate.getMonth() + 1, day: tempToDate.getDate() };
      }
    }
  }

  convertToDateFormat(date) {
    let splitDate1 = date.split('-');
    let day = splitDate1[0];
    let month = splitDate1[1];
    let year = splitDate1[2];
    return month + "-" + day + "-" + year;
  }

  showChart(showChart) {
    this.showchart = showChart;
  }
}
