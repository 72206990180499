import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { CouponComponent } from './coupon.component';
import { CouponclaimComponent } from './coupon-claim/coupon-claim.component';
import { CouponClaimHistoryComponent } from './coupon-claim-history/coupon-claim-history.component';

export const CouponClaimRoutes: Routes = [
    {
        path: 'coupon',        
        component: CouponComponent,
        children: [
            {
                path: 'couponclaim',                
                component: CouponclaimComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'CouponClaimPage.CouponClaimSubmission', isPreLogin: false }
            },
            {
                path: 'couponclaim/couponclaimhistory',                
                component: CouponClaimHistoryComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'CouponClaimPage.CouponClaimHistory.CouponClaimHistoryHeader', isPreLogin: false }
            }
        ]
    }
];