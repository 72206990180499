import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { browserRefresh } from '@app/app.component';
import { HttpImageService } from '@core/shared/services/http-img.service';
import { QuestionPageService } from '../services/questionpage.service';
import { LoadingService } from '@core/shared';

@UntilDestroy()
@Component({
  selector: 'question-page',
  templateUrl: './question-page.component.html',
  styleUrls: ['./question-page.component.scss']

})
export class QuestionPageComponent implements OnInit, OnDestroy {

  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;
  browserRefresh: boolean;
  selectedCulture: string;
  questionPageId: string;
  questionPageDetails: any;
  articleDetails: any;
  categoryDetails: any;
  selectedCategory: string = '';
  selectedCategories: string[] = [];
  showContent: any = {};
  showDateToParticipant: boolean = false;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private questionPageService: QuestionPageService,
    private imgService: HttpImageService, private translateService: TranslateService,
    public loadingService: LoadingService) { }

  ngOnInit(): void {

    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent ),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      if (this.questionPageId != this.activeRoute.snapshot.params.id) {
        this.browserRefresh = browserRefresh;
        this.questionPageId = this.activeRoute.snapshot.params.id;
        this.getQuestionPages();
      }
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh)
        this.getQuestionPages();
      else
        this.browserRefresh = false;
    });
  }

  getQuestionPages() {
    this.selectedCulture = localStorage.getItem('culture');
    this.selectedCategories = [];

    let questionPages = this.questionPageService.getQuestionPage(this.questionPageId, this.selectedCulture);
    let articles = this.questionPageService.getArticles(this.questionPageId, this.selectedCulture);
    let categories = this.questionPageService.getCategories(this.questionPageId, this.selectedCulture);

    this.loadingService.doLoading(forkJoin([questionPages, articles, categories]), this)
      .pipe(untilDestroyed(this)).subscribe(response => {
        this.questionPageDetails = response[0];
        this.articleDetails = response[1];
        this.categoryDetails = response[2];

        if (this.questionPageDetails && this.questionPageDetails.attributeJson) {
          this.showDateToParticipant = JSON.parse(this.questionPageDetails.attributeJson)['faqShowDateToParticipant'];
        }
        this.calculateUnAssignedCategories();

        this.articleDetails.forEach(article => {
          article['htmlContent'] = this.imgService.parseHtml(article.fullText);
          article['showContent'] = false;
        });

        setTimeout(() => {
          this.fetchBlobImages();
        });
      });
  }

  calculateUnAssignedCategories() {
    let unAssignedCategoryExists = false;
    this.articleDetails.forEach(article => {
      if (article.categoryIds.length === 0) {
        unAssignedCategoryExists = true;
        article.categoryIds.push("-1");
      }
    });
    if (unAssignedCategoryExists) {
      this.categoryDetails.push({ 'id': "-1", 'name': this.translateService.instant('QuestionPage.UnassignedCategoryName') });
    }
  }

  onCategoryFilterClick() {
    this.selectedCategories = [];
    if (this.selectedCategory != '')
      this.selectedCategories.push(this.selectedCategory);
  }

  showHideContent(articleId) {
    this.showContent[articleId] = !this.showContent[articleId];
  }

  fetchBlobImages() {
    let images = document.querySelectorAll('img[data-src]');
    for (let i = 0; i < images.length; i++) {
      let dataSrc = images[i].getAttribute('data-src');
      this.imgService.getImage(dataSrc).subscribe(val => {
        let type = val.type;
        var reader = new FileReader();
        reader.readAsDataURL(val);
        reader.onloadend = function () {
          let baseImage = <string>reader.result;
          images[i].setAttribute('src', `data:${type};base64,` + baseImage.split(',')[1]);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe()
  }
}

