import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LoadingService } from '@app/core/shared';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { filter, startWith, Subscription } from 'rxjs';
import { EmbeddedReportService } from '../services/embedded-report.service';

@Component({
  selector: 'app-embedded-reports',
  templateUrl: './embedded-reports.component.html',
  styleUrls: ['./embedded-reports.component.scss']
})
export class EmbeddedReportsComponent implements OnInit {

  id: string;
  reportType: string = null;
  languageChangeSubscription: Subscription;
  navigationStartSubscription: Subscription;
  browserRefresh: boolean;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private embeddedReportService: EmbeddedReportService, public loadingService: LoadingService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      if (this.id != this.activeRoute.snapshot.params.id) {
        this.id = this.activeRoute.snapshot.params.id;
        this.getEmbeddedReportType();
      }
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh)
        this.getEmbeddedReportType();
      else
        this.browserRefresh = false;
    });

  }

  getEmbeddedReportType() {
    this.loadingService.doLoading(this.embeddedReportService.getEmbeddedReportType(this.id), this).subscribe(res => {
      if (res == null ||  res == undefined ||  res == "") {
        this.reportType = 'PowerBI'
      }
      else {
        this.reportType = res;
      }
    });
  }
}
