import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';
import { SubMenuResolver } from '@core/shared/guards';

import { TabViewComponent } from './tabview.component';
import { CouponClaimHistoryComponent } from '@core/private/couponclaim/coupon-claim-history/coupon-claim-history.component';
import { CouponclaimComponent } from '@core/private/couponclaim/coupon-claim/coupon-claim.component';
import { InvoiceClaimApprovalComponent } from '@core/private/invoiceclaim/invoice-claim-approval/invoice-claim-approval.component';
import { InvoiceClaimHistoryComponent } from '@core/private/invoiceclaim/invoice-claim-history/invoice-claim-history.component';
import { InvoiceClaimSubmissionComponent } from '@core/private/invoiceclaim/invoice-claim-submission/invoice-claim-submission.component';
import { EmbeddedReportsComponent } from '../embeddedreport/embedded-reports/embedded-reports.component';
import { GameArcadeComponent } from '@core/private/game/game-arcade/game-arcade.component';
import { GoalQuestParticipantComponent } from '@core/private/goalquest/participant/goal-quest-participant.component';
import { GoalQuestManagerComponent } from '@core/private/goalquest/manager/goal-quest-manager/goal-quest.component';
import { HubPageComponent } from '@core/private/hubpage/hub-page/hub-page.component';
import { MessageArticleComponent } from '@core/private/messagepage/message-article/message-article.component';
import { MessagePageComponent } from '@core/private/messagepage/message-page/message-page.component';
import { MyAwardsComponent } from '@core/private/myawards/my-awards/my-awards.component';
import { MyPerformanceComponent } from '@core/private/myperformance/my-performance/my-performance.component';
import { MyThresholdAwardsComponent } from '@core/private/mythresholdawards/my-threshold-awards-component/my-threshold-awards.component';
import { QuestionPageComponent } from '@core/private/questionpage/question-page/question-page.component';
import { QuestionArticleComponent } from '@core/private/questionpage/question-article/question-article.component';
import { QuizParticipantComponent } from '@core/private/quiz/quiz-participant/quiz-participant.component';
import { ParticipantsQuizAttemptsComponent } from '@core/private/quiz/quiz-attempts/participants-quiz-attempts.component';
import { SalesClaimComponent } from '@core/private/salesclaim/sales-claim/sales-claim.component';
import { GoalQuestComponent } from '@core/private/communication/goalquest/goalquest.component';
import { AotComponent } from '@core/private/communication/aot/aot.component';
import { SellxyComponent } from '@core/private/communication/sellxy/sellxy.component';
import { ContentPageComponent } from '@core/private/contentpage/content-page/content-page.component';
import { MembershipTieringComponent } from '../membership-tiering/membership-tiering-component/membership-tiering.component';
import { InvoiceClaimResubmissionComponent } from '../invoiceclaim/invoice-claim-resubmission/invoice-claim-resubmission.component';
import { LeagueComponent } from '../league/league/league.component';

export const TabViewRoutes: Routes = [
    {
        path: 'multiview/:menuId',        
        component: TabViewComponent,
        resolve: {subMenu: SubMenuResolver},
        children: [
            {
                path: 'couponclaim',
                component: CouponclaimComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'CouponClaimPage.CouponClaimSubmission', isPreLogin: false }
            },
            {
                path: 'couponclaim/couponclaimhistory',
                component: CouponClaimHistoryComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'CouponClaimPage.CouponClaimHistory.CouponClaimHistoryHeader', isPreLogin: false }
            },
            {
                path: 'claims/invoiceclaimhistory/:id',
                component: InvoiceClaimHistoryComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'InvoiceClaimPage.InvoiceClaimHistory.InvoiceClaimHistoryHeader', isPreLogin: false }
            },
            {
                path: 'claims/claimsubmission/:id',
                component: InvoiceClaimSubmissionComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'InvoiceClaimPage.InvoiceClaimSubmission.InvoiceClaimSubmissionHeader', isPreLogin: false }
            },
            {
                path: 'claims/approveinvoiceclaim/:id',
                component: InvoiceClaimApprovalComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'InvoiceClaimPage.InvoiceClaimApproval.InvoiceClaimsApprovalHeader', isPreLogin: false }
            },
            {
                path: 'claims/claimresubmission/:id/:invoiceId',
                component: InvoiceClaimResubmissionComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'InvoiceClaimPage.InvoiceClaimSubmission.InvoiceClaimResubmissionHeader', isPreLogin: false }
            },
            {
                path: 'contentpage/view/:id',
                canActivate: [AuthGuard],
                component: ContentPageComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.ContentPage', isPreLogin: false }
            },
            {
                path: 'embeddedreportpage/view/:id',
                canActivate: [AuthGuard],
                component: EmbeddedReportsComponent,
                pathMatch: 'full',
                data: { title: 'Embedded Reports', isPreLogin: false, isDashboard: true }
            },
            {
                path: 'gamearcade/index/:id',
                canActivate: [AuthGuard],
                component: GameArcadeComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.Games', isPreLogin: false, isDashboard: true }
            },
            {
                path: 'goalquestpage/view/:id',
                canActivate: [AuthGuard],
                component: GoalQuestParticipantComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.GoalQuestPage', isPreLogin: false }
            },
            {
                path: 'managergoalquestpage/view/:id',
                canActivate: [AuthGuard],
                component: GoalQuestManagerComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.GoalQuestPage', isPreLogin: false }
            },
            {
                path: 'hubpage/view/:id',
                canActivate: [AuthGuard],
                component: HubPageComponent,
                runGuardsAndResolvers: 'always',
                pathMatch: 'full',
                data: { title: 'PageTitles.HubPage', isPreLogin: false }
            },
            {
                path: 'league/view/:id',
                canActivate: [AuthGuard],
                component: LeagueComponent,
                pathMatch: 'full',
                data: { title: 'League', isPreLogin: false }
            },
            {
                path: 'membershiptiering',
                canActivate: [AuthGuard],
                component: MembershipTieringComponent,
                runGuardsAndResolvers: 'always',
                pathMatch: 'full',
                data: { title: 'My Awards', isPreLogin: false }
            },
            {
                path: 'messagepage/view/:id',
                canActivate: [AuthGuard],
                component: MessagePageComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.MessagePage', isPreLogin: false }
            },
            {
                path: 'messagearticle/:messagePageId/view/:messageArticleId',
                canActivate: [AuthGuard],
                component: MessageArticleComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.MessageArticle', isPreLogin: false }
            },
            {
                path: 'myawards/page/:id',
                canActivate: [AuthGuard],
                component: MyAwardsComponent,
                runGuardsAndResolvers: 'always',
                pathMatch: 'full',
                data: { title: 'My Awards', isPreLogin: false }
            },
            {
                path: 'myperformance/index/:id',
                canActivate: [AuthGuard],
                component: MyPerformanceComponent,
                runGuardsAndResolvers: 'always',
                pathMatch: 'full',
                data: { title: 'PageTitles.MyPerformance', isPreLogin: false }
            },
            {
                path: 'mythresholdawards/page/:id',
                canActivate: [AuthGuard],
                component: MyThresholdAwardsComponent,
                runGuardsAndResolvers: 'always',
                pathMatch: 'full',
                data: { title: 'My Threshold Awards', isPreLogin: false }
            },
            {
                path: 'questionpage/view/:id',
                canActivate: [AuthGuard],
                component: QuestionPageComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.QuestionPage', isPreLogin: false }
            },
            {
                path: 'questionarticle/:questionPageId/view/:questionArticleId',
                canActivate: [AuthGuard],
                component: QuestionArticleComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.QuestionArticle', isPreLogin: false }
            },
            {
                path: 'quizandquestionnaire/index/:id',
                canActivate: [AuthGuard],
                component: QuizParticipantComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.QuizAttempt', isPreLogin: false, isDashboard: true }
            },
            {
                path: 'quizadmin/index/:id',
                canActivate: [AuthGuard],
                component: ParticipantsQuizAttemptsComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.QuizEvaluate', isPreLogin: false, isDashboard: true }
            },
            {
                path: 'claims/sales/:id',
                canActivate: [AuthGuard],
                component: SalesClaimComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.Sales', isPreLogin: false }
            },
            {
                path: 'goalquest/:id',
                component: GoalQuestComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'PageTitles.GoalQuestPage', isPreLogin: false }
            },
            {
                path: 'achievementoftarget/:id',
                component: AotComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'PageTitles.Aot', isPreLogin: false }
            },
            {
                path: 'sellxgety/:id',
                component: SellxyComponent,
                canActivate: [AuthGuard],
                pathMatch: 'full',
                data: { title: 'PageTitles.SellXGetY', isPreLogin: false }
            }
        ]
    }
];