import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WindowReferenceService, ICustomWindow, SharedService } from '@core/shared';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import { SystemSettings } from '@app/core/models';
import { Title } from '@angular/platform-browser';
import { HomeService } from '@core/private/home/services/home.service';
import { browserRefresh } from '@app/app.component';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: [
        './dashboard.style.scss'
    ]
})
export class DashboardComponent implements OnInit, OnDestroy {
    gameToken: any;
    tokenId: string;
    ssoUrl: string;
    selectedTab: number;
    tabs: any[] = [];
    isGameEnabled: boolean;
    hasValidGameToken: boolean = true;
    homeHubId: string;
    private _window: ICustomWindow;
    homeHubPage: any[];
    selectedCulture;
    defaultCulture;
    browserRefresh: boolean;
    hideMultiView: boolean = false;
    languageChangeSubscription: Subscription;
    navigationStartSubscription: Subscription;
    public currentUser = "";
    private onDestroy$ = new Subject<void>();

    public slideConfigHub: any = { dots: false, infinite: true };
    constructor(private router: Router,private activatedRoute: ActivatedRoute, public homeService: HomeService, private sharedService: SharedService,
        public windowRef: WindowReferenceService, private translateService: TranslateService,
        private store: Store<fromStore.PlatformState>, private systemSettings: SystemSettings,private titleService : Title) {
        this._window = windowRef.nativeWindow;

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute.snapshot),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            })).subscribe((route: ActivatedRouteSnapshot) => {
                this.translateService.get(route.data.title).subscribe((res: string) => {
                    this.titleService.setTitle(res);
                });
            });

    }

    ngOnInit() {
        this.defaultCulture = this.systemSettings.defaultPlatformLanguage;
        this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            if (state.user && state.user.Firstname) {
                this.currentUser = state.user.Firstname;
                if (state.user.Lastname)
                    this.currentUser = state.user.Firstname + ' ' + state.user.Lastname;
            }
        });
        this.navigationStartSubscription = this.router.events.pipe(
            filter((event) => event instanceof RouterEvent ),
            startWith('Initial call')
        ).subscribe((res : NavigationEnd) => {
            if (res.toString().startsWith('Initial call')) {
                this.browserRefresh = browserRefresh;
                this.getHomeConfig();
            }
        });

        this.languageChangeSubscription = this.translateService.onLangChange.subscribe(() => {
           
            if (!this.browserRefresh){
                this.getHomeConfig();
            }
            else
                this.browserRefresh = false;
        });

    }


    getHomeConfig() {
        this.selectedCulture = localStorage.getItem('culture');
        this.homeService.getHomeConfig().subscribe(result => {
            if (result) {
                this.isGameEnabled = result.isGameEnabled === 'true' ? true : false;
                this.homeHubId = result.hubPageId && result.hubPageId != '' ? result.hubPageId : null;
                if (this.isGameEnabled)
                    this.getGameToken();
                if (this.homeHubId)
                    this.getHomeHubPage();
            }
        });
    }

    getHomeHubPage() {
        this.homeService.getHubComponents(this.homeHubId, this.selectedCulture).subscribe(result => {
            let groups = [];
            result.forEach(group => {
                let items = { Items: [] };
                group.Items.forEach(item => {
                    if (item.hasAccess) {
                        if (item.displayName) {
                            item['tabDisplayName'] = item.displayName[this.selectedCulture] ? item.displayName[this.selectedCulture] : item.displayName[this.defaultCulture];
                        }
                        items.Items.push(item);
                    }
                });
                groups.push(items);
            });
            this.homeHubPage = groups;
            if (this.arrayHasIndex(this.homeHubPage, 2)) {
                this.homeHubPage[2].Items.forEach(item => {
                    this.tabs.push({ label: item.selectedItem.name, isDataLoaded: false });
                });
                this.selectedTab = 0;
            }
        });
    }

    getGameToken() {
        this.sharedService.getGameToken().subscribe((response: any) => {
            this.hasValidGameToken = response.tokenExist;
            if (this.hasValidGameToken) {
                this.tokenId = response.tokenId;
                this.ssoUrl = response.ssoUrl;
            }
        });
    }

    hideMultiViewRedeem() {
        this.hideMultiView = true;
    }

    arrayHasIndex = (array, index) => Array.isArray(array) && array.hasOwnProperty(index);

    ngOnDestroy() {
        if (this.languageChangeSubscription)
            this.languageChangeSubscription.unsubscribe();
        if (this.navigationStartSubscription)
            this.navigationStartSubscription.unsubscribe();
    }
}