<ng-container>
  <div class="edit-profile">
    <form [formGroup]="editProfile" *ngIf="editProfile">

      <div class="bg-color3 parent" [appIsLoading]="loadingService.isLoading(this)">
        <div class="content-width form-content bg-color2">
          <body-text class="medium-size-minu-1 block margin-bottom-40">{{ 'ParticipantActivation.PleaseFillYourDetails'
            | translate }}</body-text>

          <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
            <div class="margin-bottom-10">
              <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.ProfilePicture' | translate }}
              </body-text>
              <div class="form-image-uploader">
                <img alt="Profile Image" [src]="file" class="image-placeholder">
                <input class="form-control" type="file" accept="image/*" (change)="onChange($event)">
              </div>
            </div>
            <div class="margin-bottom-10">
            </div>
          </div>

          <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
            <div class="margin-bottom-10">
              <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.FirstName' | translate }} <span
                  class="mandatory">*</span></body-text>
              <form-input controlName="firstName" disable="true" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.FirstName' | translate }}">
              </form-input>
              <div class="help-block text-danger"
                *ngIf="!editProfile.controls.firstName.valid && (editProfile.controls.firstName.touched )">
                <span [hidden]="!editProfile.controls['firstName'].hasError('required')"
                  translate="ParticipantActivation.FirstNameRequired"></span>
              </div>
            </div>
            <div class="margin-bottom-10">
              <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.LastName' | translate }} <span
                  class="mandatory">*</span></body-text>
              <form-input controlName="lastName" disable="true" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.LastName' | translate }}">
              </form-input>
              <div class="help-block text-danger"
                *ngIf="!editProfile.controls.lastName.valid && (editProfile.controls.lastName.touched )">
                <span [hidden]="!editProfile.controls['lastName'].hasError('required')"
                  translate="ParticipantActivation.LastNameRequired"></span>
              </div>
            </div>
          </div>
          <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
            <div class="margin-bottom-10">
              <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.Username' | translate }}
              </body-text>
              <form-input controlName="userName" disable="true" theme="theme2" placeHolder="UserName">
              </form-input>
            </div>
            <div class="margin-bottom-10">
            </div>
          </div>
          <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
            <div class="margin-bottom-10">
              <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.Email' | translate }} <span
                  class="mandatory">*</span></body-text>
              <form-input controlName="email" disable="true" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.Email' | translate }}">
              </form-input>
              <div class="help-block text-danger"
                *ngIf="!editProfile.controls.email.valid && (editProfile.controls.email.touched )">
                <span [hidden]="!editProfile.controls['email'].hasError('required')"
                  translate="ParticipantActivation.EmailInvalid"></span>
              </div>
            </div>
            <div class="margin-bottom-10">
              <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.MobilePhoneNumber' | translate
                }}</body-text>
              <form-input controlName="phone" disable="true" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.MobilePhoneNumber' | translate }}">
              </form-input>
            </div>
          </div>
          <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
            <div class="margin-bottom-10">
              <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.JobRole' | translate }}
              </body-text>
              <form-input controlName="role" disable="true" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.JobRole' | translate }}">
              </form-input>
            </div>
            <div class="margin-bottom-10">
            </div>
          </div>
          <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
            <div class="margin-bottom-10">
              <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.OrganisationName' | translate
                }}</body-text>
              <form-input controlName="organisationName" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.OrganisationName' | translate }}">
              </form-input>
            </div>
            <div class="margin-bottom-10">
            </div>
          </div>
          <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
            <div class="margin-bottom-10">
              <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.Address1' | translate }}
              </body-text>
              <form-input controlName="address1" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.Address1' | translate }}">
              </form-input>
            </div>
            <div class="margin-bottom-10">
            </div>
          </div>
          <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
            <div class="margin-bottom-10">
              <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.Town' | translate }}
              </body-text>
              <form-input controlName="town" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.Town' | translate }}">
              </form-input>
            </div>
            <div class="margin-bottom-10">
            </div>
          </div>
          <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
            <div class="margin-bottom-10">
              <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.Telephone' | translate }}
              </body-text>
              <form-input controlName="telephone" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.Telephone' | translate }}">
              </form-input>
            </div>
            <div class="margin-bottom-10">
            </div>
          </div>
          <div class="margin-top-20 grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">

            <div class="margin-bottom-10">

              <div class="margin-top-30">
                <form-button text="{{ 'ParticipantActivation.Submit' | translate }}" theme="theme5" size="stretch"
                  (click)="save(editProfile)"></form-button>
              </div>
            </div>
            <div class="margin-bottom-10">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>