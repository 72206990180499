import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'cs-challenge-modal',
    templateUrl: 'challenge.component.html'
})

export class ChallengeModalComponent implements OnInit {
    formChallengeReason: FormGroup;

    constructor(private activeModal: NgbActiveModal, private fb: FormBuilder) { }

    ngOnInit() {
        this.formChallengeReason = this.fb.group({
            reason: ['', [Validators.required, Validators.maxLength(1000)]]
        });
    }

    onSubmit() {
        this.activeModal.close(this.formChallengeReason.getRawValue());
    }

    cancel() { 
        this.activeModal.dismiss();
    }
}