import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import { Subject } from 'rxjs';
import { WindowReferenceService, ICustomWindow } from '@core/shared';
import { Router } from '@angular/router';

@Component({
    selector: 'app-hub-game-arcade-page',
    templateUrl: 'game-arcade.component.html',
    styleUrls: ['game-arcade.component.scss']
})
export class GameArcadeComponent implements OnInit, AfterViewInit {
    @Input() tokenId: string;
    @Input() ssoUrl: any;
    @Input() showNavigationLink: boolean;

    public currentUser = "";
    private onDestroy$ = new Subject<void>();
    selectedCulture:string;
    private _window: ICustomWindow;

    constructor(public windowRef: WindowReferenceService, private store: Store<fromStore.PlatformState>, private router: Router) {
        this._window = windowRef.nativeWindow;
    }

    ngOnInit() {
        this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            if (state.user && state.user.Firstname) {
                this.currentUser = state.user.Firstname + '!';
                if (state.user.Lastname)
                    this.currentUser = state.user.Firstname + ' ' + state.user.Lastname + '!';
            }
        });
    }

    ngAfterViewInit() {
        this.selectedCulture = localStorage.getItem('culture');
        window.setTimeout(() => {            
            this._window.renderArcadeWidget({
                // Element to render the element into
                container: document.getElementById('arcade-widget-container'),
                // IETF language tag of the user's language and country code 
                locale: this.selectedCulture,
                // JWT token of current user session
                sessionId: this.tokenId,
                // sso endpoint for the arcade floor
                ssoEndpoint: this.ssoUrl,
                // ***OPTIONAL*** - Use IF and ONLY IF you're using Nackle Paper to style your page.
                // This flag disables the Widget's own Nackle Paper stylesheet, allowing it to use the styles of the host page.
                useHostPageNacklePaper: false,
            });
        });
    }
    redirectTo() {
        this.router.navigateByUrl(`engage/gamearcade/index/games`)
    } 
}