import { Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { LoadingService } from '@core/shared';
import { HubPageService } from '@core/private/hubpage/services/hub-page.service';

@UntilDestroy()
@Component({
    selector: 'app-hub-my-rank',
    templateUrl: 'my-rank.component.html'
})
export class MyRankComponent implements OnChanges {
    @Input() componentId: string;
    @Input() payload: any;
    @Input() culture: string;

    league: any;
    promotionName: string;

    constructor(private hubPageService: HubPageService, public loadingService: LoadingService) { }

    ngOnChanges() {
        let data = { item: JSON.stringify(this.payload) };
        this.promotionName = this.payload.selectedItem.name;
        this.loadingService.doLoading(this.hubPageService.getHubComponent(this.componentId, this.culture, data), this, "league")
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.league = response;
            });
    }
}