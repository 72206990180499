<div class="membershipTiering">
    <div class="topSection bg-color2">
        <div class="progressBar">
            <cs-goal-progress-bar-chart class="block margin-top-20" [progressPercentage]="percentageBarColor"
                trackColor="#f0f0f0" progressedTrackColor="linear-gradient(to right, #53C56D, #53C56D)"
                progressIcon="/assets/images/target_goal_rank_car_icon.png" [hideTopPoints]="true"
                goalIcon="/assets/images/profile_target.png" [loadOnInit]="true" [icon]="icon"
                [targets]="progressTargets" [medalView]='true' [noZeroStarting]="false">
            </cs-goal-progress-bar-chart>
            <p class="text-center" *ngIf="!isMaxTierReached">{{'MembershipTieringPage.Msg1' | translate}}<span
                    class="textGreen">{{pointLeftToNextLevel}}</span> {{'MembershipTieringPage.Msg2' |
                translate}} <span>{{nextTier}}</span>
            </p>
            <p class="text-center" *ngIf="isMaxTierReached">{{'MembershipTieringPage.Msg3' | translate}}<span
                    class="textGreen">{{pointLeftToNextLevel}}</span> {{'MembershipTieringPage.Msg4' | translate}}
            </p>
            <p class="text-center info">{{'MembershipTieringPage.ProgressBarMobInfo' | translate}}</p>
            <p class="side-header">{{'MembershipTieringPage.YourTierHistory' | translate}}</p>
            <div class="tableTierStructure pl-0 pr-0 mb-0">
                <table aria-describedby="Tier History" *ngIf="tierHistoryDetails && tierHistoryDetails.length > 0">
                    <tr>
                        <th scope="col">{{'MembershipTieringPage.Tier' | translate}}</th>
                        <th scope="col">{{'MembershipTieringPage.PeriodFrom' | translate}}</th>
                        <th scope="col">{{'MembershipTieringPage.PeriodTo' | translate}}</th>
                        <th scope="col">{{'MembershipTieringPage.Achieved' | translate}}</th>
                        <th scope="col">{{'MembershipTieringPage.Status' | translate}}</th>
                    </tr>
                    <tr *ngFor="let item of tierHistoryDetails">
                        <td>{{item.tier}}</td>
                        <td>{{item.fromDate | localizedDate}}</td>
                        <td>{{item.toDate | localizedDate}}</td>
                        <td>{{item.achived}}</td>
                        <td>{{item.status}}</td>
                    </tr>
                </table>
                <div class="alert alert-danger" *ngIf="tierHistoryDetails && tierHistoryDetails.length === 0">
                    {{'MembershipTieringPage.RecordNotFound' | translate}}
                </div>
                <div class="row mt-4" *ngIf="paginationDetails && paginationDetails.totalNumberOfItems &&
                            paginationDetails.totalNumberOfItems > 10">
                    <div class="col-lg-12 pagination-center-Bottom">
                        <cs-custom-pagination [paginationDetails]="paginationDetails"
                            (onChangeEvent)="onChange($event)">
                        </cs-custom-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p class="side-header">{{'MembershipTieringPage.TierStructure' | translate}}</p>
    <div class="tableTierStructure p-4 bg-color2">
        <table class="mt-5" aria-describedby="TierStructureList"
            *ngIf="activeTiersDetails && activeTiersDetails.length > 0">
            <tr>
                <th scope="col">{{'MembershipTieringPage.TierLevel' | translate}}</th>
                <th scope="col">{{'MembershipTieringPage.MinimumThreshold' | translate}}</th>
                <th scope="col">{{'MembershipTieringPage.MaximumThreshold' | translate}}</th>
            </tr>
            <tr *ngFor="let item of activeTiersDetails">
                <td>{{item.name}}</td>
                <td>{{item.minimumThreshold}}</td>
                <td>{{item.maximumThreshold}}</td>
            </tr>
        </table>
        <div class="alert alert-danger" *ngIf="activeTiersDetails && activeTiersDetails.length === 0">
            {{'MembershipTieringPage.RecordNotFound' | translate}}
        </div>
    </div>
</div>