import { Injectable } from "@angular/core";
import { BRIKS_PROMOTION_DATA, GET_MY_THRESHOLD_AWARDS_DETAILS, HUB_PAGE_THRESHOLD_POINTHISTORY, HUB_PAGE_THRESHOLD_SKU_STRUCTURE,
  HUB_PAGE_THRESHOLD_USER_ACHIEVEMENTS, UrlBuilder } from "@app/core/core-api-variables";
import { HttpService } from "@app/core/shared";
import { ParamsBuilderService } from "@app/core/shared/services/params-builder.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class MyThresholdAwardsService {
    constructor(public http: HttpService, private paramsBuilderService: ParamsBuilderService) { }

    getMyThresholdAwardsPageDetails(id: string) {
        let data = { id };
        let url = UrlBuilder(GET_MY_THRESHOLD_AWARDS_DETAILS, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }))
    }
    
    getPromotion(promotionId:any): Observable<any> {    
      let data = { promotionId };
      let url = UrlBuilder(BRIKS_PROMOTION_DATA, data);
      return this.http.get(url).pipe(map((response: any) => {
        return response;
      }));        
    }

    getPointHistory(data: any) {
      let url = UrlBuilder(HUB_PAGE_THRESHOLD_POINTHISTORY, data);
      return this.http.get(url).pipe(map((response: any) => {
        return response;
      }));
    }

    getUserAchievement(data: any) {
      const url = UrlBuilder(HUB_PAGE_THRESHOLD_USER_ACHIEVEMENTS, data);
      return this.http.get(url).pipe(map((response: any) => {
        return response;
      }));
    }
    
    getSKUPointsStructure(id) {
      let url = UrlBuilder(HUB_PAGE_THRESHOLD_SKU_STRUCTURE, id);
      return this.http.get(url).pipe(map((response: any) => {
          return response;
      }));
    }
}