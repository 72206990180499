<div
  class="grid-container-element grid-col-md-50 grid grid-gap-15 grid-col-xs-100 grid-col-xxs-100 grid-col-lt-100 grid-col-sm-100 grid-col-hd-50"
  [ngClass]="{'fullWidth': message.articles.length == 1}" *ngIf="message">
  <div class="grid-child-element" *ngFor="let slide of updatedArticles | slice:0:2; let i = index">
    <div class="message-icon-section">
      <cs-http-img [httpSrc]="slide.teaserImageToken" httpType="view" [alternativeText]="slide.teaserText">
      </cs-http-img>
    </div>
    <div class="message-content-section">      
      <p class="title mb-0"
        [ngStyle]="{'color': slide.attributeJson.bannerTextColor? slide.attributeJson.bannerTextColor: '#ffffff'}"> {{
        slide.title }}</p>
      <p [ngStyle]="{'color': slide.attributeJson.bannerTextColor? slide.attributeJson.bannerTextColor: '#ffffff'}"> {{
        slide.teaserText }}</p>
      <div class="link"
        [ngStyle]="{'color': slide.attributeJson.bannerTextColor? slide.attributeJson.bannerTextColor: '#ffffff'}">
        <span class="pointer"
          (click)="redirect(message.id, slide.id, slide.attributeJson.externalLink)">{{slide.attributeJson.buttonText}}</span>
        <fa-icon [icon]="arrowNext" class="fa-icon-arrow-next pointer"
          (click)="redirect(message.id, slide.id, slide.attributeJson.externalLink)"></fa-icon>
      </div>
    </div>
  </div>
</div>