import { Action } from '@ngrx/store';

export const TOGGLE_PANEL = 'Toggle Panel';
export const UPDATE_THEME = 'Update Theme';
export const UPDATE_RADIUS = 'Update Radius';

export class TogglePanel implements Action {
    readonly type = TOGGLE_PANEL;

    constructor(public payload: string){}
}

export class UpdateTheme implements Action {
    readonly type = UPDATE_THEME;

    constructor(public payload: string){}
}

export class UpdateRadius implements Action {
    readonly type = UPDATE_RADIUS;

    constructor(public payload: string){}
}

export type ThemeAction = TogglePanel | UpdateTheme | UpdateRadius;