import { Routes } from '@angular/router';
import { SettingsResolver } from '@core/shared/guards';
import { AuthGuard } from '@core/shared';

import { HubPageComponent } from './hub-page/hub-page.component';
import { HubComponent } from './hub.component';

export const HubPageRoutes: Routes = [
    {
        path: 'hub',
        resolve: {settings: SettingsResolver},
        component: HubComponent,
        children: [
            {
                path: 'hubpage/view/:id',
                canActivate: [AuthGuard],
                component: HubPageComponent,
                runGuardsAndResolvers: 'always',
                pathMatch: 'full',
                data: { title: 'PageTitles.HubPage', isPreLogin: false }
            }
        ]
    }
];