import { Component, OnInit, ViewChild } from '@angular/core';
import { TabSelectionService } from '../services/tab.selection.service'
import { TabDirective } from '../services/tab.directive'
import { mergeMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'profile-status-tabs',
    templateUrl: 'profile-status-tabs.component.html',
    styleUrls: [
        './scss/new_products_showcase.scss',
        './scss/profile_status_charts.scss',
        './scss/tab_body.scss',
        './scss/vertical_tabs.scss'
    ]
})
export class ProfileStatusTabsComponent implements OnInit {
    @ViewChild(TabDirective, { static: true })
    profileHost: TabDirective;
    public selectedTab: number;    
    public tabs: any;
    private destroySubject = new Subject();

    constructor(public tabSelectionService: TabSelectionService) {
        this.tabs = [
            {
                label: "Goal Achieved",
                css: "tab1"
            },
            {
                label: "My Rank",
                css: "tab2"
            },
            {
                label: "Active Contests",
                css: "tab3"
            },
            {
                label: "Claims",
                css: "tab4"
            },
            {
                label: "News",
                css: "tab5"
            },
            {
                label: "Games",
                css: "tab6"
            }
        ]
    }

    ngOnInit() {
        const viewContainerRef = this.profileHost.viewContainerRef;

        this.tabSelectionService.activeTab$.pipe(
            takeUntil(this.destroySubject),
            mergeMap(activeTab => {
                this.selectedTab = activeTab;
                return this.tabSelectionService.loadComponent(viewContainerRef, activeTab)
            })
        ).subscribe();
    }

    changeTab(index: number) {
        this.tabSelectionService.changeTab(index);
    }

}