import { Component, Input } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-benefits-page",
  templateUrl: "./benefits-page-banner.component.html"
})
export class BenefitsPageBannerComponent {
  @Input() message: any;
}
