import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from '@core/shared/models/pagination';

@Component({
    selector: 'cs-custom-pagination',
    templateUrl: 'pagination.component.html',
    styleUrls: ['pagination.component.scss']
})
export class CustomPaginationComponent {
    @Input() paginationDetails: Pagination;
    @Output() onChangeEvent: EventEmitter<Pagination> = new EventEmitter<Pagination>();

    showPagination() {
        return this.paginationDetails.totalNumberOfItems > this.paginationDetails.itemsPerPage;
    }

    onPageChange(page: number) {
        let pagination = new Pagination(this.paginationDetails.totalNumberOfItems, page, this.paginationDetails.itemsPerPage);
        this.onChangeEvent.emit(pagination);
    }
}