import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AuthenticationService } from '@core/startup';
import { AccountsService } from '../services/accounts.service';
import { LoadingService } from '@core/shared';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notification-subscription',
  templateUrl: 'notification-subscription.component.html',
  styleUrls: ['notification-subscription.component.scss']
})

export class NotificationSubscriptionComponent implements OnInit {
  formSubscription: FormGroup;
  sampledata: any = [];
  preferencedata: any = [];
  submitdata: any = [];
  grouptype: any;
  commtype: any;
  datarequest: any = [];
  constructor(private formBuilder: FormBuilder, public authService: AuthenticationService,
    public accountsService: AccountsService, public loadingService: LoadingService, public toaster: ToastrService, public translate: TranslateService) {
  }

  ngOnInit() {
    this.getNotificationSubscriptionData();
  }

  getNotificationSubscriptionData() {
    this.accountsService.notificationpreference()
      .subscribe(result => {
        this.sampledata = [];
        this.sampledata.push({ 'key': 'Transactional', 'email': true, 'message': true, 'mobilePush': true });
        this.preferencedata = result;
        for (let i of this.preferencedata) {
          this.sampledata.push(i);
        }
        let groups = {};
        for (let data of this.sampledata) {
          let controls = {};
          controls['email'] = new FormControl({ value: data['email'], disabled: data.key === 'Transactional' });
          controls['message'] = new FormControl({ value: data['message'], disabled: data.key === 'Transactional' });
          controls['mobilePush'] = new FormControl({ value: data['mobilePush'], disabled: data.key === 'Transactional' });
          controls['key'] = new FormControl({ value: data['key'], disabled: data.key === 'Transactional' });
          groups[data.key] = new FormGroup(controls)
        }
        this.formSubscription = this.formBuilder.group(groups);
      });
  }

  changePreference(groupType, commType, formkey) {
    let formdata = this.formSubscription.getRawValue();
    let payload = {
      "notificationpreference": [
        {
          "type": commType,
          "categoryType": groupType,
          "optInStatus": formdata[groupType][formkey]
        }
      ]
    }
    this.accountsService.updateNotificationpreference(payload).subscribe(result => {
      this.toaster.success(this.translate.instant('Notification Preference Is Successfully Changed'));
      this.getNotificationSubscriptionData();
    });
  }

  changeLabel(key){
    return this.translate.instant(`Notificationpreference.${key}`)
  }
}
