import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject, Subscription } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoadingService } from '@core/shared';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import * as fromStore from '@core/store';
import { SearchCriteria } from '@core/shared/models/search-criteria';
import { Pagination } from '@core/shared/models/pagination';
import { Sorter } from '@core/shared/models/sorter';
import { ImportBehaviors } from '@core/shared/models/import-behaviors';
import { InvoiceClaimService } from '../services/invoice-claim.service';
import { InvoiceClaimApprovalFilter } from '../view-models/invoice-claim-approval.filter';
import { InvoiceFilesModalComponent } from '../invoice-claim-modal/invoice-files/invoice-files.component';
import { InvoiceRejectClaimComponent } from '../invoice-claim-modal/reject-claim/reject-claim.component';
import { browserRefresh } from '@app/app.component';
import { Authentication } from '@core/models/authentication.model';

@UntilDestroy()
@Component({
  selector: 'app-invoice-claim-approval',
  templateUrl: './invoice-claim-approval.component.html',
  styleUrls: ['./invoice-claim-approval.component.scss']
})
export class InvoiceClaimApprovalComponent implements OnInit, OnDestroy {
  searchCriteria: SearchCriteria = new SearchCriteria('claimedDate');
  paginationDetails: Pagination = new Pagination();
  sorter: Sorter = new Sorter('claimedDate', false);
  invoicesForApproval: any[] = [];
  filter: InvoiceClaimApprovalFilter;
  maxSaleFromDate: any;
  minSaleFromDate: any;
  maxSaleToDate: any;
  minSaleToDate: any;
  maxClaimFromDate: any;
  minClaimFromDate: any;
  maxClaimToDate: any;
  minClaimToDate: any;

  currentUser: Authentication;
  contextId: string;
  lastUploadId: string;
  importBehaviors: ImportBehaviors;
  isButtonDisable : boolean = false;
  lineItemId :any;

  invoiceStatus = [];
  selectedItems = [];
  invoiceStatusSettings: IDropdownSettings = {};
  claimConfigurationId: string;
  browserRefresh: boolean;
  navigationStartSubscription: Subscription;
  private onDestroy$ = new Subject<void>();
  duplicateError = '';
  additionalAttributes: string[] = [];

  constructor(private invoiceClaimService: InvoiceClaimService, private route: ActivatedRoute, private router: Router,
    private modalService: NgbModal, public loadingService: LoadingService, public translate: TranslateService,
    private store: Store<fromStore.PlatformState>) {
    this.importBehaviors = new ImportBehaviors(true, false, false);
  }

  ngOnInit(): void {
    this.filter = new InvoiceClaimApprovalFilter();

    this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
      if (state.user && !this.currentUser) {
        this.currentUser = state.user;
        this.invoiceClaimService.getInvoiceClaimUploadContext().subscribe(response => {
          this.contextId = response.contextId;
          this.lastUploadId = response.lastUploadId;
        });
      }
    });

    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent ),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      if (this.claimConfigurationId != this.route.snapshot.params.id) {
        this.browserRefresh = browserRefresh;
        this.claimConfigurationId = this.route.snapshot.params.id;
      }
    });

    this.invoiceStatus = [
      { name: 'Approved' },
      { name: 'Rejected' },
      { name: 'Challenged' },
      { name: 'Submitted' }
    ];

    this.filter.selectedItems = [
      { name: 'Challenged' },
      { name: 'Submitted' },
      { name: 'Resubmitted' }
    ];

    this.invoiceStatusSettings = {
      singleSelection: false,
      idField: 'name',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.getInvoicesForApproval();
  }

  getInvoicesForApproval() {

    let payload = Object.assign({}, this.filter);

    payload.toClaimDate = this.filter.toClaimDate ? moment(this.filter.toClaimDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T23:59:59.999" : null;
    payload.fromClaimDate = this.filter.fromClaimDate ? moment(this.filter.fromClaimDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T00:00:00" : null;
    payload.fromSellingDate = this.filter.fromSellingDate ? moment(this.filter.fromSellingDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T00:00:00" : null;
    payload.toSellingDate = this.filter.toSellingDate ? moment(this.filter.toSellingDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T23:59:59.999" : null;

    payload.status = this.filter.selectedItems.map(x => x.name).join(',');

    payload.claimConfigurationId = this.claimConfigurationId;

    this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
    this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
    this.searchCriteria.sortField = this.sorter.sortColumn;
    this.searchCriteria.sortAscending = this.sorter.sortDirection;

    this.searchCriteria.additionalData = payload;

    this.loadingService.doLoading(this.invoiceClaimService.getInvoicesForApproval(this.searchCriteria), this).pipe(untilDestroyed(this)).subscribe(response => {
      this.invoicesForApproval = response.items;
      if (this.invoicesForApproval.length > 0)
        this.additionalAttributes = Object.keys(this.invoicesForApproval[0].attributesDto);

      this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
      this.isButtonDisable = false;
    });

  }
  onSearch() {
    this.getInvoicesForApproval();
  }
  onClear() {
    this.filter.invoiceNumber = "";
    this.filter.sku = "";
    this.filter.sellingUser = "";
    this.filter.Organisation = "";
    this.filter.status = "";
    this.filter.fromSellingDate = null;
    this.filter.toSellingDate = null;
    this.filter.fromClaimDate = null;
    this.filter.toClaimDate = null;
    this.filter.selectedItems = [];
    this.getInvoicesForApproval();
  }

  onChange(pagination: Pagination) {
    this.paginationDetails = pagination;
    this.getInvoicesForApproval();
  }

  onSort(sortField) {
    this.sorter.sort(sortField);
    this.getInvoicesForApproval();
  }

  downloadCSV(): void {
    this.invoiceClaimService.downloadInvoiceApprovalCSV(this.searchCriteria).subscribe((res: any) => {
      let header = res.headers;
      let contentType = header.get('content-type');
      let blob = new Blob([res.body], { type: contentType });

      let downloadLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute("href", url);
      downloadLink.setAttribute("download", "InvoiceApproval.xlsx");
      downloadLink.style.visibility = "hidden";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  approve(invoice) {
  this.lineItemId = invoice.lineItemId;
   this.isButtonDisable = true;
    this.duplicateError = '';
    this.loadingService.doLoading(this.invoiceClaimService.updateInvoiceStatus(invoice.invoiceId, invoice.lineItemId, 'Approved', ''), this).pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.getInvoicesForApproval();
      },
      error: (error) => {
        if (error.error && Array.isArray(error.error)) {
          for (let lineItem of error.error) {
            for (let key in lineItem) {
              if (key != 'external sales Identifier(unique)')
                this.duplicateError = `${this.duplicateError} ${key} = ${lineItem[key]} `;
            }
            this.duplicateError = `${this.duplicateError} </br>`;
          }
        }
      }
    });
  }

  reject(invoice) {
    this.isButtonDisable = true;
    this.duplicateError = '';
    const modalRef = this.modalService.open(InvoiceRejectClaimComponent, { backdrop: 'static' });
    modalRef.result.then((result) => {
      this.loadingService.doLoading(this.invoiceClaimService.updateInvoiceStatus(invoice.invoiceId, invoice.lineItemId, 'Rejected', result.rejectionReason), this).pipe(untilDestroyed(this)).subscribe({
        next: () => {
          this.getInvoicesForApproval();
        }
      });
    }).catch(error => {
      console.log(error);
    });
  }

  downloadInvoiceFilesModal(invoice) {
    const modalRef = this.modalService.open(InvoiceFilesModalComponent, { backdrop: 'static' });
    modalRef.componentInstance.invoice = invoice;
    modalRef.result.then(() => {
      console.log('Downloaded')
    }).catch(error => {
      console.log(error);
    });
  }

  blockOldDateSales(value) {
    if (value === 'from') {
      let tempFromDate = new Date(this.convertToDateFormat(this.filter.fromSellingDate));
      if (this.filter.fromSellingDate !== null) {
        this.minSaleToDate = { year: tempFromDate.getFullYear(), month: tempFromDate.getMonth() + 1, day: tempFromDate.getDate() };
      }
    }
    else {
      let tempToDate = new Date(this.convertToDateFormat(this.filter.toSellingDate));
      if (this.filter.toSellingDate !== null) {
        this.maxSaleFromDate = { year: tempToDate.getFullYear(), month: tempToDate.getMonth() + 1, day: tempToDate.getDate() };
      }
    }
  }

  blockOldDateClaim(value) {
    if (value === 'from') {
      let tempFromDate = new Date(this.convertToDateFormat(this.filter.fromClaimDate));
      if (this.filter.fromClaimDate !== null) {
        this.minClaimToDate = { year: tempFromDate.getFullYear(), month: tempFromDate.getMonth() + 1, day: tempFromDate.getDate() };
      }
    }
    else {
      let tempToDate = new Date(this.convertToDateFormat(this.filter.toClaimDate));
      if (this.filter.toClaimDate !== null) {
        this.maxClaimFromDate = { year: tempToDate.getFullYear(), month: tempToDate.getMonth() + 1, day: tempToDate.getDate() };
      }
    }
  }

  convertToDateFormat(date) {
    let splitDate1 = date.split('-');
    let day = splitDate1[0];
    let month = splitDate1[1];
    let year = splitDate1[2];
    return month + "-" + day + "-" + year;
  }

  importCompleted(uploadId) {
    this.invoiceClaimService.downloadByUploadId(uploadId).subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }
}
