import { Component, ElementRef, OnInit, Input } from '@angular/core';

import { AuthenticationService } from '@core/startup';
import { environment } from '@env/environment';
import { HttpImageService } from '@core/shared/services/http-img.service';
import { SystemSettings } from '@core/models'
import { QuizQuestionnaireService } from '../services/quiz-questionnaire.service';

@Component({
  selector: 'nackle-quiz-questionnaire',
  templateUrl: './quiz-questionnaire.component.html'
})

export class QuizAndQuitionnaireComponent implements OnInit {
  @Input() promotionId: string;
  @Input() enableContent: boolean;
  @Input() isAdmin: boolean;
  @Input() culture: string;
  @Input() attemptUserId: string;
  @Input() userTimeZone: string;

  briks: any;
  currentUser: string;
  quizView: ElementRef;
  apiUrl: string;
  quizPromotion: any;
  quizId: string;
  initialLanguage: string;
  lang: string;
  ready = false;
  contentHtml: any;
  briksContainer: string;
  languageChangeSubscription: any;
  timeZone: string
  isAttemptAllowedOutsideQuizDate: boolean;
  nackleEnvironment: string;
  content: any;

  constructor(private authService: AuthenticationService, private quizQuestionnaireService: QuizQuestionnaireService,
    private imgService: HttpImageService, public systemSettings: SystemSettings) {
    this.apiUrl = environment.baseUrl + environment.version;
  }
  ngOnInit(): void {
    this.getPromotion();
  }

  callbackFunction(headers) {
    var token = JSON.parse(localStorage.getItem('token'))['access_token'];
    if (token) {

      headers['Authorization'] = 'Bearer ' + token;
      headers['Nexus-Environment-Key'] = environment.environmentKey;
      let isAdmin = (<HTMLInputElement>document.getElementsByName('isAdmin')[0]).value;
      if (isAdmin === 'true')
        headers['quiz-token-id'] = (<HTMLInputElement>document.getElementsByName('attemptUserId')[0]).value;
    }
  }
  getPromotion() {
    this.quizQuestionnaireService.getQuizPromotion(this.promotionId).subscribe({
      next: (response) => {
        this.quizPromotion = JSON.parse(response.attributesJson);
        this.content = this.quizPromotion.platformConfigurations.find(x => x.culture == this.culture);
        if (this.enableContent && this.quizPromotion.contentPage) {
          this.quizQuestionnaireService.getQuizPromotionContents(this.quizPromotion.contentPage.id, this.culture).subscribe(res => {
            this.contentHtml = this.imgService.parseHtml(res);
          });
          setTimeout(() => {
            this.fetchBlobImages();
          }, 1000);
        }

        if (this.quizPromotion.quizId) {
          this.authService.getCurrentUser().subscribe(response => {
            this.currentUser = response.id;
            this.quizQuestionnaireService.getAttemptAllowedOutsideQuizDate(this.promotionId, this.quizPromotion.quizId).subscribe(res => {
              this.initialLanguage = this.culture.toLowerCase();
              this.lang = this.culture.toLowerCase();
              this.quizId = this.quizPromotion.quizId;
              this.ready = true;
              this.timeZone = this.parseTimeZone(this.userTimeZone, this.systemSettings.ianaTimeZone);
              this.isAttemptAllowedOutsideQuizDate = res.isAttemptAllowedOutsideQuizDate;
              this.nackleEnvironment = environment.nackleEnvironment;
            });
          });
        }
      }
    });
  }

  parseTimeZone(userTimeZone, systemTimeZone) {
    var timezone = (userTimeZone != null && userTimeZone != undefined && userTimeZone != 'undefined' && userTimeZone != '') ? userTimeZone : systemTimeZone;
    timezone = timezone.replaceAll("/", "-");
    return timezone;
  };

  fetchBlobImages() {
    let images = document.querySelectorAll('img[data-src]');
    for (let i = 0; i < images.length; i++) {
      let dataSrc = images[i].getAttribute('data-src');
      this.imgService.getImage(dataSrc).subscribe(val => {
        let type = val.type;
        var reader = new FileReader();
        reader.readAsDataURL(val);
        reader.onloadend = function () {
          let baseImage = <string>reader.result;
          images[i].setAttribute('src', `data:${type};base64,` + baseImage.split(',')[1]);
        }
      });
    }
  }

  isShownDiv: boolean = false; // hidden by default
  toggleShows() {
    this.isShownDiv = !this.isShownDiv;
  }

  isShownDivMaterial: boolean = false; // hidden by default
  toggleShowsMaterial() {
    this.isShownDivMaterial = !this.isShownDivMaterial;
  }

}
