<div>
    <div class="timeLeft">
        <div class="icon-gq-calendar"></div>
        <div class="goalWrapper">
            <div class="daysLeft">
                <cs-timer [endDate]="endDate"></cs-timer>
            </div>
            {{'GoalQuestPage.GoalQuestSelection.Days' | translate}}
        </div>
    </div>
    <div class="custom-rule-bottom"></div>
    <div class="gq-goals-selected">
        <div class="grid grid-gap-10 grid-col-md-33 grid-col-hd-33 grid-col-xs-100 grid-col-sm-100 grid-col-lt-100">
            <div class="goal-selected-outer-wrapper" *ngFor="let goal of goalSelectedUsers">
                <div class="percentage-achieved">
                    <h5>{{'GoalQuestPage.Manager.GoalQuestSelection.Goal' | translate}} {{goal.selectedLevel}}</h5>
                    <h5>{{calculateSelectedPercentage(goal.noOfUsersSelected)}}</h5>
                </div>
                <div class="goalPercentage">
                    <div class="totalPer"></div>
                    <div class="goalAchieved" [style.width]="calculateSelectedPercentage(goal.noOfUsersSelected)"></div>
                </div>
                <div class="total-selection">
                    <p>{{'GoalQuestPage.Manager.GoalQuestSelection.ParticipantsSelectedGoal' | translate}}</p>
                    <p class="count"><span>{{goal.noOfUsersSelected}}</span>/{{userCount}}</p>
                </div>
            </div>
        </div>
    </div>
</div>