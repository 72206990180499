<div
  class="toggle-box"
  [class]="selected ? 'selected-toggle-box' : 'unselected-toggle-box'"
  (click)="toggle()"
>
  <div
    [class]="
      selected
        ? 'toggle-point slide-animation switch-on'
        : 'toggle-point slide-off-animation switch-off'
    "
  ></div>
</div>
