import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { SystemSettings } from '@app/core/models';
import { LoadingService } from '@app/core/shared';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { filter, startWith, Subscription } from 'rxjs';
import { EmbeddedReportService } from '../../services/embedded-report.service';
import { browserRefresh } from '@app/app.component';

@Component({
  selector: 'app-superset-report',
  templateUrl: './superset-report.component.html',
  styleUrls: ['./superset-report.component.scss']
})
export class SupersetReportComponent implements OnInit, OnDestroy {


  @ViewChild('superset-container1') reportContainer: ElementRef;
  id: any;
  reportId: string;
  superSetBaseUrl: string;
  token: any;
  superSetId: string;
  selectedCulture: string;
  browserRefresh: boolean;

  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private translateService: TranslateService,
    private embeddedReportService: EmbeddedReportService, private systemSettings: SystemSettings,
    public loadingService: LoadingService) { }


  ngOnInit(): void {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      if (this.superSetId != this.activeRoute.snapshot.params.id) {
        this.browserRefresh = browserRefresh;
        this.superSetId = this.activeRoute.snapshot.params.id;
        this.getReports();
      }
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh)
        this.getReports();
      else
        this.browserRefresh = false;
    });
  }

  getReports() {
    this.selectedCulture = localStorage.getItem('culture');
    let defaultCulture = this.systemSettings.defaultPlatformLanguage;
    this.loadingService.doLoading(this.embeddedReportService.getSuperSetReport(this.superSetId, this.selectedCulture, defaultCulture), this).subscribe(data => {
      this.token = data.token;
      this.reportId = data.id;
      this.superSetBaseUrl = data.superSetBaseUrl;
      this.embedWorldBankData();
    })
  }

  embedWorldBankData() {
 
  }

  ngOnDestroy(): void {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }
}
