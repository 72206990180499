import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cs-shared-manager-goal-selection',
  templateUrl: './goal-selection.component.html'
})
export class SharedManagerGoalSelectionComponent implements OnInit {
  @Input() userCount: number;
  @Input() goalSelectedUsers: any;
  @Input() goalSelectionDetails: any;
  @Input() endDate: string;

  constructor() { }

  ngOnInit(): void { }

  calculateSelectedPercentage(selectedCount) {
    return `${(selectedCount / this.userCount) * 100}%`;
  }
}
