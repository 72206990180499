import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { map } from "rxjs/operators";
import {
  GET_COUNTRIES,
  GET_FORM_ELEMENTS,
  GET_PREPOPULATED_VALUES,
  GET_QUERIES,
  GET_QUERIES_L,
  POST_CONTACT_US,
  POST_CONTACT_US_L,
  UrlBuilder,
} from "@app/core/core-api-variables";
import { HttpService } from "@app/core/shared";
import { ContactFormBase } from "../contact-us/types";
import { AuthenticationService } from "@app/core/startup";

@Injectable({
  providedIn: "root",
})
export class ContactControlService {
  constructor(
    public http: HttpService,
    private authService: AuthenticationService
  ) { }

  isLoggedin = this.authService.getToken() ? true : false;
  
  toFormGroup(contacts: ContactFormBase<string>[], userDetails, showQueries) {
    const group: any = {
      query: new FormControl("", showQueries ? Validators.required : null),
      message: new FormControl("", showQueries ? Validators.required : null),
    };
    contacts.forEach((contact) => {
      if (contact.type === "FormField" || contact.type === "Country") {
        if (contact.attribute === "Firstname" && userDetails)
          group[contact.attribute] = new FormControl(userDetails.firstName, [Validators.required]);
        else if (contact.attribute === "Lastname" && userDetails)
          group[contact.attribute] = new FormControl(userDetails.lastName, [Validators.required]);
        else
          group[contact.attribute] = new FormControl('', [Validators.required]);
      }
    });
    return new FormGroup(group);
  }

  getFormElements(culture) {
    let data = { culture };
    let url = UrlBuilder(GET_FORM_ELEMENTS, data);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getPrePopulatedValues(culture) {
    let data = { culture };
    let url = UrlBuilder(GET_PREPOPULATED_VALUES, data);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getQueries() {
    const url = this.isLoggedin ? GET_QUERIES_L : GET_QUERIES;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCountries() {
    const url = GET_COUNTRIES;
    return this.http.post(url, {}).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  sendForm(payload) {
    const url = this.isLoggedin ? POST_CONTACT_US_L : POST_CONTACT_US;
    return this.http.post(url, payload).pipe(map((res) => res));
  }
}
