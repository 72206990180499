import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { registerLocaleData } from '@angular/common';
import { StoreModule } from "@ngrx/store";
import { CookieService } from "ngx-cookie-service";
import { reducers } from "@core/store";
import { AppRoutingModule } from "@app/app-routing.module";
import { SharedModule } from "@core/shared/shared.module";
import { AppCoreModule } from "./core/core.module";
import { AppComponent } from "./app.component";
import { MyErrorHandler } from "./core/shared/errorHandler";

import {
  FooterComponent,
  MenuComponent,
  SubMenuComponent,
  WelcomeComponent,
  MenuClickDirective,
  SubMenuHoverDirective,
} from "@core/private/_layout";

import { AppExtensionModule } from "./extension/extension.module";

import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

import localeGb from '@angular/common/locales/en-GB';
registerLocaleData(localeGb, 'gb');

import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de');

import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

import localeIN from '@angular/common/locales/en-IN';
registerLocaleData(localeIN, 'in');

import loaleCA from '@angular/common/locales/fr-CA';
registerLocaleData(loaleCA,'ca');

import localeEn from '@angular/common/locales/en';
registerLocaleData(localeEn,'en');


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    MenuComponent,
    SubMenuComponent,
    WelcomeComponent,
    MenuClickDirective,
    SubMenuHoverDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    StoreModule.forRoot(reducers),
    AppCoreModule,
    AppRoutingModule,
    SharedModule,
    AppExtensionModule
  ],
  providers: [CookieService, 
      {
        provide: LOCALE_ID, useValue: localStorage.getItem('culture')
      }, 
      { 
        provide: ErrorHandler, useClass: MyErrorHandler
      }
    ],
  bootstrap: [AppComponent],
})
export class AppModule { }
