import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { ContentPageComponent } from './content-page/content-page.component';
import { ContentComponent } from './content.component';

export const ContentPageRoutes: Routes = [
    {
        path: 'content',
        component: ContentComponent,
        children: [
                {
                    path: 'contentpage/view/:id',
                    canActivate: [AuthGuard],
                    component: ContentPageComponent,
                    pathMatch: 'full',
                    data: { title: 'PageTitles.ContentPage', isPreLogin: false }
                }
        ]
    }
];