import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { MyPerformanceComponent } from './my-performance/my-performance.component';
import { PerformanceComponent } from './performance.component';

export const MyPerformanceRoutes: Routes = [
    {
        path: 'performance',
        component: PerformanceComponent,
        children: [
            {
                path: 'myperformance/index/:id',
                canActivate: [AuthGuard],
                component: MyPerformanceComponent,
                runGuardsAndResolvers: 'always',
                pathMatch: 'full',
                data: { title: 'PageTitles.MyPerformance', isPreLogin: false }
            }
        ]
    }
];