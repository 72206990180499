<div class="content">
    <div *ngIf="uploadStatus == fileStatusTypes.validating">
        <div class="">            
            <div class="progress-bar-value">
                <strong>Validating File</strong> - You can navigate away from the page while this process is
                running<br />
                <span *ngIf="rowsValidated">{{rowsValidated}}/{{rowsToValidate}} rows</span>
            </div>
        </div>
    </div>

    <div *ngIf="uploadStatus == fileStatusTypes.importing">
        <div class="col-md-12">           
            <div class="progress-bar-value">
                <strong>Applying Import</strong> - You can navigate away from the page while this process is
                running<br />
                {{rowsImported}}/{{rowsToImport}} rows
            </div>
        </div>
    </div>

    <div *ngIf="uploadStatus == fileStatusTypes.validated">
        <div class="col-md-12">
            <cs-file-uploader-validation-result [validationResultSummary]="validationSummary"></cs-file-uploader-validation-result>
        </div>
    </div>

    <div class="mt-4" *ngIf="uploadStatus == fileStatusTypes.validated">
        <div class="col-md-12 d-flex">
            <button class="btn-primary" (click)="queueForImport()" type="button">Apply</button>
            <button class="btn-secondary ml-2" (click)="importCancelled()" type="button">Cancel</button>
        </div>
    </div>
</div>