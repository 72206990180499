<div>
    <div class="gq-goalStatus">

        <div class="grid grid-gap-10 grid-col-md-33  grid-col-hd-33  grid-col-xs-100 grid-col-sm-100 grid-col-lt-100">
            <div class="goal">
                <div class="iconHolder">
                    <span class="icon-gq-flagoutline"></span>
                </div>
                <div class="divider"></div>
                <p><span class="level">{{selectedGoal.targetLevel}}</span>
                    {{'GoalQuestPage.GoalQuestResult.YourGoalLevel' | translate}}</p>
            </div>


            <div class="goal">
                <div class="iconHolder">
                    <span class="icon-gq-target"></span>
                </div>
                <div class="divider"></div>
                <div>
                    <p>
                        <span class="target">{{selectedGoal.target}}</span>
                        {{'GoalQuestPage.GoalQuestResult.GoalTarget' | translate}}
                    </p>
                    <p *ngIf="isMinimumQualifierEnabled">
                        <span class="qualify-target">
                            <span class="qualify">{{minimumQualifier.target}}</span>
                            {{'GoalQuestPage.GoalQuestResult.MinimumQualifyTarget' | translate}}
                        </span>
                    </p>
                </div>
            </div>


            <div class="goal">
                <div class="iconHolder">
                    <span class="icon-gq-badge"></span>
                </div>
                <div class="divider"></div>
                <p>
                    <span class="level">
                        <span *ngIf="!isManualReward">{{selectedGoal.points}}</span>
                        <span *ngIf="isManualReward && selectedGoal.translations">
                            <cs-http-img [httpSrc]="selectedGoal.translations[culture].imageUrl" httpType="view"
                                alternativeText="image" height="40" width="50">
                            </cs-http-img>
                        </span>
                        <span *ngIf="isManualReward && selectedGoal.imageUrl">
                            <cs-http-img [httpSrc]="selectedGoal.imageUrl" httpType="view" alternativeText="image" height="40"
                                width="50">
                            </cs-http-img>
                        </span>
                    </span>
                    <span *ngIf="!isManualReward">{{'GoalQuestPage.GoalQuestProgress.RewardPoints' | translate}}</span>
                </p>
            </div>

        </div>
    </div>
    <div class="gq-goalProgress sm-goalProgress">
        <div class="grid grid-gap-10 grid-col-xxs-33 grid-col-xs-33 grid-col-sm-50 grid-col-md-33  grid-col-hd-33  grid-col-xs-100 single-card"
            [class]="{'item-b': !isMinimumQualifierEnabled}">
            <div class="congrats-wrapper">
                <div *ngIf="finalResult">
                    <img src="assets/images/congrats-bg.jpg" width="170px" alt="congrats"/>
                    <div class="congrats-text">
                        <h4>{{'GoalQuestPage.GoalQuestResult.Congratulations' | translate}}</h4>
                        <p>{{'GoalQuestPage.GoalQuestResult.AchievedGoal' | translate}}
                            <span *ngIf="!isManualReward">{{selectedGoal.points}}
                                {{'GoalQuestPage.GoalQuestResult.Points' | translate}}</span>
                            <span *ngIf="isManualReward">
                                <cs-http-img [httpSrc]="selectedGoal.translations[culture].imageUrl" httpType="view"
                                    alternativeText="image" height="40" width="50">
                                </cs-http-img>
                            </span>
                        </p>
                    </div>
                </div>
                <div *ngIf="!finalResult" class="failure-wrapper">
                    <img src="assets/images/failure-bg.png" alt="failure">
                    <div class="message failure">
                        <h4>Oops!</h4>
                        <p>You have Missed the Goal</p>
                    </div>
                </div>
            </div>

            <div class="qualifer-chart" *ngIf="isMinimumQualifierEnabled">
                <p>{{'GoalQuestPage.GoalQuestResult.MinimumQualifierProgress' | translate}}</p>
                <div class="chart-position">
                <mwl-gauge class="three" [max]="100" [value]="minimumQualifierAchived" [animated]="true"
                    [dialStartAngle]="115" [dialEndAngle]="65" [animationDuration]="3" [label]="percentageValue">
                </mwl-gauge>
                <em class="icon-gq-flag flag-icon-position"></em>
                </div>
            </div>

            <div class="goalProgressOuterWrapper">
                <p>Goal Progress</p>
                <div class="goalProgressWrapper sm-wrapper">
                    <div class="goalProgressInnerWrapper">
                        <h4 class="progress-achieved sm-achieved">{{'GoalQuestPage.GoalQuestResult.YouHaveAchieved' |
                            translate}} <span>{{goalAchived | roundOff:2}}%</span>{{'GoalQuestPage.GoalQuestResult.Goal'
                            | translate}}
                        </h4>
                        <div class="goalPercentage">
                            <div class="totalPer"></div>
                            <div class="goalAchieved" [style.width.%]="goalAchived"></div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>