import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@core/shared';
import { MembershipTieringComponent } from './membership-tiering-component/membership-tiering.component';
import { MemberShipTieringService } from './services/membership-tiering-services';
import { MembershipTierComponent } from './membership-tier.component';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        TranslateModule,
        NgbModule,
        FormsModule
    ],
    declarations: [
        MembershipTierComponent,
        MembershipTieringComponent
    ],
    providers: [        
        MemberShipTieringService
    ],
})
export class MembershipTieringModule { }
