export class SalesClaimFilter {
    salesStartDate: string;
    salesEndDate: string;
    salesClaimStatus: string;
    searchTerm: string;
    organisationsListUserId: string;

    constructor(_salesStartDate?: string, _salesEndDate?: string) {
        this.salesStartDate = _salesStartDate ? _salesStartDate : null;
        this.salesEndDate = _salesEndDate ? _salesEndDate : null;
        this.salesClaimStatus = '';
        this.searchTerm = '';
        this.organisationsListUserId='';
    }
}