import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EditProfileSelfRegistrationComponent } from './edit-profile-selfregistration/edit-profile-selfregistration.component';
import { SecurityQuestionsComponent } from './security-questions/security-questions.component';
import { NotificationSubscriptionComponent } from './notification-subscription/notification-subscription.component';

export const AccountRoutes: Routes = [
  {
    path: 'changepassword',
    canActivate: [AuthGuard],
    component: ChangePasswordComponent,
    pathMatch: 'full',
    data: { title: 'PageTitles.ChangePassword', isPreLogin: false }
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: EditProfileComponent,
    data: { title: 'PageTitles.ViewProfile', isPreLogin: false }
  },
  {
    path: 'editprofile/selfregistration',
    canActivate: [AuthGuard],
    component: EditProfileSelfRegistrationComponent,
    data: { title: 'PageTitles.ViewProfile', isPreLogin: false }
  },
  {
    path: 'securityquestion/:id',
    canActivate: [AuthGuard],
    component: SecurityQuestionsComponent,
    pathMatch: 'full',
    data: { title: 'PageTitles.ChangeSecurityQuestion', isPreLogin: false }
  },
  {
    path: 'notificationsubscription',
    canActivate: [AuthGuard],
    component: NotificationSubscriptionComponent,
    pathMatch: 'full',
    data: { title: 'PageTitles.NotificationSubscription', isPreLogin: false }
  }
];
