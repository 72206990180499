import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformSystemSettings } from '@core/models';
import { HttpImageService } from '../../services/http-img.service';

import { SharedService } from '../../services/shared.service';

@Component({
    selector: 'cs-most-redeem-products',
    templateUrl: 'most-redeem-products.component.html'
})

export class MostRedeemProductsComponent implements OnInit {
    @Input() singleView: boolean;
    @Input() noOfItems: number = 8;
    @Output() emptyProductEvent = new EventEmitter();
    systemSettings: PlatformSystemSettings;

    selectedCulture;
    products: any[] = [];
    public slideConfig: any;
    constructor(private sharedService: SharedService, private imageService: HttpImageService, private sanitizer: DomSanitizer) { }

    ngOnInit() {
        if (localStorage.getItem('platformSettings'))
            this.systemSettings = JSON.parse(localStorage.getItem('platformSettings'));
            
        this.slideConfig = this.singleView ? this.getSingle() : this.getMultiple();
        this.getMostRedeemedProducts();
    }

    getMostRedeemedProducts() {
        this.sharedService.getMostRedeemProducts(this.noOfItems).subscribe((response: any[]) => {
            if (response && response.length > 0)
                this.products = response;
            else
                this.getProducts();
        });
    }

    getSingle() {
        return { dots: false, infinite: false, autoplay: false }
    }

    getMultiple() {
        return {
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 8000,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1370,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        dots: true,
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        dots: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
            ]
        }
    }

    getProducts() {
        if (this.systemSettings.mostRedeemedDefaultId && this.systemSettings.mostRedeemedDefaultId != '') {
            this.selectedCulture = localStorage.getItem('culture');
            this.sharedService.getCarouselProduct(this.systemSettings.mostRedeemedDefaultId, this.selectedCulture).subscribe((response: any[]) => {
                for (let article of response) {
                    this.imageService.getImage(article.teaserImage).subscribe({
                        next: (val) => {
                            let url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(val));
                            this.products.push({
                                primaryImageUrl: url['changingThisBreaksApplicationSecurity'],
                                name: article.title,
                                description: article.teaserText
                            });
                        }
                    });
                }
                if (response.length == 0)
                    this.emptyProductEvent.emit();
            });
        }
        else
            this.emptyProductEvent.emit();
    }
}