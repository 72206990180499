import { Directive, AfterViewInit, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: "[menuClick]"
})
export class MenuClickDirective implements AfterViewInit {
    constructor(public el: ElementRef, public renderer: Renderer2) { }

    ngAfterViewInit() {
        let hasChild = this.el.nativeElement.querySelector('ul.dropdown-menu');
        if (hasChild) {
            this.renderer.setStyle(hasChild, 'display', 'none');
        }
    }

    @HostListener('click') onClick() {
        let hasChild = this.el.nativeElement.querySelector('ul.dropdown-menu');
        if (hasChild) {
            if (hasChild.style.display == 'none')
                this.renderer.setStyle(hasChild, 'display', 'block');
            else
                this.renderer.setStyle(hasChild, 'display', 'none');
        }
    }

    @HostListener('document:click', ['$event.target'])
    public onDocumentClick(target) {
        const clickedInside = this.el.nativeElement.contains(target);
        if (!clickedInside) {
            let hasChild = this.el.nativeElement.querySelector('ul.dropdown-menu');
            if (hasChild)
                this.renderer.setStyle(hasChild, 'display', 'none');
        }
    }
}