import { Injectable, ViewContainerRef } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ComponentLoader {
    loadChildren: () => Promise<any>;
}

@Injectable({
    providedIn: 'root'
})
export class TabService {
    constructor(private cfr: ViewContainerRef) { }

    forChild(vcr: ViewContainerRef, cl: ComponentLoader) {
        return from(cl.loadChildren()).pipe(
            map((component: any) => this.cfr.createComponent(component)));
    }
}