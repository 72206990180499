import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { MenuService } from '@core/private/_layout/menu/menu.service';
import { Observable } from 'rxjs';

@Injectable()
export class SubMenuResolver implements Resolve<Observable<string>> {
    constructor(public router: Router, private menuService: MenuService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let menuId = route.paramMap.get('menuId');
        return this.menuService.getMenubyId(menuId);
    }
}