import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@core/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { GameService } from './services/game.service';
import { GameArcadeComponent } from './game-arcade/game-arcade.component';
import { EngageComponent } from './engage.component';

@NgModule({
    imports: [        
        CommonModule,
        TranslateModule,
        SharedModule,
        NgbModule,
        FormsModule,
        RouterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [GameService],
    declarations: [
        GameArcadeComponent,
        EngageComponent        
    ]
})
export class GameModule { }