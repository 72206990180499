import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@core/shared/shared.module';
import { GaugeModule } from 'angular-gauge';
import { RouterModule } from '@angular/router';

import { GoalQuestService } from './services/goal-quest.service';
import { GoalQuestParticipantComponent } from './participant/goal-quest-participant.component';
import { GoalQuestManagerComponent } from './manager/goal-quest-manager/goal-quest.component';
import { GoalSelectionManagerComponent } from './manager/goal-selection-manager/goal-selection-manager.component';
import { GoalResultManagerComponent } from './manager/goal-result-manager/goal-result-manager.component';
import { GoalProgressManagerComponent } from './manager/goal-progress-manager/goal-progress-manager.component';
import { GoalQuestComponent } from './goal-quest.component';

@NgModule({
  declarations: [
    GoalQuestParticipantComponent,   
    GoalQuestManagerComponent,
    GoalSelectionManagerComponent,
    GoalResultManagerComponent,
    GoalProgressManagerComponent,
    GoalQuestComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    GaugeModule.forRoot(),
    SharedModule,
    RouterModule
  ],
  providers: [GoalQuestService]
})
export class GoalQuestModule { }
