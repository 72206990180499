<div class="profile-image-with-progres">
  <div
    class="circle-contianer"
    [style.width.px]="dimensions.width"
    [style.height.px]="dimensions.height"
  >
    <div class="edit-profile-image" *ngIf="isLoading">
      <div class="three-quarter-spinner"></div>
    </div>
    <label for="profileImage" class="edit-profile-image"
      ><fa-icon [icon]="faEdit" class="inner-icon"></fa-icon
    ></label>
    <input
      id="profileImage"
      class="form-control"
      type="file"
      accept="image/*"
      (change)="changeSelection($event)"
      style="display: none"
    />
    <div
      class="profile-image"
      *ngIf="imgUrl"
      [style]="getProfileImageStyles()"
    ></div>
    <div class="profile-image profile-name" *ngIf="!imgUrl">
      <span class="name">{{getShortName(fullName)}}</span>
    </div>
  </div>
</div>
