<p class="block medium-size-plus-3 font-bold">Monthly Progress</p>
<div [appIsLoading]="loadingService.isLoading(this, 'monthlySales')">
    <div *ngIf="lineChartData && lineChartData.datasets.length > 0 && payload.selectedItem.id === 'line'"
        style="height: 400px;">
        <canvas baseChart [data]="lineChartData" [options]="lineChartOptions" [type]="'line'" [legend]="true"></canvas>
    </div>
    <div *ngIf="barChartData && barChartData.datasets.length > 0 && payload.selectedItem.id === 'bar'"
        style="height: 400px;">
        <canvas baseChart [data]="barChartData" [options]="barChartOptions" [type]="'bar'" [legend]="true"></canvas>
    </div>
</div>