import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'cs-shared-league-page',
    templateUrl: 'shared-league-page.component.html',
    styleUrls: ['shared-league-page.component.scss']
})

export class SharedLeagueComponent implements OnInit {
    @Input() salesAchievementLevel;
    @Input() showTableTitle: boolean;
    @Input() attributes: any[];
    @Input() winningPosition: number;
    @Input() leagueData: any[];
    @Input() isHubComponent: boolean = false;

    participantAttributes: string[] = [];
    headerParticipant: boolean;
    headerOrganisationName: boolean;
    headerOrganisationCode: boolean;
    headerJobRole: boolean;
    headerPosition: boolean;
    headerResult: boolean;

    constructor() { }

    ngOnInit() {
        this.participantAttributes = this.generateHeaders();
    }

    generateHeaders() {            
        var attributes = [];
        if (!this.isHubComponent) {
            attributes.push('Position');

            for (var i = 0; i < this.attributes.length; i++) {
                if (this.attributes[i] !== 'Position' && this.attributes[i] !== 'Result') {
                    attributes.push(this.attributes[i]);
                }

                if (this.attributes[i] === 'Participant')
                    this.headerParticipant = true;
                else if (this.attributes[i] === 'Organisation Name')
                    this.headerOrganisationName = true;
                else if (this.attributes[i] === 'Organisation Code')
                    this.headerOrganisationCode = true;
                else if (this.attributes[i] === 'Job Role')
                    this.headerJobRole = true;
            }
            this.headerPosition = true;
            this.headerResult = true;
            attributes.push('Result');
            return attributes;
        }
        else {
            this.headerParticipant = this.attributes.includes("Participant");
            this.headerOrganisationName = this.attributes.includes("Organisation Name");
            this.headerOrganisationCode = this.attributes.includes("Organisation Code");
            this.headerJobRole = this.attributes.includes("Job Role");
            this.headerPosition = this.attributes.includes("Position");
            this.headerResult = this.attributes.includes("Result");
        }
    }
}