import { Routes } from '@angular/router';
import { SettingsResolver } from '@core/shared/guards';
import { AuthGuard } from '@core/shared';

import { DashboardComponent } from './dashboard/dashboard.component';

export const DashboardRoutes: Routes = [
    {
        path: 'home',
        resolve: {settings: SettingsResolver},
        canActivate: [AuthGuard],
        component: DashboardComponent,
        pathMatch: 'full',
        data: { title: 'PageTitles.Home', isPreLogin: false, isDashboard: true }
    }
];