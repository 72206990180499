import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { HttpService } from '@core/shared';
import { UrlBuilder, GET_LEAGUE_PAGE, GET_MAIN_LEAGUE } from '@core/core-api-variables';

@Injectable()
export class LeagueService {
    constructor(public http: HttpService) { }

    getLeaguePage(leagueId, culture) {
        let data = { leagueId, culture };
        let url = UrlBuilder(GET_LEAGUE_PAGE, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    getMainLeague(promotionId, leagueId, userId, pageNumber, type) {
        let data = { promotionId, leagueId, userId, pageNumber, type };
        let url = UrlBuilder(GET_MAIN_LEAGUE, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }
}