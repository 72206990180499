<ng-container>
    <div class="changePasswordSection ">
        <div class="left-aling-header">
            <div *ngIf="passwordChangeSuccess" class="col-sm-12 mx-auto mt-5">
                <ngb-alert type="success" (close)="passwordChangeSuccess = false">
                    <strong>{{ 'ChangePassword.PasswordChangedSuccessfully.WellDone' | translate }}</strong>
                    {{ 'ChangePassword.PasswordChangedSuccessfully.YouSuccessfullyChangedYourPassword' | translate }}
                </ngb-alert>
            </div>
        </div>
        <div class="changePasswordSection">
            <div class="col-sm-12 mx-auto changePassword-sub-Section">
                <div class="common-prelogin-form card card-signin changepassword-inner">
                    <div class="card-body" [appIsLoading]="loadingService.isLoading(this)">
                        <h4 class="card-title text-left mb-5">{{ 'ChangePassword.Title' | translate}}</h4>
                        <form [formGroup]="formChangePassword" class="form-signin"
                            (ngSubmit)="changePassword(formChangePassword)">
                            <div class="form-group">
                                <div class="">
                                     <div class="">
                                        <label for="oldPassword" class=" col-form-label"
                                        translate="ChangePassword.OldPassword"></label>
                                        <input type="password" id="oldPassword" class="form-control"
                                            formControlName="oldPassword" />
                                        <div class="help-block text-danger"
                                            *ngIf="!formChangePassword.controls.oldPassword.valid && (formChangePassword.controls.oldPassword.touched || submitted)">
                                            <span
                                                [hidden]="!formChangePassword.controls['oldPassword'].hasError('required')"
                                                translate="ChangePassword.OldPasswordRequired"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="">
                                    <label for="newPassword" class="col-form-label"
                                        translate="ChangePassword.NewPassword"></label>
                                    <input type="password" id="password" class="form-control form-field "
                                        formControlName="newPassword" />
                                    <div class="help-block text-danger"
                                        *ngIf="!formChangePassword.controls.newPassword.valid && (formChangePassword.controls.newPassword.touched || submitted)">
                                        <span [hidden]="formChangePassword.controls.newPassword.valid">
                                            {{
                                            'ChangePassword.PasswordDoestNotMeetRequirements.PasswordDoesNotMeetMinimumRequirement'
                                            | translate }}
                                            <br />
                                            {{ 'ChangePassword.PasswordDoestNotMeetRequirements.Must' | translate }}
                                            <ul>
                                                <li>
                                                    {{
                                                    'ChangePassword.PasswordDoestNotMeetRequirements.BeAtleast10Characters'
                                                    | translate }}
                                                </li>
                                                <li>
                                                    {{
                                                    'ChangePassword.PasswordDoestNotMeetRequirements.IncludeAtLeastOneNumber'
                                                    | translate }}
                                                </li>
                                                <li>
                                                    {{
                                                    'ChangePassword.PasswordDoestNotMeetRequirements.IncludeAtLeastOneLowercaseLetter'
                                                    | translate }}
                                                </li>
                                                <li>
                                                    {{
                                                    'ChangePassword.PasswordDoestNotMeetRequirements.IncludeAtLeastOneUppercaseLetter'
                                                    | translate }}
                                                </li>
                                                <li>
                                                    {{
                                                    'ChangePassword.PasswordDoestNotMeetRequirements.IncludeAtLeastOneSpecialCharacter'
                                                    | translate }}
                                                </li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="">
                                    <label for="confirmPassword" class=" col-form-label"
                                        translate="ChangePassword.ConfirmPassword"></label>
                                    <input type="password" id="confirmPassword" class="form-control form-field "
                                        formControlName="confirmPassword" />
                                    <div class="help-block text-danger"
                                        *ngIf="!formChangePassword.controls.confirmPassword.valid && (formChangePassword.controls.confirmPassword.touched || submitted)">
                                        <span [hidden]="formChangePassword.controls.confirmPassword.valid"
                                            translate="ChangePassword.ConfirmPasswordMustMatch"></span>
                                    </div>
                                </div>
                            </div>
                            <button class="customBtnSearch btn btn-lg btn-primary btn-block text-uppercase"
                                type="submit">{{ 'ChangePassword.Submit' | translate }}</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>