import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoadingService } from '@core/shared';
import { RegexService } from '@core/startup';
import { AccountsService } from '../services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

    public formChangePassword: FormGroup;
    public submitted: boolean = false;
    public passwordChangeSuccess: boolean = false;
    constructor(public fb: FormBuilder, public accountsService: AccountsService, public regexService: RegexService, public loadingService: LoadingService,  public toaster: ToastrService, public translate: TranslateService) { }

    ngOnInit() {
        this.formChangePassword = this.fb.group({
            oldPassword: ['', [Validators.required]],
            newPassword: [null, [Validators.required, Validators.pattern(this.regexService.passwordRegex)]],
            confirmPassword: [null, [Validators.compose([Validators.required, this.validateAreEqual.bind(this)])]]
        });
    }

    changePassword(formChangePassword) {
        this.submitted = true;
        if (formChangePassword.valid) {            
            this.loadingService.doLoading(this.accountsService.changepassword(formChangePassword.value), this)
            .pipe(untilDestroyed(this)).subscribe(result => {
                this.toaster.success(this.translate.instant('ChangePassword.PasswordChangedSuccessfully.YouSuccessfullyChangedYourPassword'));
                this.formChangePassword.reset();
                this.submitted = false;
            });
        }
    }

    validateAreEqual(fieldControl: FormControl) {
        if (!this.formChangePassword) {
            return null;
        }
        return fieldControl.value === this.formChangePassword.get("newPassword").value ? null : {
            notEqual: true
        };
    }
}