import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { filter, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { LoadingService } from '@core/shared';
import { browserRefresh } from '@app/app.component';
import { HubPageService } from '../services/hub-page.service';

@UntilDestroy()
@Component({
    selector: 'app-hub-page',
    templateUrl: 'hub-page.component.html',
    styleUrls: ['./hub-page.component.scss']
})

export class HubPageComponent implements OnInit {
    components: any[];
    hubPageId: string;
    browserRefresh: boolean;
    selectedCulture: string;
    navigationStartSubscription: Subscription;
    languageChangeSubscription: Subscription;

    public slideConfig: any = { dots: false, infinite: true };
    constructor(private activeRoute: ActivatedRoute, private router: Router, private translateService: TranslateService,
        private hubPageService: HubPageService, public loadingService: LoadingService) { }

    ngOnInit() {
        this.navigationStartSubscription = this.router.events.pipe(
            filter((event) => event instanceof RouterEvent ),
            startWith('Initial call')
        ).subscribe((res : NavigationEnd) => {
            if (this.hubPageId != this.activeRoute.snapshot.params.id) {
                this.browserRefresh = browserRefresh;
                this.hubPageId = this.activeRoute.snapshot.params.id;
                this.getHubPageComponents();
            }
        });

        this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            if (!this.browserRefresh)
                this.getHubPageComponents();
            else
                this.browserRefresh = false;
        });
    }

    getHubPageComponents() {
        this.selectedCulture = localStorage.getItem('culture');
        this.loadingService.doLoading(this.hubPageService.getHubComponents(this.hubPageId, this.selectedCulture), this)
            .pipe(untilDestroyed(this)).subscribe(response => {
                let groups = [];
                response.forEach(group => {
                    let items = { Items: [], enableCarousel: group.enableCarousel };
                    group.Items.forEach(item => {
                        if (item.hasAccess) {
                            items.Items.push(item);
                        }
                    });
                    groups.push(items);
                });                
                this.components = groups;
            });
    }
}