<div>
    <div class="gq-goals-selected">
        <div class="grid grid-gap-10 grid-col-md-33 grid-col-hd-33 grid-col-xs-100 grid-col-sm-100 grid-col-lt-100">
            <div class="goal-selected-outer-wrapper" *ngFor="let achieved of goalAchievedUsers">
                <div class="percentage-achieved">
                    <h5>{{'GoalQuestPage.Manager.GoalQuestProgress.Goal' | translate}} {{achieved.selectedLevel}}</h5>
                    <h5>{{calculateAchievedPercentage(achieved.noOfUsersSelectedGoal, achieved.noOfUsersAchievedGoal) | roundOff:2}}%
                    </h5>
                </div>
                <div class="goalPercentage">
                    <div class="totalPer"></div>
                    <div class="goalAchieved"
                        [style.width.%]="calculateAchievedPercentage(achieved.noOfUsersSelectedGoal, achieved.noOfUsersAchievedGoal)">
                    </div>
                </div>
                <div class="total-selection">
                    <p>{{'GoalQuestPage.Manager.GoalQuestProgress.TotalAchieved' | translate}}</p>
                    <p class="count"><span>{{achieved.noOfUsersAchievedGoal}}</span>/{{achieved.noOfUsersSelectedGoal}}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-rule-bottom"></div>

    <div class="table-header-gq">
        <h3>{{'GoalQuestPage.Manager.GoalQuestProgress.GoalDetail' | translate}}</h3>
        <div class="downloadText pull-right" (click)="downloadGoalProgress()">
            {{'GoalQuestPage.Manager.GoalQuestProgress.BtnDownloadReport' | translate}} <span
                class="downloadIcons"></span>
        </div>
    </div>
    <div class="gq-table table-responsive">

        <table class="table table-striped" aria-describedby="GoalQuest Progress">
            <thead>
                <tr>
                    <th scope="col">{{'GoalQuestPage.Manager.GoalQuestProgress.GoalQuestProgressTable.UserID' |
                        translate}}</th>
                    <th scope="col">{{'GoalQuestPage.Manager.GoalQuestProgress.GoalQuestProgressTable.ParticipantName' |
                        translate}}</th>
                    <th scope="col">{{'GoalQuestPage.Manager.GoalQuestProgress.GoalQuestProgressTable.Organization' |
                        translate}}</th>
                    <th scope="col">{{'GoalQuestPage.Manager.GoalQuestProgress.GoalQuestProgressTable.GoalSelected' |
                        translate}} </th>
                    <th scope="col">{{'GoalQuestPage.Manager.GoalQuestProgress.GoalQuestProgressTable.GoalTarget' |
                        translate}}</th>
                    <th scope="col">{{'GoalQuestPage.Manager.GoalQuestProgress.GoalQuestProgressTable.GoalProgress' |
                        translate}}</th>
                    <th scope="col" *ngIf="isMinimumQualifierEnabled">
                        {{'GoalQuestPage.Manager.GoalQuestProgress.GoalQuestProgressTable.MQTarget' |
                        translate}}</th>
                    <th scope="col" *ngIf="isMinimumQualifierEnabled">
                        {{'GoalQuestPage.Manager.GoalQuestProgress.GoalQuestProgressTable.MQProgress' |
                        translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let goalAchieved of goalAchievedDetails">
                    <td scope="row">{{goalAchieved.userIdName}}</td>
                    <td>{{goalAchieved.userName}}</td>
                    <td>{{goalAchieved.organisation}}</td>
                    <td>{{'GoalQuestPage.Manager.GoalQuestProgress.Goal' | translate}} {{goalAchieved.selectedLevel}}
                    </td>
                    <td>{{goalAchieved.selectedTarget}}</td>
                    <td>
                        <div class="goalPercentage">
                            <div class="totalPer"></div>
                            <div class="goalAchieved"                                
                                [style.width.%]="calculateAchievedPercentage(goalAchieved.selectedTarget, goalAchieved.targetAchievedTill)">
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isMinimumQualifierEnabled">
                        {{goalAchieved.mqTarget ? goalAchieved.mqTarget: 'GoalQuestPage.Manager.GoalQuestProgress.MQNotConfigured'|translate}}
                    </td>
                    <td *ngIf="isMinimumQualifierEnabled">
                        <div class="goalPercentage">
                            <div class="totalPer"></div>
                            <div class="goalAchieved mq-progress"
                                [style.width.%]="calculateAchievedPercentage(goalAchieved.mqTarget, goalAchieved.mqTargetAchievedTill)">
                            </div>
                        </div>
                    </td>
                </tr>                
            </tbody>
        </table>
    </div>
</div>