import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class DownloadService {
    downloadFile(response, filename) {
        var downloadURL = window.URL.createObjectURL(response);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename;
        link.click();
    }
}