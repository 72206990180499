<div class="home-page" *ngIf="menuId">
    <div class="bg-color3 home-page-bg">
        <div class="content-width home-point-gallery-section">
            <div class="flex-1 home-page-slide rightPane">
                <div class="internal-tab">
                    <div (click)="activeSubmenu(menu.url)" class="tab-item" [class]="menu.activeClass"
                        *ngFor="let menu of subMenu">
                        <span>{{menu.translations[selectedCulture]? menu.translations[selectedCulture]:
                            menu.translations[defaultCulture]}}</span>
                    </div>
                </div>
                <router-outlet></router-outlet>
            </div>
            <div class="home-left leftPane">
                <cs-profile-point-card theme="theme-white" buttonTheme="theme2"></cs-profile-point-card>
                <cs-most-redeem-products [singleView]="true"></cs-most-redeem-products>
            </div>
        </div>
    </div>
</div>