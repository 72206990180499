import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@core/shared/shared.module';
import { SalesClaimService } from './services/sales-claim.service';
import { SalesClaimComponent } from './sales-claim/sales-claim.component';
import { ClaimFormsComponent } from './claimForms.component';

@NgModule({
  declarations: [
    ClaimFormsComponent,
    SalesClaimComponent    
  ],
  providers: [SalesClaimService],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ]
})
export class SalesClaimModule { }
