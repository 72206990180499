import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-goal-progress-manager',
  templateUrl: './goal-progress-manager.component.html'
})
export class GoalProgressManagerComponent {
  @Input() isMinimumQualifierEnabled: boolean;
  @Input() goalAchievedUsers: any;
  @Input() goalAchievedDetails: any;
  @Output() downloadGoalProgressEvent = new EventEmitter();

  constructor() { }

  calculateAchievedPercentage(selectedCount, achievedCount) {        
    return (selectedCount && achievedCount) ? (achievedCount / selectedCount) * 100 : 0;
  }

  downloadGoalProgress() {
    this.downloadGoalProgressEvent.emit();
  }

}
