<div class="table-width" *ngIf="leagueData.length>0">
    <p class="table-title block medium-size-plus-3 font-bold" *ngIf="showTableTitle">{{'Leagues.LeaderBoard' | translate}}</p>
    <table class="table table-striped" aria-describedby="myTable" [ngClass]="{'mt-0': showTableTitle}">
        <thead>
            <tr>
                <th *ngIf="headerPosition" scope="col">
                    <span translate="Leagues.Position"></span>
                </th>
                <th *ngIf="headerParticipant && salesAchievementLevel !== 'Organisation'" scope="col">
                    <span translate="Leagues.Participant"></span>
                </th>
                <th *ngIf="headerOrganisationName" scope="col">
                    <span translate="Leagues.OrganisationName"></span>
                </th>
                <th *ngIf="headerOrganisationCode" scope="col">
                    <span translate="Leagues.OrganisationCode"></span>
                </th>
                <th *ngIf="headerJobRole  && salesAchievementLevel !== 'Organisation'" scope="col">
                    <span translate="Leagues.JobRole"></span>
                </th>
                <th *ngIf="headerResult" scope="col">
                    <span translate="Leagues.Result"></span>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!isHubComponent">
            <tr *ngFor="let user of leagueData" [class]="{'success':winningPosition >= user.Position}"
                [style.background-color]="user.IsCurrentUser ? '#0093D1':''"
                [style.color]="user.IsCurrentUser ? '#ffffff':''">
                <td>
                    {{user.Position}}
                </td>
                <td *ngIf="headerParticipant && salesAchievementLevel !== 'Organisation'">
                    <span>{{user.Participant}}</span>
                </td>
                <td *ngIf="headerOrganisationName">
                    <span>{{user.OrganisationName}}</span>
                </td>
                <td *ngIf="headerOrganisationCode">
                    <span>{{user.OrganisationCode}}</span>
                </td>
                <td *ngIf="headerJobRole && salesAchievementLevel !== 'Organisation'">
                    <span>{{user.JobRole}}</span>
                </td>
                <td>
                    <span>{{user.Result}}</span>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="isHubComponent">
            <tr *ngFor="let user of leagueData" [class]="{'success':winningPosition >= user.Rank}"
                [style.background-color]="user.isCurrentUser ? '#0093D1':''"
                [style.color]="user.isCurrentUser ? '#ffffff':''">
                <td *ngIf="headerPosition">
                    {{user.rank}}
                </td>
                <td *ngIf="headerParticipant && salesAchievementLevel !== 'Organisation'">
                    <cs-shared-league-profile-image [id]="user.id" fullname="{{user.firstName+' '+user.lastName}}">
                    </cs-shared-league-profile-image><span>{{user.firstName}} {{user.lastName}}</span>
                </td>
                <td *ngIf="headerOrganisationName">
                    <span>{{user.organisationName}}</span>
                </td>
                <td *ngIf="headerOrganisationCode">
                    <span>{{user.organisationCode}}</span>
                </td>
                <td *ngIf="headerJobRole && salesAchievementLevel !== 'Organisation'">
                    <span>{{user.jobRole}}</span>
                </td>
                <td *ngIf="headerResult">
                    <span>{{user.result}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>