<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve"
	*ngIf="tabName == 'ACTIVE CONTESTS'">
	<g>
		<path d="M115.4,482c-0.3-1.5-0.9-3-1-4.6c-0.1-39.7,0-79.4,0-119.1c0-1,0.2-1.9,0.4-3.7c2.5,0,4.8,0,7.1,0c34.3,0,68.7-0.1,103,0.1
		c4.7,0,7.2-1.3,8.9-5.7c1.9-4.7,4.5-9.1,7.8-13.5c0,21.8,0,43.7,0,66c5.6,0,10.6,0,16.1,0c0-21.8,0-43.6,0-65.3
		c0.5-0.2,1-0.3,1.4-0.5c2,3.8,4.5,7.5,5.9,11.6c2.1,6.1,5.9,7.4,12,7.4c33.5-0.3,67.1-0.2,100.6-0.2c2.2,0,4.3,0,7.2,0
		c0.2,2.2,0.4,4,0.4,5.9c0,38.5,0,77,0,115.5c0,1.9-0.5,3.9-0.7,5.8c-42.1,0-84.2,0-126.3,0c0-18.5,0-37,0-55.5
		c-6.3,0-11.1,0-16.7,0c0,18.8,0,37.1,0,55.5C199.6,482,157.5,482,115.4,482z M130.9,418.6c0,5.2,0,10,0,15c15.9,0,31.4,0,47,0
		c0-5.2,0-9.9,0-15C162.3,418.6,147,418.6,130.9,418.6z M178,450.2c-16.1,0-31.5,0-47.1,0c0,5.2,0,10,0,14.9c15.9,0,31.4,0,47.1,0
		C178,459.9,178,455.2,178,450.2z M194.4,450.1c0,5.4,0,10.1,0,15.1c5.2,0,10,0,15.2,0c0-5.1,0-10,0-15.1
		C204.4,450.1,199.6,450.1,194.4,450.1z" />
		<path d="M464.3,236.7c5.6,0,11.2-0.1,16.9-0.1c0,5.2,0,10.3,0,15.5c-5.6,0-11.2-0.1-16.8-0.1l0.2,0.2c0-5.2,0-10.4,0-15.6
		L464.3,236.7z" />
		<path d="M18.9,267.6c5.2,0,10.3,0,15.5,0.1l-0.1-0.2c0,5.6,0,11.3,0,16.9c0,0,0.1-0.2,0.1-0.2c-5.2,0-10.3,0-15.5,0.1
		C18.9,278.7,18.9,273.1,18.9,267.6z" />
		<path
			d="M34.4,54.3c-5.2,0-10.3,0-15.5,0.1c0-5.2,0-10.3,0-15.5c5.2,0,10.3,0.1,15.5,0.1l-0.1-0.1c0,5.2,0,10.4,0,15.6L34.4,54.3z" />
		<path d="M309.1,63.2c-6.1,10.3-11.7,19.6-17,29.1c-1.5,2.6-2.4,5.8-2.7,8.9c-1.1,11.2-1.8,22.5-2.7,34.3
		c-10.5-2.6-20.6-5.4-30.8-7.6c-3.9-0.8-8.2-0.7-12.1,0.1c-10.1,2.1-20,4.9-30.6,7.5c-0.8-11.2-1.4-22.1-2.6-32.9
		c-0.4-3.8-1.5-7.8-3.4-11.1c-5.1-9.4-10.7-18.5-16.6-28.4c9.9-4.3,19.6-8.1,29-12.6c3.4-1.6,6.7-4.1,9.2-7
		c7-8.1,13.6-16.5,20.9-25.5c3.3,4,6.9,7.5,9.5,11.5c9.2,14.3,21.9,23.6,38,28.5C301.2,59.2,304.8,61.3,309.1,63.2z" />
		<path d="M236.6,308.2c-4.9,9.7-9.5,19.1-14.4,28.4c-0.6,1.2-2.7,2.4-4.1,2.4c-44.8,0.1-89.6,0.1-135.6,0.1
		c5.3-10.5,10.2-20.4,15.3-30.1c0.5-1,2.7-1.6,4.1-1.6c44.2-0.1,88.5,0,132.7,0C235.1,307.4,235.4,307.7,236.6,308.2z" />
		<path d="M262.8,307.6c2.8-0.1,4.5-0.3,6.3-0.3c42.1,0,84.2,0.1,126.3-0.1c4.4,0,6.7,1.4,8.5,5.4c3.9,8.6,8.4,17,13,26.2
		c-2.2,0.1-3.9,0.3-5.5,0.3c-42.5,0-85,0-127.5,0.1c-3.9,0-6.1-1-7.7-4.6C272.1,325.8,267.6,317.2,262.8,307.6z" />
		<path d="M173.6,270.8c-24.5-29.1-44.4-60.4-77.6-79c7.9-15.7,15.5-30.9,23.4-46.5c18.1,11.6,31.4,26.8,40.1,46.1
		c7.3,16.1,8.2,33.4,10.5,50.4C171.3,250.8,172.3,259.8,173.6,270.8z" />
		<path d="M403.7,191.7c-33.1,18.6-53,49.8-77.4,77.2c1.2-9.5,2.2-19,3.6-28.5c1.8-12.3,2.9-24.9,6.1-36.9
		c6.8-24.9,22.1-43.9,44.2-58.3C388,160.7,395.5,175.6,403.7,191.7z" />
		<path d="M66.7,103.9c8.7,14.3,19.4,24.1,36.4,27.5c-10.6,13.2-15.3,27-13.6,43.5c-8.2-1.9-15.4-4.8-22.6-4.8
		c-7.1,0-14.2,2.9-22,4.7c-0.7-7.8-0.2-15.5-2.3-22.5c-2.2-7-6.9-13.2-11.1-20.8C46.8,127.8,58.2,118.7,66.7,103.9z" />
		<path d="M396.4,131.5c17.1-3.8,28.2-13,36.4-27.7c9.1,14.1,19.4,24.4,35.9,27.2c-4.3,7.7-9.2,13.8-11.3,20.7
		c-2.2,7-1.7,14.9-2.5,23.6c-14.6-6.2-29-6.6-44.4,0.6C411.6,159,408,144.7,396.4,131.5z" />
		<path d="M164.1,138.3c4.3-3.5,8.1-6.6,12.1-9.9c36.3,43,45.5,92.9,40.9,147.1c-4.6,0-9.4,0-15.1,0c0-4.6,0-9.1,0-13.6
		c0.2-20.5-0.1-40.8-5.6-60.8c-5.8-21.3-15.4-40.7-28.9-58.2C166.6,141.6,165.6,140.2,164.1,138.3z" />
		<path d="M335.7,138.6c-33.6,39.7-41.7,86.5-37.3,136.9c-5.9,0-10.8,0-15.9,0c-4.8-53.9,4.7-103.9,40.9-147.1c2,1.6,4,3.2,6,4.8
		C331.3,134.8,333.3,136.5,335.7,138.6z" />
		<path
			d="M257.6,275.6c-5.4,0-10.3,0-15.5,0c0-34.2,0-68,0-102.3c5.1,0,10.1,0,15.5,0C257.6,207.4,257.6,241.2,257.6,275.6z" />
		<path d="M51,267.6c4.9,0,9.8,0,15.2,0c0,5.4,0,10.4,0,16.1c-4.9,0-9.8,0-15.2,0c0,5.5,0,10.3,0,15.5c-5.5,0-10.3,0-15.9,0
		c-0.2-5-0.5-10-0.7-15c0,0-0.1,0.2-0.1,0.2c5.4-0.4,10.8-0.9,16.9-1.3c0-5.4,0-10.5,0-15.6L51,267.6z" />
		<path
			d="M34.4,38.9c0-5.2,0-10.5,0-16.1c5.8,0,10.7,0,16.6,0c0,5.3,0,10.7,0,16.1l0.1-0.2c-5.6,0-11.2,0-16.8,0L34.4,38.9z" />
		<path
			d="M440.7,39c0-5.3,0-10.5,0-16.1c5.6,0,10.4,0,16.6,0c0,5.3,0,10.7,0,16.1l0.1-0.1c-5.6,0-11.2,0-16.8,0L440.7,39z" />
		<path d="M361.6,85.5c0-5.1,0-10,0-15.2c5.2,0,10.1,0,15.4,0c0,5,0,9.8,0,15.2C372,85.5,367,85.5,361.6,85.5z" />
		<path
			d="M257.6,141.6c0,5.1,0,9.9,0,15.1c-5.2,0-10.1,0-15.4,0c0-5,0-9.8,0-15.1C247.3,141.6,252.3,141.6,257.6,141.6z" />
		<path
			d="M337.7,117.1c0-5,0-9.8,0-15c5.2,0,10.1,0,15.4,0c0,4.9,0,9.7,0,15C348.1,117.1,343.1,117.1,337.7,117.1z" />
		<path d="M449,236.8c0-5.3,0-10.6,0-16.1c5.3,0,10.2,0,15.2,0c0,5.6,0,10.9,0,16.1c0,0,0.3-0.2,0.3-0.2c-5.2,0-10.4,0-15.6,0
		L449,236.8z" />
		<path d="M34.4,267.6c0-4.9,0-9.8,0-15.1c5.8,0,10.7,0,16.6,0c0,5,0,10.1,0,15.1c0,0,0.1-0.2,0.1-0.2c-5.6,0-11.3,0-16.9,0
		L34.4,267.6z" />
		<path
			d="M122.7,70.2c5.3,0,10,0,15.1,0c0,5,0,9.8,0,15.2c-4.9,0-9.9,0-15.1,0C122.7,80.4,122.7,75.6,122.7,70.2z" />
		<path
			d="M457.2,54.3c0,4.9,0,9.8,0,15.2c-5.6,0-10.6,0-16.6,0c0-5.1,0-10.2,0-15.2l-0.2,0.1c5.6,0,11.3,0,16.9,0L457.2,54.3z" />
		<path
			d="M51,54.3c0,4.9,0,9.9,0,15.3c-5.5,0-10.5,0-16.6,0c0-5.1,0-10.2,0-15.3c0,0-0.1,0.2-0.1,0.2c5.6,0,11.3,0,16.9,0L51,54.3z" />
		<path
			d="M161.8,117.3c-5.1,0-9.8,0-15,0c0-4.9,0-9.9,0-15.2c4.9,0,9.7,0,15,0C161.8,107.1,161.8,111.9,161.8,117.3z" />
		<path
			d="M449,252c-5.1,0-10.2,0-15.6,0c0-5.2,0-10,0-15.2c5.3,0,10.5,0,15.7,0c0,0-0.2-0.2-0.2-0.2c0,5.2,0,10.4,0,15.6L449,252z" />
		<path d="M464.3,252c0.2,4.9,0.4,9.8,0.7,15.3c-5.4,0-10.4,0-15.9,0c0-5.1,0-10.2,0-15.3c0,0-0.2,0.2-0.2,0.2c5.2,0,10.4,0,15.6,0
		L464.3,252z" />
		<path d="M457.2,38.9c4.9,0,9.8,0,15.2,0c0,4.8,0,9.7,0,15.3c-5,0-10.1,0-15.2,0c0,0,0.2,0.1,0.2,0.1c0-5.2,0-10.4,0-15.6
		L457.2,38.9z" />
		<path d="M440.7,54.3c-4.9,0-9.8,0-15.2,0c0-5.2,0-10.1,0-15.3c5.1,0,10.1,0,15.1,0c0,0-0.1-0.2-0.1-0.2c0,5.2,0,10.4,0,15.6
		C440.5,54.4,440.7,54.3,440.7,54.3z" />
		<path
			d="M51,39c4.9,0,9.8,0,15.2,0c0,4.8,0,9.7,0,15.3c-5.1,0-10.1,0-15.2,0c0,0,0.2,0.1,0.2,0.1c0-5.2,0-10.4,0-15.6L51,39z" />
	</g>
</svg>


<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve"
	*ngIf="tabName == 'goalquest'">
	<g>
		<path d="M264.7,14c8.6,1.2,17.3,2.1,25.9,3.7c8.4,1.5,16.8,3.5,25.1,5.7c9,2.4,13.7,10.4,11.2,18.6c-2.4,8-10.3,12.2-19.1,9.7
		c-25.9-7.4-52.2-9.9-78.9-7.2c-34.9,3.6-67,15.1-95.8,35.2c-45.3,31.6-75,74.4-85.4,128.7c-14.7,77,7.6,142.8,65.2,195.9
		c30.1,27.8,66.4,43.7,106.8,50.1c84.8,13.4,173-31.4,212.7-108.1c25.2-48.8,30.7-99.9,15.9-152.8c-3.5-12.6,5.2-21.8,14.5-21.1
		c6.4,0.5,11.7,4.5,13.6,11.2c7.3,25.4,10.6,51.4,9.2,77.9c-2.7,51.3-19.8,97.3-52.4,137c-40.4,49.2-92.2,78.6-155.7,85.8
		C158.5,498,50.1,423.2,21,307.4c-3.1-12.4-4.3-25.4-6.3-38.1c-0.2-1.5-0.6-3-0.8-4.5c0-9.8,0-19.7,0-29.5c1.5-10.5,2.6-21,4.6-31.4
		c17.6-94.1,96.1-170.8,190.4-186.3c8.8-1.4,17.6-2.4,26.4-3.6C245,14,254.9,14,264.7,14z" />
		<path d="M404,14c7.9,3.5,11,9.6,11.4,18.1c0.7,15,2.3,30,3.4,45c0.2,2.8,1,4,4,4.2c16.2,1.1,32.5,2.4,48.7,3.7
		c6.4,0.5,11.2,3.4,13.5,9.5c2.4,6.1,0.9,11.5-3.6,16.1c-17.4,17.4-34.7,34.9-52.3,52.1c-2.4,2.4-6.3,4.2-9.7,4.6
		c-20.3,2.4-40.6,4.3-61,6.3c-3.4,0.3-6.8,0.3-10.1,1.1c-1.9,0.4-3.8,1.8-5.3,3.2c-27.2,27.1-54.4,54.3-81.5,81.5
		c-4.1,4.1-8.7,6.4-14.5,5.3c-10.9-2-15.7-15.1-8.7-23.7c1.1-1.3,2.3-2.5,3.5-3.7c26.7-26.7,53.5-53.4,80.1-80.3
		c1.8-1.8,3.1-4.9,3.4-7.5c2.4-22,4.6-44,6.6-66c0.5-5.8,2.7-10.4,6.9-14.5c15.8-15.5,31.3-31.3,47.1-46.8c3.1-3.1,6.9-5.4,10.4-8
		C399.1,14,401.5,14,404,14z M438.4,112.1c-2.5-0.2-3.7-0.3-4.9-0.4c-10.1-0.9-20.2-1.5-30.3-2.6c-6.8-0.8-11.3-5.5-12.3-12.3
		c-0.4-2.6-0.6-5.2-0.8-7.8c-0.7-8.7-1.4-17.3-2.1-26.2c-0.8,0.3-1.2,0.4-1.5,0.7c-7.8,7.8-15.7,15.6-23.4,23.5
		c-1,1.1-1.6,2.9-1.8,4.5c-1.2,10.4-2.2,20.7-3.3,31.1c-0.7,7.1-1.3,14.2-2,21.7c18.5-1.9,36.3-3.7,54.1-5.6c1-0.1,2.1-0.6,2.8-1.3
		C421.2,129.3,429.3,121.1,438.4,112.1z" />
		<path d="M86.3,250.4c0.9-80.9,60.6-149.9,140.6-161.4c22.1-3.2,44.1-2,65.7,4.1c7,2,11.1,7.3,11.2,14c0.1,6.6-3.8,12.5-10.3,14.1
		c-3.3,0.8-7,0.5-10.4-0.3c-71.6-17.5-142.1,23.6-161.9,94.5c-20.7,74.4,29,152.7,105.1,165.7c72,12.3,139.2-33.2,154.1-104.6
		c4-19.4,3.5-38.7-1-58c-0.9-3.8-1.5-8-0.7-11.7c1.3-6.5,7.3-10.5,13.9-10.6c6.5,0,12.2,4,14.1,10.5c4.1,14.1,5.9,28.5,5.9,43.2
		C412.4,324,364,387.3,292.4,407.2c-88,24.5-181.2-31.3-200.9-120.4C88.9,274.8,88,262.5,86.3,250.4z" />
		<path d="M253.1,191.1c-32.3-0.8-57.7,21.2-61.9,52.6c-3.9,28.9,16.4,57.1,45.9,64c35.5,8.3,70.8-19,71.7-55.7
		c0.2-6.9,2.4-12.4,8.8-15.4c9.9-4.6,21,2.8,20.7,14c-1.2,35.6-17.4,62.7-49.3,78.4c-33.6,16.6-66.5,12.7-95.3-11
		c-28.5-23.5-38.7-54.9-29.2-90.6c9.6-36.1,34.4-57.6,71.1-64.6c7.9-1.5,16.2-1.1,24.3-0.5c7.9,0.6,13.4,7.9,12.8,15.3
		c-0.7,8.1-7.3,13.9-15.6,13.7C255.5,191.3,253.8,191.2,253.1,191.1z" />
	</g>
</svg>


<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 249 500" style="enable-background:new 0 0 249 500;" xml:space="preserve"
	*ngIf="tabName == 'league' || tabName == 'promotion'">
	<g>
		<path d="M216.8,13c3.1,2.9,3.5,6.3,2.4,10.2C202.1,88.8,185,154.5,168,220.1c-0.7,2.9-1.8,3.5-4.7,3.5c-25.4-0.2-50.8-0.2-76.2,0
		c-3.1,0-4-1-4.7-3.6C65.2,154.4,48,88.8,30.8,23.2c-1-4-0.7-7.4,2.4-10.2c25.4,0,50.9,0,76.3,0c3.2,2.8,3.7,6.7,4.7,10.5
		c3.5,14.3,7,28.6,10.9,44.2c4.1-16.7,8-32.1,11.7-47.4c0.7-2.8,1.6-5.3,3.9-7.3C166,13,191.4,13,216.8,13z" />
		<path d="M125.3,229.5c11.5,0,22.9,0,34.4,0c10.9,0.1,16.7,6,16.7,17c0,10.7-3,15.8-10.7,18.1c-2.6,0.8-5.3,0.5-7.9,0.5
		c-6,0.1-11.9,0-17.9,0c-1.9,0-4.5-0.7-4.1,2.7c0.3,2.5-1.4,5.4,3.4,6c19.2,2.5,36.6,9.5,52,21.4c23.3,18,37.9,41.7,41.3,71
		c7.4,64.1-37.7,110.1-87.7,118.9C89.2,495.1,36,462.9,20.9,409.2c-17.5-62.6,22-120.4,80.3-133.6c3.2-0.7,6.5-1.6,9.7-1.7
		c4.6-0.1,3.1-3.3,3.4-5.6c0.4-3.1-1.5-3.2-3.7-3.2c-7.2,0.1-14.4,0.1-21.7,0c-9.4-0.1-15.2-6-15.2-15.4c0-3.1-0.2-6.4,0.5-9.4
		c1.6-6.7,7.7-10.7,15.9-10.8C101.8,229.4,113.5,229.5,125.3,229.5z M30.8,379.8c0.5,53.5,44,92.6,93,93.2
		c50.2,0.6,95.2-39,95.3-93.2c0.1-50.7-42.3-92.6-93.4-92.8C73.7,286.7,31.1,328.4,30.8,379.8z M124.8,251c11.1,0,22.3-0.1,33.4,0.1
		c2.8,0,4.3-0.4,4.2-3.8c-0.1-3-1.1-3.7-3.9-3.7c-22.3,0.1-44.5,0.1-66.8,0c-2.7,0-3.9,0.5-3.9,3.6c-0.1,3.3,1.2,3.9,4.1,3.8
		C102.8,250.9,113.8,251,124.8,251z" />
		<path d="M213.1,380c0,47.9-39.7,87.1-88.1,87.1c-48.4,0-88-39.3-88-87.2c0-47.9,39.6-86.7,88.3-86.6
		C173.8,293.4,213.1,332.2,213.1,380z M125,330.1c-5.1,10.3-10,19.8-14.6,29.4c-1.2,2.5-2.6,3.7-5.6,4c-10.8,1.3-21.5,3.1-31.1,4.5
		c4.6,5.1,9.2,10.7,14.2,15.8c10.6,10.6,10.6,10.5,7.8,25.3c-1.3,6.6-2.2,13.3-3.5,21c10.5-5.5,20-10.3,29.4-15.3
		c2.5-1.3,4.5-1.4,6.9,0c5.6,3.1,11.3,6.1,17.1,8.9c3.8,1.9,7.4,4.3,12.2,5.7c-1.8-10.8-3.3-21-5.4-31.1c-0.7-3.5,0.4-5.4,2.6-7.7
		c7.1-7.7,14-15.7,20.2-22.8c-8.4-1.2-18.9-3-29.6-4.3c-3.4-0.4-4.9-1.8-6.2-4.6C134.9,349.6,130.1,340.2,125,330.1z" />
	</g>
</svg>


<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve"
	*ngIf="tabName == 'claimform'">
	<g>
		<path d="M383,480c-3.3,0-6.6,0-10,0c-1.6-0.3-3.2-0.8-4.8-0.9c-19.8-1.7-37.8-8.3-53.7-20.1c-3.7-2.7-7.3-3.6-11.7-3.6
		c-62,0.1-124.1,0.1-186.1,0C70.4,455.3,31.2,426.2,19,383c-1.8-6.3-2.7-12.9-4-19.4c0-83.9,0-167.7,0-251.6c0.5-3.1,1-6.2,1.6-9.2
		c8.8-48.2,49.1-82.6,98.1-82.7c82.2-0.3,164.3-0.1,246.5,0c5.7,0,11.5,0.5,17,1.6c49.1,9.6,81.9,49.1,82,99.1
		c0.1,60.4,0,120.8,0.2,181.2c0,3.3,1.4,7.2,3.3,9.9c8.7,12.1,15.2,25.3,18.6,39.8c1,4.3,1.7,8.6,2.6,12.9c0,7,0,14,0,21.1
		c-0.3,0.8-0.8,1.6-1,2.5c-6.3,43.3-37.5,77.8-79.9,88.1C397.2,478,390,478.8,383,480z M443.7,292.2c0-2.4,0-3.7,0-4.9
		c0-56.5,0.1-113-0.2-169.5c0-7.8-1.4-15.9-3.7-23.4c-10.7-35.3-42.8-57.9-82-58.1c-39.7-0.1-79.4,0-119.1,0
		c-41.7,0-83.5-0.6-125.2,0.3c-47.1,1-81.6,37.2-81.7,84.3c-0.1,77.9-0.1,155.8,0.1,233.8c0,5.9,0.4,11.9,1.6,17.6
		c7.8,38.8,41,66.5,80.7,66.8c58.3,0.3,116.7,0.1,175,0.1c1,0,2-0.2,3.8-0.4c-16.4-21.6-23.5-45.5-22.1-72c-10.9,0-21.1,0.1-31.2,0
		c-6.3-0.1-9.9-3-10-7.9c-0.1-5.2,3.5-8.3,10.1-8.4c9-0.1,18.1-0.3,27.1,0.1c5,0.2,7.1-1.1,8.7-6.3c15.1-49.7,66.8-81.5,117.9-73.2
		C411.5,274.1,427.9,280.6,443.7,292.2z M287.4,374.2c-0.2,48.7,40.4,88.9,89.9,89.1c49.9,0.2,90.9-39.3,91.1-87.9
		c0.2-49-40.4-89.2-90.2-89.4C328.3,285.9,287.6,325.5,287.4,374.2z" />
		<path d="M311.5,133c-23.4,0-46.8,0-70.3,0c-1.8,0-3.7,0.1-5.5-0.4c-3.8-1-6-3.6-6-7.6c0-4,2-6.7,5.8-7.7c1.9-0.5,4-0.5,6-0.5
		c46.8,0,93.7,0,140.5,0c1.8,0,3.7-0.1,5.5,0.3c4,0.9,6.5,3.4,6.6,7.7c0,4.6-2.7,7.1-6.9,8.1c-1.6,0.4-3.3,0.2-5,0.2
		C358.7,133,335.1,133,311.5,133z" />
		<path d="M312.1,229.6c23.4,0,46.8,0,70.3,0c1.8,0,3.8-0.2,5.5,0.3c3.9,1.1,6.4,3.6,6.3,8c-0.1,4-2.3,6.5-6.1,7.5
		c-1.6,0.4-3.3,0.4-4.9,0.4c-47.4,0-94.8,0-142.2,0c-1.5,0-3,0-4.4-0.3c-4.3-0.9-6.9-3.5-6.8-8c0.1-4.6,2.9-7,7.1-7.8
		c1.6-0.3,3.3-0.1,5-0.1C265.3,229.6,288.7,229.6,312.1,229.6z" />
		<path d="M121.8,371.7c14.8-16.9,29.4-33.6,44-50.2c1.8-2.1,3.6-4.2,5.5-6.2c4.8-5.4,9.8-6.4,13.8-2.9c4,3.6,3.6,8-1.3,13.5
		c-16.2,18.5-32.3,37-48.6,55.4c-11,12.5-11,12.5-24.5,2.5c-9.5-7-19.1-14-28.6-21c-5.2-3.9-6.4-8.1-3.6-12.2
		c2.9-4.3,7.9-4.8,13.1-1C101.7,356.9,111.6,364.2,121.8,371.7z" />
		<path d="M121.9,137.9c12.4-14.1,24.5-28,36.7-41.9c4.6-5.3,9.2-10.6,13.9-15.8c4-4.4,8.9-4.9,12.6-1.6c3.7,3.3,3.7,7.7-0.2,12.2
		c-18.2,20.9-36.4,41.7-54.7,62.5c-4.1,4.6-8,5-13.2,1.3c-12-8.7-23.9-17.4-35.7-26.2c-4.4-3.3-5.3-7.7-2.7-11.5
		c2.7-4,7.6-4.8,12.2-1.6c8.6,6.1,17,12.4,25.5,18.6C118.1,135.2,119.8,136.5,121.9,137.9z" />
		<path d="M121.8,250.8c16.6-19,32.9-37.6,49.3-56.2c1.2-1.4,2.4-2.9,3.9-3.8c3.5-2.2,7.1-2,10.2,0.8c3.1,2.9,3.5,6.4,1.3,10
		c-0.9,1.4-2,2.6-3.1,3.9c-17.4,19.9-34.8,39.7-52.2,59.6c-5.2,5.9-8.9,6.3-15.2,1.7c-11.2-8.2-22.3-16.4-33.4-24.6
		c-5.5-4.1-6.8-8.4-3.8-12.6c3.1-4.3,8-4.5,13.6-0.4C102,236.2,111.7,243.4,121.8,250.8z" />
		<path d="M374.3,395.1c5.2-7.3,10.1-14.1,15-21c9.9-14,19.9-28,29.8-41.9c3.8-5.3,8.4-6.6,12.7-3.8c4.2,2.8,4.6,7.8,0.9,13.1
		c-16.2,22.9-32.5,45.7-48.7,68.5c-4.2,6-8.8,6.7-14.5,2.2c-12.3-9.7-24.5-19.6-36.7-29.5c-4.7-3.8-5.7-8.7-2.6-12.4
		c3.2-3.9,8-4.1,12.8-0.3C353.4,378.2,363.7,386.5,374.3,395.1z" />
	</g>
</svg>


<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 500 373" style="enable-background:new 0 0 500 373;" xml:space="preserve"
	*ngIf="tabName == 'message'">
	<g>
		<path d="M446.7,358.8c-130.8,0-261.6,0-392.4,0c-0.7-0.3-1.3-0.6-2-0.8C28.3,353,15,336.9,15,312.6c0-47.1,0-94.3,0-141.4
		c0-46.8-0.1-93.6,0.2-140.3c0-3.9,1.7-8.3,4-11.5c3.3-4.7,9-4.8,14.5-4.8c124.8,0,249.5,0,374.3,0c14.5,0,19.3,4.8,19.3,19.4
		c0,92,0,184,0,275.9c0,2.1-0.1,4.2,0.1,6.4c0.8,10,10.7,16.2,20,12.5c6.2-2.4,9.1-7.4,9.1-15.7c0-78,0-156.1,0-234.1
		c0-2.2,0-4.4,0-7.1c5,0,9.3-0.1,13.5,0C480.3,72.1,486,77.8,486,88c0,75.7,0,151.5,0,227.2c0,19.4-12.5,35.9-31.1,41.4
		C452.2,357.4,449.4,358.1,446.7,358.8z M44.6,43.6c0,2.3,0,4.4,0,6.5c0,87.2,0,174.4,0,261.6c0,13.2,5.1,18.2,18.2,18.2
		c109.6,0,219.1,0,328.7,0c1.9,0,3.8,0,5.9,0c0-95.8,0-191,0-286.3C279.7,43.6,162.5,43.6,44.6,43.6z" />
		<path
			d="M74.2,128.9c0-19.1,0-37.7,0-56.6c98,0,195.6,0,293.7,0c0,18.7,0,37.5,0,56.6C270.2,128.9,172.4,128.9,74.2,128.9z" />
		<path
			d="M132.6,157.9c13.9,0,27.9,0,41.8,0c11.8,0,17.2,5.4,17.2,17.1c0,26.8,0,53.6,0,80.4c0,11.6-5.5,17.2-17.2,17.2
		c-27.9,0.1-55.8,0.1-83.7,0c-11.3,0-17-5.7-17-16.9c0-27,0-54,0-81c0-11.4,5.5-16.8,16.9-16.9C104.7,157.8,118.7,157.9,132.6,157.9
		z M161.9,243.6c0-19.2,0-37.8,0-56.5c-19.6,0-38.9,0-58.3,0c0,19.1,0,37.7,0,56.5C123.1,243.6,142.3,243.6,161.9,243.6z" />
		<path
			d="M228.7,193.4c0-9.3,0-18.4,0-27.9c48.8,0,97.4,0,146.5,0c0,9.1,0,18.3,0,27.9C326.6,193.4,277.8,193.4,228.7,193.4z" />
		<path
			d="M375.4,224.9c0,9.2,0,18.3,0,27.9c-48.8,0-97.5,0-146.6,0c0-9.2,0-18.3,0-27.9C277.5,224.9,326.3,224.9,375.4,224.9z" />
		<path
			d="M221.3,272.9c24.9,0,48.9,0,73.2,0c0,9.4,0,18.5,0,28c-24.4,0-48.7,0-73.2,0C221.3,291.5,221.3,282.2,221.3,272.9z" />
	</g>
</svg>




<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="28.000000pt" height="19.000000pt"
	viewBox="0 0 28.000000 19.000000" preserveAspectRatio="xMidYMid meet" *ngIf="tabName == 'gamearcade'">
	<g transform="translate(0.000000,19.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
		<path d="M35 170 c-34 -37 -46 -138 -20 -160 13 -10 22 -8 53 14 49 34 91 33
143 -1 40 -26 42 -27 55 -8 16 22 17 44 4 99 -14 62 -27 69 -129 71 -74 2 -94
-1 -106 -15z m82 -5 c12 -3 39 -2 58 4 31 8 39 7 55 -9 11 -11 22 -32 25 -47
4 -16 8 -36 10 -46 5 -23 -4 -47 -17 -47 -5 0 -26 11 -46 25 -47 32 -79 32
-127 -1 -38 -25 -65 -26 -65 -2 1 33 23 105 37 120 9 10 23 16 32 14 9 -2 26
-7 38 -11z" />
		<path d="M57 142 c-22 -24 -21 -35 1 -55 17 -16 20 -15 40 6 l22 23 -23 22
c-23 22 -24 22 -40 4z m30 -36 c-16 -18 -40 3 -25 22 8 9 14 9 24 1 10 -8 10
-14 1 -23z" />
		<path d="M190 140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z" />
		<path d="M166 121 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z" />
		<path d="M216 121 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z" />
		<path d="M190 90 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z" />
	</g>
</svg>


<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 29.1 40" style="enable-background:new 0 0 29.1 40;" xml:space="preserve"
	*ngIf="tabNameList.indexOf(tabName) == -1 && tabNameList.indexOf(tabName) == -1">
	<style type="text/css">
		.st0 {
			clip-path: url(#SVGID_2_);
		}
	</style>
	<g>
		<defs>
			<rect id="SVGID_1_" x="0.3" y="0.2" width="28.4" height="39.5" />
		</defs>
		<clipPath id="SVGID_2_">
			<use xlink:href="#SVGID_1_" style="overflow:visible;" />
		</clipPath>
		<g id="Group_112" class="st0">
			<path id="Path_290" class="st1" d="M28,17.3c-0.8-1.4-0.8-3.1,0-4.5l0.3-0.4c0.7-0.9,0.5-2.2-0.3-2.8c-0.2-0.2-0.4-0.3-0.7-0.4
                           L26.8,9c-1.5-0.6-2.5-2-2.7-3.7l0-0.5c0-1.1-0.8-2.1-2-2.1c-0.3,0-0.5,0-0.8,0.1l-0.5,0.1c-1.6,0.4-3.3-0.2-4.3-1.4l-0.3-0.4
                           c-0.6-0.9-1.9-1.2-2.8-0.5c-0.2,0.1-0.4,0.3-0.5,0.5l-0.3,0.4c-1.1,1.2-2.7,1.8-4.3,1.4L7.7,2.8C6.6,2.4,5.5,3,5.1,4
                           C5,4.3,5,4.5,5,4.8l0,0.5C4.8,6.9,3.8,8.3,2.3,9L1.8,9.2c-1.1,0.3-1.7,1.4-1.4,2.5c0.1,0.2,0.2,0.5,0.4,0.7l0.3,0.4
                           c0.8,1.4,0.8,3.1,0,4.5l-0.3,0.4c-0.7,0.9-0.5,2.2,0.3,2.8c0.2,0.2,0.4,0.3,0.7,0.4l0.5,0.2c1.5,0.6,2.5,2,2.7,3.7l0,0.5
                           c0,1.1,0.8,2.1,2,2.1c0.3,0,0.5,0,0.8-0.1l0.5-0.1c1.6-0.4,3.3,0.2,4.3,1.4l0.3,0.4c0.6,0.9,1.9,1.2,2.8,0.5
                           c0.2-0.1,0.4-0.3,0.5-0.5l0.3-0.4c1.1-1.2,2.7-1.8,4.3-1.4l0.5,0.1c1.1,0.4,2.2-0.2,2.6-1.2c0.1-0.2,0.1-0.5,0.1-0.8l0-0.5
                           c0.1-1.6,1.2-3,2.7-3.7l0.5-0.2c1.1-0.3,1.7-1.4,1.4-2.5c-0.1-0.3-0.2-0.5-0.4-0.7L28,17.3z M14.5,24.8c-5.4,0-9.7-4.3-9.7-9.7
                           s4.3-9.7,9.7-9.7c5.4,0,9.7,4.3,9.7,9.7C24.2,20.4,19.9,24.7,14.5,24.8" />
			<path id="Path_291" class="st1" d="M23,25c-0.5,1-1.5,1.7-2.7,1.7l0,0c-0.3,0-0.7-0.1-1-0.2l-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1
                           c-0.6,0-1.2,0.3-1.6,0.8l-0.3,0.4c-0.4,0.6-0.9,1-1.6,1.3v6.3l8,4.5L23,25z" />
			<path id="Path_292" class="st1"
				d="M11.9,27.6l-0.2-0.4c-0.4-0.5-0.9-0.8-1.5-0.8c-0.1,0-0.2,0-0.4,0.1l-0.4,0.1
                           c-0.3,0.1-0.6,0.2-0.9,0.2c-1.1,0-2.1-0.7-2.5-1.7v14.6l7.4-4.6v-6.2C12.8,28.6,12.3,28.2,11.9,27.6" />
			<path id="Path_293" class="st1"
				d="M14.5,7.1c-4.4,0-8,3.6-8,8s3.6,8,8,8c4.4,0,8-3.6,8-8C22.5,10.6,18.9,7.1,14.5,7.1
                           C14.5,7.1,14.5,7.1,14.5,7.1 M13.9,17.4h-1.7v-5h1.7L13.9,17.4z M16.8,17.4h-1.7v-5h1.7V17.4z" />
		</g>
	</g>
</svg>