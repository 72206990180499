import { Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { LoadingService } from '@core/shared';
import { HubPageService } from '@core/private/hubpage/services/hub-page.service';

@UntilDestroy()
@Component({
    selector: 'app-hub-message-page',
    templateUrl: 'message-page.component.html'
})
export class MessagePageComponent implements OnChanges {
    @Input() componentId: string;
    @Input() payload: any;
    @Input() culture: string;

    message: any;

    constructor(private hubPageService: HubPageService, public loadingService: LoadingService) { }

    ngOnChanges() {
        let data = { item: JSON.stringify(this.payload) };       
        this.loadingService.doLoading(this.hubPageService.getHubComponent(this.componentId, this.culture, data), this, "message")
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.message = response;
            });
    }
}