import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { forkJoin, Observable, OperatorFunction, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter, startWith } from 'rxjs/operators';
import { browserRefresh } from '@app/app.component';
import moment from 'moment';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { InvoiceClaimService } from '../services/invoice-claim.service';
import { SharedService, LoadingService } from '@core/shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faFilter, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { SearchComponent } from '../invoice-claim-modal/search/search.component';


@UntilDestroy()
@Component({
  selector: 'app-invoice-claim-resubmission',
  templateUrl: './invoice-claim-resubmission.component.html',
  styleUrls: ['./invoice-claim-resubmission.component.scss']
})
export class InvoiceClaimResubmissionComponent implements OnInit, OnDestroy {

  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;
  browserRefresh: boolean;
  selectedCulture: any;
  public faFilter = faFilter;
  public faDelete = faTrashAlt;
  claimFormId: string;
  invoiceId: string;
  claimForm: any;
  attributesDetails: any[] = [];
  lineItemAttributesDetails: any[] = [];
  products: any[] = [];
  invoiceSubmissionForm: FormGroup;
  success: boolean;
  submitted: boolean;
  maxSaleDate: any;
  minSaleDate: any;
  extensions: string[] = [];
  fileErrorMessage: string;
  files: any[] = [];
  uploadedFiles: string[] = [];
  deletedFiles: string[] = [];
  duplicateError: boolean = false;
  invoice: any;
  organisations: any[] = [];
  @ViewChild('fileUpload') fileUpload: ElementRef;

  instructions: string;
  helpTitle: string;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private invoiceClaimService: InvoiceClaimService,
    private sharedService: SharedService, public loadingService: LoadingService, private translate: TranslateService,
    private modal: NgbModal) { }

  ngOnInit(): void {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      this.selectedCulture = localStorage.getItem('culture');
      if (this.claimFormId != this.route.snapshot.params.id || this.invoiceId != this.route.snapshot.params.invoiceId) {
        this.browserRefresh = browserRefresh;
        this.init();
      }
    });

    this.languageChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedCulture = localStorage.getItem('culture');
      if (!this.browserRefresh)
        this.init();
      else
        this.browserRefresh = false;
    });
  }

  init() {
    let todayDate = new Date();
    this.maxSaleDate = { year: todayDate.getFullYear(), month: todayDate.getMonth() + 1, day: todayDate.getDate() };
    this.claimFormId = this.route.snapshot.params.id;
    this.invoiceId = this.route.snapshot.params.invoiceId;
    let claimConfiguration = this.sharedService.getClaimConfiguration(this.claimFormId);
    let invoiceClaimFields = this.invoiceClaimService.getInvoiceClaimFields(this.claimFormId);
    let invoiceDetails = this.invoiceClaimService.getInvoiceDetails(this.invoiceId)
    forkJoin([claimConfiguration, invoiceClaimFields, invoiceDetails]).subscribe(response => {
      this.claimForm = response[0];
      this.attributesDetails = response[1].headerFields;
      this.lineItemAttributesDetails = response[1].lineItemFields;
      this.invoice = response[2];
      this.uploadedFiles = this.invoice.uploadedFiles;
      this.setupUserGuide(this.claimForm);

      this.extensions = this.claimForm.fileTypes.split(",");
      let date = new Date();
      let minDate = new Date(date.setDate(date.getDate() - this.claimForm.daysAllowedToClaimSale));
      this.minSaleDate = { year: minDate.getFullYear(), month: minDate.getMonth() + 1, day: minDate.getDate() };
      this.getProducts(this.claimForm.productMasterId);
      let organisationAttribute = this.attributesDetails.find(x => x.destination  == "Organisation");
      if (organisationAttribute)
        this.getEligibleOrganisation();
    });
  }

  openAdvanceFilterModal(index: any) {
    const filterModal = this.modal.open(SearchComponent, { centered: true });
    filterModal.componentInstance.productMasterId = this.claimForm.productMasterId;
    filterModal.componentInstance.claimFormId = this.claimFormId;
    filterModal.result.then((res) => {
      if (typeof res == "object") {
        this.invoiceSubmissionForm.get('salesLineItems')['controls'][index].controls.productName.setValue(res.externalIdentifier);
      }
    });
  }

  setupUserGuide(claimConfiguration) {
    this.instructions = '';
    claimConfiguration.instructions.forEach(instruction => {
      if (instruction.instruction && instruction.culture === this.selectedCulture) {
        this.instructions = instruction.instruction;
        this.helpTitle = instruction.title;
      }
    });
  }


  getProducts(productMasterId) {
    this.invoiceClaimService.getProducts(productMasterId, this.claimFormId).subscribe(response => {
      this.products = response;
      if (this.invoice)
        this.loadForm();
    })
  }

  getEligibleOrganisation() {
    this.invoiceClaimService.getEligibleOrganisation(this.claimFormId).subscribe(response => {
      this.organisations = response;
    });
  }

  loadForm() {
    let invoiceDate = moment(new Date(this.invoice.dateOfSale)).format("DD-MM-YYYY");
    let formData = {
      id: this.invoice.id,
      claimConfigurationId: this.claimFormId,
      invoiceNumber: [{ value: this.invoice.invoiceNumber, disabled: this.invoice.disableInvoice }, Validators.required],
      dateOfSale: [invoiceDate, Validators.required],
      attributes: this.fb.group({}),
      salesLineItems: this.fb.array([])
    };
    this.attributesDetails.forEach(attribute => {
      let validators: ValidatorFn[] = [];
      let attributeValidators;

      if (attribute.required)
        validators.push(Validators.required);

      if (attribute.dataType.name === 'Integer')
        attributeValidators.push(Validators.pattern("/^[-+]?\d+$/"));
      else if (attribute.dataType.name === 'Decimal')
        attributeValidators.push(Validators.pattern("/^(?!0\d|$)\d*(\.\d{1,4})?$/"));

      if (validators.length == 0)
        validators.push(Validators.nullValidator);


      let headerAttribute = this.invoice.attributesDto.find(x => x.name == attribute.source);
      formData.attributes.addControl(attribute.destination, new FormControl(headerAttribute ? headerAttribute.valueAsString : null, Validators.compose(validators)));
    });
    this.invoiceSubmissionForm = this.fb.group(formData);
    for (let lineItem of this.invoice.salesLineItems) {
      let product = this.products.find(x => x.id == lineItem.product);
      this.addNewLineItem(lineItem.lineItemId, product?.externalIdentifier, lineItem.quantity, lineItem.value, lineItem.additionalAttributesDto);
    }

  }

  addNewLineItem(id?, product?, quantity?, value?, additionalAttributes?) {

    const salesLineItems = this.invoiceSubmissionForm.get('salesLineItems') as FormArray;
    let lineItemGroup = this.fb.group({
      lineItemId: id,
      productName: new FormControl(product, [Validators.compose([Validators.required, this.validateProduct.bind(this)])]),
      quantity: new FormControl(quantity, this.getQuantityValidation()),
      value: new FormControl(value, this.getValueValidation()),
      attributeJson: new FormControl('{}'),
      additionalAttributes: this.fb.group({})
    });
    this.lineItemAttributesDetails.forEach(attribute => {
      let validators;
      if (attribute.dataType.name === 'Integer')
        validators = attribute.required ? Validators.compose([Validators.required, Validators.pattern("/^[-+]?\d+$/")]) : Validators.pattern("/^[-+]?\d+$/");
      else if (attribute.dataType.name === 'Decimal')
        validators = attribute.required ? Validators.compose([Validators.required, Validators.pattern("/^(?!0\d|$)\d*(\.\d{1,4})?$/")]) : Validators.pattern("/^(?!0\d|$)\d*(\.\d{1,4})?$/");
      else
        validators = attribute.required ? Validators.required : null;

      let additionalAttribute = additionalAttributes ? additionalAttributes.find(x => x.name == attribute.source) : null;
      (<FormGroup>lineItemGroup.controls.additionalAttributes).addControl(attribute.destination, new FormControl(additionalAttribute ? additionalAttribute.valueAsString : null, validators));
    });
    salesLineItems.push(lineItemGroup);
  }

  getQuantityValidation() {
    let validators = null;
    if (this.claimForm.isQuantityEnabled) {
      validators = Validators.compose([Validators.required, Validators.min(1), Validators.max(10000000)])
    }
    return validators;
  }

  getValueValidation() {
    let validators = null;
    if (this.claimForm.isValueEnabled) {
      validators = Validators.compose([Validators.required, Validators.min(1), Validators.max(10000000)])
    }
    return validators;
  }

  validateProduct(fieldControl: FormControl) {
    if (!fieldControl.value) {
      return null;
    }
    let product = this.products.find(p => p.externalIdentifier.toLowerCase() === fieldControl.value.toLowerCase());
    return product ? null : {
      notEqual: true
    };
  }

  checkAttributesValidation(key) {
    return this.invoiceSubmissionForm.get(`attributes.${key}`).errors;
  }

  deleteLineItem(index: number) {
    const formSalesLineItems = this.invoiceSubmissionForm.get('salesLineItems') as FormArray;
    formSalesLineItems.removeAt(index)
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(debounceTime(50), distinctUntilChanged(), map(term => term.length < 2 ? []
      : this.products.map(x => x.externalIdentifier).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  organisationSearch: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(debounceTime(50), distinctUntilChanged(), map(term => term.length < 2 ? []
      : this.organisations.filter(v => v.organisationCode.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.organisationName.toLowerCase().indexOf(term.toLowerCase()) > -1).map(x => x.organisationName).slice(0, 10))
    )

  onSubmit() {
    this.duplicateError = false;
    this.submitted = true;
    if ((this.files.length > 0 || this.uploadedFiles.length > 0) && !this.checkFileValidation() && this.invoiceSubmissionForm.valid) {
      let payload = this.invoiceSubmissionForm.getRawValue();
      if (payload.claimConfigurationId == null) {
        payload.claimConfigurationId = this.claimFormId;
      }
      let attributes = []
      this.attributesDetails.forEach(x => {
        let attribute = {};
        attribute['name'] = x.source;
        attribute['dataType'] = x.dataType;
        attribute['sortOrder'] = x.sortOrder;
        attribute['valueAsString'] = x.dataType.name === 'Date' ? moment(payload.attributes[x.destination], "DD-MM-YYYY").format("YYYY-MM-DD") + "T00:00:00" : payload.attributes[x.destination];
        attribute['isKey'] = x.isKey;
        attributes.push(attribute);
      });

      delete payload.attributes;
      payload['attributes'] = attributes;
      payload.salesLineItems = this.processLineItems(payload);
      payload.dateOfSale = moment(payload.dateOfSale, "DD-MM-YYYY").format("YYYY-MM-DD") + "T00:00:00";

      this.loadingService.doLoading(this.invoiceClaimService.invoiceReSubmission(payload), this)
        .pipe(untilDestroyed(this)).subscribe({
          next: () => {
            this.uploadInvoices(this.invoiceId);
          },
          error: () => {
            this.duplicateError = true;
          }
        });
    }
  }

  processLineItems(payload) {
    payload.salesLineItems.forEach(item => {
      let product = this.products.find(p => p.externalIdentifier.toLowerCase() === item.productName.toLowerCase());
      if (product)
        item.product = product.id;
      if (item.quantity === null)
        item.quantity = 0;
      if (item.value === null)
        item.value = 0;

      let additionalAttributes = [];
      this.lineItemAttributesDetails.forEach(x => {
        let additionalAttribute = {};
        additionalAttribute['name'] = x.source;
        additionalAttribute['dataType'] = x.dataType;
        additionalAttribute['sortOrder'] = x.sortOrder;
        additionalAttribute['valueAsString'] = x.dataType.name === 'Date' ? moment(item.additionalAttributes[x.destination], "DD-MM-YYYY").format("YYYY-MM-DD") + "T00:00:00" : item.additionalAttributes[x.destination];
        additionalAttribute['isKey'] = x.isKey;
        additionalAttributes.push(additionalAttribute);
      });

      delete item.additionalAttributes;
      item['additionalAttributes'] = additionalAttributes;
    });

    return payload.salesLineItems;
  }

  uploadInvoices(invoiceId) {
    this.loadingService.doLoading(this.invoiceClaimService.reuploadInvoices(invoiceId, this.files, this.deletedFiles), this)
      .pipe(untilDestroyed(this)).subscribe({
        next: () => {
          this.clearForm();
          let isMultiview = window.location.href.includes('multiview');
          if (isMultiview)
            this.router.navigateByUrl(`/multiview/${this.route.parent.snapshot.params.menuId}/claims/invoiceclaimhistory/${this.claimFormId}`);
          else
            this.router.navigateByUrl(`/invoice/claims/invoiceclaimhistory/${this.claimFormId}`);
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 6000);
          this.submitted = false;
        },
        error: () => {
          this.submitted = false;
        }
      });
  }

  fileHandler(files: any[]) {
    this.fileErrorMessage = null;
    if (files.length > 0) {
      files.forEach(file => {
        if (this.files.find(x => x.name == file.name) == null)
          this.files.push(file);
      });
    }
    if (this.fileUpload.nativeElement)
      this.fileUpload.nativeElement.value = null;
  }

  removeFile(file) {
    let index = this.files.indexOf(file);
    if (index != -1)
      this.files.splice(index, 1);
  }

  removeUploadedFile(index, file) {
    this.uploadedFiles.splice(index, 1);
    this.deletedFiles.push(file);
  }

  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  checkFileValidation() {
    this.fileErrorMessage = null;
    let hasError = false;
    for (let file of this.files) {
      if (hasError) break;

      let extension = this.getExtension(file.name);
      if (!this.extensions.includes(`.${extension}`)) {
        hasError = true;
        this.fileErrorMessage = this.translate.instant('InvoiceClaimPage.InvoiceClaimSubmission.FileTypeInvalid');
        break;
      }

      if (this.files.length > this.claimForm.maxNoOfFiles) {
        hasError = true;
        this.translate.get('InvoiceClaimPage.InvoiceClaimSubmission.FileNumberInvalid', { number: this.claimForm.maxNoOfFiles }).subscribe((res) => {
          this.fileErrorMessage = res;
        });
        break;
      }
      let sizeConverter;
      if (this.claimForm.fileSizeMetrics == "mb") {
        sizeConverter = 1023 * 1023;
      }
      else {
        sizeConverter = 1023;
      }

      if ((file.size / sizeConverter) > this.claimForm.maxFileSize) {
        hasError = true;
        this.translate.get('InvoiceClaimPage.InvoiceClaimSubmission.FileSizeInvalid', { size: this.claimForm.maxFileSize }).subscribe((res) => {
          this.fileErrorMessage = res;
        });
        break;
      }
    }
    return hasError;
  }

  clearForm() {
    this.invoiceSubmissionForm.reset();
    const formSalesLineItems = this.invoiceSubmissionForm.get('salesLineItems') as FormArray;
    while (formSalesLineItems.length !== 0) {
      formSalesLineItems.removeAt(0)
    }
    this.addNewLineItem();
    this.files = [];
    if (this.fileUpload.nativeElement)
      this.fileUpload.nativeElement.value = null;
  }

  isShownDiv: boolean = false; // hidden by default
  toggleShows() {
    this.isShownDiv = !this.isShownDiv;
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe()
  }

}
