<div id="clockdiv">
    <p class="text-left">
        <span *ngIf="!showOnlyMinutes"> <span class="days">{{daysSpan}}</span> {{'MyPerformance.singleEmptySpace' |
            translate}}
            <span translate="MyPerformance.Days"></span> :
        </span>
        <span *ngIf="!showOnlyMinutes"> <span class="hours">{{hoursSpan}}</span> {{'MyPerformance.singleEmptySpace'
            | translate}}
            <span translate="MyPerformance.Hours"></span> :
        </span>
        <span> <span class="minutes">{{minutesSpan}}</span> {{'MyPerformance.singleEmptySpace' | translate}} <span
                translate="MyPerformance.Minutes"></span> : </span>
        <span> <span class="seconds">{{secondsSpan}}</span> {{'MyPerformance.singleEmptySpace' | translate}} <span
                translate="MyPerformance.Seconds"></span></span>
    </p>
</div>