<div [appIsLoading]="loadingService.isLoading(this)">
    <div *ngIf="summaryDetails && summary">
        <p class="side-header"> {{summaryDetails.displayName}}</p>
        <div *ngIf="viewType == 'Tile'">
            <div *ngIf="summaryDetails.displayContent" [innerHtml]="summaryDetails.templiateContent | sanitizeHtml">
            </div>
            <app-point-summary [summaryDetails]="summaryDetails" [summary]="summary"></app-point-summary>
            <div *ngIf="summaryDetails.pointsStatement">
                <a routerLink="/pointsstatement/view/{{summaryDetails.id}}">{{summaryDetails.pointsStatementLink}}</a>
            </div>
        </div>
        <div *ngIf="viewType == 'Table'" class="pointSummaryTableView">
            <table class="mt-4" aria-describedby="Point Summary">
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
                <tr>
                    <td>{{summaryDetails.earnedPointLable}}</td>
                    <td>{{summary.totalEarned}}</td>
                </tr>
                <tr>
                    <td>{{summaryDetails.redeemedPointsLable}}</td>
                    <td>{{summary.totalRedeemed}}</td>
                </tr>
                <tr>
                    <td>{{summaryDetails.avaliableBalanceLable}}</td>
                    <td>{{summary.endingBalance}}</td>
                </tr>
                <tr *ngIf="isFIFOEnabled && summaryDetails?.isToDisplayExpiringPoints">
                    <td>{{summaryDetails.expiringPointsLable}}</td>
                    <td>{{summary.expiringPoints}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>