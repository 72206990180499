import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from "@ngneat/until-destroy";
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-hub-claim-home',
    templateUrl: 'claim-home.component.html',
    styleUrls: ['claim-home.component.scss'],
})

export class ClaimHomeComponent implements OnInit {
    @Input() payload: any;
    public currentUser = "";
    private onDestroy$ = new Subject<void>();
    constructor(private store: Store<fromStore.PlatformState>, private router: Router) {
    }

    ngOnInit() {
        this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            if (state.user && state.user.Firstname) {
                this.currentUser = state.user.Firstname + '!';
                if (state.user.Lastname)
                    this.currentUser = state.user.Firstname + ' ' + state.user.Lastname + '!';
            }
        });
    }

    redirectTo() {
        if (this.payload && this.payload.redirectionLink)
            this.router.navigateByUrl(this.payload.redirectionLink);
    }
}