<div class="modal-header">
    <h4 class="modal-title">{{'InvoiceClaimPage.ChallengeModal.ChallengeTitle' | translate }}</h4>
</div>
<form [formGroup]="formChallengeReason" (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <div class="form-group">
            <label>{{ 'InvoiceClaimPage.ChallengeModal.ChallengeReason' | translate }}</label>
            <textarea class="form-control" rows="5" formControlName="reason"></textarea>           
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-light" [disabled]="!formChallengeReason.controls.reason.valid">{{ 'Shared.Confirmation.OK' | translate }}</button>
        <button class="btn btn-default" type="button" (click)="cancel()">{{'Shared.Confirmation.Cancel' | translate }}</button>
    </div>
</form>