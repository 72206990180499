<div class="home-page">
    <div class="bg-color4 home-page-bg">
        <div class="content-width home-point-gallery-section">
            <div class="flex-1 home-page-slide">
                <div *ngIf="homeHubPage && arrayHasIndex(homeHubPage, 0)" class="section-one">
                    <ngx-slick-carousel class="carousel" [config]="slideConfigHub" #slickModal="slick-carousel">
                        <div ngxSlickItem *ngFor="let item of homeHubPage[0].Items" class="slide">
                            <div [ngSwitch]="item.type">
                                <app-hub-cms *ngSwitchCase="'cms'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-cms>

                                <app-hub-message-page *ngSwitchCase="'message'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-message-page>

                                <app-hub-myaward-page *ngSwitchCase="'myawards'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-myaward-page>

                                <app-hub-league-page *ngSwitchCase="'league'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-league-page>

                                <app-hub-participant-goal-quest *ngSwitchCase="'goalquest'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-participant-goal-quest>

                                <app-hub-points-summary *ngSwitchCase="'pointssummary'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-points-summary>

                                <app-hub-manager-goal-quest *ngSwitchCase="'managergoalquest'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-manager-goal-quest>

                                <app-hub-claim *ngSwitchCase="'claimform'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item">
                                </app-hub-claim>

                                <app-hub-performance-page *ngSwitchCase="'promotion'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-performance-page>

                                <div *ngSwitchDefault>{{item.type}}</div>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>

                <div *ngIf="homeHubPage && arrayHasIndex(homeHubPage, 1)" class="section-two">
                    <ngx-slick-carousel class="carousel" [config]="slideConfigHub" #slickModal="slick-carousel">
                        <div ngxSlickItem *ngFor="let item of homeHubPage[1].Items" class="slide">
                            <div [ngSwitch]="item.type">

                                <app-hub-cms *ngSwitchCase="'cms'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-cms>

                                <app-hub-message-page *ngSwitchCase="'message'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-message-page>

                                <app-hub-myaward-page *ngSwitchCase="'myawards'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-myaward-page>

                                <app-hub-league-page *ngSwitchCase="'league'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-league-page>

                                <app-hub-participant-goal-quest *ngSwitchCase="'goalquest'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-participant-goal-quest>

                                <app-hub-points-summary *ngSwitchCase="'pointssummary'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-points-summary>

                                <app-hub-manager-goal-quest *ngSwitchCase="'managergoalquest'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-manager-goal-quest>

                                <app-hub-claim *ngSwitchCase="'claimform'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item">
                                </app-hub-claim>

                                <app-hub-performance-page *ngSwitchCase="'promotion'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-performance-page>

                                <div *ngSwitchDefault>{{item.type}}</div>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>

                <div
                    *ngIf="homeHubPage && !(arrayHasIndex(homeHubPage, 0) || arrayHasIndex(homeHubPage, 1) || arrayHasIndex(homeHubPage, 2))">
                    No Records Found</div>
            </div>

            <div class="home-left">
                <cs-profile-point-card></cs-profile-point-card>
                <cs-most-redeem-products [singleView]="true"></cs-most-redeem-products>
            </div>

        </div>

        <div class="tab-body">
            <div class="container-fluid magin-left-right">
                <div *ngIf="homeHubPage && arrayHasIndex(homeHubPage, 2)">
                    <div class="tab-center">
                        <div class="content-width high">
                            <nav ngbNav #nav="ngbNav" class="nav-tabs nav-fill">
                                <ng-container ngbNavItem *ngFor="let item of homeHubPage[2].Items">
                                    <a ngbNavLink>
                                        <cs-dashboard-svg [tabName]="item.type"></cs-dashboard-svg>
                                        {{item.tabDisplayName | uppercase}}
                                    </a>
                                    <ng-template ngbNavContent>
                                        <div [ngSwitch]="item.type">
                                            <div class="both"></div>
                                            <app-hub-cms *ngSwitchCase="'cms'" [componentId]="item.selectedItem.id"
                                                [culture]="selectedCulture" [payload]="item"></app-hub-cms>

                                            <app-hub-message-page *ngSwitchCase="'message'"
                                                [componentId]="item.selectedItem.id" [culture]="selectedCulture"
                                                [payload]="item"></app-hub-message-page>

                                            <app-hub-myaward-page *ngSwitchCase="'myawards'"
                                                [componentId]="item.selectedItem.id" [culture]="selectedCulture"
                                                [payload]="item"></app-hub-myaward-page>

                                            <app-hub-my-rank *ngSwitchCase="'league'"
                                                [componentId]="item.selectedItem.id" [culture]="selectedCulture"
                                                [payload]="item"></app-hub-my-rank>

                                            <app-hub-participant-goal-quest *ngSwitchCase="'goalquest'"
                                                [componentId]="item.selectedItem.id" [culture]="selectedCulture"
                                                [payload]="item"></app-hub-participant-goal-quest>

                                            <app-hub-points-summary *ngSwitchCase="'pointssummary'"
                                                [componentId]="item.selectedItem.id" [culture]="selectedCulture"
                                                [payload]="item"></app-hub-points-summary>

                                            <app-hub-manager-goal-quest *ngSwitchCase="'managergoalquest'"
                                                [componentId]="item.selectedItem.id" [culture]="selectedCulture"
                                                [payload]="item">
                                            </app-hub-manager-goal-quest>

                                            <app-hub-claim-home *ngSwitchCase="'claimform'"
                                                [componentId]="item.selectedItem.id" [culture]="selectedCulture"
                                                [payload]="item">
                                            </app-hub-claim-home>

                                            <app-hub-performance-page *ngSwitchCase="'promotion'"
                                                [componentId]="item.selectedItem.id" [culture]="selectedCulture"
                                                [payload]="item">
                                            </app-hub-performance-page>

                                            <div *ngSwitchDefault></div>
                                            <div class="both"></div>
                                        </div>
                                        <div class="both"></div>
                                    </ng-template>
                                </ng-container>
                                <ng-container ngbNavItem *ngIf="isGameEnabled && hasValidGameToken">
                                    <a ngbNavLink>
                                        <cs-dashboard-svg [tabName]="'gamearcade'"></cs-dashboard-svg>
                                        {{'HomePage.GameArcade' | translate}}</a>
                                    <ng-template ngbNavContent>
                                        <app-hub-game-arcade-page [tokenId]="tokenId" [ssoUrl]="ssoUrl"
                                            [showNavigationLink]="true">
                                        </app-hub-game-arcade-page>
                                        <div class="both"></div>
                                    </ng-template>
                                </ng-container>
                            </nav>
                        </div>
                    </div>
                    <div class="container-fluid body-tab-content tabwidget-continer-color">
                        <div class="content-width tab-continer-color">
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
        <div class="bg-color3" *ngIf="!hideMultiView">
            <div class="content-width home-point-gallery-section mb-0">
                <div class="multiView-redeem-product">
                    <p class="text-highlight">{{'MultiviewReedem.Hey' | translate}} {{currentUser}} {{'MultiviewReedem.Msg_hard_earned' | translate}}</p>
                    <p class="mb-0">{{'MultiviewReedem.Msg1' | translate}}</p>
                    <p>{{'MultiviewReedem.Msg2' | translate}}</p>
                    <div class="mt-40">
                        <img alt="" src="assets/images/handpick.png"/>
                        <p class="text-highlight-20 mb-0 pl-3">{{'MultiviewReedem.HandPicked' | translate}}</p>
                    </div>
                    <cs-most-redeem-products (emptyProductEvent)="hideMultiViewRedeem()"></cs-most-redeem-products>
                </div>
            </div>
        </div>
    </div>
</div>