import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@core/shared';
import { MyPerformanceService } from './services/my-performance.service';
import { MyPerformanceComponent } from './my-performance/my-performance.component';
import { RouterModule } from '@angular/router';
import { PerformanceComponent } from './performance.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        SharedModule
    ],
    declarations: [PerformanceComponent,MyPerformanceComponent],
    providers: [
        MyPerformanceService
    ],
})
export class MyPerformanceModule { }
