import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { SearchCriteria } from '@core/shared/models/search-criteria';
import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

import { UrlBuilder, GET_MY_AWARDS } from '@core/core-api-variables';
import { HttpService } from '@core/shared';

@Injectable()
export class MyAwardsService {
    constructor(public http: HttpService,private paramsBuilderService : ParamsBuilderService) { }

    getMyAwards(awardsPageId:string,searchCriteria: SearchCriteria) {
        
        let params = this.paramsBuilderService.buildParams(searchCriteria);
        let data = { awardsPageId,params }        
        let url = UrlBuilder(GET_MY_AWARDS, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }
}