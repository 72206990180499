export class Authentication {
    Id: string;
    Username: string;
    Firstname: string;
    Lastname: string;
    Culture: string;
    PrimaryOrganisationId: string;
    PrimaryOrganisationCode: string;
    PrimaryOrganisationName: string;
    tier: string;
    UserCookiePolicyAcceptedDetails: any;

    constructor(id: string, username: string, firstname: string, lastname: string, culture: string, primaryOrganisationId: string, primaryOrganisationCode: string,
        primaryOrganisationName: string, tier: string, userCookiePolicyAcceptedDetails: any) {
        this.Id = id;
        this.Username = username;
        this.Firstname = firstname;
        this.Lastname = lastname;
        this.Culture = culture;
        this.PrimaryOrganisationId = primaryOrganisationId;
        this.PrimaryOrganisationCode = primaryOrganisationCode;
        this.PrimaryOrganisationName = primaryOrganisationName;
        this.tier = tier;
        this.UserCookiePolicyAcceptedDetails = userCookiePolicyAcceptedDetails
    }
}