<div class="prelogin-body">
    <div class="content-width">
        <div class="prelogin-form-card-container">
            <form [formGroup]="formResetPassword" class="common-prelogin-form round-card shadowed bg-color2"
            (ngSubmit)="changePassword(formResetPassword)" >
                <div class="margin-bottom-20 text-center">
                    <body-text class="medium-size-plus-4">{{'ResetPassword.Title' | translate}}</body-text>
                </div>
                <div class="margin-bottom-10">
                    <form-input controlName="password"  inputType="password" placeHolder="{{'ResetPassword.NewPassword' | translate}}">
                    </form-input>
                    <div class="help-block text-danger"
                        *ngIf="!formResetPassword.controls.password.valid && (formResetPassword.controls.password.touched )">
                        <span [hidden]="!formResetPassword.controls['password'].hasError('required')"
                            translate="ResetPassword.PasswordRequired"></span>
                    </div>
                </div>
                <div class="margin-bottom-10">
                    <form-input controlName="confirmPassword"  inputType="password" placeHolder="{{'ResetPassword.ConfirmPassword' | translate}}">
                    </form-input>
                    <div class="help-block text-danger"
                        *ngIf="!formResetPassword.controls.confirmPassword.valid && (formResetPassword.controls.confirmPassword.touched )">
                        <span [hidden]="!formResetPassword.controls['confirmPassword'].hasError('required')"
                            translate="ParticipantLogin.PasswordRequired"></span>
                    </div>
                </div>
                <div class="margin-bottom-20 text-center">
                    <a class="link-no-decor" [routerLink]="['/']">
                        <body-text class="primary-color medium-size">Existing User? Click here to log in</body-text>
                    </a>
                </div>

                <div class="margin-bottom-10 margin-top-20">
                    <form-button text="{{ 'ForgotPassword.ResetPassword' | translate }}" size="stretch"
                        buttonType="submit"></form-button>
                </div>
            </form>
        </div>
    </div>
</div>