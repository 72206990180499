import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'cs-claim-error',
    templateUrl: 'claim-error.component.html'
})

export class ClaimErrorComponent  {
    @Input() errorLabel;

    constructor(private activeModal: NgbActiveModal) { }   

    ok() {
        this.activeModal.dismiss();
    }
}