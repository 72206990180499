import { Component } from '@angular/core';

@Component({
    selector: 'app-not-found',
    template: `
    <div>
        <div class="row">
            <div class="col-sm-12">
                <h1>404 ERROR</h1>
                <h4>Page not found</h4>
                <p>The requested URL was not found on this server</p>
            </div>
        </div>
    </div>`
})

export class NotFoundComponent {
    constructor() { }
}