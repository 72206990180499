<input type="hidden" id="tokenId" name="tokenId" [value]="tokenId" />
<input type="hidden" id="locale" name="locale" [value]="" />
<input type="hidden" id="ssoEndpoint" name="ssoEndpoint" [value]="ssoUrl" />
<div *ngIf="!showNavigationLink">
    <div class="module-liner">                                                                            
        <div class="module-content">                                                   
            <div id="arcade-widget-container"></div>
        </div>
    </div>
    <div class="both"></div>
</div>
<div *ngIf="showNavigationLink" class="grid grid-col-sm-100 grid-col-xs-100 grid-col-hd-25-custom-home-game grid-gap-35">
    <div></div>
    <div>
        <img alt="" src="/assets/images/home-game-banner-2.gif"/>
    </div>
    <div>
        <p class="userInfo">{{'Shared.Hey' | translate}} {{currentUser}}</p>
        <p>{{'HomePage.GameInfo' | translate}}</p>
        <div class="btn-group mt-5">
            <button type="button" class="btn btn-primary btn-WhiteBg-BrandColor" (click)="redirectTo()">
                <strong>{{'PageTitles.Games' | translate}}</strong>
                <span class="glyphicon glyphicon-menu-right pl-1"></span>
            </button>
        </div>
    </div>
    <div></div>
</div>