import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';

import { ArticlesComponent } from './articles.component';
import { MessagePageComponent } from './message-page/message-page.component';
import { MessageArticleComponent } from './message-article/message-article.component';

export const MessagePageRoutes: Routes = [
    {
        path: 'articles',
        component: ArticlesComponent,
        children: [
            {
                path: 'messagepage/view/:id',
                canActivate: [AuthGuard],
                component: MessagePageComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.MessagePage', isPreLogin: false }
            },
            {
                path: 'messagearticle/:messagePageId/view/:messageArticleId',
                canActivate: [AuthGuard],
                component: MessageArticleComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.MessageArticle', isPreLogin: false }
            }
        ]
    }
];