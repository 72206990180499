<div class="home-page">
  <div class="bg-color3 home-page-bg">
    <div class="content-width home-point-gallery-section" [appIsLoading]="loadingService.isLoading(this)">
      <div *ngIf="!showSuccess" class="bg-color2 p-5">
        <div class="contact-us-header">
          <div [innerHtml]="header"></div>
        </div>
        <div *ngIf="contactFormGroup" class="contact-form-section">
          <form (ngSubmit)="onSubmit()" [formGroup]="contactFormGroup">
            <div class="contact-wrapper">
              <div *ngFor="let contact of contacts">
                <app-form-builder [contact]="contact" [form]="contactFormGroup" [countries]="countries"
                  (onChange)="onChange($event)" *ngIf="
                    contact.type === 'FormField' || contact.type === 'Country'
                  "></app-form-builder>
              </div>
              <div *ngIf="showQueries">
                <label for="query">{{QueryName}}</label><sup>*</sup>
                <select #queryDropdown formControlName="query" name="query" id="" class="form-control">
                  <option value="" disabled>Select Query</option>
                  <option *ngFor="let query of queries" [ngValue]="query">
                    {{ query.translations[0].description }}
                  </option>
                </select>
                <div class="error-message" *ngIf="
                    !isValid('query') && contactFormGroup.controls.query.touched
                  ">
                  Query is required
                </div>
              </div>
            </div>
            <div class="margin-top-20" *ngIf="showQueries">
              <label for="" translate>{{'ContactUs.Message'}}</label><sup>*</sup>
              <textarea formControlName="message" name="" id="" cols="30" rows="10" class="form-control" (change)="
                  onChangeValue($event, contactFormGroup.controls.query.value.id)
                "></textarea>
              <div class="error-message" *ngIf="
                  !isValid('message') && contactFormGroup.controls.message.touched
                ">
                Message is required
              </div>
            </div>
            <div class="button-section mt-5">
              <button class="btn btn-primary customBtnSearch" type="submit">submit</button>
              <button class="btn btn-primary customBtnReset" (click)="resetForm()">
                reset
              </button>
            </div>
          </form>
        </div>
        <div class="contact-us-footer margin-bottom-20">
          <div [innerHtml]="footer"></div>
        </div>
      </div>
      <div *ngIf="showSuccess" class="success-container p-5">
        <h3>Submitted Successfully.</h3>
        <div>
          <a (click)="resubmit()" class="clickHere">Click here</a>
          to submit another request!
        </div>
      </div>
    </div>
  </div>
</div>