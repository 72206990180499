import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';
import { ApprovalComponent } from './approval.component';
import { UserRegistrationApprovalComponent } from './user-registration-approval/user-registration-approval.component';

export const RegistrationApprovalRoutes: Routes = [
    {
        path: 'userregistration',
        component: ApprovalComponent,
        children: [
            {
                path: 'approval',
                canActivate: [AuthGuard],
                component: UserRegistrationApprovalComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.UserRegistrationApproval', isPreLogin: false, isDashboard: true }
            }
        ]
    }
];