import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GET_CASH_REWARDS_SSO, SSO_CASH_REWARDS } from '@app/core/core-api-variables';
import { HttpService } from '@app/core/shared';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable()

export class CashrewardsService {

  constructor(public http: HttpService, public httpClient: HttpClient) {

  }

  getCashRewardsSsoDetails():Observable<any>{
    return this.http.get(GET_CASH_REWARDS_SSO);
  }
  //ssoCashRewards(data):Observable<any>{
  //  return this.httpClient.post(`${environment.cashBaseUrl}${SSO_CASH_REWARDS}`, data);
  //}
  sso(data):Observable<any>{
    return this.httpClient.post(`http://localhost:4000/api/sso`, data);
  }
}
