import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { browserRefresh } from '@app/app.component';
import { HttpImageService } from '@app/core/shared/services/http-img.service';
import { MessageService } from '@app/core/shared/services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter,map, pairwise, startWith } from 'rxjs/operators';
import { LegalPagesServices } from '../services/legalpages.services';

@Component({
    selector: 'app-legal-page',
    templateUrl: './legal-page.component.html'
})
export class LegalPageComponent implements OnInit {

    languageChangeSubscription: Subscription;
    navigationStartSubscription: Subscription;
    browserRefresh: boolean;
    public page: string;
    public selectedCulture;
    public content;
    public legalPage: string;
    constructor(
        public activatedRoute: ActivatedRoute,
        public legalPageService: LegalPagesServices,
        private imgService: HttpImageService,
        private messageService: MessageService,
        private translate: TranslateService,
        private router: Router, private titleService: Title) { 
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute.snapshot),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                })).subscribe((routeSnapshot: ActivatedRouteSnapshot) => {
                    this.translate.get(routeSnapshot.data.title).subscribe((res: string) => {
                        this.titleService.setTitle(res);
                    });
                });
        }

    ngOnInit() {
        this.activatedRoute.paramMap.subscribe(params => {
            this.legalPage = params.get('page');
            this.renderPage(params.get('page'));
        });

        this.navigationStartSubscription = this.router.events
            .pipe(
                filter((event) => event instanceof RouterEvent ),
                pairwise(),
                filter((events) => events[0] !== events[1]),
                startWith("Initial call")
            )
            .subscribe((res) => {
                this.browserRefresh = browserRefresh;
            });

        this.languageChangeSubscription = this.translate.onLangChange.subscribe(() => {
            this.selectedCulture = localStorage.getItem('culture');
            if (!this.browserRefresh)
                this.renderPage(this.legalPage);
            else
                this.browserRefresh = false;
        });
    }

    renderPage(page) {
        switch (page) {
            case "accessadvice": {
                this.page = "AccessAdvice";
                break;
            }
            case "cookies": {
                this.page = "Cookies";
                break;
            }
            case "privacypolicy": {
                this.page = "PrivacyPolicy";
                break;
            }
            case "termsofuse": {
                this.page = "TermsOfUse";
                break;
            }
            default: {
                //statements; 
                break;
            }
        }
        this.selectedCulture = localStorage.getItem('culture');
        if (this.page == 'Cookies' || this.page == 'PrivacyPolicy') {
            let policyType = this.page == 'Cookies' ? 'cookie' : 'privacy'
            this.legalPageService.getPolicyContentPage(policyType, this.selectedCulture).subscribe(response => {
                if (response == "" || response == undefined) {
                    response = ""
                    this.messageService.showError(this.translate.instant('LegalPage.NoContentConfigured'));
                }
                this.content = this.imgService.parseHtml(response);
            })
        }

    }
}