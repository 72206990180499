export class InvoiceClaimApprovalFilter {
    claimConfigurationId: string;
    sellingUser : string;
    Organisation : string;
    invoiceNumber: string;
    sku: string;
    status: string;
    fromSellingDate: string;
    toSellingDate: string;
    fromClaimDate: string;
    toClaimDate: string;
    selectedItems : any[];
    constructor(){}
}