<div [formGroup]="form">
  <label [attr.for]="contact.attribute">{{
    contact.translation.description
  }}</label><sup>*</sup>

  <div [ngSwitch]="contact.type">
    <input
      *ngSwitchCase="'FormField'"
      [formControlName]="contact.attribute"
      [id]="contact.attribute"
      [type]="'text'"
      class="form-control"
      [attr.disabled]="contact.disableElement == true ? true : null"
      (change)="onChangeValue($event, contact)"
    />
    <select
      [id]="contact.attribute"
      *ngSwitchCase="'Country'"
      [formControlName]="contact.attribute"
      class="form-control"
      (change)="onChangeValue($event, contact)"
    >
      <option value="" disabled>
        Select {{ contact.attribute }}
      </option>
      <option *ngFor="let opt of countries" [value]="opt.value">
        {{ opt.name }}
      </option>
    </select>
  </div>

  <div class="error-message" *ngIf="!isValid && isTouched">
    {{ contact.translation.description }} is required
  </div>
</div>
