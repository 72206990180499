<div [ngSwitch]="template" class="category-card-wrap">
    <div *ngSwitchCase="'theme1'" class="category-card" [class]="ratio"
        [style]="{'background-image': 'url(' + bgUrl + ')'}">
        <div class="descriptions">
            <div>
                <body-text class="super-heading5 font-light block text-center color2" [textContent]="text1"></body-text>
                <body-text class="block medium-size text-center color2" [textContent]="text2"></body-text>
                <div class="text-center margin-top-20" *ngIf="showButton">
                    <cs-redeem-now buttonTheme="theme3"></cs-redeem-now>
                </div>
            </div>
        </div>
    </div>
</div>