export class InvoiceClaimHistoryFilter {
    claimConfigurationId: string;
    invoiceNumber: string;
    sku: string;    
    status: string;
    fromSellingDate: string;
    toSellingDate: string;
    fromClaimDate: string;
    toClaimDate: string;
    constructor(){}

}