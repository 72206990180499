<div class="flex-1">
  <div class="claim-filter-section">
    <div ngbAccordion>
      <div
        ngbAccordionItem="claimfilter"
        #claimfilter="ngbAccordionItem"
        [collapsed]="false"
        class="padding-15"
      >
      <div ngbAccordionHeader>
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="accordion-header">
            {{ "QuizEvaluation.Description" | translate }}
          </h5>
          <button ngbAccordionToggle class="btn btn-link accordion">
            {{ !claimfilter.collapsed ? "-" : "+" }}
          </button>
        </div>
      </div>
        <div ngbAccordionCollapse>
          <div class="padding-left-0" ngbAccordionBody>
            <ng-template>
              <div class="grid grid-gap-40 grid-col-hd-50 grid-col-xs-100">
                <div class="margin-bottom-10">
                  <label>{{
                    "QuizEvaluation.Filter.UserName" | translate
                  }}</label>
                  <br />
                  <input
                    #userName
                    placeholder="User Name"
                    [(ngModel)]="filter.userName"
                    class="customField"
                  />
                </div>
                <div class="margin-bottom-10">
                  <label>{{
                    "QuizEvaluation.Filter.FirstName" | translate
                  }}</label>
                  <br />
                  <input
                    #firstName
                    placeholder="First Name"
                    [(ngModel)]="filter.firstName"
                    class="customField"
                  />
                </div>
              </div>
              <div class="grid grid-gap-40 grid-col-hd-50 grid-col-xs-100">
                <div class="margin-bottom-10">
                  <label>{{
                    "QuizEvaluation.Filter.LastName" | translate
                  }}</label>
                  <br />
                  <input
                    #lastName
                    placeholder="Last Name"
                    [(ngModel)]="filter.lastName"
                    class="customField"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center btn-align">
                  <button
                    class="btn btn-primary customBtnSearch"
                    (click)="onSearch()"
                  >
                    {{ "QuizEvaluation.SearchBtn" | translate }}
                  </button>
                  <button
                    class="btn btn-primary customBtnReset"
                    (click)="onClear()"
                  >
                    {{ "QuizEvaluation.ResetBtn" | translate }}
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-width">
    <h1 class="sub-pageHeader" id="myTable">
      {{ "QuizEvaluation.ListTitle" | translate }}
    </h1>
  </div>
  <div class="claim-filter-section">
    <div class="row" *ngIf="paginationDetails.totalNumberOfItems > 0">
      <div class="col-12 col-md-6">
        <div>
          <p>
            {{ "TableRecords.FoundTotalOf" | translate
            }}{{ paginationDetails.totalNumberOfItems
            }}{{ "TableRecords.matches" | translate }}
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6">        
          <cs-items-per-page
            class="col-sm-12"
            [paginationDetails]="paginationDetails"
            (onChangeEvent)="onChange($event)"
          ></cs-items-per-page>        
      </div>
    </div>

    <div
      class="table-width"
      #dataLoading="appIsLoading"
      [appIsLoading]="loadingService.isLoading(this)"
    >
      <table
        class="table table-striped paxQuizTable"
        aria-describedby="myTable"
        *ngIf="paginationDetails.totalNumberOfItems > 0"
      >
        <thead>
          <tr>
            <th
              class="cm_12 cm_bold"
              role="button"
              scope="col"
              (click)="onSort('userName')"
            >
              {{ "QuizEvaluation.Table.UserName" | translate }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('userName')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              (click)="onSort('firstName')"
              scope="col"
            >
              {{ "QuizEvaluation.Table.FirstName" | translate }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('firstName')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              scope="col"
              (click)="onSort('lastName')"
            >
              {{ "QuizEvaluation.Table.LastName" | translate }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('lastName')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              scope="col"
              (click)="onSort('numberOfAttemptsTaken')"
            >
              {{ "QuizEvaluation.Table.QuizAttemptsTaken" | translate }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('numberOfAttemptsTaken')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              scope="col"
              (click)="onSort('numberOfAttemptsCancelled')"
            >
              {{ "QuizEvaluation.Table.QuizAttemptsCancelled" | translate }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('numberOfAttemptsCancelled')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              (click)="onSort('numberOfAttemptsRemaining')"
              scope="col"
            >
              {{ "QuizEvaluation.Table.QuizAttemptsRemaining" | translate }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('numberOfAttemptsRemaining')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              scope="col"
              (click)="onSort('achivedScore')"
            >
              {{ "QuizEvaluation.Table.Score" | translate }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('achivedScore')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let quizAttempt of quizAttemptDetails" class="padding-10">
            <td>{{ quizAttempt.userName }}</td>
            <td>{{ quizAttempt.firstName }}</td>
            <td>{{ quizAttempt.lastName }}</td>
            <td>{{ quizAttempt.numberOfAttemptsTaken }}</td>
            <td>{{ quizAttempt.numberOfAttemptsCancelled }}</td>
            <td>{{ quizAttempt.numberOfAttemptsRemaining }}</td>
            <td>{{ quizAttempt.achivedScore }}</td>
            <td>
              <button
              class="btn-quiz"
              [disabled]="
                quizAttempt.quizEvaluated ||
                quizAttempt.numberOfAttemptsTaken == 0 ||
                quizAttempt.attemptStatus == 'Cancelled'
              "
              (click)="evaluate(quizAttempt)"
            >
              {{ "QuizEvaluation.Validate" | translate }}
            </button>
            </td>            
          </tr>
        </tbody>
      </table>
      <div
        class="quizEvaluationNoData alert alert-danger"
        *ngIf="
          !dataLoading.appIsLoading &&
          paginationDetails.totalNumberOfItems === 0
        "
      >
        {{ "CouponClaimPage.RecordNotFound" | translate }}
      </div>
    </div>
    <div class="row" *ngIf="paginationDetails.totalNumberOfItems !== 0">
      <div class="col-lg-12 pagination-center-Bottom">
        <cs-custom-pagination
          [paginationDetails]="paginationDetails"
          (onChangeEvent)="onChange($event)"
        >
        </cs-custom-pagination>
      </div>
    </div>
  </div>
</div>
