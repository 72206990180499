import { Component, Input } from '@angular/core';

@Component({
    selector: 'cs-shared-manager-goal-progress',
    templateUrl: './goal-progress.component.html'
})
export class SharedManagerGoalProgressComponent {
    @Input() isMinimumQualifierEnabled: boolean;
    @Input() goalAchievedUsers: any;
    @Input() goalAchievedDetails: any;
    @Input() endDate: string;

    constructor() { }

    calculateAchievedPercentage(selectedCount, achievedCount) {
        return (selectedCount && achievedCount) ? ((achievedCount / selectedCount) * 100) : 0;
    }
}