<div class="form-container">
    <div class="formGroup">
        <p>{{'UserRegistrationApproval.Content' | translate}}
        </p>
        <form>
            <div
                class="registration-form grid grid-gap-10 grid-col-md-33 grid-col-hd-33 grid-col-xxs-100 grid-col-xs-50 grid-col-sm-50 grid-col-lt-33">
                <div class="addContactControls remove-right-margin col-100">
                    <input placeholder="{{'UserRegistrationApproval.UsernameOrEmailOrMobile' | translate}}" class="form-control" name="username" [(ngModel)]="filter.username" />
                </div>

                <div class="addContactControls remove-right-margin col-100">
                    <input placeholder="{{'UserRegistrationApproval.Firstname' | translate}}" class="form-control" name="firstname"
                        [(ngModel)]="filter.firstname" />
                </div>

                <div class="addContactControls remove-right-margin col-100">
                    <input placeholder="{{'UserRegistrationApproval.Lastname' | translate}}" class="form-control" name="lastname" [(ngModel)]="filter.lastname" />
                </div>

                <div class="addContactControls remove-right-margin col-100">
                    <select name="list" class="form-control" [(ngModel)]="filter.jobrole" name="jobrole">
                        <option selected value="undefined">{{'UserRegistrationApproval.Jobrole' | translate}}</option>
                        <option [value]="jobrole" *ngFor="let jobrole of jobroles"> {{jobrole}}</option>
                    </select>
                </div>

                <div class="addContactControls remove-right-margin col-100">
                    <input placeholder="{{'UserRegistrationApproval.Organisation' | translate}}" class="form-control padding" [(ngModel)]="filter.organisation"
                        name="organisation" [ngbTypeahead]="organisationSearch" />
                    <fa-icon [icon]="faSearch"></fa-icon>
                </div>

                <div class="addContactControls remove-right-margin col-100">
                    <select name="list" class="form-control" [(ngModel)]="filter.status" name="status">
                        <option value="undefined">{{'UserRegistrationApproval.Status' | translate}}</option>
                        <option value="All">All</option>
                        <option [value]="status" *ngFor="let status of statuses"> {{status}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center btn-align">
                    <button class="btn btn-primary customBtnSearch" (click)="getUserApprovalDetails()">{{'UserRegistrationApproval.Search' | translate}}</button>
                    <button class="btn btn-primary customBtnReset" (click)="reset()">{{'UserRegistrationApproval.Reset' | translate}}</button>
                </div>
            </div>

        </form>
    </div>
    <div class="formGroup" #dataLoading="appIsLoading" [appIsLoading]="loadingService.isLoading(this)">
        <div class="divider mb-5"></div>
        <div class="row p-0 m-0 upload-download-section mb-5" *ngIf="paginationDetails.totalNumberOfItems > 0">
            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6">
                <p class="m-0">{{'TableRecords.FoundTotalOf' |
                    translate}}{{paginationDetails.totalNumberOfItems}}{{'TableRecords.matches' |
                    translate}}</p>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 col-lg-6 upload-download-section-wrap text-right pr-0 pl-0">
                <div class="upload-section pt-3">
                    <button class="btn btn-primary customBtnReset" (click)="uploadBulkApproval()">{{'UserRegistrationApproval.UploadList' | translate}}
                        <a class="button-icon">
                            <img src="/assets/images/Upload.svg" width="25" alt="Upload"/>
                        </a>
                    </button>
                </div>
                <div class="download-section pt-3">
                    <button class="btn btn-primary customBtnReset" (click)="exportUserApprovalDetails()">{{'UserRegistrationApproval.DownloadList' | translate}}
                        <a class="button-icon">
                            <img src="/assets/images/Download.svg" width="25" alt="Download"/>
                        </a>
                    </button>
                </div>
            </div>
        </div>

        <div class="registrationApprovalDataTable" *ngIf="paginationDetails.totalNumberOfItems > 0">
            <div class="table-headers">
                <div class="custom-table-header pointer" (click)="onSort('username')">
                    <p class="table-coloumn-header">{{'UserRegistrationApproval.Username' | translate}}
                        <cs-sort-icons *ngIf="sorter.showSortIcons('username')" [sortAscending]="sorter.sortDirection">
                        </cs-sort-icons>
                    </p>
                </div>
                <div class="custom-table-header pointer" (click)="onSort('firstname')">
                    <p class="table-coloumn-header">{{'UserRegistrationApproval.Firstname' | translate}}
                        <cs-sort-icons *ngIf="sorter.showSortIcons('firstname')" [sortAscending]="sorter.sortDirection">
                        </cs-sort-icons>
                    </p>
                </div>
                <div class="custom-table-header pointer" (click)="onSort('lastname')">
                    <p class="table-coloumn-header">{{'UserRegistrationApproval.Lastname' | translate}}
                        <cs-sort-icons *ngIf="sorter.showSortIcons('lastname')" [sortAscending]="sorter.sortDirection">
                        </cs-sort-icons>
                    </p>
                </div>
                <div class="custom-table-header pointer" (click)="onSort('organisation')">
                    <p class="table-coloumn-header">{{'UserRegistrationApproval.Organisation' | translate}}
                        <cs-sort-icons *ngIf="sorter.showSortIcons('organisation')" [sortAscending]="sorter.sortDirection">
                        </cs-sort-icons>
                    </p>
                </div>
                <div class="custom-table-header pointer" (click)="onSort('status')">
                    <p class="table-coloumn-header">{{'UserRegistrationApproval.RegistrationStatus' | translate}}
                        <cs-sort-icons *ngIf="sorter.showSortIcons('status')" [sortAscending]="sorter.sortDirection">
                        </cs-sort-icons>
                    </p>
                </div>
                <div class="custom-table-header">
                    <p class="table-coloumn-header">{{'UserRegistrationApproval.Action' | translate}}</p>
                </div>
                <div class="custom-table-header">
                    <p class="table-coloumn-header">{{'UserRegistrationApproval.View' | translate}}</p>
                </div>
            </div>
            <div class="table-body" *ngFor="let detail of approvalDetails; let y = index">
                <div class="custom-table-cell ">
                    <span class="mobile-column-name">{{'UserRegistrationApproval.Username' | translate}}</span>
                    <div class="center-wrapped">
                        <p> {{detail.username}} </p>
                    </div>
                </div>

                <div class="custom-table-cell ">
                    <span class="mobile-column-name">{{'UserRegistrationApproval.Firstname' | translate}}</span>
                    <div class="center-wrapped">
                        <p>{{detail.firstname}} </p>
                    </div>
                </div>

                <div class="custom-table-cell ">
                    <span class="mobile-column-name">{{'UserRegistrationApproval.Lastname' | translate}}</span>
                    <div class="center-wrapped">
                        <p>{{detail.lastname}} </p>
                    </div>
                </div>

                <div class="custom-table-cell ">
                    <span class="mobile-column-name">{{'UserRegistrationApproval.Organisation' | translate}}</span>
                    <div class="center-wrapped">
                        <p>{{detail.organisation}} </p>
                    </div>
                </div>

                <div class="custom-table-cell ">
                    <span class="mobile-column-name">{{'UserRegistrationApproval.RegistrationStatus' | translate}}</span>
                    <div class="center-wrapped">
                        <p>{{detail.status}} </p>
                    </div>
                </div>

                <div class="custom-table-cell icon">
                    <span class="mobile-column-name">{{'UserRegistrationApproval.Action' | translate}}</span>
                    <div class="center-wrapped">
                        <a class="icon-approve" *ngIf="detail.showAction || detail.showReApprove">
                            <fa-icon [icon]="faApprove" (click)="approve(detail.historyId)"></fa-icon>
                        </a>
                        <a class="icon-reject" *ngIf="detail.showAction">
                            <fa-icon [icon]="faRemoveIcon" (click)="reject(detail.historyId)"></fa-icon>
                        </a>
                    </div>
                </div>

                <div class="custom-table-cell icon">
                    <span class="mobile-column-name">{{'UserRegistrationApproval.View' | translate}}</span>
                    <div class="center-wrapped">
                        <a class="icon-view" (click)="view(detail.userId)">
                            <img src="/assets/images/View.svg" width="25" alt="View"/>
                        </a>
                    </div>
                </div>
            </div>

        </div>
        <div class="couponSummaryNoData alert alert-danger"
            *ngIf="!dataLoading.appIsLoading && paginationDetails.totalNumberOfItems===0">
            {{'UserRegistrationApproval.RecordNotFound' | translate}}
        </div>
        <div class="row" *ngIf="paginationDetails.totalNumberOfItems > 0">
            <div class="col-lg-12 pagination-center-Bottom">
                <cs-custom-pagination [paginationDetails]="paginationDetails" (onChangeEvent)="onChange($event)">
                </cs-custom-pagination>
            </div>
        </div>

    </div>
</div>