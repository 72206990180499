<div *ngIf="summaryDetails && summary" class="grid grid-col-sm-100 grid-gap-20 margin-top-20"
    [ngClass]="[isFIFOEnabled && summaryDetails?.isToDisplayExpiringPoints?'grid-col-hd-25':'grid-col-hd-33']">
    <cs-points-balance *ngIf="summaryDetails.isToDisplayEarnedPoints" [content]="summaryDetails.earnedPointLable"
        icon="assets/images/star_1.png" [value]="summary.totalEarned">
    </cs-points-balance>
    <cs-points-balance *ngIf="summaryDetails.isToDisplayRedemeedPoints" [content]="summaryDetails.redeemedPointsLable"
        icon="assets/images/gift_1.png" [value]="summary.totalRedeemed"></cs-points-balance>
    <cs-points-balance *ngIf="summaryDetails.isToDisplayAvaliableBalance"
        [content]="summaryDetails.avaliableBalanceLable" icon="assets/images/currency_1.png"
        [value]="summary.endingBalance || summary.availableBalance"></cs-points-balance>
    <cs-points-balance *ngIf="isFIFOEnabled && summaryDetails?.isToDisplayExpiringPoints" [content]="summaryDetails?.expiringPointsLable"
        icon="assets/images/currency_1.png" [value]="summary.expiringPoints"></cs-points-balance>
</div>