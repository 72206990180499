<div>
  <div class="claim-filter-section">
    <div ngbAccordion>
      <div
        ngbAccordionItem="claimfilter"
        #claimfilter="ngbAccordionItem"
        [collapsed]="false"
        class="padding-15"
      >
        <div ngbAccordionHeader>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="accordion-header">
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.InvoiceClaimHistoryHeader"
                  | translate
              }}
            </h5>
            <button ngbAccordionToggle class="btn btn-link accordion">
              {{ !claimfilter.collapsed ? "-" : "+" }}
            </button>
          </div>
        </div>
        <div ngbAccordionCollapse>
          <div  class="padding-left-0" ngbAccordionBody>
            <ng-template>
              <p class="claimDescription">
                {{
                  "InvoiceClaimPage.InvoiceClaimHistory.InvoiceClaimHistoryDescription"
                    | translate
                }}
              </p>
              <div class="grid grid-gap-40 grid-col-hd-50 grid-col-xs-100">
                <div class="margin-bottom-10">
                  <label>{{
                    "InvoiceClaimPage.InvoiceClaimHistory.SearchbyInvoiceNumber"
                      | translate
                  }}</label>
                  <br />
                  <input
                    #couponId
                    placeholder="Invoice No"
                    [(ngModel)]="filter.invoiceNumber"
                    class="customField"
                  />
                </div>
                <div class="margin-bottom-10">
                  <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
                    <div class="margin-bottom-10">
                      <label>{{
                        "InvoiceClaimPage.InvoiceClaimHistory.DateofSales"
                          | translate
                      }}</label>
                      <br />
                      <div class="form-group item">
                        <div class="input-group">
                          <label
                            for="sellingFromDate"
                            class="datePlacement lbl-date"
                            >{{
                              "InvoiceClaimPage.InvoiceClaimHistory.From"
                                | translate
                            }}</label
                          >
                          <input
                            class="form-control datePickerCalander"
                            placeholder="mm-dd-yyyy"
                            name="sellingFromDate"
                            ngbDatepicker
                            #dpsellingFromDate="ngbDatepicker"
                            readonly
                            [maxDate]="maxSaleFromDate"
                            [minDate]="minSaleFromDate"
                            [(ngModel)]="filter.fromSellingDate"
                            (ngModelChange)="blockOldDateSales('from')"
                          />

                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              type="button"
                              (click)="dpsellingFromDate.toggle()"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="margin-bottom-10">
                      <label class="label-empty"></label>
                      <br />
                      <div class="form-group item">
                        <div class="input-group">
                          <label
                            for="fromdate"
                            class="datePlacement lbl-date"
                            >{{
                              "InvoiceClaimPage.InvoiceClaimHistory.To"
                                | translate
                            }}</label
                          >
                          <input
                            class="form-control datePickerCalander"
                            placeholder="mm-dd-yyyy"
                            name="sellingToDate"
                            ngbDatepicker
                            #dpsellingToDate="ngbDatepicker"
                            readonly
                            [maxDate]="maxSaleToDate"
                            [minDate]="minSaleToDate"
                            [(ngModel)]="filter.toSellingDate"
                            (ngModelChange)="blockOldDateSales('to')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              type="button"
                              (click)="dpsellingToDate.toggle()"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-gap-40 grid-col-hd-50 grid-col-xs-100">
                <div class="margin-bottom-10">
                  <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
                    <div class="margin-bottom-10">
                      <label>{{
                        "InvoiceClaimPage.InvoiceClaimHistory.SeachbySKUs"
                          | translate
                      }}</label>
                      <br />
                      <input
                        #couponId
                        placeholder="SKU"
                        [(ngModel)]="filter.sku"
                        class="customField"
                      />
                    </div>
                    <div class="margin-bottom-10">
                      <label>{{
                        "InvoiceClaimPage.InvoiceClaimHistory.SearchbyStatus"
                          | translate
                      }}</label>
                      <br />
                      <select
                        name="list"
                        [(ngModel)]="filter.status"
                        class="form-control select-status"
                      >
                        <option value="">Filter By Status</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Challenged">Challenged</option>
                        <option value="submitted">submitted</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="margin-bottom-10">
                  <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
                    <div class="margin-bottom-10">
                      <label>{{
                        "InvoiceClaimPage.InvoiceClaimHistory.DateofClaim"
                          | translate
                      }}</label>
                      <br />
                      <div class="form-group item">
                        <div class="input-group">
                          <label
                            for="sellingclaimFromDate"
                            class="datePlacement lbl-date"
                            >{{
                              "InvoiceClaimPage.InvoiceClaimHistory.From"
                                | translate
                            }}</label
                          >
                          <input
                            class="form-control datePickerCalander"
                            placeholder="mm-dd-yyyy"
                            name="claimFromDate"
                            ngbDatepicker
                            #dpclaimFromDate="ngbDatepicker"
                            readonly
                            [maxDate]="maxClaimFromDate"
                            [minDate]="minClaimFromDate"
                            [(ngModel)]="filter.fromClaimDate"
                            (ngModelChange)="blockOldDateClaim('from')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              type="button"
                              (click)="dpclaimFromDate.toggle()"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="margin-bottom-10">
                      <label class="label-empty"></label>
                      <br />
                      <div class="form-group item">
                        <div class="input-group">
                          <label
                            for="claimToDate"
                            class="datePlacement lbl-date"
                            >{{
                              "InvoiceClaimPage.InvoiceClaimHistory.To"
                                | translate
                            }}</label
                          >
                          <input
                            class="form-control datePickerCalander"
                            placeholder="mm-dd-yyyy"
                            name="claimToDate"
                            ngbDatepicker
                            #dpclaimToDate="ngbDatepicker"
                            readonly
                            [maxDate]="maxClaimToDate"
                            [minDate]="minClaimToDate"
                            [(ngModel)]="filter.toClaimDate"
                            (ngModelChange)="blockOldDateClaim('to')"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary calendar"
                              type="button"
                              (click)="dpclaimToDate.toggle()"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center btn-align">
                  <button
                    class="btn btn-primary customBtnSearch"
                    (click)="onSearch()"
                  >
                    {{ "InvoiceClaimPage.SearchBtn" | translate }}
                  </button>
                  <button
                    class="btn btn-primary customBtnReset"
                    (click)="onClear()"
                  >
                    {{ "InvoiceClaimPage.ResetBtn" | translate }}
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-width">
    <h1 class="sub-pageHeader">
      {{ "InvoiceClaimPage.InvoiceClaim" | translate }}
    </h1>
  </div>

  <div class="claim-filter-section">
    <div
      class="row"
      *ngIf="invoiceHistoryDetails && invoiceHistoryDetails.length > 0"
    >
      <div class="col-12 col-md-6">
        <p>
          {{ "TableRecords.FoundTotalOf" | translate
          }}{{ paginationDetails.totalNumberOfItems
          }}{{ "TableRecords.matches" | translate }}
        </p>
      </div>
      <div class="col-12 col-md-6">
        <div class="grid grid-col-xs-100 grid-col-hd-50 grid-gap-10">
          <cs-items-per-page
            class=""
            [paginationDetails]="paginationDetails"
            (onChangeEvent)="onChange($event)"
          ></cs-items-per-page>
          <div class="pull-right">
            <div class="downloadText" (click)="downloadCSV()">
              Download Report <span class="downloadIcons"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="table-width"
      #dataLoading="appIsLoading"
      [appIsLoading]="loadingService.isLoading(this)"
    >
      <table
        class="table table-striped"
        aria-describedby="Invoice Claim History"
        *ngIf="paginationDetails.totalNumberOfItems !== 0"
      >
        <thead>
          <tr>
            <th
              class="cm_12 cm_bold"
              role="button"
              (click)="onSort('invoiceNumber')"
              scope="col"
            >
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.InvoiceNo"
                  | translate
              }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('invoiceNumber')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              (click)="onSort('dateOfSale')"
              scope="col"
            >
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.DateofSale"
                  | translate
              }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('dateOfSale')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              (click)="onSort('sku')"
              scope="col"
            >
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.SKU"
                  | translate
              }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('sku')"
                [sortAscending]="sorter.sortDirection"
              >
              </cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              (click)="onSort('quantity')"
              scope="col"
            >
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.Qty"
                  | translate
              }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('quantity')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              (click)="onSort('value')"
              scope="col"
            >
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.Value"
                  | translate
              }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('value')"
                [sortAscending]="sorter.sortDirection"
              >
              </cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              (click)="onSort('ClaimedDate')"
              scope="col"
            >
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.ClaimDate"
                  | translate
              }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('ClaimedDate')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              *ngFor="let attribute of additionalAttributes"
            >
              {{ attribute }}
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              (click)="onSort('status')"
              scope="col"
            >
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.Status"
                  | translate
              }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('status')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              (click)="onSort('actionDate')"
              scope="col"
            >
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.DateofAction"
                  | translate
              }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('actionDate')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th scope="col">
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.ErrorReason"
                  | translate
              }}
            </th>
            <th scope="col">
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.Action"
                  | translate
              }}
            </th>
            <th scope="col">
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.Download"
                  | translate
              }}
            </th>
            <th scope="col">
              {{
                "InvoiceClaimPage.InvoiceClaimHistory.HistoryTable.Remarks"
                  | translate
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let invoice of invoiceHistoryDetails">
            <td>{{ invoice.invoiceNumber }}</td>
            <td>{{ invoice.dateOfSale | localizedDate }}</td>
            <td>{{ invoice.sku }}</td>
            <td>{{ invoice.quantity | localizednumber }}</td>
            <td>{{ invoice.value | localizednumber }}</td>
            <td>{{ invoice.claimedDate | localizedDate }}</td>
            <td *ngFor="let attribute of additionalAttributes">
              {{ invoice.attributesDto[attribute] }}
            </td>
            <td>{{ invoice.status }}</td>
            <td>{{ invoice.actionDate | localizedDate }}</td>
            <td>
              <span *ngIf="invoice.status === 'Rejected'">{{
                invoice.rejectReason
              }}</span>
            </td>
            <td>
              <span
                class="resubmit"
                *ngIf="invoice.status === 'Rejected'"
                (click)="
                  resubmit(invoice.claimConfigurationId, invoice.invoiceId)
                "
              ></span>
              <span
                class="challenge margin-right-5"
                (click)="challenge(invoice)"
                *ngIf="invoice.disableChallenge"
              ></span>
            </td>
            <td>
              <span
                class="download"
                (click)="downloadInvoiceFilesModal(invoice)"
              ></span>
            </td>
            <td>
              <span *ngIf="invoice.status === 'Challenged'">{{
                invoice.remarks
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="invoiceSummaryNoData alert alert-danger"
        *ngIf="
          !dataLoading.appIsLoading &&
          paginationDetails.totalNumberOfItems === 0
        "
      >
        {{ "InvoiceClaimPage.NoRecordsFound" | translate }}
      </div>
    </div>
    <div
      class="row"
      *ngIf="invoiceHistoryDetails && invoiceHistoryDetails.length > 0"
    >
      <div class="col-lg-12 pagination-center-Bottom">
        <cs-custom-pagination
          [paginationDetails]="paginationDetails"
          (onChangeEvent)="onChange($event)"
        >
        </cs-custom-pagination>
      </div>
    </div>
  </div>
</div>
