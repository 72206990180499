import { Action } from '@ngrx/store';
import { Authentication } from '@core/models/authentication.model';

export const CHANGE_AUTHENTICATION_STATUS = 'Change Authentication Status';
export const UPDATE_USER = 'Update User';
export const UPDATE_NAME = 'Update Name';
export const CHANGE_IMPERSONATE_STATUS = 'Change Impersonate Status';

export class ChangeAuthentication implements Action {
    readonly type = CHANGE_AUTHENTICATION_STATUS;

    constructor(public payload: boolean) { }
}

export class UpdateUser implements Action {
    readonly type = UPDATE_USER;

    constructor(public payload: Authentication) { }
}

export class UpdateName implements Action {
    readonly type = UPDATE_NAME;

    constructor(public payload: any) { }
}

export class ChangeImpersonateStatus implements Action {
    readonly type = CHANGE_IMPERSONATE_STATUS;

    constructor(public payload: boolean) { }
}

export type AuthenticationAction = ChangeAuthentication | UpdateUser | UpdateName | ChangeImpersonateStatus;