import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'form-button-select',
    templateUrl: './form-button-select.component.html',
    styleUrls: ['./form-button-select.component.scss']
})
export class FormButtonSelectComponent implements OnInit {
    @Input() value: string;
    @Input() theme: string;
    @Input() options: any[];
    @Input() placeHolder: string;
    @Input() optionsLabelKey: string;
    @Input() optionstrackbyKey: string;
    @Input() translateLabel: boolean;
    @Output() onChange = new EventEmitter();

    public inputState = { open: false };
    public template: string;

    constructor(public translate: TranslateService) { }

    ngOnInit() {
        this.template = this.theme ? this.theme : 'theme1';
    }

    inputChanged(value) {
        if (this.translateLabel) {
            value[this.optionsLabelKey] = this.translate.instant(value[this.optionsLabelKey]);
            value[this.optionstrackbyKey] = this.translate.instant(value[this.optionstrackbyKey]);
        }
        this.onChange.emit(value);
        this.inputState.open = false;
    }
}