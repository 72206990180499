<ng-template #popContent class="popover">
    <button *ngIf="!disableMerchandise && menuAccess.merchandise && !impersonated"
        class="popover-button" (click)="merchandiseCateloge()">
        <div class="redeem-mm"></div>
        {{'ProfilePointCard.Merchandise' | translate}}
    </button>
    <button *ngIf="!disableExperience && menuAccess.experience && !impersonated"
        class="popover-button" (click)="experianceCatelogue()">
        <div class="redeem-em"></div>
        {{'ProfilePointCard.Experience' | translate}}
    </button>
    <button *ngIf="!disableMastery && menuAccess.mastery"
    class="popover-button" (click)="masteryCateloge()">
    <div class="redeem-em"></div>
    {{'ProfilePointCard.Mastery' | translate}}
</button>
</ng-template>
<div *ngIf="menuAccess && !disablePopover" class="inline-block" [ngbPopover]="popContent" [disablePopover]="disablePopover"
    placement="bottom" popoverClass="my-custom-class" container="body">
    <form-button text="Redeem Now" [theme]="buttonTheme" button-type="button"></form-button>
</div>