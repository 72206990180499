import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LoadingService } from "@app/core/shared";
import { AuthenticationService } from "@app/core/startup";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { ContactControlService } from "../services/contact-control.service";
import { ContactFormBase } from "./types";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
  providers: [ContactControlService],
})
export class ContactUsComponent implements OnInit {
  contacts: ContactFormBase<string>[] | null = [];
  contactFormGroup!: FormGroup;
  payload = [];
  queryGroup!: FormGroup;
  selectedCulture: string;
  header: any;
  footer: any;
  queries: any;
  countries: any;
  user: any;
  QueryName: string = "Query";
  languageChangeSubscription;
  formResponse: any;
  formResponseValue: any;
  showQueries: boolean;
  showSuccess: boolean = false;
  prePopulatedValues: object = {};
  constructor(
    private contactService: ContactControlService,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private translateService: TranslateService,
    public loadingService: LoadingService
  ) {
  }

  ngOnInit(): void {
    this.getQueries();
    this.getUser();
    this.getCountries();
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedCulture = localStorage.getItem("culture");
      this.getUser();
    });
  }

  onChange(value) {
    if (value.type === "Query" || value.type === "Message") {
      this.formResponseValue = {
        ...this.formResponseValue,
        Query: value,
      };
    } else {
      this.formResponse.forEach((val: any) => {
        if (
          val.id === value.contextId &&
          val.type !== "Header" &&
          val.type !== "Footer"
        ) {
          this.formResponseValue = {
            ...this.formResponseValue,
            [val.attribute]: value,
          };
        } else if (val.attribute === "Firstname" && this.user) {
          val.value = this.user.firstName;
          const emitData: any = {
            contextId: val.id,
            type: val.type,
            value: this.user.firstName,
          };
          this.formResponseValue = {
            ...this.formResponseValue,
            [val.attribute]: emitData,
          };
        } else if (val.attribute === "Lastname" && this.user) {
          val.value = this.user.lastName;
          const emitData: any = {
            contextId: val.id,
            type: val.type,
            value: this.user.lastName,
          };
          this.formResponseValue = {
            ...this.formResponseValue,
            [val.attribute]: emitData,
          };
        }
      });
    }
  }

  onSubmit() {
    if (this.contactFormGroup.valid) {
      const contactUsQueryDetails = [];
      this.formResponse.forEach(x => {
        if (x.disableElement) {
          contactUsQueryDetails.push({ "contextId": x.id, "type": x.type, "value": x.value });
        }
        else if (x.type !== "Header" && x.type !== "Footer" && contactUsQueryDetails.findIndex(x => (x.type == "Query" || x.type == "Message")) == -1 ) {
          if (x.type === "Query" || x.type === "Message") {
            contactUsQueryDetails.push({ "contextId": this.contactFormGroup.value.query.id, "type": x.type, "value": this.contactFormGroup.value.message });
          }
          else
            contactUsQueryDetails.push({ "contextId": x.id, "type": x.type, "value": this.contactFormGroup.value[x.attribute] });
        }
      });
      const browserDetail = this.getBrowserName();
      const payload = {
        userId: this.user ? this.user.id : "",
        ipAddress: "0.0.0.0",
        browserName: browserDetail,
        browserVersion: "",
        medium: "Web",
        status: "pending",
        contactUsQueryDetails,
        selectedLanguage: this.selectedCulture
      };
      this.contactService.sendForm(payload).subscribe((res) => {
        this.showSuccess = true;
      });
    } else {
      this.contactFormGroup.markAllAsTouched();
    }
  }

  buildFormElements(response: any) {
    this.formResponse = response;
    this.contacts = response.filter(
      (val) => val.type === "FormField" || val.type === "Country"
    );
    this.header = response.filter(
      (val) => val.type === "Header"
    )[0].translation.description;
    this.footer = response.filter(
      (val) => val.type === "Footer"
    )[0].translation.description;
    this.QueryName = response.filter(
      (val) => val.type.toLowerCase() === "query"
    )[0].translation.description;
    this.contactFormGroup = this.contactService.toFormGroup(
      this.contacts,
      this.user,
      this.showQueries
    );
    this.contactFormGroup.patchValue(this.prePopulatedValues);
  }

  getContactUsFrom() {
    this.selectedCulture = localStorage.getItem("culture");
    this.loadingService
      .doLoading(
        this.contactService.getFormElements(this.selectedCulture),
        this
      )
      .subscribe((response) => {
        if (this.authService.getToken()) {
          this.contactService.getPrePopulatedValues(this.selectedCulture).subscribe(res => {
            this.prePopulatedValues = res;
            response = response.map(x => {
              x.attribute = x.attribute.toLowerCase();
              if (res[x.attribute.toLowerCase()]) {
                x.value = res[x.attribute.toLowerCase()];
                x.disableElement = true;
              }
              else {
                x.disableElement = false;
                x.value = "";
              }
              return x;
            });
            this.buildFormElements(response);
          });
        }
        else {
          this.buildFormElements(response);
        }
      });
  }

  getQueries() {
    const culture = localStorage.getItem("culture");
    this.contactService.getQueries().subscribe((res) => {
      this.queries = res
        .map((val) => {
          const translations = val.translations.filter(
            (cul) => cul.culture.toLowerCase() === culture.toLowerCase()
          );
          if (translations[0]) {
            return {
              ...val,
              translations,
            };
          }
        })
        .filter((val) => val);
      this.showQueries = this.queries.length > 0 ? true : false;
    });
  }

  getCountries() {
    this.contactService
      .getCountries()
      .subscribe((res) => (this.countries = res.country));
  }

  resetForm() {
    this.contactFormGroup.reset();
    this.getContactUsFrom();
  }

  resubmit() {
    this.showSuccess = false;
    this.resetForm();
  }

  getUser() {
    if (localStorage.getItem("token")) {
      this.authService.getCurrentUser().subscribe((res) => {
        this.user = res;
        this.getContactUsFrom();
      });
    } else {
      this.getContactUsFrom();
    }
  }

  onChangeValue(e, id) {
    const val = e.target ? e.target.value : e;
    const emitData: any = {
      contextId: id,
      type: "Query",
      value: val,
    };
    this.onChange(emitData);
  }

  isValid(attribute) {
    return this.contactFormGroup.get(attribute).valid;
  }

  getBrowserName(): any {

    let userAgent = window.navigator.userAgent;
    let browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };

    for (let key in browsers) {
      if (browsers[key].test(userAgent)) {
        return key;
      }
    }
  }
}
