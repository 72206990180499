import { Injectable } from '@angular/core';
import { SearchCriteria } from '../models/search-criteria';

@Injectable({
    providedIn: 'root'
})
export class ParamsBuilderService {
    buildParams(criteria: SearchCriteria) {
        let params = `itemsPerPage=${criteria.itemsPerPage}&pageNumber=${criteria.pageNumber}&sortAscending=${criteria.sortAscending}&sortField=${criteria.sortField}`;
        let additionalParams = this.buildAdditionalData(criteria.additionalData);
        if (additionalParams)
            params = params + additionalParams;

        return params;
    }

    private buildAdditionalData(additionalData: object) {
        let additionalParams = ``;
        if (additionalData)
            Object.keys(additionalData).forEach(key => {
                if (additionalData[key])
                    additionalParams = additionalParams + `&${key}=${additionalData[key]}`;
            });
        return additionalParams
    }

    buildStandardReportsParams(request: any) {        
        let params = ``;
        if (request) {
            Object.keys(request).forEach(key => {
                if (request[key]) {
                    if (Array.isArray(request[key])) {
                        request[key].forEach(arr => {
                            params = params + `${key}=${arr}&`
                        })
                    }
                    else
                        params = params + `${key}=${request[key]}&`
                }
            });
        }
        return params.slice(0, -1);
    }
}