import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '@core/shared';

import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

import {
  UrlBuilder,
  GET_MESSAGE_PAGE,
  GET_MESSAGE_CATEGORIES,
  GET_MESSAGE_ARTICLES,
  GET_MESSAGE_ARTICLE,
  GET_MESSAGE_ARTICLE_PREVIEW
} from '@core/core-api-variables';

@Injectable()
export class MessagePageService {

  constructor(public http: HttpService, public paramsBuilderService: ParamsBuilderService) { }

  getMessagePage(messagePageId: string, culture: string): Observable<any> {
    let data = { messagePageId, culture }
    let url = UrlBuilder(GET_MESSAGE_PAGE, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getArticles(messagePageId: string, culture: string): Observable<any> {
    let data = { messagePageId, culture }
    let url = UrlBuilder(GET_MESSAGE_ARTICLES, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getCategories(messagePageId: string, culture: string): Observable<any> {
    let data = { messagePageId, culture }
    let url = UrlBuilder(GET_MESSAGE_CATEGORIES, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getArticle(messagePageId: string, culture: string, messageArticleId: string): Observable<any> {
    let data = { messagePageId, culture, messageArticleId }
    let url = UrlBuilder(GET_MESSAGE_ARTICLE, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getArticlePreview(messagePageId: string, culture: string, messageArticleId: string): Observable<any> {
    let data = { messagePageId, culture, messageArticleId }
    let url = UrlBuilder(GET_MESSAGE_ARTICLE_PREVIEW, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
}
