import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@core/shared/shared.module';
import { MessagePageService } from './services/messagepage.service';
import { ArticlesComponent } from './articles.component';
import { MessagePageComponent } from './message-page/message-page.component';
import { MessageArticleComponent } from './message-article/message-article.component';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,        
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],   
    providers: [        
        MessagePageService
    ], 
    declarations: [        
        ArticlesComponent,
        MessagePageComponent,
        MessageArticleComponent
    ]
})

export class MessagePageModule { }
