import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@core/shared/shared.module';
import { ContentPageService } from './services/contentpage.service';
import { ContentPageComponent } from './content-page/content-page.component';
import { ContentComponent } from './content.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,        
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule
    ],   
    providers: [ContentPageService], 
    declarations: [        
        ContentPageComponent,
        ContentComponent
    ]
})

export class ContentPageModule { }
