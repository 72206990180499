import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { LoadingService } from '@core/shared';
import { SearchCriteria } from '@core/shared/models/search-criteria';
import { Pagination } from '@core/shared/models/pagination';
import { Sorter } from '@core/shared/models/sorter'
import { InvoiceClaimService } from '../services/invoice-claim.service';
import { InvoiceClaimHistoryFilter } from '../view-models/invoice-claim-history.filter';
import { InvoiceChallengeModalComponent } from '../invoice-claim-modal/challenge/invoice-challenge.component';
import { InvoiceFilesModalComponent } from '../invoice-claim-modal/invoice-files/invoice-files.component';
import { browserRefresh } from '@app/app.component';

@UntilDestroy()
@Component({
  selector: 'app-invoice-claim-history',
  templateUrl: './invoice-claim-history.component.html',
  styleUrls: ['./invoice-claim-history.component.scss']
})
export class InvoiceClaimHistoryComponent implements OnInit, OnDestroy {
  searchCriteria: SearchCriteria = new SearchCriteria('claimedDate');
  paginationDetails: Pagination = new Pagination();
  sorter: Sorter = new Sorter('claimedDate', false);
  invoiceHistoryDetails: any[] = [];
  filter: InvoiceClaimHistoryFilter;
  claimConfigurationId: any;
  maxSaleFromDate: any;
  minSaleFromDate: any;
  maxSaleToDate: any;
  minSaleToDate: any;
  maxClaimFromDate: any;
  minClaimFromDate: any;
  maxClaimToDate: any;
  minClaimToDate: any;
  browserRefresh: boolean;
  additionalAttributes: string[] = [];
  navigationStartSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private invoiceClaimService: InvoiceClaimService,
    private modalService: NgbModal, public loadingService: LoadingService) { }

  ngOnInit(): void {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent ),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      if (this.claimConfigurationId != this.route.snapshot.params.id) {
        this.browserRefresh = browserRefresh;
        this.claimConfigurationId = this.route.snapshot.params.id;
        let sellingFromDate = new Date();
        sellingFromDate.setFullYear(sellingFromDate.getFullYear() - 1);
        sellingFromDate.setMonth(sellingFromDate.getMonth() + 1);
        sellingFromDate.setDate(1);

        this.filter = new InvoiceClaimHistoryFilter();
        this.filter.status = '';
        this.getInvoiceClaimHistory();
      }
    });
  }

  getInvoiceClaimHistory() {

    let payload = Object.assign({}, this.filter);

    payload.toClaimDate = this.filter.toClaimDate ? moment(this.filter.toClaimDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T23:59:59.999" : null;
    payload.fromClaimDate = this.filter.fromClaimDate ? moment(this.filter.fromClaimDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T00:00:00" : null;
    payload.fromSellingDate = this.filter.fromSellingDate ? moment(this.filter.fromSellingDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T00:00:00" : null;
    payload.toSellingDate = this.filter.toSellingDate ? moment(this.filter.toSellingDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T23:59:59.999" : null;

    this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
    this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
    this.searchCriteria.sortField = this.sorter.sortColumn;

    this.searchCriteria.sortAscending = this.sorter.sortDirection;
    payload.claimConfigurationId = this.claimConfigurationId;
    this.searchCriteria.additionalData = payload;

    this.loadingService.doLoading(this.invoiceClaimService.getInvoiceClaimHistory(this.searchCriteria), this).pipe(untilDestroyed(this)).subscribe(response => {
      this.invoiceHistoryDetails = response.items;
      if (this.invoiceHistoryDetails.length > 0)
        this.additionalAttributes = Object.keys(this.invoiceHistoryDetails[0].attributesDto);

      this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
    });
  }

  onSearch() {
    this.getInvoiceClaimHistory();
  }
  onClear() {
    this.filter.invoiceNumber = "";
    this.filter.fromSellingDate = "";
    this.filter.toSellingDate = "";
    this.filter.status = "";
    this.filter.fromClaimDate = "";
    this.filter.toClaimDate = "";
    this.getInvoiceClaimHistory();
  }

  onChange(pagination: Pagination) {
    this.paginationDetails = pagination;
    this.getInvoiceClaimHistory();
  }

  onSort(sortField) {
    this.sorter.sort(sortField);
    this.getInvoiceClaimHistory();
  }

  downloadCSV(): void {
    this.invoiceClaimService.downloadViewCSV(this.searchCriteria).subscribe((res: any) => {
      let header = res.headers;
      let contentType = header.get('content-type');
      let blob = new Blob([res.body], { type: contentType });

      let downloadLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute("href", url);
      downloadLink.setAttribute("download", "InvoiceClaimHistory.xlsx");
      downloadLink.style.visibility = "hidden";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }
  challenge(invoice) {
    const modalRef = this.modalService.open(InvoiceChallengeModalComponent, { backdrop: 'static' });
    modalRef.result.then((result) => {
      this.invoiceClaimService.updateInvoiceStatus(invoice.invoiceId, invoice.lineItemId, 'Challenged', result.reason).subscribe(response => {
        this.getInvoiceClaimHistory();
      });
    });
  }
  challengeActionText(invoice) {
    return 'Challenge';
  }

  downloadInvoiceFilesModal(invoice) {
    const modalRef = this.modalService.open(InvoiceFilesModalComponent, { backdrop: 'static' });
    modalRef.componentInstance.invoice = invoice;
    modalRef.result.then((result) => {
    });
  }

  blockOldDateSales(value) {
    if (value === 'from') {
      let tempFromDate = new Date(this.convertToDateFormat(this.filter.fromSellingDate));
      if (this.filter.fromSellingDate !== null) {
        this.minSaleToDate = { year: tempFromDate.getFullYear(), month: tempFromDate.getMonth() + 1, day: tempFromDate.getDate() };
      }
    }
    else {
      let tempToDate = new Date(this.convertToDateFormat(this.filter.toSellingDate));
      if (this.filter.toSellingDate !== null) {
        this.maxSaleFromDate = { year: tempToDate.getFullYear(), month: tempToDate.getMonth() + 1, day: tempToDate.getDate() };
      }
    }
  }

  blockOldDateClaim(value) {
    if (value === 'from') {
      let tempFromDate = new Date(this.convertToDateFormat(this.filter.fromClaimDate));
      if (this.filter.fromClaimDate !== null) {
        this.minClaimToDate = { year: tempFromDate.getFullYear(), month: tempFromDate.getMonth() + 1, day: tempFromDate.getDate() };
      }
    }
    else {
      let tempToDate = new Date(this.convertToDateFormat(this.filter.toClaimDate));
      if (this.filter.toClaimDate !== null) {
        this.maxClaimFromDate = { year: tempToDate.getFullYear(), month: tempToDate.getMonth() + 1, day: tempToDate.getDate() };
      }
    }
  }

  resubmit(claimConfigurationId, invoiceId) {
    let isMultiview = window.location.href.includes('multiview');
    if (isMultiview)
      this.router.navigateByUrl(`/multiview/${this.route.parent.snapshot.params.menuId}/claims/claimresubmission/${claimConfigurationId}/${invoiceId}`);
    else
      this.router.navigateByUrl(`/invoice/claims/claimresubmission/${claimConfigurationId}/${invoiceId}`);
  }

  convertToDateFormat(date) {
    let splitDate1 = date.split('-');
    let day = splitDate1[0];
    let month = splitDate1[1];
    let year = splitDate1[2];
    return month + "-" + day + "-" + year;
  }

  ngOnDestroy() {
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }
}
