import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { WindowReferenceService, ICustomWindow, LoadingService } from '@core/shared';
import { GameService } from '../services/game.service';
import { browserRefresh } from '@app/app.component';

@UntilDestroy()
@Component({
  selector: 'nackle-game-arcade',
  templateUrl: './game-arcade.component.html'
})
export class GameArcadeComponent implements OnInit, OnDestroy {
  gameToken: any;
  tokenId: string;
  ssoUrl: string;
  selectedCulture: string;
  _window: ICustomWindow;
  browserRefresh: boolean;  

  languageChangeSubscription: Subscription;
  navigationStartSubscription: Subscription;

  constructor(private router: Router, private gameService: GameService, public windowRef: WindowReferenceService,
    public loadingService: LoadingService, private translateService: TranslateService) {
    this._window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      this.browserRefresh = browserRefresh;
      this.loadGame();
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh)
        this.loadGame();
      else
        this.browserRefresh = false;
    });
  }

  loadGame() {    
    this.selectedCulture = localStorage.getItem('culture');

    this.loadingService.doLoading(this.gameService.getGameToken(), this)
      .pipe(untilDestroyed(this)).subscribe(response => {
        this._window.renderArcadeWidget({
          // Element to render the element into
          container: document.getElementById('arcade-widget-container'),
          // IETF language tag of the user's language and country code 
          locale: this.selectedCulture,
          // JWT token of current user session
          sessionId: response.tokenId,
          // sso endpoint for the arcade floor
          ssoEndpoint: response.ssoUrl,
          // ***OPTIONAL*** - Use IF and ONLY IF you're using Nackle Paper to style your page.
          // This flag disables the Widget's own Nackle Paper stylesheet, allowing it to use the styles of the host page.
          useHostPageNacklePaper: false,
        });        
      });
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe()
  }

}
