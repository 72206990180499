<div class="modal-header">
    <h4 class="modal-title">{{'UserRegistrationApproval.RejectReason.Title' | translate}}</h4>
</div>
<form [formGroup]="formRejectReason" (ngSubmit)="onSubmit()"> 
    <div class="modal-body">        
        <div class="form-group">
            <label for="rejectionReason" class="col-sm-4 control-label">{{'UserRegistrationApproval.RejectReason.RejectionReason' | translate}}</label>
            <div class="col-sm-8 col-md-6">
                <textarea class="form-control" rows="5" id="rejectionReason" name="rejectionReason" formControlName="rejectionReason"></textarea>
            </div>
        </div>
    </div>
  
    <div class="modal-footer">
        <button type="submit" class="btn btn-light" [disabled]="!formRejectReason.controls.rejectionReason.valid">{{'UserRegistrationApproval.RejectReason.OK' | translate}}</button>
        <button class="btn btn-default" type="button" (click)="cancel()">{{'UserRegistrationApproval.RejectReason.Cancel' | translate}}</button>                
    </div>
</form>