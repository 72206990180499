import { Routes } from "@angular/router";
import { AuthGuard } from "@app/core/shared";
import { MyThresholdAwardsComponent } from "./my-threshold-awards-component/my-threshold-awards.component";
import { ThresholdComponent } from "./threshold.component";

export const MyThresholdAwardsRoutes: Routes = [
    {
        path: 'threshold',
        component: ThresholdComponent,
        children: [
            {
                path: 'mythresholdawards/page/:id',
                canActivate: [AuthGuard],
                component: MyThresholdAwardsComponent,
                runGuardsAndResolvers: 'always',
                pathMatch: 'full',
                data: { title: 'My Threshold Awards', isPreLogin: false }
            }]
    }
]