import { Routes } from '@angular/router';
import { SettingsResolver } from '@app/core/shared/guards';
import { AuthGuard, RouteGuard } from '@core/shared';

import { PointSummaryComponent } from './points-summary/points-summary.component';

export const PointSummaryRoutes: Routes = [
    {
        path: 'pointsstatement',
        resolve: {settings: SettingsResolver},
        canActivate: [AuthGuard, RouteGuard],
        component: PointSummaryComponent,
        pathMatch: 'full',
        data: { title: 'PageTitles.PointsStatementPage', isPreLogin: false, page: 'pointStatement' }
    },
    {
        path: 'pointsstatement/view/:id',
        resolve: {settings: SettingsResolver},
        canActivate: [AuthGuard],
        component: PointSummaryComponent,
        pathMatch: 'full',
        data: { title: 'PageTitles.PointsStatementPage', isPreLogin: false, page: 'pointStatement' }
    }
];