import * as fromGlobalMenuAction from '../actions/globalmenu.action';

export interface GlobalMenuState {
    menuAccess: any
}

export const initialState: GlobalMenuState = {
    menuAccess: null
}

export function reducer(state, action: fromGlobalMenuAction.GlobalMenuAccessAction): GlobalMenuState {
    if (!state)
        state = initialState;
    if (action.type === fromGlobalMenuAction.CHANGE_GLOBAL_MENU_ACCESS)
        return { ...state, menuAccess: action.payload };
    else
        return state;
}

export const getGlobalMenuState = (state: GlobalMenuState) => state;