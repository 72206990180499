<div class="prelogin-body">
    <div class="content-width">
        <div class="prelogin-form-card-container">
            <form [formGroup]="formForgotPassword" class="common-prelogin-form round-card shadowed bg-color2"
                (ngSubmit)="changePassword(formForgotPassword)">
                <div class="margin-bottom-20 ">
                    <body-text class="medium-size-plus-4">{{'ForgotPassword.Title' | translate}}</body-text>
                </div>
                <div class="margin-bottom-10">
                    <form-input controlName="username" placeHolder="{{'ParticipantLogin.Username' | translate}}">
                    </form-input>
                    <div class="help-block text-danger"
                        *ngIf="!formForgotPassword.controls.username.valid && (formForgotPassword.controls.username.touched || submitted)">
                        <span [hidden]="!formForgotPassword.controls['username'].hasError('required')"
                            translate="ParticipantLogin.UsernameRequired"></span>
                    </div>
                </div>
                <div class="margin-bottom-10 margin-top-20">
                    <form-button text="{{ 'ForgotPassword.ResetPassword' | translate }}" size="stretch"
                        buttonType="submit"></form-button>
                </div>
                <div class="margin-bottom-0 margin-top-20 text-center">
                    <a class="link-no-decor" [routerLink]="['/']">
                        <body-text class="primary-color medium-size">Existing User? Click here to log in</body-text>
                    </a>
                </div>
            </form>
        </div>
    </div>
</div>