import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { filter, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { browserRefresh } from '@app/app.component';
import { HttpImageService } from '@core/shared/services/http-img.service';
import { ContentPageService } from '../services/contentpage.service';
import { LoadingService } from '@core/shared';
import { PreBuiltTemplateStyleManagerService } from '@app/core/shared/services/prebuilt-template-style-manager';

@UntilDestroy()
@Component({
  selector: 'content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss']

})
export class ContentPageComponent implements OnInit, OnDestroy {
  contentPageId: string;
  contentPageDetails: any;
  browserRefresh: boolean;
  selectedCulture: string;
  style: any;

  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;

  constructor(private activeRoute: ActivatedRoute, private router: Router,
    private contentPageService: ContentPageService,
    private imgService: HttpImageService, private translateService: TranslateService,
    public loadingService: LoadingService, private prebuiltTemplateService: PreBuiltTemplateStyleManagerService) { }

  ngOnInit(): void {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent ),      
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {      
      if (this.contentPageId != this.activeRoute.snapshot.params.id) {
        this.browserRefresh = browserRefresh;
        this.contentPageId = this.activeRoute.snapshot.params.id;
        this.getPreBuiltTemplateCss();

      }
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh)
        this.getPreBuiltTemplateCss();

      else
        this.browserRefresh = false;
    });
  }

  getContentPage(css) {
    this.selectedCulture = localStorage.getItem('culture');

    this.loadingService.doLoading(this.contentPageService.getContentPage(this.contentPageId, this.selectedCulture), this)
      .pipe(untilDestroyed(this)).subscribe(response => {
        this.contentPageDetails = this.imgService.parseHtml(response);
        if (css != null) {
          this.prebuiltTemplateService.setStyle(this.contentPageId, css);
        }

        setTimeout(() => {
          this.fetchBlobImages();
        });
      });
  }

  fetchBlobImages() {
    let images = document.querySelectorAll('img[data-src]');
    for (let i = 0; i < images.length; i++) {
      let dataSrc = images[i].getAttribute('data-src');
      this.imgService.getImage(dataSrc).subscribe(val => {
        let type = val.type;
        var reader = new FileReader();
        reader.readAsDataURL(val);
        reader.onloadend = function () {
          let baseImage = <string>reader.result;
          images[i].setAttribute('src', `data:${type};base64,` + baseImage.split(',')[1]);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }

  getPreBuiltTemplateCss() {
    this.contentPageService.getPreBuiltTemplateCss().subscribe((res: any) =>{
      if(res != null){
        this.style = res.map(x => x.css).join();
        this.getContentPage(this.style);
      }
      else{
        this.getContentPage(null)
      }
    }
    )}
}
