<div class="claim-filter-section">
  <div class="custom-toggle" *ngIf="!isAdmin && contentHtml != undefined">
    <div class="header">
      <div class="header-title">{{content? content.cmsTitle : 'Material'}}</div>
      <button (click)="toggleShowsMaterial()" class="btn btn-link accordion"> {{!isShownDivMaterial ? '+' :
        '-'}}</button>
    </div>
    <div class="opens bg" [class]="{'newClass':isShownDivMaterial}">
      <div [innerHTML]="contentHtml | sanitizeHtml" *ngIf="contentHtml != undefined"></div>
      <div class="row">
        <div class="col-xs-12">
          <button class="btn btn-primary custombtn pull-right" (click)="toggleShows()"> {{content? content.buttonText : 'Start Quiz'}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="custom-toggle">
    <div class="header">
      <div class="header-title">{{content? content.quizTitle : 'Quiz'}}</div>
      <button (click)="toggleShows()" class="btn btn-link accordion"> {{!isShownDiv ? '+' : '-'}}</button>
    </div>    
    <div class="open bg" [class]="{'newClass':isShownDiv}" *ngIf="isShownDiv">
      <div class="container" *ngIf="quizId">
        <briks-container #quizPreview [populateHttpHeaders]="callbackFunction" [apiUrl]="apiUrl" [initialLanguage]="initialLanguage"
         [quizId]="quizId" [ready]="ready" [lang] = "lang" [isAttemptAllowedOutsideQuizDate]="isAttemptAllowedOutsideQuizDate" 
         [timezone]="timeZone" [isAdmin]="isAdmin" [environment] = "nackleEnvironment">
        </briks-container>
        <div class="both"></div>
      </div>
      <div class="both"></div>
    </div>
  </div>  
  <input name="attemptUserId" type="hidden" [value] = "attemptUserId">
  <input name="isAdmin" type="hidden" [value] = "isAdmin">
</div>