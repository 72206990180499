import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '@core/shared';

import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

import {
  UrlBuilder,
  GET_GAME_TOKEN
} from '@core/core-api-variables';

@Injectable()
export class GameService {

  constructor(public http: HttpService,private paramsBuilderService : ParamsBuilderService) { }

  getGameToken(): Observable<any> {        
    let url = UrlBuilder(GET_GAME_TOKEN, '');
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
    
  } 
}
