import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '@core/shared/services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { PreloginService } from '@core/prelogin/services/prelogin.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html'    
})
export class ForgotPasswordComponent implements OnInit {

    public formForgotPassword: FormGroup;
    public submitted: boolean = false;
    public isMailSent: boolean = false;
    constructor(public fb: FormBuilder, public PreloginService: PreloginService,private messageService: MessageService,public translate: TranslateService) { }

    ngOnInit() {
        this.formForgotPassword = this.fb.group({
            username: ['', [Validators.required]]
        });
    }

    changePassword(formForgotPassword) {
        this.submitted = true;
        if (formForgotPassword.valid) {
            var data = { username: formForgotPassword.value.username }
            this.PreloginService.forgotPassword(data).subscribe(result => {
                this.isMailSent = true;
                this.messageService.showInfo(this.translate.instant('ForgotPassword.ResetPasswordEmailHasBeenSent')); 
            });
        }
    }
}