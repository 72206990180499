import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '@core/shared';

import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

import {
  UrlBuilder,
  GET_QUESTION_PAGE,
  GET_QUESTION_CATEGORIES,
  GET_QUESTION_ARTICLES,
  GET_QUESTION_ARTICLE,
  GET_QUESTION_ARTICLE_PREVIEW
} from '@core/core-api-variables';

@Injectable()
export class QuestionPageService {

  constructor(public http: HttpService, public paramsBuilderService: ParamsBuilderService) { }
  
  getQuestionPage(questionPageId:string,culture:string): Observable<any> {            
    let data = { questionPageId,culture }
    let url = UrlBuilder(GET_QUESTION_PAGE, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }
  getArticles(questionPageId:string,culture:string): Observable<any> {            
    let data = { questionPageId,culture }
    let url = UrlBuilder(GET_QUESTION_ARTICLES, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  } 

  getCategories(questionPageId:string,culture:string): Observable<any> {            
    let data = { questionPageId,culture }
    let url = UrlBuilder(GET_QUESTION_CATEGORIES, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  } 

  getArticle(questionPageId:string,culture:string,questionArticleId:string): Observable<any> {            
    let data = { questionPageId,culture,questionArticleId }
    let url = UrlBuilder(GET_QUESTION_ARTICLE, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  } 
  getArticlePreview(questionPageId:string,culture:string,questionArticleId:string): Observable<any> {            
    let data = { questionPageId,culture,questionArticleId }
    let url = UrlBuilder(GET_QUESTION_ARTICLE_PREVIEW, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  } 
}
