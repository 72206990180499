import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-goal-selection-manager',
  templateUrl: './goal-selection-manager.component.html'
})
export class GoalSelectionManagerComponent implements OnInit {
  @Input() userCount: number;
  @Input() goalSelectedUsers: any;
  @Input() goalSelectionDetails: any;
  @Output() downloadGoalSelectionEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  calculateSelectedPercentage(selectedCount) {
    return `${(selectedCount / this.userCount) * 100}%`;
  }

  downloadGoalSelection() {
    this.downloadGoalSelectionEvent.emit();
  }
}
