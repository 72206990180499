<div class="prelogin-body">
  <div class="content-width">
    <div class="self-registration-page">
      <div class="row">
        <div class="mx-auto">
          <div class="card card-signin my-5">
            <div class="card-body">
              <div class="cms-card-body" *ngIf="contentPageDetails != undefined">
                <div class="custom-toggle">
                  <div class="header">
                    <div class="header-title">{{'Help'}}</div>
                    <button (click)="toggleShowsMaterial()" class="btn btn-link accordion">{{!isShownDivMaterial ? '+' :'-'}}</button>
                  </div>
                  <div class="opens bg" [class]="{'newClass':isShownDivMaterial}">
                    <div [innerHTML]="contentPageDetails | sanitizeHtml" *ngIf="contentPageDetails != undefined"></div>

                  </div>
                </div>
              </div>
              <div id="selfRegistrationRoot"></div>
            </div>
            <div class="margin-bottom-20 margin-top-20 text-center" *ngIf="showLoginPageLink">
              <a class="link-no-decor" [routerLink]="['/']">
                <body-text class="primary-color medium-size">Click here to get back to login page</body-text>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
          </div>
        </div>
