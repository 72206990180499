import { Component } from "@angular/core";

@Component({
  selector: "app-static-message-page",
  templateUrl: "./static-message-article-page.component.html",
  styleUrls: ["./static-message-article-page.component.scss"],
})
export class StaticMessageArticlePageComponent {
  
}
