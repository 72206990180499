import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { browserRefresh } from '@app/app.component';
import { QuestionPageService } from '../services/questionpage.service';
import { LoadingService } from '@core/shared';

@UntilDestroy()
@Component({
  selector: 'question-article',
  templateUrl: './question-article.component.html'
})
export class QuestionArticleComponent implements OnInit {

  questionPageId: string;
  questionArticleId: string;
  questionArticle: any;
  browserRefresh: boolean;
  selectedCulture: string;
  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private translateService: TranslateService,
    private questionPageService: QuestionPageService, public loadingService: LoadingService) { }

  ngOnInit(): void {

    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent ),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      if (this.questionArticleId != this.activeRoute.snapshot.params.questionArticleId) {
        this.browserRefresh = browserRefresh;
        this.questionPageId = this.activeRoute.snapshot.params.questionPageId;
        this.questionArticleId = this.activeRoute.snapshot.params.questionArticleId;
        this.getQuestionArticlePage();
      }
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh)
        this.getQuestionArticlePage();
      else
        this.browserRefresh = false;
    });
  }

  getQuestionArticlePage() {
    this.selectedCulture = localStorage.getItem('culture');

    this.loadingService.doLoading(this.questionPageService.getArticle(this.questionPageId, this.selectedCulture, this.questionArticleId), this)
      .pipe(untilDestroyed(this)).subscribe(response => {
        this.questionArticle = response;
      });   
  }
}
