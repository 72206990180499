<!-- logo bar primary starts -->
<div class="bg-color3 custom-justify-end">
    <div class="content-width">
        <div class="bar-container">

            <div class="top-profile-bar bar-item">
                <div class="bar-item" *ngIf="isAuthenticated && currentUser && !isPreLogin">
                    <body-text>

                        <ul class="m-0 p-0">
                            <li ngbDropdown class="nav-item">
                                <a class="nav-link dropdown-toggle" id="log" ngbDropdownToggle>
                                    <fa-icon [icon]="faUser"></fa-icon> {{currentUser.Firstname+ ' ' +
                                    currentUser.Lastname}}<strong>{{isImpersonate?'(Impersonated)':''}}</strong>

                                </a>
                                <div class="dropdown-menu dropdown-menu-right logo-dropdown-menu" ngbDropdownMenu aria-labelledby="log" id="logdropdown">
                                  <a *ngIf="menuAccess && menuAccess.securityQuestion" ngbDropdownItem class="c-pointer" [class.active]=" " href="javascript:void(0);"
                                     (click)="changeSecurityQuestion()"
                                     translate="UserMenu.ChangeSecurityQuestion"></a>
                                  <a *ngIf="menuAccess && menuAccess.changePassword" ngbDropdownItem class="c-pointer" [class.active]=" " href="javascript:void(0);"
                                     (click)="changePassword()" translate="UserMenu.ChangePassword"></a>
                                  <a *ngIf="menuAccess && menuAccess.notificationPreference" ngbDropdownItem class="c-pointer" [class.active]=" " href="javascript:void(0);"
                                     (click)="changePreference()" translate="UserMenu.NotificationPreference"></a>
                                  <a *ngIf="menuAccess && menuAccess.viewProfile" ngbDropdownItem class="c-pointer" href="javascript:void(0);"
                                     (click)="editProfile()" translate="UserMenu.ViewMyProfile"></a>
                                  <a ngbDropdownItem class="c-pointer logout-border-top" href="javascript:void(0);"
                                     (click)="logout()" *ngIf="isAuthenticated && currentUser && !isPreLogin"
                                     translate="UserMenu.LogOut"></a>
                                </div>
                            </li>
                        </ul>
                    </body-text>
                </div>
                <div class="logout bar-item">
                    <body-text>
                        <ul class="m-0 p-0">
                            <li ngbDropdown class="nav-item">
                                <a class="nav-link dropdown-toggle" id="dropdownCultures" ngbDropdownToggle>
                                    <fa-icon [icon]="faGlobeAmericas"></fa-icon> {{displayLanguage}}
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu
                                    aria-labelledby="dropdownCulture" id="culturedropdown">
                                    <a ngbDropdownItem class="c-pointer"
                                        [class.active]="selectedCulture==language.languageCulture"
                                        *ngFor="let language of systemSettings.platformLanguages"
                                        (click)="changeLanguage(language.languageCulture)">
                                        {{language.languageName}}
                                    </a>
                                    
                                </div>
                               
                               
                            </li>
                        </ul>
                    </body-text>
                </div>
                <div *ngIf="isAuthenticated && currentUser && !isPreLogin" class="logout bar-item notification">
                    <body-text>
                        <ul class="m-0 p-0">
                            <li ngbDropdown class="nav-item">
                                <a ngbDropdownItem id="notificationIcon"class="c-pointer notification-icon" href="javascript:void(0);"
                                    (click)="messageCentre()">
                                    <fa-icon [icon]="faBell"></fa-icon>({{notificationsCount}})
                                </a>
                            </li>
                        </ul>
                    </body-text>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- logo bar primary ends -->

<!-- logo bar secondary starts -->
<div class="content-width">
    <div class="logo-bar">
        <div class="main-logo">
            <a (click)="logoClicked()">
                <img class="logo" alt="brand logo" src="assets/images/brand_logo.png">
                <img class="logo" alt="program logo" src="assets/images/program_logo.png">
            </a>
        </div>
        <div>
            <img class="logo" alt="program logo" src="/assets/images/biw-full.png">
        </div>
    </div>
</div>
<!-- logo bar secondary ends -->
