import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService, RegexService } from '@core/startup';
import { PreloginService } from '@core/prelogin/services/prelogin.service';
import { SelectOptions } from '@core/models';

@Component({
    selector: 'app-complete-profile',
    templateUrl: './complete-profile.component.html'
})
export class CompleteProfileComponent implements OnInit {
    @Input() user: any;

    public submitted: boolean = false;
    public formActivation: FormGroup;
    public securityQuestion: Array<SelectOptions>;

    constructor(public router: Router, public formBuilder: FormBuilder, public authenticationService: AuthenticationService, public PreloginService: PreloginService,
        public regexService: RegexService, public toaster: ToastrService, public translate: TranslateService, 
        private modalService: NgbModal) {
        this.setupSecurityQuestion();
    }

    ngOnInit() {
        this.formActivation = this.formBuilder.group({
            firstName: [this.user.firstName, [Validators.required]],
            lastName: [this.user.lastName, [Validators.required]],
            email: [this.user.email, [Validators.required]],
            mobileNumber: [this.user.mobileNumber ? this.user.mobileNumber : null],
            jobRole: [{ value: this.user.jobRole ? this.user.jobRole.description : null, disabled: true }],
            securityQuestion: [null, [Validators.required]],
            securityAnswer: [null, [Validators.required]],
            password: [null, [Validators.required, Validators.pattern(this.regexService.passwordRegex)]],
            confirmPassword: [null, [Validators.compose([Validators.required, this.validateAreEqual.bind(this)])]],
            termsAccepted: [false, [Validators.requiredTrue]],
          type: ['username'],
          preferenceAccepted: [false]
        });
    }

    activate(formActivation) {
        this.submitted = true;
        if (formActivation.valid) {
            this.user.firstName = formActivation.value.firstName;
            this.user.lastName = formActivation.value.lastName;
            this.user.email = formActivation.value.email;
            this.user.mobileNumber = formActivation.value.mobileNumber;
            this.user.securityQuestion = formActivation.value.securityQuestion;
            this.user.securityAnswer = formActivation.value.securityAnswer;
            this.user.password = formActivation.value.password;
            this.user.confirmPassword = formActivation.value.confirmPassword;
            this.user.termsAccepted = true;
            this.user.type = formActivation.value.type;
          this.user.username = this.user.userName;
          this.user.preferenceAccepted = formActivation.value.preferenceAccepted;
            this.PreloginService.activate(this.user).subscribe(result => {
                this.toaster.success('User Activated Successfully');
                this.authenticationService.login(this.user.username, this.user.password, 'username', 'password', '', false).subscribe(result => {
                    this.router.navigate([`/home`]);
                });
            });
        }
    }

    validateAreEqual(fieldControl: FormControl) {
        if (!this.formActivation) {
            return null;
        }
        return fieldControl.value === this.formActivation.get("password").value ? null : {
            notEqual: true
        };
    }

    setupSecurityQuestion() {
        this.securityQuestion = [
            { label: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.CityBorn'), value: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.CityBorn') },
            { label: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstPet'), value: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstPet') },
            { label: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.MothersMaidenName'), value: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.MothersMaidenName') },
            { label: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.DadBorn'), value: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.DadBorn') },
            { label: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstCar'), value: this.translate.instant('ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstCar') },
        ];
    }

    securityQuestionChange(selectedValue) {
        this.formActivation.controls['securityQuestion'].setValue(selectedValue.value);
    }

    termsAndConditions(terms) {
        this.modalService.open(terms, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { },
            (error) => { });
    }
}
