import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '@core/shared';

import { HttpHeaders } from '@angular/common/http';

import { SearchCriteria } from '@core/shared/models/search-criteria';
import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

import {
  UrlBuilder,
  BRIKS_PROMOTION_DATA,
  ATTEMPT_ALLOWED_DETAILS,
  QUIZ_CONTENTS,
  QUIZ_ATTEMPT_DETAILS
} from '@core/core-api-variables';

@Injectable()
export class QuizQuestionnaireService {

  constructor(public http: HttpService,private paramsBuilderService : ParamsBuilderService) { }

  getQuizPromotion(promotionId:any): Observable<any> {    
    let data = { promotionId };
    let url = UrlBuilder(BRIKS_PROMOTION_DATA, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
    
  } 
  getQuizPromotionContents(contentPageId:string,culture : string): Observable<any> {      
    let data = { contentPageId ,culture};
    let url = UrlBuilder(QUIZ_CONTENTS, data);

    let httpOptions = {
      headers: new HttpHeaders({
          'Accept': 'text/html',
          'Content-Type': 'application/json'
      }),
      responseType: 'text'
    }

    return this.http.get(url,httpOptions).pipe(map((response: any) => {
      return response;
    }));
  }
  getAttemptAllowedOutsideQuizDate(promotionId:string,userId : string): Observable<any> {    
    let data = { promotionId ,userId};
    let url = UrlBuilder(ATTEMPT_ALLOWED_DETAILS, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }

  getQuizAttemptDetails(searchCriteria: SearchCriteria) {
    let params = this.paramsBuilderService.buildParams(searchCriteria);
    let data = { params }
    let url = UrlBuilder(QUIZ_ATTEMPT_DETAILS, data);    
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
  }


}
