import { Component, OnInit } from "@angular/core";
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
import { LoadingService } from "@app/core/shared";
import { Pagination } from "@app/core/shared/models/pagination";
import { SearchCriteria } from "@app/core/shared/models/search-criteria";
import { Sorter } from "@app/core/shared/models/sorter";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { NotificationCornerService } from "../services/notificationcorner.service";
import { NotificationFilter } from "../view-models/notification-filters";
import { ViewNotificationModalComponent } from "../view-notification/view-notification.component";
import { faClock, faCross } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'notification-corner',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationCornerComponent implements OnInit {
    public faClock = faClock;
    public faCross = faCross;
    filter: NotificationFilter;
    minNotificationToDate: any;
    maxNotificationFromDate: any;
    searchCriteria: SearchCriteria = new SearchCriteria('createdDate');
    paginationDetails: Pagination = new Pagination();
    sorter: Sorter = new Sorter('createdDate', false);
    notifications: any;

    constructor(public loadingService: LoadingService,public notificationCornerService: NotificationCornerService,private modalService: NgbModal,private router: Router,private activatedRoute : ActivatedRoute, private translateService: TranslateService, private titleService: Title){
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute.snapshot),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            })).subscribe((route: ActivatedRouteSnapshot) => {
                this.translateService.get(route.data.title).subscribe((res: string) => {
                    this.titleService.setTitle(res);
                });
            });
    }

    ngOnInit(): void {
        this.filter = new NotificationFilter();

        this.getNotifications();
    }

    blockOldDateSales(value) {
        if (value === 'from') {
            let tempFromDate = new Date(this.convertToDateFormat(this.filter.date));
            if (this.filter.date !== null) {
                this.minNotificationToDate = { year: tempFromDate.getFullYear(), month: tempFromDate.getMonth() + 1, day: tempFromDate.getDate() };
            }
        }
        else {
            let tempToDate = new Date(this.convertToDateFormat(this.filter.toNotificationDate));
            if (this.filter.toNotificationDate !== null) {
                this.maxNotificationFromDate = { year: tempToDate.getFullYear(), month: tempToDate.getMonth() + 1, day: tempToDate.getDate() };
            }
        }
    }

    convertToDateFormat(date) {
        let splitDate1 = date.split('-');
        let day = splitDate1[0];
        let month = splitDate1[1];
        let year = splitDate1[2];
        return month + "-" + day + "-" + year;
    }

    getNotifications() {
        let payload = Object.assign({}, this.filter);

        payload.toNotificationDate = this.filter.toNotificationDate ? moment(this.filter.toNotificationDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T23:59:59.999" : null;
        payload.date = this.filter.date ? moment(this.filter.date, "DD-MM-YYYY").format('YYYY-MM-DD') + "T00:00:00" : null;
        payload.title = this.filter.title;
        payload.status = this.filter.status;

        this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
        this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
        this.searchCriteria.sortField = this.sorter.sortColumn;
        this.searchCriteria.sortAscending = this.sorter.sortDirection;

        this.searchCriteria.additionalData = payload;

        this.notificationCornerService.getNotifications(this.searchCriteria).pipe().subscribe(response => {
            this.notifications = response.items;
            this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
        })
    }

    onSearch() {
        this.getNotifications();
    }

    onSort(sortField) {
        this.sorter.sort(sortField);
        this.getNotifications();
    }

    viewMessage(notification: any) {
        this.notificationCornerService.getNotificationById(notification.id).pipe().subscribe(response => {
            let notifificationContent = response;
            if (!notification.isRead) {
                let payLoad = {
                    status: true
                }
                this.notificationCornerService.makeMessageAsRead(notification.id, payLoad).pipe().subscribe(res => {
                    this.getNotifications();
                });
            }
            const modalRef = this.modalService.open(ViewNotificationModalComponent, { backdrop: 'static' });
            modalRef.componentInstance.notification = notifificationContent;
            modalRef.result.then();
        })
    }

    deleteMessage(notification) {
        if (confirm("Are you sure you want to delete notification")) {
            this.notificationCornerService.deleteMessage(notification.id).pipe().subscribe(response => {
                this.getNotifications();
            })
        }
    }

    onChange(pagination: Pagination) {
        this.paginationDetails = pagination;
        this.getNotifications();
    }

    onRemove(content) {
        this.modalService.open(content, { centered: true });
    }
}