import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';
import { ReportsComponent } from './reports.component';
import { EmbeddedReportsComponent } from './embedded-reports/embedded-reports.component';

export const EmbeddedReportRoutes: Routes = [
    {
        path: 'reports',
        component: ReportsComponent,
        children: [
            {
                path: 'embeddedreportpage/view/:id',
                canActivate: [AuthGuard],
                component: EmbeddedReportsComponent,
                pathMatch: 'full',
                data: { title: 'Embedded Reports', isPreLogin: false, isDashboard: true }
            },
           
        ]
    }
];