import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class ErrorMessages {
    constructor(public alertType: AlertTypes, public errors: string[]) { }
}

export enum AlertTypes {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Danger = 'danger',
    Primary = 'primary',
    Secondary = 'secondary',
    Light = 'light',
    Dark = 'dark',
}

@Injectable()
export class AlertService {
    public errorMessages: BehaviorSubject<ErrorMessages> = new BehaviorSubject<ErrorMessages>(new ErrorMessages(null, []));

    addMessages(type: AlertTypes, messages: string[]) {
        this.errorMessages.next(new ErrorMessages(type, messages));
    }

    removeMessages(){
        this.errorMessages.next(null);
    }
}