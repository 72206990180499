<ul>
    <li ngbDropdown class="nav-item" *ngIf="currentUser">
        <a class="nav-link dropdown-toggle" id="dropdownCulture" ngbDropdownToggle>
            <fa-icon [icon]="faUser"></fa-icon> {{currentUser.firstName+ ' ' + currentUser.lastName}}
        </a>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownCulture">
          <a ngbDropdownItem class="c-pointer" translate="UserMenu.ChangeSecurityQuestion"></a>
          <a ngbDropdownItem class="c-pointer" [routerLink]="['/changepassword']" translate="UserMenu.ChangePassword"></a>
          <a ngbDropdownItem class="c-pointer" [routerLink]="['/notificationsubscription']" translate="UserMenu.NotificationPreference"></a>

          <a ngbDropdownItem class="c-pointer" translate="UserMenu.ViewMyProfile"></a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem class="c-pointer" (click)="logout()" translate="UserMenu.LogOut"></a>
        </div>
    </li>
</ul>
