import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';
import * as pbi from 'powerbi-client';

import { browserRefresh } from '@app/app.component';
import { EmbeddedReportService } from '../../services/embedded-report.service';
import { SystemSettings } from '@core/models';
import { LoadingService } from '@core/shared';

@Component({
  selector: 'app-power-bi-report',
  templateUrl: './power-bi-report.component.html',
  styleUrls: ['./power-bi-report.component.scss']
})
export class PowerBIReportComponent  implements OnInit, OnDestroy {
  @ViewChild('reportContainer') reportContainer: ElementRef;
  id: string;
  url: string;
  reportid: string;
  tokenid: string;
  token: string;
  powerbiid: string;
  selectedCulture: string;
  browserRefresh: boolean;

  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private translateService: TranslateService,
    private embeddedReportService: EmbeddedReportService, private systemSettings: SystemSettings,
    public loadingService: LoadingService) { }

  ngOnInit(): void {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent ),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      if (this.powerbiid != this.activeRoute.snapshot.params.id) {
        this.browserRefresh = browserRefresh;
        this.powerbiid = this.activeRoute.snapshot.params.id;
        this.getReports();
      }
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh)
        this.getReports();
      else
        this.browserRefresh = false;
    });

  }

  getReports() {
    this.selectedCulture = localStorage.getItem('culture');
    let defaultCulture = this.systemSettings.defaultPlatformLanguage;

    this.loadingService.doLoading(this.embeddedReportService.getEmbeddedReport(this.powerbiid, this.selectedCulture, defaultCulture), this).subscribe(data => {
        this.id = data.embedResult.id;
        this.url = data.embedResult.embedUrl;
        this.token = data.embedResult.embedToken.token;
        this.tokenid = data.embedResult.embedToken.tokenId;

        // Report's Secured Token
        let accessToken = this.token;
        // Embed URL
        let embedUrl = this.url;
        // Report ID
        let embedReportId = this.id;

        let config = {
          type: 'report',
          tokenType: 1,
          accessToken: accessToken,
          embedUrl: embedUrl,
          id: embedReportId,
          permissions: 7,
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: true
          }
        };

        // Grab the reference to the div HTML element that will host the report.
        let reportContainer = <HTMLElement>document.getElementById('reportContainer');

        // Embed the report and display it within the div container.
        let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        let report = powerbi.embed(reportContainer, config);

        // Report.off removes a given event handler if it exists.
        report.off("loaded");

        // Report.on will add an event handler which prints to Log window.
        report.on("loaded", function () {
          console.log("Loaded");
        });
      });
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }
}

