import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@core/shared/shared.module';
import { LegalPageComponent } from './legal-pages/legal-page.component';
import { LegalPagesServices } from './services/legalpages.services';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        SharedModule
    ],   
    declarations: [
        LegalPageComponent
    ],
    providers:[
        LegalPagesServices
    ]
})
export class LegalPageModule { }