import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { LoadingService } from '@core/shared';
import { HubPageService } from '@core/private/hubpage/services/hub-page.service';

@UntilDestroy()
@Component({
    selector: 'app-hub-participant-goal-quest',
    templateUrl: 'participant-goal-quest.component.html',
    styleUrls: ['goal-quest-participant.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ParticipantGoalQuestComponent implements OnChanges {
    @Input() componentId: string;
    @Input() payload: any;
    @Input() culture: string;
    @Input() showProgress: boolean = false;

    goalQuest: any;
    selectedCulture: string;
    goalSelectionRemainingTime: number;
    goalProgressRemainingTime: number;
    isMinimumQualifierEnabled: boolean;
    isGoalSelected: boolean;
    selectedGoal: any;
    activeStage: number;
    minimumQualifier: any;
    progressStyle: any;
    isProgramStarted: boolean;

    constructor(private hubPageService: HubPageService, public loadingService: LoadingService) { }

    ngOnChanges() {
        let data = { item: JSON.stringify(this.payload) };
        this.loadingService.doLoading(this.hubPageService.getHubComponent(this.componentId, this.culture, data), this, "participantgq")
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.goalQuest = response;
                this.calculateGoalQuestProgress();
            });
    }

    calculateGoalQuestProgress() {
        this.selectedCulture = localStorage.getItem('culture');
        if (this.goalQuest && this.goalQuest.rewards && this.goalQuest.rewards.length > 0) {            
            this.isProgramStarted = this.calculateDateDifference(new Date(this.goalQuest.goalSelectionStartDate), new Date()) > 0;
            this.goalSelectionRemainingTime = this.calculateDateDifference(new Date(), new Date(this.goalQuest.goalSelectionEndDate))
            this.goalProgressRemainingTime = this.calculateDateDifference(new Date(), new Date(this.goalQuest.promotionEndDate))
            this.isMinimumQualifierEnabled = this.goalQuest.isMinimumQualifier;
            if (this.isMinimumQualifierEnabled) {
                this.minimumQualifier = this.goalQuest.minimumQualifier;
                this.minimumQualifier['achievement'] = this.goalQuest.minimumQualifierAchieved;
            }
            this.getSelectedReward();
            this.findStage();
        }
    }

    calculateDateDifference(startDate, endDate) {
        var startDateUtc = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
        var endDateUtc = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());

        return (endDateUtc - startDateUtc) / 1000;
    }

    getSelectedReward() {
        let selectedReward = this.goalQuest.rewards.find(reward => reward.selected);
        if (selectedReward)
            this.selectedGoal = selectedReward;
    }

    findStage() {
        this.progressStyle = {
            'stage1': { 'selected': 'selected', 'active': 'inactive' },
            'stage2': { 'selected': '', 'active': 'inactive' },
            'stage3': { 'selected': '', 'active': 'inactive' },
            'width': '0%'
        };
        if (this.goalSelectionRemainingTime > 0) {
            this.activeStage = 1;
            this.progressStyle.stage1.active = 'active';
        }
        else if (this.goalProgressRemainingTime > 0) {
            this.activeStage = 2;
            if (!this.selectedGoal)
                this.selectedGoal = this.goalQuest.rewards[0];

            this.progressStyle.stage1.active = 'active';
            this.progressStyle.stage2.active = 'active';
            this.progressStyle.stage2.selected = 'selected';
            this.progressStyle.width = '50%';
        }
        else {
            this.activeStage = 3;
            if (!this.selectedGoal)
                this.selectedGoal = this.goalQuest.rewards[0];

            this.progressStyle.stage1.active = 'active';
            this.progressStyle.stage2.active = 'active';
            this.progressStyle.stage3.active = 'active';
            this.progressStyle.stage2.selected = 'selected';
            this.progressStyle.stage3.selected = 'selected';
            this.progressStyle.width = '100%'
        }
    }

    selectTarget(target) {
        let payload = { goalQuestPageId: this.goalQuest.id, targetLevel: target };
        this.hubPageService.changeReward(payload).subscribe(response => {
            this.goalQuest.rewards.forEach(x => {
                x.selected = x.targetLevel === target ? true : false;
            });
            this.getSelectedReward();
            this.findStage();
        });
    }
}