<div class="bg-color3 thresholdPage">
  <div class="home-page">
    <div class="bg-color3 home-page-bg">
      <div class="content-width home-point-gallery-section">
        <!-- Right side -->
        <div class="flex-1 home-page-slide rightPane">
          <div class="content-width">
            <h1 class="sub-pageHeader">
              {{ "NotificationCorner.notifications" | translate }}
            </h1>
          </div>

          <div>
            <div class="claim-filter-section">
              <div class="filter-section margin-bottom-10">
                <div>
                  <label class="margin-right-5 margin-top-5">
                    {{
                    "NotificationCorner.ViewNotifications" | translate
                    }}</label>
                  <select class="customField" [(ngModel)]="filter.status">
                    <option value="">All</option>
                    <option value="read">Read</option>
                    <option value="unread">Unread</option>
                  </select>
                </div>
                <div>
                  <label class="margin-right-5 margin-top-5">
                    {{
                    "NotificationCorner.SearchByTitle" | translate
                    }}</label>
                  <input #couponId placeholder="{{'NotificationCorner.enterTitle' | translate}}"
                    [(ngModel)]="filter.title" class="customField input-text-filter" />
                </div>
                <div class="mr-3">
                  <div class="form-group item input-calender">
                    <div class="input-group">
                      <label for="fromdate" class="datePlacement lbl-date">{{
                        "NotificationCorner.SearchByDate" | translate
                        }}</label>
                      <input class="form-control datePickerCalander "
                        placeholder="{{'NotificationCorner.searchDate' | translate}}" name="date" ngbDatepicker
                        #dpsellingFromDate="ngbDatepicker" [maxDate]="maxNotificationFromDate"
                        [minDate]="minNotificationToDate" readonly [(ngModel)]="filter.date"
                        (ngModelChange)="blockOldDateSales('from')" />
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" type="button"
                          (click)="dpsellingFromDate.toggle()"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button class="btn btn-primary customBtnSearch" (click)="onSearch()">
                    Search
                  </button>
                </div>
              </div>
              <div [appIsLoading]="loadingService.isLoading(this)" class="notification-records">
                <div *ngIf="paginationDetails.totalNumberOfItems > 0">
                  <div>
                    <div>
                      <p>
                        {{ "TableRecords.FoundTotalOf" | translate
                        }}{{ paginationDetails.totalNumberOfItems
                        }}{{ "TableRecords.matches" | translate }}
                      </p>
                    </div>
                  </div>
                  <div *ngFor="let notification of notifications">
                    <div class="card" [class]="
                        !notification.isRead ? 'unread-message-card' : ''
                      ">
                      <div class="card-body">
                        <div class="notification-body">
                          <div class="flex-center">
                            <div class="dot"></div>
                            <div class="flex-center">
                              <h3 class="margin-left-15">
                                <strong>
                                  {{ notification.title }}
                                </strong>
                              </h3>
                            </div>
                          </div>
                          <div class="remove-button" (click)="deleteMessage(notification)">
                            <span class="hide-on-mobile">Remove &nbsp;</span><span class="close-icon">&#x2715;</span>
                          </div>
                        </div>
                        <div class="view-section notification-body">
                          <a (click)="viewMessage(notification)">View Details</a>
                          <div class="notification-date-time hide-on-mobile">
                            <fa-icon [icon]="faClock"></fa-icon><span>{{
                              notification.createdDate
                              | localizedDate
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12" *ngIf="paginationDetails.totalNumberOfItems > 0">
                    <div class="col-lg-12 pagination-center-Bottom">
                      <cs-custom-pagination [paginationDetails]="paginationDetails" (onChangeEvent)="onChange($event)">
                      </cs-custom-pagination>
                    </div>
                  </div>
                </div>
                <div class="invoiceApprovalNoData alert alert-danger"
                  *ngIf="paginationDetails.totalNumberOfItems === 0">
                  {{ "NotificationCorner.NoRecordsFound" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Left side -->
        <div class="home-left leftPane">
          <cs-profile-point-card theme="theme-white" buttonTheme="theme2"></cs-profile-point-card>
          <cs-most-redeem-products [singleView]="true"></cs-most-redeem-products>
        </div>
      </div>
    </div>
  </div>
</div>