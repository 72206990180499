import { Injectable } from '@angular/core';
import { Router, Resolve } from '@angular/router';
import { PlatformSystemSettings } from '@app/core/models';
import { Observable } from 'rxjs';
import { SharedService } from '../services/shared.service';

@Injectable()
export class SettingsResolver implements Resolve<Observable<PlatformSystemSettings> | PlatformSystemSettings> {
    constructor(public router: Router, private sharedService: SharedService) { }
    resolve() {
        if (localStorage.getItem('platformSettings'))
            return JSON.parse(localStorage.getItem('platformSettings'));
        else
            return this.sharedService.getPlatformSettings();
    }
}