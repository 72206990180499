import moment from 'moment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { filter, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { browserRefresh } from '@app/app.component';

import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoadingService } from '@core/shared';

import { SearchCriteria } from '@core/shared/models/search-criteria';
import { Pagination } from '@core/shared/models/pagination';
import { Sorter } from '@core/shared/models/sorter';

import { MyAwardsFilter } from '../view-models/my-awards.filter';
import { MyAwardsService } from '../services/my-awards.service';

@UntilDestroy()
@Component({
  selector: 'my-awards',
  templateUrl: './my-awards.component.html',
  styleUrls: ['./my-awards.component.scss']
})
export class MyAwardsComponent implements OnInit, OnDestroy {  
  paginationDetails: Pagination = new Pagination();
  searchCriteria: SearchCriteria = new SearchCriteria('Date of Sale');
  sorter: Sorter = new Sorter('Date of Sale');
  filter: MyAwardsFilter;
  myAwardsDetails: any;
  awardsPageId: string;
  attributeHeaders: string[] = [];

  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;

  browserRefresh: boolean;
  selectedCulture: string;

  maxFromDate: any;
  minToDate: any;

  maxClaimFromDate: any;
  minClaimToDate: any;


  constructor(private myAwardsService: MyAwardsService, private activeRoute: ActivatedRoute, private router: Router,
    public loadingService: LoadingService, public translate: TranslateService) {
    this.filter = new MyAwardsFilter();
  }

  ngOnInit(): void {
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent ),      
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      if (this.awardsPageId != this.activeRoute.snapshot.params.id) {
        this.browserRefresh = browserRefresh;
        this.awardsPageId = this.activeRoute.snapshot.params.id;
        this.getMyAwards();
      }
    });

    this.languageChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (!this.browserRefresh)
        this.getMyAwards();
      else
        this.browserRefresh = false;
    });
  }

  getMyAwards() {

    let payload = Object.assign({}, this.filter);

    payload.salesStartDate = this.filter.salesStartDate ? moment(this.filter.salesStartDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T00:00:00" : null;
    payload.salesEndDate = this.filter.salesEndDate ? moment(this.filter.salesEndDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T23:59:59.999" : null;
    payload.claimsStartDate = this.filter.claimsStartDate ? moment(this.filter.claimsStartDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T00:00:00" : null;
    payload.claimsEndDate = this.filter.claimsEndDate ? moment(this.filter.claimsEndDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T23:59:59.999" : null;

    this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
    this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
    this.searchCriteria.sortField = this.sorter.sortColumn;
    this.searchCriteria.sortAscending = this.sorter.sortDirection;
    this.searchCriteria.additionalData = payload;

    this.loadingService.doLoading(this.myAwardsService.getMyAwards(this.awardsPageId, this.searchCriteria), this)
      .pipe(untilDestroyed(this)).subscribe(response => {
        this.myAwardsDetails = response.items;
        this.setAttributeHeaders();
        this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
      });
  }

  restrictSalesFromToDate(value) {
    let tempFromDate = new Date(this.convertToDateFormat(this.filter.salesStartDate));
    let tempToDate = new Date(this.convertToDateFormat(this.filter.salesEndDate));

    if (value === 'from') {
      if (this.filter.salesStartDate !== null) {
        this.minToDate = { year: tempFromDate.getFullYear(), month: tempFromDate.getMonth() + 1, day: tempFromDate.getDate() };
      }
    }
    else {
      if (this.filter.salesEndDate !== null) {
        this.maxFromDate = { year: tempToDate.getFullYear(), month: tempToDate.getMonth() + 1, day: tempToDate.getDate() };
      }
    }
  }
  restrictClaimFromToDate(value) {
    let tempFromDate = new Date(this.convertToDateFormat(this.filter.claimsStartDate));
    let tempToDate = new Date(this.convertToDateFormat(this.filter.claimsEndDate));

    if (value === 'from') {
      if (this.filter.salesStartDate !== null) {
        this.minClaimToDate = { year: tempFromDate.getFullYear(), month: tempFromDate.getMonth() + 1, day: tempFromDate.getDate() };
      }
    }
    else {
      if (this.filter.salesEndDate !== null) {
        this.maxClaimFromDate = { year: tempToDate.getFullYear(), month: tempToDate.getMonth() + 1, day: tempToDate.getDate() };
      }
    }
  }
  convertToDateFormat(date) {
    if (date) {
      let splitDate1 = date.split('-');
      let day = splitDate1[0];
      let month = splitDate1[1];
      let year = splitDate1[2];
      return month + "-" + day + "-" + year;
    }
  }


  setAttributeHeaders() {
    this.attributeHeaders = [];
    if (this.myAwardsDetails.length > 0) {
      this.myAwardsDetails[0].attributes.forEach(attribute => {
        this.attributeHeaders.push(attribute.name)
      });
    }
  }

  onSearch() {
    this.getMyAwards();
  }
  onClear() {
    this.filter.salesStartDate = "";
    this.filter.salesEndDate = "";
    this.filter.claimsStartDate = "";
    this.filter.claimsEndDate = "";
    this.filter.searchTerm = "";
    this.getMyAwards();
  }

  onChange(pagination: Pagination) {
    this.paginationDetails = pagination;
    this.getMyAwards();
  }

  onSort(sortField) {
    this.sorter.sort(sortField);
    this.getMyAwards();
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe()
  }

}