import { Routes } from '@angular/router';
import { CatalogueaccessComponent } from './catalogueaccess.component';

export const CatalogueAccessRoutes: Routes = [
    {
        path: 'catalogue/verification/:menuType',
        component: CatalogueaccessComponent,
        pathMatch: "full",
        data: { title: "Rewards Login with OTP", isPreLogin: false },
    }
];