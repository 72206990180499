import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ContactFormBase } from "../contact-us/types";

@Component({
  selector: "app-form-builder",
  templateUrl: "./form-builder.component.html",
  styleUrls: ["./form-builder.component.scss"],
})
export class FormBuilderComponent {
  @Input() contact!: ContactFormBase<string>;
  @Input() form!: FormGroup;
  @Input() countries: any;
  @Output() onChange = new EventEmitter();
  
  get isValid() {
    return this.form.controls[this.contact.attribute].valid;
  }
  get isTouched() {
    return this.form.controls[this.contact.attribute].touched;
  }

  onChangeValue(e, val) {
    const emitData: any = {
      contextId: val.id,
      type: val.type,
      value: e.target.value,
    };
    this.onChange.emit(emitData);
  }
}
