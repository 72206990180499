import { Injectable } from "@angular/core";

@Injectable({
    providedIn:'root'
})



export class PreBuiltTemplateStyleManagerService{

    setStyle(key:string,style:string){
        getLinkElementForKey(key,style).innerHTML = style;
    }

    removeStyle(key: string) {
        const existingLinkElement = getExistingLinkElementByKey(key);
        if (existingLinkElement) {
            document.head.removeChild(existingLinkElement);
        }
    }
}

function getLinkElementForKey(key: string,style:string) {
    return getExistingLinkElementByKey(key) || createLinkElementWithKey(key,style);
}

function getExistingLinkElementByKey(key: string) {
    return document.head.querySelector(
        `style#s_${key}`
    );
}

function createLinkElementWithKey(key: string,style:string) {
    const linkEl = document.createElement("style");
    linkEl.setAttribute("rel", "text/css");
    linkEl.setAttribute("id", `s_${key}`);
    document.head.appendChild(linkEl);
    return linkEl;
}