<div [appIsLoading]="loadingService.isLoading(this)">
    <div class="row" *ngFor="let groups of components">
        <div class="col-md-12" *ngIf="groups.enableCarousel">
            <div class="card" style="margin-bottom: 20px;">
                <div class="card-body">
                    <ngx-slick-carousel class="carousel" [config]="slideConfig" #slickModal="slick-carousel">
                        <div ngxSlickItem *ngFor="let item of groups.Items" class="slide">
                            <div [ngSwitch]="item.type">
                                <app-hub-cms *ngSwitchCase="'cms'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-cms>

                                <app-hub-my-threshold-awards *ngSwitchCase="'mythresholdawards'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-my-threshold-awards>

                                <app-hub-message-page *ngSwitchCase="'message'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-message-page>

                                <app-hub-myaward-page *ngSwitchCase="'myawards'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-myaward-page>

                                <app-hub-league-page *ngSwitchCase="'league'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item"></app-hub-league-page>

                                <app-hub-participant-goal-quest *ngSwitchCase="'goalquest'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-participant-goal-quest>

                                <app-hub-points-summary *ngSwitchCase="'pointssummary'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-points-summary>

                                <app-hub-manager-goal-quest *ngSwitchCase="'managergoalquest'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-manager-goal-quest>

                                <app-hub-claim *ngSwitchCase="'claimform'" [componentId]="item.selectedItem.id"
                                    [culture]="selectedCulture" [payload]="item">
                                </app-hub-claim>

                                <app-hub-performance-page *ngSwitchCase="'promotion'"
                                    [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                                </app-hub-performance-page>

                                <div *ngSwitchDefault>{{item.type}}</div>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
        <div style="width: 100%;display: flex;margin-bottom: 20px;" *ngIf="!groups.enableCarousel">
            <div [class]="{'col-md-12': groups.Items.length === 1, 'col-md-6': groups.Items.length === 2, 'col-md-4': groups.Items.length === 3}"
                *ngFor="let item of groups.Items">
                <div class="card" [class]="{'no-border': item.type  == 'mythresholdawards' }">
                    <div class="card-body" [class]="{'bg-color3': item.type  == 'mythresholdawards' }">
                        <div [ngSwitch]="item.type">
                            <app-hub-cms *ngSwitchCase="'cms'" [componentId]="item.selectedItem.id"
                                [culture]="selectedCulture" [payload]="item"></app-hub-cms>

                            <app-hub-my-threshold-awards *ngSwitchCase="'mythresholdawards'"
                                [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                            </app-hub-my-threshold-awards>

                            <app-hub-message-page *ngSwitchCase="'message'" [componentId]="item.selectedItem.id"
                                [culture]="selectedCulture" [payload]="item"></app-hub-message-page>

                            <app-hub-myaward-page *ngSwitchCase="'myawards'" [componentId]="item.selectedItem.id"
                                [culture]="selectedCulture" [payload]="item"></app-hub-myaward-page>

                            <app-hub-league-page *ngSwitchCase="'league'" [componentId]="item.selectedItem.id"
                                [culture]="selectedCulture" [payload]="item"></app-hub-league-page>

                            <app-hub-participant-goal-quest *ngSwitchCase="'goalquest'"
                                [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                            </app-hub-participant-goal-quest>

                            <app-hub-points-summary *ngSwitchCase="'pointssummary'" [componentId]="item.selectedItem.id"
                                [culture]="selectedCulture" [payload]="item"></app-hub-points-summary>

                            <app-hub-manager-goal-quest *ngSwitchCase="'managergoalquest'"
                                [componentId]="item.selectedItem.id" [culture]="selectedCulture" [payload]="item">
                            </app-hub-manager-goal-quest>

                            <app-hub-claim *ngSwitchCase="'claimform'" [componentId]="item.selectedItem.id"
                                [culture]="selectedCulture" [payload]="item">
                            </app-hub-claim>

                            <app-hub-performance-page *ngSwitchCase="'promotion'" [componentId]="item.selectedItem.id"
                                [culture]="selectedCulture" [payload]="item">
                            </app-hub-performance-page>

                            <div *ngSwitchDefault>{{item.type}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>