import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { browserRefresh } from '@app/app.component';
import { SearchCriteria } from '@core/shared/models/search-criteria';
import { Pagination } from '@core/shared/models/pagination';
import { Sorter } from '@core/shared/models/sorter';
import { SalesClaimFilter } from '../view-models/sales-claim.filter';
import { SalesClaimService } from '../services/sales-claim.service';
import { AuthenticationService } from '@core/startup';
import { SharedService, ChallengeModalComponent, ResolveChallengeModalComponent, RejectClaimComponent, ClaimErrorComponent, LoadingService } from '@core/shared';
import * as fromStore from '@core/store';

@UntilDestroy()
@Component({
  selector: 'app-sales-claim',
  templateUrl: 'sales-claim.component.html',
  styleUrls: ['sales-claim.component.scss']
})
export class SalesClaimComponent implements OnInit, OnDestroy {
  navigationStartSubscription: Subscription;
  languageChangeSubscription: Subscription;
  browserRefresh: boolean;
  selectedCulture: any;
  userId: string;
  username: string;
  claimStatuses: any[] = [];
  filter: SalesClaimFilter;
  claimFormId: string;
  userIsResolver: boolean = false;
  configuration: any
  sales: any;
  attributeHeaders: any[] = [];
  paginationDetails: Pagination = new Pagination();
  searchCriteria: SearchCriteria = new SearchCriteria('Date of Sale');
  sorter: Sorter = new Sorter('Date of Sale', false);
  maxFromDate: any;
  minFromDate: any;
  maxToDate: any;
  minToDate: any;
  instructions: string;
  helpTitle: string;
  private onDestroy$ = new Subject<void>();

  claimStatus = {
    UNCLAIMED: "Unclaimed",
    CLAIMED: "Claimed",
    CHALLENGED: "Challenged",
    RESOLVED: "Resolved",
    APPROVED: "Approved",
    REJECTED: "Rejected"
  };

  constructor(private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private authService: AuthenticationService,
    private salesClaimService: SalesClaimService, private sharedService: SharedService, private translateService: TranslateService,
    private store: Store<fromStore.PlatformState>, public loadingService: LoadingService) { }

  ngOnInit() {
    this.filter = new SalesClaimFilter();
    this.navigationStartSubscription = this.router.events.pipe(
      filter((event) => event instanceof RouterEvent  ),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      this.selectedCulture = localStorage.getItem('culture');
      if (this.claimFormId != this.route.snapshot.params.id) {
        this.browserRefresh = browserRefresh;
        this.claimFormId = this.route.snapshot.params.id;
        this.init();
      }
    });

    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedCulture = localStorage.getItem('culture');
      if (!this.browserRefresh)
        this.init();
      else
        this.browserRefresh = false;
    });

    this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
      if (state.user && !this.userId) {
        this.userId = state.user.Id;
        this.username = state.user.Username;
      }
    });

    this.setupClaimStatusFilter();

  }

  init() {
    this.loadingService.doLoading(this.sharedService.getClaimConfiguration(this.claimFormId), this, 'configuration')
      .pipe(untilDestroyed(this)).subscribe(response => {
        this.configuration = response;
        this.setupUserGuide(this.configuration);

        this.getSales();
        if (this.configuration.resolvingAudienceId)
          this.setResolvingUser();
        if (this.configuration.displayToAudienceId)
          this.checkClaimedUser();
      });
  }

  getSales() {
    this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
    this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
    this.searchCriteria.sortField = this.sorter.sortColumn;
    this.searchCriteria.sortAscending = this.sorter.sortDirection;

    this.filter.organisationsListUserId = this.userId;
    let fromDate = this.filter.salesStartDate ? moment(this.filter.salesStartDate, "DD-MM-YYYY").format("YYYY-MM-DD") + "T00:00:00" : null;
    let toDate = this.filter.salesEndDate ? moment(this.filter.salesEndDate, "DD-MM-YYYY").format("YYYY-MM-DD") + "T23:59:59.999" : null;
    let payload = Object.assign({}, this.filter, { salesStartDate: fromDate, salesEndDate: toDate });
    this.searchCriteria.additionalData = payload;

    this.loadingService.doLoading(this.salesClaimService.getSales(this.claimFormId, this.searchCriteria), this, 'sales')
      .pipe(untilDestroyed(this)).subscribe({
        next: (response) => {
          this.sales = response.items;
          this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
          this.setAttributeHeaders();
        },
        error: (error) => {
          this.paginationDetails.totalNumberOfItems = 0
          this.openErrorModal(this.getErrorLabel(error.errorMessages[0]));
        }
      });
  }

  setupUserGuide(claimConfiguration) {
    this.instructions = '';
    claimConfiguration.instructions.forEach(instruction => {
      if (instruction.instruction && instruction.culture === this.selectedCulture) {
        this.instructions = instruction.instruction;
        this.helpTitle = instruction.title;
      }
    });
  }

  setAttributeHeaders() {
    if (this.sales.length > 0)
      this.attributeHeaders = this.sales[0].attributes
  }

  checkClaimedUser() {
    this.sharedService.containsUser(this.configuration.displayToAudienceId, this.userId).subscribe((response) => {
      //this.userIsResolver = response
    });
  }

  setResolvingUser() {
    this.sharedService.containsUser(this.configuration.resolvingAudienceId, this.userId).subscribe((response) => {
      this.userIsResolver = response
    });
  }

  claimActionText(sale) {
    if (sale.claimStatus.name === this.claimStatus.UNCLAIMED) {
      return "SalesSearch.SalesList.ActionButton.Claim";
    }
    if (sale.claimStatus.name === this.claimStatus.CLAIMED && sale.sellerId === this.userId) {
      return "SalesSearch.SalesList.ActionButton.Cancel";
    }
    return '';
  }

  challengeActionText(sale) {
    if (sale.claimStatus.name === this.claimStatus.CHALLENGED && sale.lastClaimById === this.userId) {
      return "SalesSearch.SalesList.ActionButton.Cancel";
    }
    if (sale.claimStatus.name === this.claimStatus.CLAIMED && sale.sellerId !== this.userId) {
      return "SalesSearch.SalesList.ActionButton.Challenge";
    }
    return '';
  }

  claim(sale, detail?: string) {
    this.salesClaimService.claim(this.claimFormId, sale.saleId, detail).subscribe({
      next: (response) => {
        this.updateSaleWithClaimResult(sale, response);
      },
      error: (error) => {
        this.openErrorModal(this.getErrorLabel(error.errorMessages[0]));
      }
    });
  }

  challenge(sale) {
    if (sale.claimStatus.name === this.claimStatus.CHALLENGED) {
      this.claim(sale);
    }
    else {
      const modalRef = this.modalService.open(ChallengeModalComponent, { backdrop: 'static' });
      modalRef.result.then((result) => {
        this.claim(sale, result.reason);
      });
    }
  }

  openResolve(sale) {
    this.salesClaimService.checkResolver(this.claimFormId, sale.saleId).subscribe(response => {
      const modalRef = this.modalService.open(ResolveChallengeModalComponent, { backdrop: 'static' });
      modalRef.componentInstance.resolveDto = response;
      modalRef.result.then((result) => {
        this.resolve(sale, result);
      }).catch((error) => {
        console.log(error);
      });
    });
  }

  resolve(sale, resolveDto) {
    this.salesClaimService.resolve(this.claimFormId, sale.saleId,
      { detail: resolveDto.resolveReason, claimedByUserId: resolveDto.winnerId }).subscribe({
        next: (response) => {
          this.updateSaleWithClaimResult(sale, response);
        },
        error: (error) => {
          this.openErrorModal(this.getErrorLabel(error.errorMessages[0]));
        }
      });
  }

  approve(sale) {
    this.salesClaimService.approve(this.claimFormId, sale.saleId,
      { claimedByUserId: sale.sellerId, claimConfigurationId: this.claimFormId, saleId: sale.saleId }).subscribe({
        next: (response) => {
          this.updateSaleWithApproval(sale, response);
        },
        error: (error) => {
          this.openErrorModal(this.getErrorLabel(error.errorMessages[0]));
        }
      });
  }

  rejectClaim(sale, rejectDto) {
    this.salesClaimService.reject(this.claimFormId, sale.saleId, {
      detail: rejectDto.rejectionReason,
      claimedByUserId: rejectDto.claimedByUserId, claimConfigurationId: this.claimFormId, saleId: sale.saleId
    }).subscribe({
      next: (response) => {
        this.updateSaleWithApproval(sale, response);
      },
      error: (error) => {
        this.openErrorModal(this.getErrorLabel(error.errorMessages[0]));
      }
    });
  }

  showRejectModal(sale) {
    this.salesClaimService.checkResolver(this.claimFormId, sale.saleId).subscribe(response => {
      this.openRejectModal(sale, response);
    });
  }

  updateSaleWithClaimResult(sale, result) {
    sale.claimStatus = result.status;
    sale.dateOfStatusChange = result.dateOfClaim;
    sale.sellerId = result.sellerId;
    this.setSellingUser(sale, result.status, result.sellerUsername);
    if (sale.claimStatus.name === this.claimStatus.CHALLENGED) {
      sale.lastClaimById = this.userId;
      sale.canBeChallenged = false;
    }
  }

  updateSaleWithApproval(sale, result) {
    sale.claimStatus = result.status;
    sale.dateOfStatusChange = result.dateOfClaim;
    sale.sellerId = result.sellerId;
    this.setSellingUser(sale, result.status, result.sellerUsername);
  }

  openRejectModal(sale, rejectDto) {
    const modalRef = this.modalService.open(RejectClaimComponent, { backdrop: 'static' });
    modalRef.componentInstance.rejectDto = rejectDto;
    modalRef.result.then((result) => {
      this.rejectClaim(sale, result);
    }).catch((error) => {
      console.log(error);
    });
  }

  setSellingUser(sale, newClaimStatus, username) {
    var sellingUserAttribute = sale.attributes.find(attribute => attribute.name === "Selling User");

    if (sellingUserAttribute) {
      if (newClaimStatus.name === this.claimStatus.CLAIMED || newClaimStatus.name === this.claimStatus.RESOLVED) {
        sellingUserAttribute.valueAsString = username;
      } else {
        sellingUserAttribute.valueAsString = '';
      }
    }
  }

  openErrorModal(errorLabel) {
    const modalRef = this.modalService.open(ClaimErrorComponent);
    modalRef.componentInstance.errorLabel = errorLabel;
    modalRef.result.then((result) => {

    }).catch((error) => {
      console.log(error);
    });
  }

  getErrorLabel(error) {
    return this.toPascalCase(Object.keys(error)[0]);
  }

  toPascalCase(text) {
    var start = text.charAt(0);
    start = start.toUpperCase();
    text = text.substring(1, text.length);
    return start + text;
  }

  onPaginationChange(pagination: Pagination) {
    this.paginationDetails = pagination;
    this.getSales();
  }

  reset() {
    this.filter = new SalesClaimFilter();
    this.getSales();
  }

  setupClaimStatusFilter() {
    for (var property in this.claimStatus) {
      if (this.claimStatus.hasOwnProperty(property)) {
        this.claimStatuses.push({
          value: this.claimStatus[property],
          text: 'SalesSearch.ClaimStatus.' + this.claimStatus[property]
        });
      }
    }
  }

  blockOldDate(value) {
    if (value === 'from') {
      let tempFromDate = new Date(this.convertToDateFormat(this.filter.salesStartDate));
      if (this.filter.salesStartDate !== null) {
        this.minToDate = { year: tempFromDate.getFullYear(), month: tempFromDate.getMonth() + 1, day: tempFromDate.getDate() };
      }
    }
    else {
      let tempToDate = new Date(this.convertToDateFormat(this.filter.salesEndDate));
      if (this.filter.salesEndDate !== null) {
        this.maxFromDate = { year: tempToDate.getFullYear(), month: tempToDate.getMonth() + 1, day: tempToDate.getDate() };
      }
    }
  }

  convertToDateFormat(date) {
    let splitDate1 = date.split('-');
    let day = splitDate1[0];
    let month = splitDate1[1];
    let year = splitDate1[2];
    return month + "-" + day + "-" + year;
  }


  isShownDiv: boolean = false; // hidden by default
  toggleShows() {
    this.isShownDiv = !this.isShownDiv;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe()
  }

}
