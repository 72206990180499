<ul class="dropdown-menu">
    <li subMenuHover [ngClass]="{'dropdown-submenu': item.children.length > 0}" *ngFor="let item of menu">
        <a *ngIf="item.menuType !== 'expand' && item.menuType != 'externalLink' && item.menuType !== 'merchandise' && 
        item.menuType !== 'experience' && item.menuType !== 'mastery'" [routerLink]="item.url | lowercase" class="dropdown-item"
            [ngClass]="{'dropdown-toggle': item.children.length > 0}">
            {{item.translations[selectedCulture]? item.translations[selectedCulture]:
            item.translations[defaultCulture]}}
        </a>
        <a *ngIf="item.menuType === 'merchandise' || item.menuType === 'experience' || item.menuType === 'mastery'" class="nav-link" (click)="rewardCatalogue(item.menuType)">
            {{item.translations[selectedCulture]? item.translations[selectedCulture]:
                item.translations[defaultCulture]}}
        </a>
        <a target="blank" *ngIf="item.menuType == 'externalLink'" class="nav-link" [href]="item.url">
            {{item.translations[selectedCulture]? item.translations[selectedCulture]:
            item.translations[defaultCulture]}}
        </a>
        <a *ngIf="item.menuType ==='expand' && !item.isForTabView" class="nav-link" [href]=""
            [ngClass]="{'dropdown-toggle': item.children.length > 0}">
            {{item.translations[selectedCulture]? item.translations[selectedCulture]:
            item.translations[defaultCulture]}}
        </a>
        <a *ngIf="item.menuType ==='expand' && item.isForTabView" class="nav-link" [routerLink]="item.url | lowercase">
            {{item.translations[selectedCulture]? item.translations[selectedCulture]:
            item.translations[defaultCulture]}}
        </a>
        <sub-menu-component *ngIf="item.children.length > 0 && !item.isForTabView" [subMenu]="item.children"
            [selectedCulture]="selectedCulture" [defaultCulture]="defaultCulture"></sub-menu-component>
    </li>
</ul>