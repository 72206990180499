import { Injectable } from "@angular/core";
import { MEMBERSHIP_ACHIEVED_POINTS, MEMBERSHIP_TIER_ACTIVE_TIERS, MEMBERSHIP_TIER_HISTORY, UrlBuilder } from "@app/core/core-api-variables";
import { HttpService } from "@app/core/shared";
import { map } from "rxjs/operators";

@Injectable()
export class MemberShipTieringService {
    constructor(public http: HttpService) { }

    getActiveTiers() {
        return this.http.get(MEMBERSHIP_TIER_ACTIVE_TIERS).pipe(map((response: any) => {
            return response;
        }))
    }

    getEarnedPoints() {
      return this.http.get(MEMBERSHIP_ACHIEVED_POINTS).pipe(map((response: any) => {
          return response;
      }))
    }

    getTierHistory(data: any) {
      let url = UrlBuilder(MEMBERSHIP_TIER_HISTORY, data);
      return this.http.get(url).pipe(map((response: any) => {
        return response;
      }));
    }

}