import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
    UrlBuilder, GET_HUB_PAGE_COMPONENTS, GET_HUB_PAGE_COMPONENT, CHANGE_REWARD, GET_POINT_SUMMARY, GET_SALES, CHECK_RESOLVER, CLAIM, APPROVE,
    REJECT, RESOLVE, GET_GOALQUEST_MANAGER_HUB_PAGE, HUB_PAGE_THRESHOLD_SKU_STRUCTURE, HUB_PAGE_THRESHOLD_POINTHISTORY,
    HUB_PAGE_THRESHOLD_USER_ACHIEVEMENTS,
    BRIKS_PROMOTION_DATA,
    GET_MONTHLY_SALES,
    GET_PREBUILT_TEMPLATE_CSS
} from '@core/core-api-variables';
import { HttpService } from '@core/shared';
import { SearchCriteria } from '@core/shared/models/search-criteria';
import { ParamsBuilderService } from '@core/shared/services/params-builder.service';
import { Observable } from 'rxjs';

@Injectable()
export class HubPageService {
    constructor(private http: HttpService, private paramsBuilderService: ParamsBuilderService) { }

    getHubComponents(hubPageId, culture) {
        let data = { hubPageId, culture }
        let url = UrlBuilder(GET_HUB_PAGE_COMPONENTS, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    getPromotion(promotionId: any): Observable<any> {
        let data = { promotionId };
        let url = UrlBuilder(BRIKS_PROMOTION_DATA, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    getPointHistory(data: any) {
        let url = UrlBuilder(HUB_PAGE_THRESHOLD_POINTHISTORY, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    getHubComponent(componentId, culture, payload) {
        let data = { componentId, culture }
        let url = UrlBuilder(GET_HUB_PAGE_COMPONENT, data);
        return this.http.post(url, payload).pipe(map((response: any) => {
            return convertPropertyNames(response, pascalCaseToCamelCase);
        }));
    }

    getGoalQuestHubComponent(id) {
        let data = { id };
        let url = UrlBuilder(GET_GOALQUEST_MANAGER_HUB_PAGE, data);
        return this.http.get(url).pipe(map((response: any) => {
            return convertPropertyNames(response, pascalCaseToCamelCase);
        }));
    }

    changeReward(payload) {
        let url = CHANGE_REWARD;
        return this.http.post(url, payload).pipe(map((response: any) => {
            return response;
        }));
    }

    getPointsummary() {
        return this.http.get(GET_POINT_SUMMARY).pipe(map((response: any) => {
            return response;
        }));
    }

    getSKUPointsStructure(id) {
        let url = UrlBuilder(HUB_PAGE_THRESHOLD_SKU_STRUCTURE, id);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    getUserAchievement(data: any) {
        const url = UrlBuilder(HUB_PAGE_THRESHOLD_USER_ACHIEVEMENTS, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    getPointsHistory(url) {
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    getSales(claimFormId, searchCriteria: SearchCriteria) {
        let params = this.paramsBuilderService.buildParams(searchCriteria);
        let data = { claimFormId, params };
        let url = UrlBuilder(GET_SALES, data);

        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    checkResolver(claimFormId, saleId) {

        let data = { claimFormId, saleId };
        let url = UrlBuilder(CHECK_RESOLVER, data);

        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    claim(claimFormId, saleId, detail) {
        let payload = { Detail: detail };
        let data = { claimFormId, saleId };
        let url = UrlBuilder(CLAIM, data);

        return this.http.post(url, payload).pipe(map((response: any) => {
            return response;
        }));
    }

    resolve(claimFormId, saleId, payload) {

        let data = { claimFormId, saleId };
        let url = UrlBuilder(RESOLVE, data);

        return this.http.post(url, payload).pipe(map((response: any) => {
            return response;
        }));

    }

    approve(claimFormId, saleId, payload) {

        let data = { claimFormId, saleId };
        let url = UrlBuilder(APPROVE, data);

        return this.http.post(url, payload).pipe(map((response: any) => {
            return response;
        }));
    }

    reject(claimFormId, saleId, payload) {

        let data = { claimFormId, saleId };
        let url = UrlBuilder(REJECT, data);

        return this.http.post(url, payload).pipe(map((response: any) => {
            return response;
        }));
    }

    getMontlySales(promotionId, promotionType) {
        let data = { promotionId, promotionType };
        let url = UrlBuilder(GET_MONTHLY_SALES, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }
    
    getPreBuiltTemplateCss():Observable<any>{
        return this.http.get(GET_PREBUILT_TEMPLATE_CSS);
      }
}

let pascalCasePattern = new RegExp("^([A-Z])([a-z]+)");
function pascalCaseToCamelCase(propname) {
    if (pascalCasePattern.test(propname)) {
        return propname.charAt(0).toLowerCase() + propname.slice(1);
    }
    else {
        return propname;
    }
}

function convertPropertyNames(obj, converterFn) {
    let r, value, t = Object.prototype.toString.apply(obj);
    if (t == "[object Object]") {
        r = {};
        for (let propname in obj) {
            value = obj[propname];
            r[converterFn(propname)] = convertPropertyNames(value, converterFn);
        }
        return r;
    }
    else if (t == "[object Array]") {
        r = [];
        for (let i = 0, L = obj.length; i < L; ++i) {
            value = obj[i];
            r[i] = convertPropertyNames(value, converterFn);
        }
        return r;
    }
    return obj;
}