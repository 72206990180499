import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-goal-result-manager',
  templateUrl: './goal-result-manager.component.html'
})
export class GoalResultManagerComponent {
  @Input() goalAchievedUsers: any;
  @Input() mostRedeemedProducts: any;

  constructor() { }

  calculateAchievedPercentage(selectedCount, achievedCount) {
    return (selectedCount && achievedCount) ? (achievedCount / selectedCount) * 100 : 0;
  }

}
