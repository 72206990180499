import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { browserRefresh } from '@app/app.component';
import { CommunicationService } from '../services/communication.service';

@Component({
    selector: 'app-communication-goalquest',
    templateUrl: 'goalquest.component.html'
})

export class GoalQuestComponent implements OnInit {
    promotionId: string;
    promotion: any;
    communicationId: string;
    communicationPage: any[];
    selectedCulture: string;
    browserRefresh: boolean;
    languageChangeSubscription: Subscription;
    navigationStartSubscription: Subscription;

    constructor(private router: Router, private route: ActivatedRoute, private translateService: TranslateService,
        private communicationService: CommunicationService) { }

    ngOnInit() {
        this.promotionId = this.route.snapshot.params.id;
        this.navigationStartSubscription = this.router.events.pipe(
            filter((event) => event instanceof RouterEvent ),
            startWith('Initial call')
        ).subscribe((res : NavigationEnd) => {
            if (res.url.startsWith('Initial call')) {
                this.browserRefresh = browserRefresh;
                this.getPromotion();
            }
        });

        this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            if (!this.browserRefresh)
                this.getPromotion();
            else
                this.browserRefresh = false;
        });
    }

    getPromotion() {
        this.communicationService.getPromotion(this.promotionId).subscribe((result) => {
            this.promotion = result;
            let attributesJson = JSON.parse(result.attributesJson);
            if (attributesJson['communicationId']) {
                this.communicationId = attributesJson['communicationId'];
                this.getCommunication();
            }
        })
    }

    getCommunication() {
        this.selectedCulture = localStorage.getItem('culture');
        this.communicationService.getCommunicationComponents(this.communicationId, this.selectedCulture).subscribe((result) => {
            let groups = [];
            result.forEach(group => {
                let items = { Items: [] };
                group.Items.forEach(item => {
                    if (item.hasAccess)
                        items.Items.push(item);
                });
                groups.push(items);
            });
            this.communicationPage = groups;
        })
    }

    arrayHasIndex = (array, index) => Array.isArray(array) && array.hasOwnProperty(index);
}