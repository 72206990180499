import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import moment from 'moment';

import { LoadingService } from '@core/shared';
import { CouponclaimService } from '../services/couponclaim.service';
import { CouponClaimHistoryFilter } from '../view-models/coupon-claim-history.filter';
import { SearchCriteria } from '@core/shared/models/search-criteria';
import { Pagination } from '@core/shared/models/pagination';
import { Sorter } from '@core/shared/models/sorter'

@UntilDestroy()
@Component({
  selector: 'app-coupon-claim-history',
  templateUrl: './coupon-claim-history.component.html',
  styleUrls: ['./coupon-claim-history.component.scss']
})
export class CouponClaimHistoryComponent implements OnInit {
  searchCriteria: SearchCriteria = new SearchCriteria('claimedDate');
  paginationDetails: Pagination = new Pagination();
  sorter: Sorter = new Sorter('claimedDate', false);
  couponHistoryDetails: any;
  filter: CouponClaimHistoryFilter;
  totalNoOfItems: number = 0;

  constructor(private couponclaimService: CouponclaimService, public loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.filter = new CouponClaimHistoryFilter();
    this.filter.status = '';
    this.getCouponClaimHistory();
  }

  getCouponClaimHistory() {
    let payload = Object.assign({}, this.filter);
    payload.claimedDate = this.filter.claimedDate ? moment(this.filter.claimedDate, "DD-MM-YYYY").format('YYYY-MM-DD') + "T00:00:00" : null;
    this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
    this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
    this.searchCriteria.sortField = this.sorter.sortColumn;
    this.searchCriteria.sortAscending = this.sorter.sortDirection;
    this.searchCriteria.additionalData = payload;

    this.loadingService.doLoading(this.couponclaimService.getCouponClaimHistory(this.searchCriteria), this).pipe(untilDestroyed(this)).subscribe(response => {
      if (response.items.length > 0) {
        this.totalNoOfItems = response.totalNumberOfItems;
      }
      this.couponHistoryDetails = response.items;
      this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
    });
  }

  onSearch() {
    this.getCouponClaimHistory();
  }
  onClear() {
    this.filter.claimedDate = "";
    this.filter.couponCode = "";
    this.filter.productId = "";
    this.filter.status = "";
    this.getCouponClaimHistory();
  }

  onChange(pagination: Pagination) {
    this.paginationDetails = pagination;
    this.getCouponClaimHistory();
  }

  onSort(sortField) {
    this.sorter.sort(sortField);
    this.getCouponClaimHistory();
  }

  downloadCSV(): void {
    this.couponclaimService.downloadViewCSV(this.searchCriteria).subscribe((res: any) => {
      let header = res.headers;      
      let contentType = header.get('content-type');      
      let blob = new Blob([res.body], { type: contentType });

      let downloadLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute("href", url);
      downloadLink.setAttribute("download", "CouponClaimHistory.xlsx");
      downloadLink.style.visibility = "hidden";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }
}
