<div>
    <div class="timeLeft">
        <span class="icon-gq-calendar"></span>

        <div
            class="grid grid-gap-10 grid-col-md-50 grid-col-hd-50 grid-col-xs-100 grid-col-sm-100 grid-col-lt-100 goalWrapper">
            <div class="daysLeft">
                <cs-timer [endDate]="endDate"></cs-timer>
            </div>
            <div class="currentDate">
                <p>
                    {{'GoalQuestPage.GoalQuestProgress.Days' | translate}} | <span class="duration">{{startDate | localizedDate}} - {{endDate | localizedDate}}</span>
                </p>
            </div>
        </div>

    </div>

    <div class="gq-goalStatus">

        <div class="grid grid-gap-10 grid-col-md-33 grid-col-hd-33 grid-col-xs-100 grid-col-sm-100 grid-col-lt-100">
            <div class="goal">
                <div class="iconHolder">
                    <span class="icon-gq-flagoutline"></span>
                </div>
                <div class="divider"></div>
                <p><span class="level">{{selectedGoal.targetLevel}}</span>
                    {{'GoalQuestPage.GoalQuestProgress.YourGoalLevel' |
                    translate}}</p>
            </div>


            <div class="goal">
                <div class="iconHolder">
                    <span class="icon-gq-target"></span>
                </div>
                <div class="divider"></div>
                <div>
                    <p>
                        <span class="target">{{selectedGoal.target}}</span>
                        {{'GoalQuestPage.GoalQuestProgress.GoalTarget' | translate}}
                    </p>
                    <p *ngIf="isMinimumQualifierEnabled">
                        <span class="qualify-target">
                            <span class="qualify">{{minimumQualifier.target}}</span>
                            {{'GoalQuestPage.GoalQuestProgress.MinimumQualifyTarget' | translate}}
                        </span>
                    </p>
                </div>
            </div>


            <div class="goal">
                <div class="iconHolder">
                    <span class="icon-gq-badge"></span>
                </div>
                <div class="divider"></div>
                <p>
                    <span class="level">
                        <span *ngIf="!isManualReward">{{selectedGoal.points}}</span>
                        <span *ngIf="isManualReward && selectedGoal.translations">
                            <cs-http-img [httpSrc]="selectedGoal.translations[culture].imageUrl" httpType="view"
                                alternativeText="image" height="40" width="50">
                            </cs-http-img>
                        </span>
                        <span *ngIf="isManualReward && selectedGoal.imageUrl">
                            <cs-http-img [httpSrc]="selectedGoal.imageUrl" httpType="view" alternativeText="image" height="40"
                                width="50">
                            </cs-http-img>
                        </span>
                    </span>
                    <span *ngIf="!isManualReward">{{'GoalQuestPage.GoalQuestProgress.RewardPoints' | translate}}</span>
                </p>
            </div>

        </div>
    </div>

    <div class="gq-goalProgress">
        <div class="row">
            <div class="col-md-4 col-sm-12" *ngIf="isMinimumQualifierEnabled">
                <p>Minimum Qualifier Progress</p>
                <div>
                    <mwl-gauge class="three" [max]="100" [value]="minimumQualifierAchived" [animated]="true"
                        [dialStartAngle]="115" [dialEndAngle]="65" [animationDuration]="3" [label]="percentageValue">
                    </mwl-gauge>
                </div>
            </div>

            <div class="col-sm-12"
                [class]="{'col-md-8': isMinimumQualifierEnabled, 'col-md-12': !isMinimumQualifierEnabled}">
                <div [class]="{'goalProgressOuterWrapper': isMinimumQualifierEnabled}">
                    <p>Goal Progress</p>
                    <div class="goalProgressWrapper">
                        <div class="goalProgressInnerWrapper">
                            <h4 class="progress-achieved">
                                {{'GoalQuestPage.GoalQuestProgress.YouHaveAchieved' | translate}}
                                <span>{{goalAchived | roundOff : 2}}%</span> {{'GoalQuestPage.GoalQuestProgress.Goal' |
                                translate}}
                            </h4>
                            <div class="goalPercentage">
                                <div class="totalPer"></div>
                                <div class="goalAchieved" [style.width.%]="goalAchived"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>