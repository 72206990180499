<ng-container *ngIf="template == 'theme2'">
    <div class="form-input-select theme2" [ngClass]="{'open' : inputState.open}">
        <button class="viewport" type="button" (click)="inputState.open = !inputState.open">
            <input class="view-text" value="{{value ? value : placeHolder}}">
            <img alt="down arrow" class="arrow-icon" src="assets/images/down-arrow_black.png">
        </button>
        <div class="options">           
            <button class="options-item" type="button" *ngFor="let option of options" (click)="inputChanged(option)">
                {{translateLabel ? (option[optionsLabelKey] | translate) : option[optionsLabelKey]}}               
            </button>
        </div>
    </div>
</ng-container>