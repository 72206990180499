<div class="flex-container grid-col-md-20 grid grid-gap-15 grid-col-xs-100 grid-col-xxs-100 grid-col-lt-50 grid-col-sm-50 grid-col-hd-20">
  <div>
    <span>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
        <g>
          <path d="M261.3,487c-37.2-9.9-74.4-20-111.8-29.3c-5.7-1.4-12.6,0.8-18.8,2.3c-3.9,1-7.4,3.8-11.2,5.5c-4.1,1.8-6.7,3.9-6.3,9.2
		c0.4,5.2-3.5,8.1-8.5,8c-21.3-0.5-42.6-1.1-63.8-1.9c-6.1-0.2-7.8-4.5-7.7-10c0.6-19,1-38,1.6-57c0.7-24.6,1.5-49.2,2.2-73.9
		c0.2-7.6,2.8-10.3,10.3-10.1c20.2,0.5,40.5,1,60.7,1.6c6.7,0.2,9.1,3,9.2,9.9c0,3.3,0,6.6,0,9.8c9.3-2.8,18-6.2,27.1-8.1
		c24.1-4.9,47.4-2,69.4,9.2c12.6,6.4,25.5,9.9,39.6,9.7c14.2-0.1,28.4,1,42.6,0.9c22.1-0.2,40.6,6.4,52.9,26.5
		c20.9-9.5,41.6-18.9,62.2-28.3c8.8-4,17.8-5.7,27.4-3.8c12.7,2.5,21.8,9.9,28.5,20.5c0,1.7,0,3.3,0,5c-2.5,2.1-4.7,4.6-7.5,6.4
		c-44.4,27.9-88.8,55.9-133.5,83.2c-8.9,5.4-19.4,8.2-29.2,11.9c-3.6,1.3-7.6,1.7-11.4,2.6C277.2,487,269.2,487,261.3,487z
		 M114.9,449.8c1.4-0.5,2.2-0.7,2.9-1.1c14.2-8.5,28.9-8.8,44.6-4.3c29.6,8.5,59.4,16.7,89.3,24.2c23.3,5.8,45.9,3.3,66.8-9.8
		c40.3-25.3,80.6-50.4,121-75.6c2.3-1.4,4.5-2.9,7.3-4.7c-9.1-7-18.2-7.9-28-3.5c-18.2,8.3-36.4,16.6-54.6,24.9
		c-3.4,1.5-6.7,3.2-10,4.7c0.1,1.5,0.2,2.6,0.3,3.6c1.2,11.2-1.2,14-12.4,13.7c-25-0.6-50.1-1.2-75.1-1.8c-5.6-0.1-11.4,0.1-16.8-1
		c-2.7-0.5-6.1-3.4-6.8-5.9c-0.7-2.1,1.3-5.9,3.3-7.8c1.4-1.4,4.7-1.4,7.1-1.4c24.8,0.5,49.7,1.2,74.5,1.8c3,0.1,6.1,0,9.2,0
		c-3.1-15.2-16.4-26.1-32.3-26.7c-14.4-0.5-28.8-1.4-43.2-1.1c-20.6,0.4-39.7-3.9-58.5-13c-27.3-13.2-55-11.2-81.8,2.8
		c-3.7,1.9-5.4,4.3-5.4,8.7c-0.1,13.8-0.8,27.5-1,41.3C114.8,428.3,114.9,438.7,114.9,449.8z M53,346.4
		c-1.1,39.5-2.3,79.2-3.4,119.7c16.3,0,31.7,0,47.8,0c1.1-39.5,2.3-79.2,3.4-119.7C84.5,346.4,69.2,346.4,53,346.4z" />
          <path d="M252.5,115.6c60.8-0.1,108.8,40.1,118.4,97.3c8.3,49.9-22.2,100.9-72,120.4c-67.5,26.4-142.6-12.2-158.4-81.5
		c-13.1-57.1,23.6-116.1,82.2-132c5.8-1.6,11.8-2.7,17.8-3.5C245.2,115.7,250,115.8,252.5,115.6z M256.8,326.1
		c4.4-0.5,11.4-0.7,18.1-2.1c37.6-8.2,64.4-29.7,76.2-66.6c11.6-36.3,3-69-23.6-96.3c-38.6-39.6-104.2-39.8-144-1
		c-35.1,34.2-39.3,88-9.4,126.5C194.3,312.5,221.5,325,256.8,326.1z" />
          <path d="M263,56.5c0,11.7,0.1,23.3,0,35c0,6.5-2.7,9.7-7.9,9.8c-5.2,0.1-8.2-3.1-8.2-9.5c-0.1-23.3-0.1-46.7,0-70
		c0-6.6,2.7-9.7,7.9-9.7c5.5-0.1,8.1,3,8.1,10.1C263.1,33.5,263,45,263,56.5z" />
          <path d="M202.5,76.2c0,5.6,0,11.2,0,16.8c-0.1,4.9-2.6,7.9-7.5,8.1c-5.3,0.3-8.4-2.7-8.5-7.8c-0.3-11.4-0.2-22.9,0-34.3
		c0.1-5.1,3-8.3,8.3-8.2c5.2,0.1,7.8,3.3,7.9,8.5C202.5,65,202.5,70.6,202.5,76.2z" />
          <path d="M307.5,76c0-5.6,0-11.2,0-16.8c0-4.9,2.5-7.9,7.4-8.3c5.3-0.3,8.5,2.7,8.6,7.8c0.3,11.6,0.3,23.3,0,34.9
		c-0.1,4.8-3.1,7.7-8.1,7.6c-5.3-0.1-7.9-3.1-7.9-8.4C307.5,87.2,307.5,81.6,307.5,76z" />
          <path d="M254.4,163c-4.5,0.6-9.1,1.1-13.6,1.7c-4.7,0.6-8.6-0.5-9.6-5.6c-1.1-5.5,2.4-8.1,7.2-9c53.8-10,101.5,30.3,99.4,82.2
		c-1.5,36.3-29.3,67.3-67.7,74.9c-42.8,8.4-85.7-18.9-95.7-61.1c-4.4-18.4-2-36.1,6.5-52.9c2.8-5.6,7.3-7.8,11.5-5.3
		c5.1,3.1,4.3,7.2,2,11.9c-12.1,25.3-8,52.8,10.7,72.6c19.7,20.8,50.1,27.2,76.6,16.2c25.7-10.7,41.8-35.2,41-62.5
		C321.7,191.2,291.3,163.2,254.4,163z" />
          <path d="M209.8,177.6c-4.6-0.4-7.5-2.8-7.3-7.5c0.2-4.4,3-6.7,7.4-6.7c4.4,0,7.2,2.4,7.4,6.8C217.4,174.9,214.5,177.2,209.8,177.6z
		" />
        </g>
      </svg>
    </span>
    <p class="pt-2">{{'StaticMessageArticle.EarnPoints' | translate}}</p>
  </div>


  <div>
    <span>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
        <g>
          <path d="M427,459.8c-92.1,0-184.3,0-276.4,0c-2.2-2.8-4.9-5.6-2.8-9.6c2-4,5.9-3.7,9.5-3.7c5.3,0,10.6,0,15.8,0
		c11.6,0,23.2,0,35.1,0c0-68.1,0-135.7,0-203.3c-48.5,0-96.7,0-145.2,0c0,2.2,0,4,0,5.8c0,59.7,0,119.4,0,179.1
		c0,12.3,6,18.4,18.4,18.5c14.2,0.1,28.4,0.1,42.6,0c3.9,0,7.4,0.5,8.8,4.7c1.3,4-1.1,6.5-4.1,8.6c-18.6,0-37.1,0-55.7,0
		c-18-5.7-24.5-18.2-24.3-36.9c0.6-57.8,0.2-115.6,0.2-173.4c0-2.1,0-4.2,0-6.6c-8.6,0-16.5,0.4-24.2-0.2c-3.7-0.3-7.2-2.4-10.7-3.7
		c0-23.7,0-47.3,0-71c2.1-3.5,5.2-4.6,9.2-4.6c11.6,0.2,23.3,0.1,34.9,0.1c5.7,0,11.4,0,17.2,0c0-0.9,0-1.1-0.1-1.2
		c-0.4-0.6-0.9-1.1-1.4-1.6c-9.3-9-14.1-20-15.3-32.9c-1.5-16.4,2.8-31.7,8.7-46.7c8.3-21.1,20.9-37.4,45.1-41c3.3,0,6.6,0,9.8,0
		c8,2.3,16,4.4,23.9,7.1c3.6,1.2,5.4,4.4,3.8,8.4c-1.5,3.9-4.7,5-8.5,4c-2.8-0.7-5.4-2.1-8.2-3c-22.1-7.2-37.9-0.9-48.6,19.8
		c-6.3,12-10.4,24.7-12.2,38.2c-3.7,27.2,15.1,48.9,42.6,48.9c31.8,0,63.7,0,95.5,0c2.1,0,4.2,0,6.5,0c0-12.8,0.2-24.6-0.1-36.4
		c-0.1-2.8-1.3-6.1-3.1-8.1c-9.6-10.1-19.5-20.1-29.7-29.7c-5.9-5.6-12.7-10.3-19-15.5c-2.9-2.4-3.6-5.5-1.6-8.7
		c2.2-3.5,5.5-3.9,9.1-2.4c1.3,0.6,2.4,1.6,3.6,2.4c17.8,13.2,33.5,28.5,48.4,44.8c1.7,1.8,4.8,3.2,7.3,3.3
		c11.1,0.3,22.2-0.1,33.3,0.3c5.4,0.2,8.8-1.7,12.5-5.6c28.4-29.9,57.3-59.3,100.3-67.6c3.3,0,6.6,0,9.8,0
		c17.6,2.7,30.9,12.1,38.4,27.9c5.8,12.2,10.7,25.1,13.7,38.2c4.8,20.7,1.4,40.1-14.9,55.6c-0.3,0.3-0.1,0.9-0.2,1.6
		c17.4,0,34.7,0.1,51.9-0.1c4.6-0.1,7.7,1.4,9.4,5.7c0,22.6,0,45.2,0,67.7c-1.4,4.5-4.6,6-9.1,5.9c-8.5-0.2-17-0.1-25.9-0.1
		c0,2.8,0,5,0,7.1c0,59.6,0,119.2,0,178.8c0,14.9-7.6,25.2-21.6,29.9C428.5,458.9,427.8,459.4,427,459.8z M291.4,229.4
		c2.5,0,4.4,0,6.4,0c23.1,0,46.2,0,69.4,0c1.8,0,4.2-0.4,5.3,0.6c2.1,1.8,4.6,4.5,4.7,6.9c0,1.9-3.2,4.2-5.4,5.6
		c-1.3,0.8-3.5,0.3-5.4,0.3c-22.8,0-45.5,0-68.3,0c-2.1,0-4.3,0-6.6,0c0,68.2,0,135.6,0,203.5c2.1,0.1,3.9,0.2,5.6,0.2
		c40.2,0,80.5,0,120.7,0c13,0,19-6,19-19c0-59.5,0-119,0-178.6c0-1.9-0.2-3.8-0.3-6.1c-2.7,0-4.9,0-7,0c-10.4,0-20.8,0-31.1-0.1
		c-4.3,0-7-2.4-7-6.7c0-4,2.8-6.2,6.7-6.6c1.4-0.2,2.9-0.1,4.4-0.1c21.1,0,42.2,0,63.3,0c1.9,0,3.8,0,5.7,0c0-17.9,0-34.9,0-52
		c-60.2,0-120.1,0-180.2,0C291.4,194.7,291.4,211.6,291.4,229.4z M223,177c0,89.9,0,179.4,0,269c18.2,0,36.1,0,53.9,0
		c0-89.9,0-179.4,0-269C258.8,177,241.2,177,223,177z M282.9,163c1.3,0.3,1.8,0.5,2.3,0.5c34.8,0,69.5,0.3,104.3-0.1
		c18.1-0.2,35.5-15.9,37.5-33.9c2.6-24-5.2-45.3-19.4-64.1c-7.1-9.4-17.4-12.6-29.2-11.3c-11.7,1.3-22,6.1-31.9,12.1
		c-23.8,14.4-43.5,33.5-61.7,54.2c-1,1.1-1.8,2.8-1.9,4.3C282.8,137.3,282.9,150,282.9,163z M28.2,229c60.4,0,120.2,0,179.9,0
		c0-17.5,0-34.7,0-51.8c-60.2,0-120,0-179.9,0C28.2,194.6,28.2,211.7,28.2,229z M231.1,162.9c13,0,25.3,0,37.4,0c0-12.4,0-24.1,0-36
		c-12.6,0-24.9,0-37.4,0C231.1,139.1,231.1,151,231.1,162.9z" />
        </g>
      </svg>
    </span>
    <p class="pt-2">{{'StaticMessageArticle.ParticipateInContests' | translate}}</p>
  </div>


  <div>
    <span>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
        <g>
          <path d="M14,443.3c2.8-3.8,6.5-5,11.1-4.8c9.1,0.3,18.3,0.1,27.8,0.1c0-52.5,0-104.5,0-157.1c-4.9,0-9.8,0-15,0
		c0-5.5,0-10.3,0-15.8c4.9,0,9.8,0,15.4,0c0-8,0.1-15.2-0.1-22.4c0-1.3-1.6-2.8-2.7-3.9c-13.9-13.4-16.9-32.5-7.5-48.7
		c9-15.5,27.6-23,45-18c17.4,5,29.1,21.4,28.3,39.9c-0.5,10.6-5.1,19.3-12.6,26.8c-1.6,1.6-2.8,4.3-2.9,6.6
		c-0.4,6.3-0.1,12.6-0.1,19.4c5.3,0,10.1,0,15.3,0c0,5.4,0,10.3,0,15.8c-4.8,0-9.6,0-14.9,0c0,52.6,0,104.7,0,157.5
		c9.4,0,18.7,0,28,0c8.7,0,10.9,2.3,10.9,11c0,8.2-0.3,16.5,0.1,24.7c0.2,5.2-1.2,9.1-5.7,11.7c-39.9,0-79.9,0-120.2,0
		C14,471.8,14,457.5,14,443.3z M84.4,438.3c0-52.6,0-104.5,0-156.5c-5.3,0-10.2,0-15,0c0,52.4,0,104.3,0,156.5
		C74.5,438.3,79.3,438.3,84.4,438.3z M100.5,210.5c0.1-12.9-10.4-23.6-23.4-23.6c-13-0.1-23.7,10.3-23.7,23.2
		c-0.1,12.9,10.5,23.6,23.4,23.8C89.5,234.1,100.4,223.4,100.5,210.5z M29.9,469.5c31.7,0,62.8,0,93.8,0c0-5.2,0-10,0-14.8
		c-31.5,0-62.6,0-93.8,0C29.9,459.9,29.9,464.5,29.9,469.5z M84.1,265.3c0-5.8,0-10.9,0-15.8c-5.3,0-9.9,0-14.6,0
		c0,5.1,0,10.4,0,15.8C74.6,265.3,79.3,265.3,84.1,265.3z" />
          <path d="M365.8,486c-4.5-2.6-6-6.5-5.7-11.7c0.3-8.6,0-17.2,0.1-25.8c0.1-7.2,2.6-9.8,9.7-9.9c9.7-0.1,19.4,0,29.3,0
		c0-52.6,0-104.6,0-157c-4.9,0-9.7,0-15,0c0-5.4,0-10.3,0-16c4.8,0,9.8,0,15.2,0c0-7.8,0.2-14.8-0.1-21.9c-0.1-1.5-1.5-3.2-2.8-4.4
		c-12.9-12.2-16.4-30-9-45.7c7.3-15.5,23.5-24.2,41.1-22.1c16.1,1.9,29.8,14.8,33,31.6c2.7,14.2-1.8,26.1-11.9,36.2
		c-1.6,1.6-2.8,4.3-3,6.6c-0.4,6.3-0.1,12.7-0.1,19.5c5.2,0,10,0,15.3,0c0,5.4,0,10.3,0,15.9c-4.8,0-9.8,0-15,0
		c0,52.6,0,104.6,0,157.4c9.3,0,18.6,0.2,27.9-0.1c4.7-0.1,8.4,1,11.1,4.8c0,14.2,0,28.5,0,42.7C445.9,486,405.8,486,365.8,486z
		 M430.6,438.3c0-52.6,0-104.5,0-156.5c-5.2,0-10.1,0-15.1,0c0,52.4,0,104.3,0,156.5C420.6,438.3,425.4,438.3,430.6,438.3z
		 M422.8,186.9c-13,0.1-23.4,10.7-23.3,23.7c0.1,12.8,11.1,23.5,23.8,23.3c12.9-0.2,23.4-11,23.3-23.9
		C446.5,197.2,435.8,186.8,422.8,186.9z M470.1,454.8c-31.6,0-62.7,0-93.8,0c0,5.2,0,9.9,0,14.8c31.4,0,62.6,0,93.8,0
		C470.1,464.5,470.1,459.9,470.1,454.8z M415.5,249.5c0,5.1,0,10.2,0,15.6c5.2,0,10.1,0,15,0c0-5.5,0-10.7,0-15.6
		C425.3,249.5,420.5,249.5,415.5,249.5z" />
          <path d="M253.4,14c1.9,2.5,4,4.8,5.5,7.5c5.4,9.8,10.7,19.7,15.7,29.8c1.6,3.2,3.7,4.7,7.2,5.2c12.2,1.5,24.5,3.2,36.7,4.8
		c4.2,0.5,8.3,1.2,9.9,6c1.5,4.8-1.5,7.7-4.6,10.5c-8.9,8-17.8,16-26.5,24.3c-1.5,1.4-2.6,4.5-2.3,6.5c1.8,11.8,4.4,23.5,6.1,35.4
		c0.4,3-0.7,7.1-2.6,9.5c-2.5,3.1-6.3,2-9.8,0.3c-11.5-5.8-23.1-11.5-34.8-16.9c-2.1-1-5.5-1-7.7,0c-11.7,5.4-23.2,11.2-34.8,16.9
		c-3.4,1.7-7.3,2.9-9.8-0.3c-1.9-2.4-3-6.4-2.6-9.5c1.7-11.8,4.2-23.5,6.1-35.4c0.3-2.1-0.8-5.1-2.3-6.5c-8.9-8.5-18.1-16.8-27.3-25
		c-3-2.7-5.1-5.6-3.8-9.8c1.3-4.2,4.9-5.4,8.7-5.9c12.2-1.6,24.5-3.3,36.7-4.8c4-0.5,6.6-1.8,8.6-5.8c4.8-9.9,10.1-19.6,15.4-29.3
		c1.5-2.7,3.7-5,5.5-7.5C248.9,14,251.1,14,253.4,14z M250,38.6c-4.8,9-9.2,16.7-13,24.8c-2.5,5.1-5.9,7.5-11.6,8.1
		c-9,0.8-18,2.4-28.2,3.8c6.6,6,11.9,11.7,18.1,16.4c6.5,4.9,8,10.4,6,18.1c-1.9,7.4-2.8,15.1-4.3,23.6c9.6-4.7,18-8.6,26.3-12.9
		c4.6-2.4,8.6-2.5,13.2-0.1c8.4,4.4,17,8.4,26.5,13c-1.3-7-1.9-13.2-3.6-19c-3.2-10.7-1.4-19,8.4-25.6c5.3-3.5,9.5-8.5,14.9-13.5
		c-10.6-1.4-19.8-2.8-29.1-3.8c-4.7-0.5-7.9-2.4-10.1-6.8C259.5,56.2,255,48,250,38.6z" />
          <path
            d="M343.6,319.1c12.1-9.3,23.3-18,35-27c2.8,3.2,6.2,7.1,10.4,11.9c-12.4,9.6-25.5,19.8-39.4,30.4c10.8,27.6,22,56,33.5,85.2
		c-4.7,2-9.3,3.8-14.6,6c-11.2-28.3-22.3-56.4-33.4-84.7c-56.8,25.4-113.3,25.5-170.3,0c-11.1,28.1-22.2,56.2-33.4,84.6
		c-4.9-1.9-9.4-3.7-14.6-5.8c11.3-28.9,22.6-57.5,33.6-85.4c-13.6-10.5-26.7-20.6-39.7-30.6c4.4-4.4,7.9-7.9,11.3-11.3
		c10.8,8.3,22.2,17.1,34.4,26.5c21.2-53.7,41.8-106,62.6-158.8c4.9,1.9,9.5,3.7,14.7,5.7c-21,53.4-41.9,106.4-62.7,159.3
		c43.3,23.9,109.4,25.8,158,0.2c-20.8-52.9-41.7-105.9-62.8-159.5c4.9-2,9.6-3.8,14.7-5.8C301.7,212.9,322.3,265.1,343.6,319.1z" />
        </g>
      </svg>
    </span>
    <p class="pt-2">{{'StaticMessageArticle.EnjoyExclusiveTierBenefits' | translate}}</p>
  </div>


  <div>
    <span>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" viewBox="0 0 414 500" style="enable-background:new 0 0 414 500;" xml:space="preserve">
        <g>
          <path d="M401.4,15c0,135.2,0,270.4,0,405.6c-6.4,6.5-12.8,13-19,19.6c-5.2,5.5-5.8,10.8-1,15.9c6.4,6.7,13.3,13,20,19.4
		c0,2.4,0,4.8,0,7.2c-3.8,1.4-7.7,4.1-11.5,4.1C277.9,487,165.9,487,54,487c-24,0-40.4-16.3-40.4-40.2c0-141.2,0-282.3,0-423.5
		c0-10.1,1.1-11.3,11.1-11.3c122.1,0,244.1,0,366.2,0.1C394.3,12.1,397.9,14,401.4,15z M91.5,409.9c98.4,0,196.1,0,293.9,0
		c0-127.8,0-255.1,0-382.6c-98.2,0-196,0-293.9,0C91.5,155,91.5,282.2,91.5,409.9z M74.9,410c0-128,0-255.5,0-382.9
		c-15.4,0-30.2,0-45.3,0c0,130,0,259.7,0,390.7C43.8,407,59.5,411.4,74.9,410z M373.2,425.7c-3.2,0-5.7,0-8.2,0
		c-102.4,0-204.7,0-307.1,0c-3.4,0-6.8,0-10.1,0.6c-14.1,2.4-22.6,17.7-16.9,30.8c4.1,9.6,11.6,14.3,22.1,14.3
		c105.2,0,210.5,0,315.7,0c1.3,0,2.6-0.3,4.8-0.5c-2.4-2.8-5-4.7-5.8-7.2c-2.4-7.4-7.9-7.4-14.1-7.4c-92,0.1-184.1,0.1-276.1,0.1
		c-3,0-6,0-9,0c0-5.8,0-10.5,0-15.6c3.2,0,5.8,0,8.4,0c53.6,0,107.2,0,160.8,0c39.9,0,79.8,0,119.7-0.1c2.4,0,5.7-0.3,7-1.8
		C367.5,435.3,369.8,430.8,373.2,425.7z" />
          <path d="M238.1,287.7c-9.6,0-19.2,0-28.8,0c-6.5,0-9.7-3.2-9.7-9.7c0.1-11.3-0.2-22.6,0.1-33.9c0.4-14.2-3.9-26.5-14.3-36.4
		c-21.3-20.3-19.8-59.1-10.5-79.1c11.9-25.5,37.7-41.3,66.1-40.3c28.4,1,54.1,18.3,62.8,45.1c9,27.8,7.6,54.5-14.6,77
		c-7.9,8-11.6,18.1-11.7,29.4c-0.1,12.5,0,25-0.1,37.5c-0.1,7.8-2.6,10.3-10.4,10.4C257.4,287.7,247.7,287.7,238.1,287.7z
		 M261.4,241.2c0.7-15.9,5.1-30.2,16.3-41.4c17.5-17.6,18.5-38.4,11.8-60.2c-6.4-21.1-26.6-35.1-49.1-36.1
		c-21.2-1-41.9,11.7-51.1,31.2c-9.5,20.2-6.6,47.1,7,61.7c8.4,9,14.9,18.8,17.5,30.9c1,4.6,1.4,9.3,2.1,14c5,0,9.5,0,14.4,0
		c0-25.4,0-50.5,0-76.4c-5.1,0-9.8,0-14.6,0c0-5.5,0-10.1,0-15c15.4,0,30.4,0,45.6,0c0,5.1,0,9.8,0,15.3c-5.1,0-9.7,0-14.4,0
		c0,25.9,0,51,0,76.1C252,241.2,256.5,241.2,261.4,241.2z M215.7,271.5c15.6,0,30.6,0,45.5,0c0-5,0-9.4,0-14.1
		c-15.3,0-30.3,0-45.5,0C215.7,262.3,215.7,266.7,215.7,271.5z" />
          <path d="M238.5,387.4c-39.9,0-79.7,0-119.6,0c-10.4,0-12.2-1.8-12.3-12.1c0-18-0.1-36.1,0-54.1c0-8.7,1.9-10.5,10.7-10.5
		c80.7,0,161.4,0,242.1,0c9,0,10.8,1.9,10.9,11.2c0.1,18,0.1,36.1,0,54.1c0,9.3-2.2,11.4-11.5,11.4
		C318.8,387.4,278.6,387.4,238.5,387.4z M354.6,326.5c-77.5,0-154.7,0-231.9,0c0,15.3,0,30.1,0,44.7c77.6,0,154.6,0,231.9,0
		C354.6,356.1,354.6,341.5,354.6,326.5z" />
          <path
            d="M149.1,77.2c2.6-2.5,6-5.8,9.4-9c7.2,7.5,14.6,15.2,21.9,22.7c-2.7,2.9-6,6.3-9.2,9.8C163.6,92.6,156.3,84.8,149.1,77.2z" />
          <path d="M305.5,101.1c-3.3-3.6-6.4-7-8.9-9.7c7.3-7.4,14.8-15,22.3-22.6c2.5,2.8,5.7,6.4,8.7,9.8C321,85.3,313.7,92.8,305.5,101.1z
		" />
          <path
            d="M245.8,72.4c-5.2,0-9.6,0-14.5,0c0-10,0-19.7,0-29.8c4.8,0,9.4,0,14.5,0C245.8,52.6,245.8,62.2,245.8,72.4z" />
          <path
            d="M339.2,341.7c0,5,0,9.6,0,14.6c-67,0-133.8,0-201.1,0c0-4.8,0-9.4,0-14.6C204.9,341.7,271.7,341.7,339.2,341.7z" />
        </g>
      </svg>
    </span>
    <p class="pt-2">{{'StaticMessageArticle.UpYourLearning' | translate}}</p>
  </div>


  <div>
    <span>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" viewBox="0 0 500 438" style="enable-background:new 0 0 500 438;" xml:space="preserve">
        <g>
          <path d="M227.1,427c-12.3-4.7-16.5-13.9-16.2-26.7c0.5-25.8,0.1-51.6,0.2-77.4c0-2.1-0.4-4.8,0.7-6.3c1.7-2.2,4.6-4.9,6.8-4.7
		c2.2,0.1,4.6,3.2,6.2,5.5c0.8,1.2,0.3,3.4,0.3,5.2c0,26.6,0,53.1,0,79.7c0,8.8,1.9,10.7,10.6,10.7c75.8,0,151.6,0,227.4,0
		c8.7,0,10.4-1.7,10.4-10.1c0-51.4,0-102.7,0-154.1c0-1.7-0.2-3.4-0.3-5.7c-2.4,0-4.5,0-6.6,0c-65.8,0-131.7,0-197.5-0.1
		c-4,0-6.9,1-9.8,3.9c-58.2,57.9-116.4,115.6-174.7,173.3c-2.6,2.6-5.7,4.5-8.6,6.8c-2.7,0-5.5,0-8.2,0c-3.5-3-7.3-5.8-10.6-9
		c-10.5-10.2-20.8-20.6-31.2-30.9c-9.7-9.7-9.8-16.1-0.2-25.7c7-7,14-13.9,21-20.9c-7.4-7.4-14.1-14.5-21.2-21.2
		c-6.1-5.7-11.2-11.8-13-20.2c0-1.6,0-3.1,0-4.7c2.3-14.8,11.8-21.7,25.7-24.7c13.5-2.9,26.8-6.8,40.1-10.7c2.8-0.8,5.2-3.4,7.4-5.5
		c4.1-3.8,7.5-8.5,12.1-11.6c3.2-2.2,4.9-4.6,5.8-8.1c3.9-14.9,7.9-29.8,12-44.6c5.7-20.6,28.4-26.9,43.7-11.9
		c8.6,8.5,16.8,17.4,25.8,26.9c8.1-8.5,15.2-16,22.5-23.3c7.9-8,15.4-8,23.3-0.1c11.7,11.5,23.4,22.9,34.8,34.7
		c3.4,3.5,5.6,8.1,9,13.2c5.7,0,13.1,0,21.6,0c-1.5-1.7-2.4-2.6-3.2-3.6c-7.7-9.6-3.1-24.4,9-25.1c14-0.8,21.7-6.5,27-19.4
		c4.9-11.8,22.2-11.6,27,0.3c5.2,12.9,13,18.2,26.6,19c12.2,0.7,17,15.4,9.4,25c-0.8,1-1.7,2-3.3,3.8c2.8,0.1,4.8,0.3,6.8,0.3
		c26.9,0,53.9-0.1,80.8,0.2c3.6,0,7.2,2.7,10.8,4.1c0,59.4,0,118.8,0,178.2c-3.1,7-8.3,12-15.2,15.2C390.5,427,308.8,427,227.1,427z
		 M219.4,189.1c-62.2,61.7-124.3,123.3-185.5,184c13.2,13.5,25.8,26.4,38.7,39.5c61.8-61.3,123.8-122.8,185.4-184
		C245,215.3,232.3,202.3,219.4,189.1z M143.8,243.9c10.9-11.1,20.8-21.2,30.7-31.2c-8.3-8.4-16.8-17.6-25.9-26.2
		c-6.7-6.4-16.1-3.5-18.9,5.5c-2.1,6.5-3.7,13.1-5.5,19.7c-2.2,8.2-4.3,16.5-6.5,24.8c4.5,1.1,8.4,2,12.7,3.1
		c2.5-9.2,5-18.2,7.5-27.2c1.7-5.9,5.1-8.5,9.5-6.8c5.1,2,5.3,5.9,3.9,10.7C148.7,225,146.5,233.9,143.8,243.9z M87,299.5
		c-9.4,2.5-18.8,5.1-28.2,7.4c-4.9,1.2-8.9-0.8-9.6-4.9c-0.8-4.7,1.8-7.3,6.1-8.5c7.7-2.1,15.5-4,23.2-6.1c1.9-0.5,3.7-1.2,5.3-1.8
		c-1.2-4.6-2.2-8.3-3.2-12.5c-15.6,4.2-30.9,8-46,12.4C27,287.8,24,297,29.2,302.6c8.7,9.6,18.3,18.5,27.4,27.5
		C66.9,319.7,76.8,309.7,87,299.5z M343.6,186.2c-0.7,0-1.3,0-2,0c-2.3,4.6-5.2,9.1-6.9,14c-2.7,7.8-7.9,10.8-15.8,11.1
		c-5.2,0.2-10.4,1.6-15.6,2.5c-0.3,0.7-0.5,1.4-0.8,2.1c3.1,2.6,6.5,5,9.2,8c3.5,4,7.4,5.5,12.8,5.3c11.7-0.4,23.5-0.8,35.2,0.1
		c11.9,0.9,15.7-8.5,23.8-15.4c-8.7-1.3-15.5-2.6-22.4-3.4c-4.2-0.5-6.7-2.5-8.4-6.2C349.8,198.2,346.7,192.3,343.6,186.2z
		 M129.7,256.8c-6.7-7.9-20.2-8.2-28.6-0.1c-7.7,7.5-8.7,22,0.3,28.9C110.8,276,120.2,266.5,129.7,256.8z" />
          <path d="M340.9,19c2.2,2.7,4.3,5.4,7.1,9c2.7-0.3,7.3-0.9,9.1,4.4c1.7,5.2-1.8,7.8-6,9.8c1.9,4.3,3.6,8.5-1.1,11.7
		c-4.4,3-7.7,0.7-10.8-3.2c-3.2,3.5-6.7,6.5-11.4,3c-4.1-3.1-3.3-7-0.6-11.2c-4.3-2.3-8.7-5.4-5.7-10.6c1.5-2.6,5.8-3.5,9-4.4
		c2.3-2.8,4.6-5.7,6.9-8.5C338.5,19,339.7,19,340.9,19z" />
          <path d="M277.4,93.6c-3.7,15.5-12.5,23.3-28.4,21c0,0.5,0,1-0.1,1.5c2.6,2.6,4.9,5.6,7.8,7.9c5.1,4,6.6,8.7,4.7,14.9
		c-1,3.3-1.1,6.8-2,12.4c12.8-10.9,24.6-10.2,36.9-0.9c-5.4-14.5-3.1-26,10.3-33c-0.1-1.3,0-1.8-0.2-2c-4.2-2.7-6.2-6.3-3-10.7
		c3.2-4.4,7.6-3.2,11.6-1c7.4,4.2,9.8,14.9,3.3,20.5c-8.3,7.1-11,14.2-8.4,25.1c2.4,10.3-9.6,18.9-18.7,13.6
		c-9.5-5.5-17.2-5.5-26.6,0c-9.1,5.2-20.7-2.9-18.7-13c2.1-10.9-0.2-18.3-8.5-25.8c-7.7-7-3.1-20.3,7.3-21.6
		c10.7-1.3,17.2-5.2,21.6-15.5c4.2-9.7,17.8-9.2,23.5-0.2c1.3,2.1,2.4,4.5,3.4,6.8c1.8,4,0.9,7.6-3,9.4c-3.8,1.8-7.3,0.7-9.4-3.2
		C280,98.3,279.1,96.8,277.4,93.6z" />
          <path d="M407.8,124.8c-3.5,3.7-7,6.2-11.4,2.8c-4-3.1-3.3-7.1-0.4-11.1c-4-2.3-9-4.8-6.1-10c1.6-2.8,5.9-4,9.6-5.2
		c1.4-3.9,3.1-8.7,8.6-7.6c3.1,0.6,5.5,4.5,7.7,7.7c3.9,0,8.3,0.1,10.1,5.3c1.8,5.4-2.3,7.6-6.3,9.7c2.6,4.2,3.7,8.3-0.7,11.5
		C414.6,130.9,411.1,128.7,407.8,124.8z" />
          <path d="M442.7,209.9c-1.8-2.6-4.7-5-5.1-7.7c-0.6-4.2,2.6-7,6.9-6.9c4.2,0.1,7.4,2.9,6.4,7.2c-0.6,2.8-3.6,5-5.5,7.4
		C444.5,210,443.6,210,442.7,209.9z" />
          <path
            d="M445,52.3c6.4,0.4,9,2.8,9,7.1c-0.1,4-2.5,6.6-6.7,6.6c-4.4,0-7.4-2.6-6.7-6.8C441,56.4,443.8,54,445,52.3z" />
          <path
            d="M340.1,88.5c-0.5,6.4-2.9,8.9-7.2,8.8c-4.1-0.1-6.6-2.7-6.6-6.8c0-4.4,2.8-7.4,7-6.7C336,84.3,338.4,87.3,340.1,88.5z" />
          <path d="M379.9,150.5c-2.5,1.9-4.7,4.8-7.4,5.4c-4.2,0.9-7-1.9-7.2-6.4c-0.2-4.5,2.7-7.4,6.8-6.9c2.8,0.4,5.2,3.3,7.8,5.1
		C379.9,148.7,379.9,149.6,379.9,150.5z" />
        </g>
      </svg>
    </span>
    <p class="pt-2">{{'StaticMessageArticle.ReedemExcitingRewards' | translate}}</p>
  </div>

</div>