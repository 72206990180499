import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@core/shared/shared.module';

import {
    AccountActivationComponent,
    ActivateUserComponent,
    CompleteProfileComponent,
    CompleteProfileSelfRegistrationComponent,
    AccountRegisterComponent,    
    TermsConditionsComponent,
    ExpiredPasswordComponent,
    ForgotPasswordComponent,
    LoginComponent,
    ResetPasswordComponent,
    SiteMonitoringComponent,
    PreloginLegalPageComponent
} from './accounts';

import { PreloginService } from './services';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        RouterModule,
        TranslateModule,
        SharedModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        PreloginService,
        { provide: Window, useValue: window }
    ],
    declarations: [
        AccountActivationComponent,
        ActivateUserComponent,
        CompleteProfileComponent,
        CompleteProfileSelfRegistrationComponent,
        AccountRegisterComponent,
        TermsConditionsComponent,
        ExpiredPasswordComponent,
        ForgotPasswordComponent,
        LoginComponent,
        ResetPasswordComponent,
        SiteMonitoringComponent,
        PreloginLegalPageComponent
    ]
})
export class PreloginModule { }