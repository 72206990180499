export class SearchCriteria {
    pageNumber: number;
    itemsPerPage: number;
    sortField: string;
    sortAscending: boolean;
    additionalData: object;

    constructor (_sortField) {
        this.pageNumber = 1;
        this.itemsPerPage = 10;
        this.sortField = _sortField;
        this.sortAscending = true;
    }
}