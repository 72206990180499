<div [appIsLoading]="loadingService.isLoading(this)">
    <div class="add-lessons-form" [formGroup]="form">
        <div class="grid grid-gap-10 grid-col-hd-100 grid-col-xs-100 mb-2">
            <div class="margin-bottom-10">
                <label>{{'SearchInvoice.SearchByProductAttributes' | translate}}</label>
                <br>
                <ng-multiselect-dropdown name="list" [placeholder]="'Search By Status'"
                    [settings]="productNameSearchSettings" [data]="products" formControlName="selectProduct">
                </ng-multiselect-dropdown>
            </div>
        </div>
        <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
            <ng-container formArrayName="params">
                <ng-container *ngFor="let paramsForm of params.controls; let i = index">
                    <div class="position-relative" [formGroup]="paramsForm">
                        <label> {{paramsForm.value.key}} </label>
                        <input class="form-control" formControlName="value" />
                        <!-- <fa-icon [icon]="faDelete" class="fa-delete-custom position-absolute"
                            (click)="deleteParams(i)"></fa-icon> -->
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div class="grid grid-gap-10 grid-col-hd-100 grid-col-xs-100 mt-3" *ngIf="ErrMessage">
            <span translate>{{ErrMessage}}</span>
        </div>

        <div class="text-center mt-5">
            <button class="submit" (click)="submit()">{{'SearchInvoice.Submit' | translate}}</button>
            <button class="cancel" (click)="activeModal.close()">{{'SearchInvoice.Cancel' | translate}}</button>
        </div>

        <div
            class="grid grid-gap-10 grid-col-md-50 grid-col-hd-50 grid-col-xs-100 grid-col-sm-100 grid-col-lt-100 custom-rule-bottom">
        </div>

        <div class="overflow-auto mt-5">
            <table aria-describedby="Products" *ngIf="tableData?.length > 0"
                [appIsLoading]="loadingService.isLoading(this, 'filteredProduct')">
                <tr *ngFor="let items of tableData.slice(0,1)">
                    <th>Select Product</th>
                    <th *ngFor="let header of items.attributes | keyvalue;">{{header.key}}</th>
                </tr>
                <tr *ngFor="let items of tableData">
                    <td><fa-icon [icon]="faCheck" class="fa-delete-custom fa-icon-green"
                            (click)="addProduct(items)"></fa-icon></td>
                    <td *ngFor="let attribute of items.attributes | keyvalue; let i = index">{{attribute.value}}</td>
                </tr>
            </table>
            <div *ngIf="tableData.length == 0">
                <span>{{'SearchInvoice.NoRecordsFound' | translate}}</span>
            </div>
        </div>
    </div>
</div>