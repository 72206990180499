<ng-container *ngIf="template == 'theme1'">
    <div class="form-input">
        <input class="form-field" [type]="type" [id]="controlName" [name]="controlName" [formControlName]="controlName" [placeholder]="placeHolder" />
    </div>
</ng-container>

<ng-container *ngIf="template == 'theme2'">
    <div class="form-input theme2 {{type}}">
        <input class="form-field" [type]="type" [id]="controlName" [name]="controlName" [formControlName]="controlName" [placeholder]="placeHolder" />
    </div>
</ng-container>

<ng-container *ngIf="template == 'theme3'">
    <div class="form-input theme3">
        <input class="form-field" [type]="type" [id]="controlName" [name]="controlName" [formControlName]="controlName" [placeholder]="placeHolder" />
        <em class="icon" ng-class="rightIcon"></em>
    </div>
</ng-container>