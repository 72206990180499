import { Component, OnInit } from '@angular/core';
import { faTimesCircle, faCheckCircle, faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, debounceTime, distinctUntilChanged, Observable, of, OperatorFunction, switchMap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SearchCriteria } from '@core/shared/models/search-criteria';
import { Pagination } from '@core/shared/models/pagination';
import { Sorter } from '@core/shared/models/sorter';
import { LoadingService } from '@core/shared';
import { UserRegistrationApprovalService } from '../services/user-registration-approval.service';
import { UserRegistrationRejectReasonComponent } from '../reject-reason/reject-reason.component';
import { BulkApprovalComponent } from '../bulk-approval/bulk-approval.component';
import { UserRegistrationViewUserComponent } from '../view-user/view-user.component';

@UntilDestroy()
@Component({
    selector: 'app-user-registration-approval',
    templateUrl: 'user-registration-approval.component.html',
    styleUrls: ['./user-registration-approval.component.scss']
})
export class UserRegistrationApprovalComponent implements OnInit {
    statuses: string[] = [];
    jobroles: string[] = [];
    filter: any = {};
    approvalDetails: any[] = [];
    searchCriteria: SearchCriteria = new SearchCriteria('username');
    paginationDetails: Pagination = new Pagination();
    sorter: Sorter = new Sorter('username', false);
    initalLoading: boolean = false;
    public faRemoveIcon = faTimesCircle;
    public faApprove = faCheckCircle;
    public faView = faEye;
    public faSearch = faSearch;

    constructor(private modalService: NgbModal, public loadingService: LoadingService, private approvalService: UserRegistrationApprovalService) { }

    ngOnInit() {
        this.getStatuses();
        this.getUserJobRoles();
        this.getUserApprovalDetails();
    }

    getUserJobRoles() {
        this.approvalService.getUserJobroles().subscribe({
            next: (response) => {
                this.jobroles = response;
            }
        })
    }

    organisationSearch: OperatorFunction<string, readonly string[]> = (searchText$: Observable<string>) =>
        searchText$.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(term =>
                this.approvalService.getUserOrganisations(term).pipe(
                    catchError(() => {
                        return of([]);
                    }))
            )
        );

    getStatuses() {
        this.approvalService.getApprovalStatuses().subscribe({
            next: (response) => {
                this.statuses = response;
            }
        })
    }

    getUserApprovalDetails() {
        this.buildPayload();
        this.loadingService.doLoading(this.approvalService.getUserApprovalDetails(this.filter, this.searchCriteria), this).pipe(untilDestroyed(this)).subscribe({
            next: (response) => {
                this.initalLoading = true;
                this.approvalDetails = response.items;
                this.paginationDetails.totalNumberOfItems = response.totalNumberOfItems;
            }
        });
    }

    exportUserApprovalDetails() {
        this.buildPayload();
        this.approvalService.exportUserApprovalDetails(this.filter, this.searchCriteria).subscribe();
    }

    uploadBulkApproval() {
        const modalRef = this.modalService.open(BulkApprovalComponent, { backdrop: 'static' });
        modalRef.result.then(() => {
            this.getUserApprovalDetails();
        });
    }

    buildPayload() {
        this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
        this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
        this.searchCriteria.sortField = this.sorter.sortColumn;
        this.searchCriteria.sortAscending = this.sorter.sortDirection;
        if (this.filter['jobrole'] && this.filter['jobrole'] === 'undefined')
            delete this.filter['jobrole'];
        if (this.filter['status'] && this.filter['status'] === 'undefined')
            delete this.filter['status'];
    }

    reset() {
        this.filter = {};
        this.getUserApprovalDetails();
    }

    onChange(pagination: Pagination) {
        this.paginationDetails = pagination;
        this.getUserApprovalDetails();
    }

    onSort(sortField) {
        this.sorter.sort(sortField);
        this.getUserApprovalDetails();
    }

    view(userId) {
        const modalRef = this.modalService.open(UserRegistrationViewUserComponent, { backdrop: 'static', size: 'xl' });
        modalRef.componentInstance.userId = userId;
    }

    approve(historyId) {
        this.submit(historyId, true);
    }

    reject(historyId) {
        const modalRef = this.modalService.open(UserRegistrationRejectReasonComponent, { backdrop: 'static' });
        modalRef.result.then((result) => {
            this.submit(historyId, false, result.rejectionReason);
        });
    }

    submit(historyId, isApproved, reason?) {
        let payload = {
            historyId: historyId,
            isApproved: isApproved,
            reason: reason
        };
        this.loadingService.doLoading(this.approvalService.approve(payload), this).pipe(untilDestroyed(this)).subscribe({
            next: () => {
                this.getUserApprovalDetails();
            }
        });
    }
}