import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'cs-shared-goal-result',
  templateUrl: './goal-result.component.html'
})
export class SharedParticipantGoalResultComponent implements OnInit, OnChanges {
  @Input() goalAchieved: number;
  @Input() selectedGoal: any;
  @Input() isMinimumQualifierEnabled: boolean;
  @Input() minimumQualifier;
  @Input() isManualReward: boolean;
  @Input() culture: string;

  finalResult: boolean;
  goalAchived: number;
  minimumQualifierAchived: number;
  percentageValue: (value: number) => string;

  ngOnInit() {
    this.percentageValue = (value: number): string => {
      if (this.minimumQualifier && this.minimumQualifier.target && !Number.isNaN(value))
        return `${value % 1 != 0 ? value.toFixed(2) : value}%`;
      else
        return 'N/A';
    };
  }

  ngOnChanges() {
    let goalAchievedPercentage = (this.goalAchieved / this.selectedGoal.target) * 100;
    this.goalAchived = goalAchievedPercentage > 100 ? 100 : goalAchievedPercentage;
    this.finalResult = this.goalAchieved >= this.selectedGoal.target;

    if (this.isMinimumQualifierEnabled) {
      this.minimumQualifierAchived = 0;
      if (this.minimumQualifier.target) {
        let achievement = this.minimumQualifier.achievement ? this.minimumQualifier.achievement : 0;
        let minimumQualifierAchivedPercentage = (achievement / this.minimumQualifier.target) * 100
        this.minimumQualifierAchived = minimumQualifierAchivedPercentage > 100 ? 100 : minimumQualifierAchivedPercentage;
        this.finalResult = this.finalResult && (achievement >= this.minimumQualifier.target);
      }
    }
  }

}
