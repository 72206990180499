<div class="modal-header">
    <h4 class="modal-title">{{ 'SalesSearch.ResolveModal.Title' | translate }}</h4>
</div>
<form [formGroup]="formResolveChallenge" (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <div class="row mb-4">
            <div class="col-md-4 col-lg-4">
                <label class="control-label">{{ 'SalesSearch.ResolveModal.ClaimedBy' | translate }}</label>
            </div>
            <div class="col-sm-8 col-md-6">
                <div class="static-field">
                    {{resolveDto.claimedByUsername}}
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-4 col-lg-4">
                <label class="control-label">{{ 'SalesSearch.ResolveModal.ChallengedBy' | translate }}</label>
            </div>
            <div class="col-lg-8 col-md-6">
                <div class="static-field">
                    {{resolveDto.challengedByUsername}}
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-4 col-lg-4">
                <label class="control-label">{{ 'SalesSearch.ResolveModal.ChallengeReason' | translate }}</label>
            </div>
            <div class="col-lg-8 col-md-6">
                <div class="static-field">
                    {{resolveDto.challengeReason}}
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-4 col-lg-4">
                <label class="control-label">{{ 'SalesSearch.ResolveModal.SelectWinner' | translate }}</label>
            </div>
            <div class="col-lg-8 col-md-6">
                <select class="form-control" formControlName="winnerId">
                    <option value="">{{'SalesSearch.ResolveModal.Select' | translate}}</option>
                    <option value="{{resolveDto.claimedByUserId}}">{{resolveDto.claimedByUsername}}</option>
                    <option value="{{resolveDto.challengedByUserId}}">{{resolveDto.challengedByUsername}}
                    </option>
                </select>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-md-4 col-lg-4">
                <label class="control-label">{{ 'SalesSearch.ResolveModal.ResolveReason' | translate }}</label>
            </div>
            <div class="col-lg-8 col-md-6">
                <textarea class="form-control" rows="5" formControlName="resolveReason"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-light" [disabled]="!formResolveChallenge.valid">{{
            'Shared.Confirmation.OK' | translate }}</button>
        <button class="btn btn-default" type="button" (click)="cancel()">{{ 'Shared.Confirmation.Cancel' | translate
            }}</button>
    </div>
</form>