import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs";
import {
    GET_USER_REGISTRATION_APPROVAL_STATUSES, GET_USER_REGISTRATION_APPROVAL_JOBROLES, GET_USER_REGISTRATION_APPROVAL_DETAILS,
    GET_USER_REGISTRATION_APPROVAL_ORGANISATIONS, USER_REGISTRATION_APPROVE, GET_USER_REGISTRATION_APPROVAl_CONTEXT,
    UrlBuilder, EXPORT_USER_REGISTRATION_APPROVAL_DETAILS, DOWNLOAD_COUPON_UPLOADED_FILE, GET_SELF_REGISTRATION_CONFIG
} from "@core/core-api-variables";
import { HttpService, DownloadService } from "@core/shared";
import { environment } from "@env/environment";

@Injectable()
export class UserRegistrationApprovalService extends DownloadService {

    constructor(public http: HttpService, public httpClient: HttpClient) {
        super();
    }

    getApprovalStatuses() {
        return this.http.get(GET_USER_REGISTRATION_APPROVAL_STATUSES).pipe(map((response: any) => {
            return response;
        }));
    }

    getUserJobroles() {
        return this.http.get(GET_USER_REGISTRATION_APPROVAL_JOBROLES).pipe(map((response: any) => {
            return response;
        }));
    }

    getUserOrganisations(searchText) {
        let data = { searchText }
        let url = UrlBuilder(GET_USER_REGISTRATION_APPROVAL_ORGANISATIONS, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    getUserApprovalDetails(filter, searchCriteria) {
        let payload = { ...filter, ...searchCriteria }
        return this.http.post(GET_USER_REGISTRATION_APPROVAL_DETAILS, payload).pipe(map((response: any) => {
            return response;
        }));
    }

    exportUserApprovalDetails(filter, searchCriteria) {
        let payload = { ...filter, ...searchCriteria }
        return this.http.downloadPost(EXPORT_USER_REGISTRATION_APPROVAL_DETAILS, payload).pipe(map((response: any) => {
            this.downloadFile(response, 'UserRegistrationApprovalStatus.csv');
            return true;
        }));
    }

    approve(payload) {
        return this.http.post(USER_REGISTRATION_APPROVE, payload).pipe(map((response: any) => {
            return response;
        }));
    }

    getUserRegistrationApprovalContext() {
        return this.http.get(GET_USER_REGISTRATION_APPROVAl_CONTEXT).pipe(map((response: any) => {
            return response;
        }));
    }

    selfRegistrationConfig() {
        let url = GET_SELF_REGISTRATION_CONFIG;
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }

    editProfileSelfRegistration(configs, userId, locale) {

        let headers = new HttpHeaders();
        headers = headers.set('companyid', configs.companyId)
            .set('formid', configs.userProfileFormId)
            .set('nexus-environment-key', environment.environmentKey)
            .set('regid', configs.regId)
            .set('orgcode', configs.orgCode)
            .set('editForm', 'true')
            .set('personId', userId)
            .set('locale', locale)

        return this.httpClient.get(configs.baseUrl, { headers: headers })
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    downloadByUploadId(uploadId) {
        let data = { uploadId };
        let url = UrlBuilder(DOWNLOAD_COUPON_UPLOADED_FILE, data);
        return this.http.download(url).pipe(map((response: any) => {
            this.downloadFile(response, 'UserRegistrationBulkApproval.csv');
            return true;
        }));
    }
}