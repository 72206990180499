import { Component, Input, OnInit } from '@angular/core';
import { PlatformSystemSettings, SystemSettings } from '@core/models';
import { CatalogueService } from '@core/shared/services/catalogue.service';
import * as fromStore from '@core/store';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'cs-redeem-now',
    templateUrl: 'redeem-now.component.html',
    styleUrls: ['redeem-now.component.scss']
})

export class RedeemNowComponent implements OnInit {
    @Input() buttonTheme;
    private onDestroy$ = new Subject<void>();
    menuAccess: any;
    disablePopover = true;
    disableMerchandise = true;
    disableExperience = true;
    disableMastery = true;
    systemSettings: PlatformSystemSettings;
    impersonated = false;

    constructor(private catalogueService: CatalogueService, private store: Store<fromStore.PlatformState>, private router: Router, private systemSettingsService: SystemSettings,) {
        this.store.select(fromStore.globalMenuState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            // if (state && state.menuAccess && this.systemSettings) {
            //     this.menuAccess = state.menuAccess;
            //     this.checkShop();
            // }
        });
    }

    ngOnInit(): void {
        if (localStorage.getItem('platformSettings')) {
            this.systemSettings = JSON.parse(localStorage.getItem('platformSettings'));
            if (this.menuAccess == undefined) {
                this.store.select(fromStore.globalMenuState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
                    if (state && state.menuAccess && this.systemSettings) {
                        this.menuAccess = state.menuAccess;
                        this.checkShop();
                    }
                });
            } else {
                this.checkShop();
            }
        }
        if (localStorage.getItem('token')) {
            let token = JSON.parse(localStorage.getItem('token'));
            if (token.impersonatorId) {
                this.impersonated = true;
            } else {
                this.impersonated = false;
            }
        }
    }    

    experianceCatelogue() {
        //this.catalogueService.redirectExperianceCatalogue();
        if(this.systemSettingsService.ssoCatelogueOtpBasedLogin)
        this.router.navigate([`/catalogue/verification/experience`]);
        else
        this.catalogueService.redirectExperianceCatalogue(null,null);
    }

    merchandiseCateloge() {
        //this.catalogueService.redirectMerchandiseCatalogue();
        if(this.systemSettingsService.ssoCatelogueOtpBasedLogin)
        this.router.navigate([`/catalogue/verification/merchandise`]);
        else
        this.catalogueService.redirectMerchandiseCatalogue(null,null);
    }

    masteryCateloge(){
        //this.catalogueService.redirectMasteryCatalogue();
        if(this.systemSettings.ssoCatelogueOtpBasedLogin)
        this.router.navigate([`/catalogue/verification/merchandise`]);
        else
        this.catalogueService.redirectMasteryCatalogue(null,null);
    }

    checkShop() {
        this.disablePopover = true;
        if (this.systemSettings.catalogueReferences.filter(x => x.isEnableMerchandise).length > 0 && this.menuAccess && this.menuAccess.merchandise) {
            this.disablePopover = false;
            this.disableMerchandise = false;
        }
        if (this.systemSettings.catalogueReferences.filter(x => x.isEnableExperience).length > 0 && this.menuAccess && this.menuAccess.experience) {
            this.disablePopover = false;
            this.disableExperience = false;
        }
        if (this.systemSettings.catalogueReferences.filter(x => x.isEnableMastery).length > 0 && this.menuAccess && this.menuAccess.mastery) {
            this.disablePopover = false;
            this.disableMastery = false;
        }
    }
}