<div class="claim-filter-section" [appIsLoading]="loadingService.isLoading(this, 'managergq')">
    <div class="gq-topBanner goal-status-manager">
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="goal-status" [class]="{'current-status': activeStage === 1}">
                    {{'GoalQuestPage.Manager.GoalQuestSelection.GoalSelection' | translate}}</div>
            </div>
            <div class="col-md-4 col-sm-4">
                <div class="goal-status" [class]="{'current-status': activeStage === 2}">
                    {{'GoalQuestPage.Manager.GoalQuestSelection.GoalProgress' | translate}}</div>
            </div>
            <div class="col-md-4 col-sm-4">
                <div class="goal-status" [class]="{'current-status': activeStage === 3}">
                    {{'GoalQuestPage.Manager.GoalQuestSelection.GoalResult' | translate}}</div>
            </div>
        </div>
    </div>

    <h2 class="gqHeadline">{{'GoalQuestPage.Manager.GoalQuestSelection.header' | translate}}</h2>
    <div class="gq-goalStatus gq-badge" *ngIf="activeStage === 2 || activeStage === 3">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="goal"
                    [class]="{'completed': activeStage === 3 && goalQuest.participantsAchievedTargetCount >0}">
                    <div class="iconHolder">
                        <span class="icon-gq-badge"></span>
                    </div>
                    <div class="divider"></div>
                    <p><span class="level">{{goalQuest.managerEarnedPoints}}</span>
                        {{'GoalQuestPage.Manager.GoalQuestProgress.RewardPoints' | translate}}</p>
                    <cs-redeem-now class="btn-redeem" *ngIf="activeStage === 3" buttonTheme="theme2">
                    </cs-redeem-now>
                </div>
            </div>
        </div>
    </div>

    <cs-shared-manager-goal-selection *ngIf="activeStage === 1" [userCount]="goalQuest.countOfUsers"
        [goalSelectedUsers]="goalQuest.goalsSelectedUsersCounts" [endDate]="goalQuest.goalSelectionLastDate"
        [goalSelectionDetails]="goalQuest.participantGoalSelection">
    </cs-shared-manager-goal-selection>
    <cs-shared-manager-goal-progress *ngIf="activeStage === 2" [isMinimumQualifierEnabled]="isMinimumQualifierEnabled"
        [goalAchievedUsers]="goalQuest.goalsAchievedUsersCounts" [endDate]="goalQuest.promotionLastDate"
        [goalAchievedDetails]="goalQuest.participantGoalAchievement"></cs-shared-manager-goal-progress>
    <cs-shared-manager-goal-result *ngIf="activeStage === 3" [isMinimumQualifierEnabled]="isMinimumQualifierEnabled"
        [goalAchievedUsers]="goalQuest.goalsAchievedUsersCounts" [endDate]="goalQuest.promotionLastDate"
        [goalAchievedDetails]="goalQuest.participantGoalAchievement"></cs-shared-manager-goal-result>
</div>