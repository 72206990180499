<div class="row mx-0">
  <div class="mx-auto content-width">
    <div class="card card-signin my-5">
      <div class="card-body">
        <div *ngIf="contentPageDetails != undefined">
          <div class="custom-toggle">
            <div class="header">
              <div class="header-title">{{'Help'}}</div>
              <button (click)="toggleShowsMaterial()" class="btn btn-link accordion">{{!isShownDivMaterial ? '+' :'-'}}</button>
            </div>
            <div class="opens bg" [class]="{'newClass':isShownDivMaterial}">
              <div [innerHTML]="contentPageDetails | sanitizeHtml" *ngIf="contentPageDetails != undefined"></div>
            </div>
          </div>
        </div>
        <div id="selfRegistrationRoot"></div>
      </div>
    </div>
  </div>
</div>