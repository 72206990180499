import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';

import { MessageService } from '@core/shared/services/message.service';
import { FileUploadService } from '@core/shared/services/file-upload.service';
import { interval, Subscription, switchMap, takeWhile } from 'rxjs';

@Component({
    selector: 'cs-file-uploader-progress',
    templateUrl: 'file-uploader-progress.component.html',
    styles: [`.validation-msg {
        background: #d3d3d4;
    }
    
    .example-section {
        display: flex;
        align-content: center;
        align-items: center;
        height: 60px;
    }
    
    .example-margin {
        margin: 0 10px;
    }
    
    .progress-bar-value {
        position: relative;
        padding: 10px 0 10px 0;
        color: #545454;
        white-space: normal;
    }`]
})

export class FileUploaderProgressComponent implements OnInit, OnChanges, OnDestroy {
    @Input() uploadId: string;
    @Input() uploadStatus: number;
    @Output() changeUploadStatus: EventEmitter<string> = new EventEmitter<string>();
    @Output() onImportComplete = new EventEmitter();


    private statusPollingSubscription: Subscription;
    private importComplete: boolean = false;
    public statusHandlerRunning: boolean = false;
    public customValidation: boolean = false;
    public customValidationMessage: string;
    public validationSummary: any;
    public rowsValidated: number;
    public rowsToValidate: number;
    public rowsImported: number;
    public rowsToImport: number;
    private firstProgressCall: boolean = true; // Track the first call

    // rowsToImport: number;
    fileStatusTypes: any = {
        readyForScan: 1,
        scanned: 2,
        saved: 3,
        validating: 4,
        validated: 5,
        importing: 6,
        imported: 7
    };
    private statusCheckInterval: any;

    constructor(private progressService: FileUploadService, private messageService: MessageService) { }

    ngOnInit(): void {
        console.log("This is Init");    
        
    }
     /**
     * Lifecycle hook called when any data-bound property changes.
     * @param changes - The set of changes in data-bound properties.
     */
     ngOnChanges(changes: SimpleChanges): void {
        if (changes.uploadId && changes.uploadId.previousValue !== changes.uploadId.currentValue) {
            // Check if import is already complete and skip resetting importComplete if true
            if (!this.importComplete) {
                this.importComplete = false;  // Reset import completion flag only if not already complete
            }
            this.startPolling(changes.uploadId.currentValue);
        }
    }

    /**
     * Lifecycle hook called to clean up the component before it's destroyed.
     */
    ngOnDestroy(): void {
        this.stopPolling();
    }

    /**
     * Starts polling the server for upload status updates.
     * @param uploadId - The ID of the current upload process.
     */
    private startPolling(uploadId: string): void {
        this.stopPolling();  // Ensure any existing polling is stopped

        if (uploadId) {
            this.statusPollingSubscription = interval(5000)
                .pipe(
                    switchMap(() => this.progressService.progress(uploadId)),
                    takeWhile((upload: any) => upload.status !== "Imported" && upload.status !== "Cancelled")
                )
                .subscribe({
                    next: (upload: any) => {
                        if (this.firstProgressCall) {
                            console.log("First progress call:", upload);
                            this.firstProgressCall = false; // Set to false after first log
                        }
                        this.handleUploadStatus(upload);
                        
                    },
                    error: (error) => console.error('Error fetching upload progress:', error),
                    complete: () => {
                        if (!this.importComplete) {
                            this.fileImported();
                        }
                        this.stopPolling();
                    }
                });
        }
    }

    /**
     * Stops polling for upload status updates.
     */
    private stopPolling(): void {
        if (this.statusPollingSubscription) {
            this.statusPollingSubscription.unsubscribe();
            this.statusPollingSubscription = null;
        }
    }

    /**
     * Handles the upload status and takes appropriate actions based on the status.
     * @param upload - The current status of the upload process.
     */
    private handleUploadStatus(upload: any): void {
        console.log(upload);
        
        if (upload.status === "Imported") {
            this.importComplete = true;
            return;  
        }
        if (upload.status === "Cancelled"){
            return;
        }

        switch (upload.status) {
            case "Uploaded":
                this.progressService.validate(upload.uploadId).subscribe();
                break;
            case "ValidationInProgress":
                this.processValidationInProgress(upload);
                break;
            case "Validated":
                this.fileValidated(upload.validationSummary);
                break;
            case "ImportInProgress":
                this.processImportInProgress(upload);
                break;
            case "Error":
                this.onFileUploadProcessingError(upload.errorMessage);
                break;
        }
    }

    /**
     * Processes the validation in progress status.
     * @param status - The current status of the validation process.
     */
    private processValidationInProgress(status: any): void {
        this.changeUploadStatus.emit(this.fileStatusTypes.validating);
        this.customValidation = status.customValidation;
        this.customValidationMessage = status.customValidationMessage;
        this.rowsValidated = status.numberOfRowsProcessed;
        this.rowsToValidate = status.totalNumberOfRows;
        this.rowsImported = null;
        this.rowsToImport = null;
    }

    /**
     * Processes the import in progress status.
     * @param status - The current status of the import process.
     */
    private processImportInProgress(status: any): void {
        this.changeUploadStatus.emit(this.fileStatusTypes.importing);
        this.rowsValidated = null;
        this.rowsToValidate = null;
        this.customValidation = false;
        this.customValidationMessage = null;
        this.rowsImported = status.numberOfRowsProcessed;
        this.rowsToImport = status.totalNumberOfRows;
    }

    /**
     * Handles the completion of the file import process.
     */
    private fileImported(): void {
        if (!this.firstProgressCall) {
            this.importComplete = true;  
            this.onImportComplete.emit();
            this.messageService.showSuccess("File import complete");
            this.changeUploadStatus.emit(this.fileStatusTypes.saved);
        }
       
    }

    /**
     * Handles the file validation completion.
     * @param validationResultSummary - Summary of the validation results.
     */
    private fileValidated(validationResultSummary: any): void {
        this.changeUploadStatus.emit(this.fileStatusTypes.validated);
        this.validationSummary = validationResultSummary;
    }

    /**
     * Handles errors during the file upload process.
     * @param uploadErrorMessage - The error message returned by the upload process.
     */
    private onFileUploadProcessingError(uploadErrorMessage: string): void {
        this.messageService.showError(uploadErrorMessage);
        this.changeUploadStatus.emit(this.fileStatusTypes.saved);
        this.stopPolling();
    }

    /**
     * Cancels the file upload process.
     */
    public importCancelled(): void {
        this.changeUploadStatus.emit(this.fileStatusTypes.saved);
        this.stopPolling();
        this.progressService.cancel(this.uploadId).subscribe(() => {
            this.messageService.showInfo("File upload process is cancelled");
        });
    }

    /**
     * Queues the file for import.
     */
    public queueForImport(): void {
        this.changeUploadStatus.emit(this.fileStatusTypes.importing);
        this.progressService.import(this.uploadId).subscribe();
    }
}
