import { Component, ViewEncapsulation } from '@angular/core';
import { PreloginService } from '@core/prelogin/services/prelogin.service';

@Component({
    selector: 'app-account-activation',
    templateUrl: './account-activation.component.html',    
    encapsulation: ViewEncapsulation.None
})
export class AccountActivationComponent {
    public canActivate: boolean = false;
    public user: any;
    public selfRegistrationIsEnabled = false;

    constructor(public PreloginService: PreloginService){        
    }

    completeProfile(user: any) {
        this.canActivate = true;
        this.user = user;
        this.PreloginService.getSelfRegistrationStatus().subscribe(data => {
            this.selfRegistrationIsEnabled = data.isEnabled;
        });
    }
}