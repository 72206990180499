import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { HttpService } from '@core/shared';

import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

import {
  UrlBuilder,
  GET_POWERBI_REPORT,
  GET_SUPERSET_REPORT,
  GET_EMBEDDED_REPORT_TYPE
  
} from '@core/core-api-variables';

@Injectable()
export class EmbeddedReportService {

  constructor(public http: HttpService,private paramsBuilderService : ParamsBuilderService) { }

  getEmbeddedReport(embeddedReportPageId:string,culture:string,fallBackCulture:string): Observable<any> {            
    let data = { embeddedReportPageId,culture,fallBackCulture }
    let url = UrlBuilder(GET_POWERBI_REPORT, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    }));
    
  } 

  getSuperSetReport(superSetReportPageId:string,culture:string,fallBackCulture:string):Observable<any>{
    let data = { superSetReportPageId,culture,fallBackCulture }
    let url = UrlBuilder(GET_SUPERSET_REPORT, data);
    return this.http.get(url).pipe(map((response: any) => {
      return response;
    })); 
  }

  getEmbeddedReportType(id:string):Observable<any>{
    let data ={id}
    let url = UrlBuilder(GET_EMBEDDED_REPORT_TYPE,data);
    return this.http.get(url).pipe(map((res:any)=>{
      return res;
    }))
  }
}
