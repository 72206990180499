import { Injectable } from '@angular/core';
import { HttpService } from '@core/shared';
import { map } from 'rxjs/operators';

import { SearchCriteria } from '@core/shared/models/search-criteria';
import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

import { UrlBuilder, GET_SALES,CHECK_RESOLVER,CLAIM,RESOLVE,APPROVE,REJECT
  } from '@core/core-api-variables';
  

@Injectable()
export class SalesClaimService {

  constructor(public http: HttpService, public paramsBuilderService: ParamsBuilderService) { }

  getSales(claimFormId, searchCriteria: SearchCriteria) {
    let params = this.paramsBuilderService.buildParams(searchCriteria);
    let data = {claimFormId,params};
    let url = UrlBuilder(GET_SALES, data);

    return this.http.get(url).pipe(map((response: any) => {
        return response;
      }));
  }

  checkResolver(claimFormId, saleId) {

    let data = {claimFormId,saleId};
    let url = UrlBuilder(CHECK_RESOLVER, data);

    return this.http.get(url).pipe(map((response: any) => {
        return response;
      }));
  }

  claim(claimFormId, saleId, detail) {
    let payload = { Detail: detail };
    let data = {claimFormId, saleId};
    let url = UrlBuilder(CLAIM, data);
    
    return this.http.post(url, payload).pipe(map((response: any) => {
      return response;
    }));
  }

  resolve(claimFormId, saleId, payload) {

    let data = {claimFormId, saleId};
    let url = UrlBuilder(RESOLVE, data);
    
    return this.http.post(url, payload).pipe(map((response: any) => {
      return response;
    }));
    
  }

  approve(claimFormId, saleId, payload) {

    let data = {claimFormId, saleId};
    let url = UrlBuilder(APPROVE, data);
    
    return this.http.post(url, payload).pipe(map((response: any) => {
      return response;
    }));
  }

  reject(claimFormId, saleId, payload) {

    let data = {claimFormId, saleId};
    let url = UrlBuilder(REJECT, data);
    
    return this.http.post(url, payload).pipe(map((response: any) => {
      return response;
    }));
  }
}
