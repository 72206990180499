import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '@core/startup';

@Injectable()
export class RouteGuard implements CanActivate {
    constructor(public router: Router, public authService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        let page = route.data['page'];
        if (!page)
            return Promise.resolve(true)
        else
            return new Promise(res => {
                this.authService.getGlobalMenuAccess().subscribe((response) => {
                    if (!response[page])
                        this.router.navigate(['/home']);
                    res(response[page]);

                });
            });
    }
}