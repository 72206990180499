import { Injectable, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabService } from './tab.service';

@Injectable({ providedIn: 'root' })
export class TabSelectionService {
    private activeTab = new BehaviorSubject(0);
    activeTab$ = this.activeTab.asObservable();

    constructor(public tabService: TabService) { }

    private getTab1() {
        return () =>
            import('../profile-status-tabs/profile-status-tab1/profile-status-tab1.component').then(
                m => m.ProfileStatusTab1Component
            );
    }

    private getTab2() {
        return () =>
            import('../profile-status-tabs/profile-status-tab2/profile-status-tab2.component').then(
                m => m.ProfileStatusTab2Component
            );
    }
    private getTab6() {
        return () =>
            import('../profile-status-tabs/profile-status-tab6/profile-status-tab6.component').then(
                m => m.ProfileStatusTab6Component
            );
    }

    changeTab(index: number) {
        this.activeTab.next(index);
    }


    loadComponent(vcr: ViewContainerRef, tab: number) {       
        vcr.clear();
        let selectedTab;
        switch (tab) {
            case 0:
                selectedTab = this.getTab1();
                break;
            case 1:
                selectedTab = this.getTab2();
                break;
            case 5:
                selectedTab = this.getTab6();
                break;    
            default:
                selectedTab = this.getTab1();
                break;
        }
        return this.tabService.forChild(vcr, {
            loadChildren: selectedTab
        });
    }
}