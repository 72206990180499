import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CashrewardsService } from './services/cashrewards.service';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import { Subject, takeUntil } from 'rxjs';
import { PlatformSystemSettings } from '@app/core/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cashrewards',
  templateUrl: './cashrewards.component.html',
  styleUrls: ['./cashrewards.component.scss']
})
export class CashrewardsComponent implements OnInit {
  @ViewChild('myForm') myForm: ElementRef<HTMLFormElement>;
  constructor(private cashRewards: CashrewardsService, private store: Store<fromStore.PlatformState>, private router:Router) {
    this.store.select(fromStore.globalMenuState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
      if (state && state.menuAccess && this.systemSettings) {
          this.menuAccess = state.menuAccess;
          this.checkAccess();
      }
  });
  }
  private onDestroy$ = new Subject<void>();
  menuAccess: any;
  access:boolean = false;
  data:any;
  url:string = '';
  systemSettings: PlatformSystemSettings;

  ngOnInit(): void {
    if (localStorage.getItem('platformSettings')) {
      this.systemSettings = JSON.parse(localStorage.getItem('platformSettings'));
      if (this.menuAccess == undefined) {
          this.store.select(fromStore.globalMenuState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
              if (state && state.menuAccess && this.systemSettings) {
                  this.menuAccess = state.menuAccess;
                  this.checkAccess();
              }
          });
      } else {
          this.checkAccess();
      }
      this.cashRewards.getCashRewardsSsoDetails().subscribe((res)=>{
        this.data = res
        this.url = `${this.systemSettings.crBaseUrl}?request=${res.request}&companyId=${res.companyId}&country=${res.country}`;
        this.redirectToAnotherApp();
        this.router.navigate([`/home/`]);
      })
    }
  }

  redirectToAnotherApp() {
    // Programmatically submit the form to initiate the redirect
    if (document.getElementById('cashRewardsForm') != null)
                document.getElementById('cashRewardsForm').remove();
            let bodyContent = '';
            bodyContent = bodyContent + '<input type="hidden" name="request" value="' + this.data.request + '" />';
            bodyContent = bodyContent + '<input type="hidden" name="companyId" value="' + this.data.companyId + '" />';
            bodyContent = bodyContent + '<input type="hidden" name="country" value="' + this.data.country + '" />';
            let form = $('<form id="cashRewardsForm" target="_blank" action="' + this.url + '" method="get">' +
                bodyContent +
                '</form>');
            $('body').append(form);
            form.trigger('submit');
  }
  checkAccess() {
    if(this.systemSettings.isCashRedemptionEnabled){
      this.access = true;
    }
  }
}
