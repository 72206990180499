<body-text class="block medium-size-plus-3 font-bold" translate>{{'TabView.QuickTip'}}</body-text>
<div [appIsLoading]="loadingService.isLoading(this, 'message')">
    <div class="padding-v-20 bg-color2" *ngIf="message">
        <cs-http-img [httpSrc]="message.articles[0].teaserImageToken" httpType="view"
            [alternativeText]="message.articles[0].teaserText" height="322">
        </cs-http-img>


        <body-text class="block super-heading6 font-bold margin-top-20"
            [ngStyle]="{'color': attributeJson.bannerTextColor? attributeJson.bannerTextColor: '#ffffff'}">
            {{message.articles[0].title}}</body-text>
        <body-text class="block medium-size-minus-2"
            [ngStyle]="{'color': attributeJson.bannerTextColor? attributeJson.bannerTextColor: '#ffffff'}">
            {{message.articles[0].teaserText}}</body-text>

        <div class="margin-top-10">
            <form-button theme="theme6" [text]="attributeJson.buttonText" class="ng-isolate-scope"
                (click)="redirect(message.id, message.articles[0].id, attributeJson.externalLink)"></form-button>
            <fa-icon [icon]="arrowNext"
                (click)="redirect(message.id, message.articles[0].id, attributeJson.externalLink)" class="ml-3 mr-3">
            </fa-icon>
        </div>
    </div>
</div>