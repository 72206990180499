import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { LoadingService } from '@core/shared';
import { HubPageService } from '@core/private/hubpage/services/hub-page.service';

@UntilDestroy()
@Component({
    selector: 'app-hub-manager-goal-quest',
    templateUrl: 'manager-goal-quest.component.html',
    styleUrls: ['goal-quest-manager.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ManagerGoalQuestComponent implements OnChanges {
    @Input() componentId: string;
    @Input() payload: any;
    @Input() culture: string;
    
    goalQuest: any;
    mostRedeemedProducts: any;
    goalSelectionRemainingTime: number;
    goalProgressRemainingTime: number;
    isMinimumQualifierEnabled: boolean;
    activeStage: number;

    constructor(private hubPageService: HubPageService, public loadingService: LoadingService) { }

  ngOnChanges() {
    this.loadingService.doLoading(this.hubPageService.getGoalQuestHubComponent(this.payload.selectedItem.id), this, "managergq")
      .pipe(untilDestroyed(this)).subscribe(response => {
        this.goalQuest = response;
        this.calculateGoalQuestProgress();
      });
  }

    calculateGoalQuestProgress() {        
        if (this.goalQuest.goalsSelectedUsersCounts && this.goalQuest.goalsSelectedUsersCounts.length > 0) {
            this.goalSelectionRemainingTime = this.calculateDateDifference(new Date(), new Date(this.goalQuest.goalSelectionLastDate))
            this.goalProgressRemainingTime = this.calculateDateDifference(new Date(), new Date(this.goalQuest.promotionLastDate))
            this.isMinimumQualifierEnabled = this.goalQuest.isMinimumQualifierEnabled;
            this.findStage();
        }
    }

    calculateDateDifference(startDate, endDate) {
        var startDateUtc = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
        var endDateUtc = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());

        return (endDateUtc - startDateUtc) / 1000;
    }

    findStage() {
        if (this.goalSelectionRemainingTime > 0) {
            this.activeStage = 1;
        }
        else if (this.goalProgressRemainingTime > 0) {
            this.activeStage = 2;
        }
        else {
            this.activeStage = 3;
        }
    }  
}
