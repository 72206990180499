<div class="home-page">
  <div class="bg-color3 home-page-bg">
    <div class="content-width home-point-gallery-section">

      <!-- Right side -->
      <div class="flex-1 home-page-slide rightPane">
        <!-- Toggle button -->
        <div *ngIf="showPointTypeTab" class="internal-tab">
          <!-- Rewards Tab -->
          <div (click)="onPointTypeChange('rewards')" class="tab-item" [class.active]="pointType === 'rewards'">
            <span>Rewards</span>
          </div>

          <!-- Wallet Tab -->
          <div (click)="onPointTypeChange('wallet')" class="tab-item" [class.active]="pointType === 'wallet'">
            <span>Wallet</span>
          </div>
        </div>
        <div [appIsLoading]="loadingService.isLoading(this,'config') || loadingService.isLoading(this, 'data')">
          <app-point-summary [summaryDetails]="summaryDetails" [summary]="summary"></app-point-summary>
        </div>

        <div class="filter-section">
          <div class="color2 block medium-size-plus-3 padding-h-10 benefits-heading"></div>
          <div class="pointSummary-points">
            <form #summaryFilter="ngForm" (ngSubmit)=onSubmit()>
              <div class="grid grid-gap-0 grid-col-hd-25 grid-col-sm-100">
                <div class="form-group item">
                  <label for="fromdate" class="datePlacement">{{'PointsSummaryPages.From' | translate}}</label><br />
                  <div class="input-group">
                    <input class="form-control datePickerCalander" [(ngModel)]="filter.fromDate"
                      placeholder="mm-dd-yyyy" name="fromDate" ngbDatepicker #dpFromDate="ngbDatepicker"
                      [maxDate]="maxFromDate" [minDate]="minFromDate" (ngModelChange)="blockOldDate('from')" readonly />
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="dpFromDate.toggle()"
                        type="button"></button>
                    </div>
                  </div>
                </div>
                <div class="form-group item">
                  <label for="fromdate" class="datePlacement"><span
                      translate="PointsSummaryPages.To"></span></label><br />
                  <div class="input-group">
                    <input class="form-control datePickerCalander" [(ngModel)]="filter.toDate" placeholder="mm-dd-yyyy"
                      name="toDate" ngbDatepicker #dpToDate="ngbDatepicker" [maxDate]="maxToDate" [minDate]="minToDate"
                      (ngModelChange)="blockOldDate('to')" readonly />
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="dpToDate.toggle()"
                        type="button"></button>
                    </div>
                  </div>
                </div>
                <div class="form-group item">
                  <label for="promotion"><span translate="PointsSummaryPages.PromotionName"></span></label><br />
                  <select name="list" class="form-control" [(ngModel)]="filter.promotion">
                    <option value="">Select Promotion</option>
                    <option *ngFor="let promotion of promotions" [value]="promotion.id">
                      {{ promotion.name }}
                    </option>
                  </select>
                  <em class="fa fa-chevron-down" aria-hidden="true"></em>
                </div>

                <div>
                  <div class="form-group item">
                    <label for="transactionType" class="control-label">&nbsp;</label><br />
                    <label class="radio-inline">
                      <input type="radio" name="transactionType" value="EarnedPoints" [(ngModel)]="filter.type">
                      <span translate="PointsSummaryPages.Earned"></span>
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="transactionType" value="RedeemPoints" [(ngModel)]="filter.type"><span
                        translate="PointsSummaryPages.Redeemed"></span>
                    </label>
                    <label *ngIf="showExpiredData" class="radio-inline">
                      <input type="radio" name="transactionType" value="ExpiredPoints" [(ngModel)]="filter.type"><span
                        translate="PointsSummaryPages.Expired"></span>
                    </label>
                    <label class="radio-inline">
                      <input type="radio" name="transactionType" value="" [(ngModel)]="filter.type">
                      <span translate="PointsSummaryPages.All"></span>
                    </label>
                    <button class="btn btn-primary pointSummaryBtn" type="submit"> GO </button>
                  </div>

                </div>
              </div>
            </form>
          </div>


          <app-points-statement-chart-view [ngStyle]="{'display': showchart ? 'block' : 'none'}"
            (showChart)='showChart($event)' [payload]="payload"></app-points-statement-chart-view>
          <app-points-statement-table-view [payload]="payload"></app-points-statement-table-view>
        </div>
      </div>

      <!-- Left side -->
      <div class="home-left leftPane">
        <cs-profile-point-card theme="theme-white" buttonTheme="theme2"></cs-profile-point-card>
        <cs-most-redeem-products [singleView]="true"></cs-most-redeem-products>
      </div>
    </div>
  </div>
</div>