import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';

import { LoadingService } from '@core/shared';
import { PointSummaryService } from '../services/pointsummary.service';

@UntilDestroy()
@Component({
    selector: 'app-points-statement-chart-view',
    templateUrl: 'points-statement-chart-view.component.html',
    styleUrls: [`points-statement-chart-view.component.scss`]
})

export class PointsStatementChartViewComponent implements OnChanges {
    @Input() payload;
    @Output('showChart') showChart = new EventEmitter();

    public lineChartData: ChartConfiguration<'line'>['data'] = { datasets: [] };
    public lineChartOptions: ChartOptions<'line'> = { responsive: true };
    public lineChartLegend = true;

    lineChartLabels: any[];
    lineChartPlugins = [];

    constructor(private pointsummaryService: PointSummaryService, public loadingService: LoadingService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.payload && changes.payload.previousValue != changes.payload.currentValue) {
            this.setupLabels();
            this.getPointsStatementForChart();
        }
    }

    getPointsStatementForChart() {
        this.loadingService.doLoading(this.pointsummaryService.getPointsStatementForChart(this.payload.fromDate, this.payload.toDate, this.payload.promotion, this.payload.pointType), this)
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.setupChartData(response);
            });
    }

    setupLabels() {

        let labels = [];
        let startDate = moment(this.payload.fromDate);
        let toDate = moment(this.payload.toDate);

        while (startDate.isBefore(toDate)) {
            labels.push(startDate.format("MMM-YY"));
            startDate.add(1, 'month');
        }

        this.lineChartLabels = labels;
    }

    setupChartData(response) {
        let showChartData = true;
        if (this.payload.type === "ExpiredPoints") {
            showChartData = false;
        }
        this.showChart.emit(showChartData);

        let dataSets: ChartConfiguration<'line'>['data'] = {
            datasets: []
        };
        let earned = [];
        let redeemed = [];

        this.lineChartLabels.forEach(label => {
            let earn = response.find(x => x.monthYear === label && x.type === "EarnedPoints");
            let redeem = response.find(x => x.monthYear === label && x.type === "RedeemPoints");
            earned.push(earn ? earn.point : 0);
            redeemed.push(redeem ? redeem.point : 0);
        });

        if (this.payload.type === '' || this.payload.type === "EarnedPoints") {
            let earnedDataSet: any = {};
            earnedDataSet.data = earned;
            earnedDataSet.label = "Earned";
            earnedDataSet.backgroundColor = 'rgba(255,165,0,0.28)';
            earnedDataSet.borderColor = '#FF8C00';
            earnedDataSet.fill = true;
            earnedDataSet.tension = 0.5;
            dataSets.datasets.push(earnedDataSet);
        }
        if (this.payload.type === '' || this.payload.type === "RedeemPoints") {
            let redeemedDataSet: any = {};
            redeemedDataSet.data = redeemed;
            redeemedDataSet.label = "Redeemed";
            redeemedDataSet.backgroundColor = 'rgba(0,128,0,0.28)';
            redeemedDataSet.borderColor = '#228B22';
            redeemedDataSet.fill = true;
            redeemedDataSet.tension = 0.5;
            dataSets.datasets.push(redeemedDataSet);
        }
        dataSets.labels = this.lineChartLabels;
        this.lineChartData = dataSets;
    }
}