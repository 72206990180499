import { Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { PreloginService } from '@app/core/prelogin/services';
import { WindowReferenceService, ICustomWindow } from '@core/shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SelectOptions } from '@core/models';
import { RegexService, AuthenticationService } from '@app/core/startup';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { browserRefresh } from '@app/app.component';
let windowObject = window as any;

@Component({
  selector: 'app-complete-profile-self-registration',
  templateUrl: './complete-profile-self-registration.component.html',
  styleUrls: ['./complete-profile-self-registration.component.scss']
})
export class CompleteProfileSelfRegistrationComponent implements OnInit, OnDestroy {
  @Input() user: any;

  private _window: ICustomWindow;
  public userId: string;
  public formActivationSelfRegistration: FormGroup;
  public submitted: boolean = false;
  public securityQuestion: Array<SelectOptions>;
  public active: number = 1;
  selectedCulture: string;
  languageChangeSubscription: Subscription;
  navigationStartSubscription: Subscription;
  browserRefresh: boolean;

  constructor(public PreloginService: PreloginService, public windowRef: WindowReferenceService,
    public formBuilder: FormBuilder, public regexService: RegexService, public authenticationService: AuthenticationService,
    public toaster: ToastrService, public router: Router, private modalService: NgbModal, private translateService: TranslateService) {
    this._window = windowRef.nativeWindow;
    this.setupSecurityQuestion();
  }

  ngOnInit(): void {
    windowObject.parent.preRegistrationCallBack = function (userDetails): Promise<any> {
      var success = {
        "success": true,
        "errorCode": "",
        "responseMessage": ""
      };
      return Promise.resolve(success);
    };

    windowObject.parent.postRegistrationCallBack = (userDetails, message) => {
      this.getUserDetails();
    
    };
    this.formActivationSelfRegistration = this.formBuilder.group({
      firstName: [this.user.firstName, [Validators.required]],
      lastName: [this.user.lastName, [Validators.required]],
      email: [this.user.email, [Validators.required]],
      mobileNumber: [this.user.mobileNumber ? this.user.mobileNumber : null],
      jobRole: [{ value: this.user.jobRole ? this.user.jobRole.description : null, disabled: true }],
      securityQuestion: [null, [Validators.required]],
      securityAnswer: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.pattern(this.regexService.passwordRegex)]],
      confirmPassword: [null, [Validators.compose([Validators.required, this.validateAreEqual.bind(this)])]],
      termsAccepted: [false, [Validators.requiredTrue]],
      type: ['username'],
      preferenceAccepted: [ false ]

    })

    this.navigationStartSubscription = this.router.events.pipe(
      filter((e) => e instanceof RouterEvent),
      startWith('Initial call')
    ).subscribe((res : NavigationEnd) => {
      this.browserRefresh = browserRefresh;
      this.getConfig();
    });


    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      window.location.reload();
    });
  }

  getConfig() {
    this.selectedCulture = localStorage.getItem('culture');
    this.PreloginService.selfRegistrationConfig().subscribe(data => {
      this.renderForm(data);
    })
  }

  renderForm(configs) {
    this.PreloginService.accountActivationSelfRegistration(configs, this.user.userId, this.selectedCulture).subscribe(data => {
      this._window.__selfRegistrationAppData__ = data;
      this._window.loadSelfRegistrationInDom('selfRegistrationRoot');
    });
  }

  setupSecurityQuestion() {
    this.securityQuestion = [
      { label: "ResetSecurityQuestionAndAnswer.SecurityQuestions.CityBorn", value: "ResetSecurityQuestionAndAnswer.SecurityQuestions.CityBorn" },
      { label: "ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstPet", value: "ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstPet" },
      { label: "ResetSecurityQuestionAndAnswer.SecurityQuestions.MothersMaidenName", value: "ResetSecurityQuestionAndAnswer.SecurityQuestions.MothersMaidenName" },
      { label: "ResetSecurityQuestionAndAnswer.SecurityQuestions.DadBorn", value: "ResetSecurityQuestionAndAnswer.SecurityQuestions.DadBorn" },
      { label: "ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstCar", value: "ResetSecurityQuestionAndAnswer.SecurityQuestions.FirstCar" }
    ];
  }

  securityQuestionChange(selectedValue) {
    this.formActivationSelfRegistration.controls['securityQuestion'].setValue(selectedValue.value);
  }

  activate(formActivationSelfRegistration) {
    this.submitted = true;
    if (formActivationSelfRegistration.valid) {
      this.user.firstName = formActivationSelfRegistration.value.firstName;
      this.user.lastName = formActivationSelfRegistration.value.lastName;
      this.user.email = formActivationSelfRegistration.value.email;
      this.user.mobileNumber = formActivationSelfRegistration.value.mobileNumber;
      this.user.securityQuestion = formActivationSelfRegistration.value.securityQuestion;
      this.user.securityAnswer = formActivationSelfRegistration.value.securityAnswer;
      this.user.password = formActivationSelfRegistration.value.password;
      this.user.confirmPassword = formActivationSelfRegistration.value.confirmPassword;
      this.user.termsAccepted = true;
      this.user.type = formActivationSelfRegistration.value.type;
      this.user.username = this.user.userName;
      this.user.preferenceAccepted = formActivationSelfRegistration.value.preferenceAccepted;
      this.PreloginService.activate(this.user).subscribe(result => {
        this.toaster.success('User Activated Successfully');
        this.authenticationService.login(this.user.username, this.user.password, 'username', 'password', '', false).subscribe(result => {
          this.router.navigate([`/home`]);
        });
      });
    }
  }

  validateAreEqual(fieldControl: FormControl) {
    if (!this.formActivationSelfRegistration) {
      return null;
    }
    return fieldControl.value === this.formActivationSelfRegistration.get("password").value ? null : {
      notEqual: true
    };
  }

  getUserDetails() {
    this.active = 2;
  }

  termsAndConditions(terms) {
    this.modalService.open(terms, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { },
      (error) => { });
  }

  navbarChange(event) {
    event.preventDefault();
  }

  ngOnDestroy() {
    if (typeof windowObject.parent.postRegistrationCallBack === 'function') {
      delete windowObject.parent.postRegistrationCallBack;
    }
    if (typeof windowObject.parent.preRegistrationCallBack === 'function') {
      delete windowObject.parent.preRegistrationCallBack;
    }

    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }
}
