import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Pagination } from '@core/shared/models/pagination';

@Component({
    selector: 'cs-items-per-page',
    templateUrl: 'items-per-page.component.html'
})
export class PaginationItemsPerPageComponent {
    @Input() paginationDetails: Pagination;
    @Output() onChangeEvent: EventEmitter<Pagination> = new EventEmitter<Pagination>();

    onItemsChange(selectedValue: number) {
        let pagination = new Pagination(this.paginationDetails.totalNumberOfItems, 1, selectedValue);
        this.onChangeEvent.emit(pagination);
    }
}