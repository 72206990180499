<div class="cs-profile-point-card" [class]="theme">
    <div class="inner-container">
        <div class="flex-1">
            <div class="profile-image-container" [ngClass]="{'custom-profile-image-container': showBalance}">
                <profile-image-with-progress *ngIf="user" [imgUrl]="profileImage" trackColor="#fff" size="90"
                    fullName="{{user.Firstname+ ' ' +user.Lastname}}" [changeSelection]="onchangeFunction" [isLoading]="isLoading"></profile-image-with-progress>
            </div>
            <div class="margin-top-10">
                <body-text class="block text-center font-light super-heading5" *ngIf="user">{{'ProfilePointCard.Hi' | translate}}
                    <span *ngIf="!showBalance">{{user.Firstname | uppercase}} {{user.Lastname | uppercase}}</span>
                </body-text>
                <body-text class="block text-center font-light super-heading5" *ngIf="showBalance && menuAccess && menuAccess.points">
                    {{'ProfilePointCard.Congrats' |
                    translate}}<br>
                    <strong>
                        <cs-animated-digit [digit]="point" [duration]="1000"></cs-animated-digit>
                    </strong> {{'ProfilePointCard.Points' | translate}}
                </body-text>
            </div>
            <div class="text-center margin-top-20">
                <cs-redeem-now [buttonTheme]="buttonTheme"></cs-redeem-now>
                <div class="margin-top-10">
                    <a class="link-btn link-no-decor color-inherit" ui-sref="main.pointSummary" *ngIf="menuAccess && menuAccess.pointStatement">
                        <a herf="/pointsummary" (click)="pointSummary()">
                            <body-text class="font-light medium-size block padding-10">
                                {{'ProfilePointCard.PointsStatement' | translate}}</body-text>
                        </a>
                    </a>
                </div>
            </div>            
        </div>
    </div>
    <div class="gold-member" *ngIf="tierName">
        {{tierName}}
    </div>
</div>