import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import {
  StartupService,
  AuthenticationService,
  AlertService,
  RegexService  
} from './services';
import {
  httpInterceptorProviders,
  startupServiceFactory,
  httpLoaderFactory
} from './interceptor.registry';

@NgModule({ declarations: [],
    exports: [], imports: [CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        })], providers: [
        StartupService,
        AuthenticationService,
        RegexService,
        AlertService,
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true
        },
        ...httpInterceptorProviders,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class StartupModule {
  /* make sure StartupModule is imported only by one NgModule the AppModule */
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: StartupModule
  ) {
    if (parentModule) {
      throw new Error('StartupModule is already loaded. Import only in AppModule');
    }
  }
}
