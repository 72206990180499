import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';
import { MembershipTierComponent } from './membership-tier.component';
import { MembershipTieringComponent } from './membership-tiering-component/membership-tiering.component';

export const MembershipTieringRoutes: Routes = [

    {
        path: 'tier',
        component: MembershipTierComponent,
        children: [
            {
                path: 'membershiptiering',
                canActivate: [AuthGuard],
                component: MembershipTieringComponent,
                runGuardsAndResolvers: 'always',
                pathMatch: 'full',
                data: { title: 'Tier', isPreLogin: false }
            }
        ]
    }
];