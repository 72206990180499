import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "@app/core/shared";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { NotificationCornerComponent } from "./notifications/notification.component";
import { NotificationCornerService } from "./services/notificationcorner.service";
import { ViewNotificationModalComponent } from "./view-notification/view-notification.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        FontAwesomeModule
    ],
    providers: [
        NotificationCornerService
    ],
    declarations: [
        NotificationCornerComponent,
        ViewNotificationModalComponent
    ]
})
export class NotificationCornerModule { }