import { Component, Input } from '@angular/core';

@Component({
  selector: 'cs-points-balance',
  templateUrl: './points-balance.component.html',
  styleUrls: ['./points-balance.component.scss']
})
export class PointBalanceComponent {  
  @Input() content;
  @Input() icon;
  @Input() value;
}
