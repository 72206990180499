import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ImportBehaviors } from '@core/shared/models/import-behaviors';
import { environment } from '@env/environment';

@Injectable()
export class FileUploadService {

	constructor(private httpClient: HttpClient) { }	

	fileUpload(file, uploadType, userId, uploadContext, importBehaviours: ImportBehaviors): Observable<object> {
		const formdata = new FormData();
		formdata.append('data', file);
		formdata.append('uploadContext', uploadContext);
        formdata.append('userId', userId);
		formdata.append('appendNewRecords', String(importBehaviours.appendNewRecords));
		formdata.append('updateExistingRecords', String(importBehaviours.updateExistingRecords));
		formdata.append('deleteMissingRecords', String(importBehaviours.deleteMissingRecords));
		formdata.append('delimiter', ',');
		formdata.append('uploadType', uploadType);
		return this.httpClient.post(`${environment.baseUrl + environment.version}customUploads`, formdata, {observe: 'response'});
	}

    progress(uploadId): Observable<object> {
        return this.httpClient.get(`${environment.baseUrl + environment.version}customUploads/${uploadId}/progress`);
    }

    validate(uploadId): Observable<object> {
        return this.httpClient.put(`${environment.baseUrl + environment.version}customUploads/${uploadId}/validate`, {});
    }

    import(uploadId): Observable<object> {
        return this.httpClient.put(`${environment.baseUrl + environment.version}customUploads/${uploadId}/import`, {});
    }

    cancel(uploadId): Observable<object> {
        return this.httpClient.put(`${environment.baseUrl + environment.version}customUploads/${uploadId}/cancel`, {});
    }
}