import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { FormBuilderComponent } from "./form-builder/form-builder.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SharedModule } from "@app/core/shared";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [ContactUsComponent, FormBuilderComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class ContactuspageModule {}
