import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { HttpService } from '@core/shared';
import { PLATFORM_MENU, PLATFORM_MENU_BYID, REPORT_IMPERSONATE, UrlBuilder } from '@core/core-api-variables';

export interface Menu {
    displayIndex: number;
    id: string;
    menuType: string;
    name: string;
    parentId: string;
    show: boolean;
    children: Menu[];
    translations: any;
    attributesJson: any;
    url: string;
    displayNone: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    constructor(public http: HttpService) { }

    getMenu(): Observable<any> {
        return this.http.get(PLATFORM_MENU).pipe(map((response: Menu) => {
            return response;
        }));
    }

    getMenubyId(menuId): Observable<any> {
        let url = UrlBuilder(PLATFORM_MENU_BYID, { menuId: menuId });
        return this.http.get(url).pipe(map((response: Menu) => {
            return response;
        }));
    }

    getImpersonateToken(userId) {
        let payload = { UserId: userId };
        return this.http.post(REPORT_IMPERSONATE, payload).pipe(map(response => {
            return response;
        }));
    }
}
