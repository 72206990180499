import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'cs-invoice-reject-claim',
    templateUrl: 'reject-claim.component.html'
})

export class InvoiceRejectClaimComponent implements OnInit {
    @Input() rejectDto;
    formRejectClaim: FormGroup;

    constructor(private activeModal: NgbActiveModal, private fb: FormBuilder) { }

    ngOnInit() {
        this.formRejectClaim = this.fb.group({            
            rejectionReason: [null, [Validators.required]]
        });
    }

    onSubmit() {
        this.activeModal.close(this.formRejectClaim.getRawValue());
    }

    cancel() {
        this.activeModal.dismiss();
    }
}