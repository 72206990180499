<div class="banner-container">
  <div *ngIf="message">
    <ngx-slick-carousel class="carousel" [config]="slideConfigHub" #slickModal="slick-carousel">
      <div ngxSlickItem *ngFor="let slide of updatedArticles; let i = index">
        <div class="banner-container-inner">
          <div class="banner-teaser-text">
            <h2
              [ngStyle]="{'color': slide.attributeJson.bannerTextColor? slide.attributeJson.bannerTextColor: '#ffffff'}">
              {{slide.title }}
            </h2>
            <p
              [ngStyle]="{'color': slide.attributeJson.bannerTextColor? slide.attributeJson.bannerTextColor: '#ffffff'}">
              {{ slide.teaserText }}
            </p>
            <div class="btn-group margin-top-10">
              <button type="button" class="btn btn-primary customBtnSearch"
                (click)="redirect(message.id, slide.id, slide.attributeJson.externalLink)"><strong>{{slide.attributeJson.buttonText}}</strong></button>
            </div>
          </div>
          <cs-http-img cssClass="img-fluid background-img" [httpSrc]="slide.teaserImageToken" httpType="view" [alternativeText]="slide.Title">
          </cs-http-img>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>