import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, InjectionToken, NgModule } from '@angular/core';
import Gauge from 'svg-gauge';
class GaugeDefaults {
  constructor() {
    /**
     * The angle in degrees to start the dial
     */
    this.dialStartAngle = 135;
    /**
     * The angle in degrees to end the dial. This MUST be less than dialStartAngle
     */
    this.dialEndAngle = 45;
    /**
     * The radius of the gauge
     */
    this.dialRadius = 40;
    /**
     * The minimum value for the gauge
     */
    this.min = 0;
    /**
     * The maximum value for the gauge
     */
    this.max = 100;
    /**
     * Whether to show the value at the center of the gauge
     */
    this.showValue = true;
    /**
     * The CSS class of the gauge
     */
    this.gaugeClass = 'gauge';
    /**
     * The CSS class of the gauge's dial
     */
    this.dialClass = 'dial';
    /**
     * The CSS class of the gauge's fill (value dial)
     */
    this.valueDialClass = 'value';
    /**
     * 	The CSS class of the gauge's text
     */
    this.valueClass = 'value-text';
    /**
     * Whether to animate changing the gauge
     */
    this.animated = false;
  }
}
class GaugeComponent {
  constructor(elm, defaults) {
    this.elm = elm;
    this.defaults = defaults;
    /**
     * Called when the gauge is created
     */
    this.gaugeCreated = new EventEmitter();
  }
  ngAfterViewInit() {
    const options = {
      dialStartAngle: this.dialStartAngle,
      dialEndAngle: this.dialEndAngle,
      dialRadius: this.dialRadius,
      min: this.min,
      max: this.max,
      label: this.label,
      showValue: this.showValue,
      gaugeClass: this.gaugeClass,
      dialClass: this.dialClass,
      valueDialClass: this.valueDialClass,
      valueClass: this.valueClass,
      value: this.value,
      color: this.color
    };
    Object.keys(this.defaults).forEach(optionKey => {
      const key = optionKey;
      if (options[key] == null) {
        options[key] = this.defaults[key];
      }
    });
    Object.keys(options).forEach(optionKey => {
      const key = optionKey;
      if (options[key] == null) {
        delete options[key];
      }
    });
    this.gauge = Gauge(this.elm.nativeElement, options);
    this.gaugeCreated.emit({
      gauge: this.gauge
    });
    this.updateValue();
  }
  ngOnChanges(changes) {
    if (changes['value']) {
      this.updateValue();
    }
  }
  updateValue() {
    if (this.gauge) {
      if (this.animated) {
        this.gauge.setValueAnimated(this.value, this.animationDuration);
      } else {
        this.gauge.setValue(this.value);
      }
    }
  }
  static {
    this.ɵfac = function GaugeComponent_Factory(t) {
      return new (t || GaugeComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(GaugeDefaults));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: GaugeComponent,
      selectors: [["mwl-gauge"]],
      inputs: {
        dialStartAngle: "dialStartAngle",
        dialEndAngle: "dialEndAngle",
        dialRadius: "dialRadius",
        min: "min",
        max: "max",
        label: "label",
        color: "color",
        showValue: "showValue",
        gaugeClass: "gaugeClass",
        dialClass: "dialClass",
        valueDialClass: "valueDialClass",
        valueClass: "valueClass",
        value: "value",
        animated: "animated",
        animationDuration: "animationDuration"
      },
      outputs: {
        gaugeCreated: "gaugeCreated"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function GaugeComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GaugeComponent, [{
    type: Component,
    args: [{
      selector: 'mwl-gauge',
      template: ''
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: GaugeDefaults
    }];
  }, {
    dialStartAngle: [{
      type: Input
    }],
    dialEndAngle: [{
      type: Input
    }],
    dialRadius: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    showValue: [{
      type: Input
    }],
    gaugeClass: [{
      type: Input
    }],
    dialClass: [{
      type: Input
    }],
    valueDialClass: [{
      type: Input
    }],
    valueClass: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    animated: [{
      type: Input
    }],
    animationDuration: [{
      type: Input
    }],
    gaugeCreated: [{
      type: Output
    }]
  });
})();
const USER_DEFAULTS = new InjectionToken('gauge defaults');
class GaugeModule {
  static forRoot(userDefaults = {}) {
    return {
      ngModule: GaugeModule,
      providers: [{
        provide: USER_DEFAULTS,
        useValue: userDefaults
      }, {
        provide: GaugeDefaults,
        useFactory: options => {
          const defaults = new GaugeDefaults();
          Object.assign(defaults, options);
          return defaults;
        },
        deps: [USER_DEFAULTS]
      }]
    };
  }
  static {
    this.ɵfac = function GaugeModule_Factory(t) {
      return new (t || GaugeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: GaugeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GaugeModule, [{
    type: NgModule,
    args: [{
      declarations: [GaugeComponent],
      imports: [],
      exports: [GaugeComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of angular-gauge
 */

/**
 * Generated bundle index. Do not edit.
 */

export { GaugeComponent, GaugeModule, USER_DEFAULTS };
