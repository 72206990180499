<div class="container-fluid myClaimsHeader">
    <div class="content-width">
                    
        <!-- <div class="drawer">
            <span class="settings"><img alt="settings" src="assets/images/settings.png" width="30px" height="30px" (click)="toggleDrawer()"></span> 
            <div *ngIf="isShownDrawer" class="floating-drawer" [@fadeInOut] >
              <div class="setting-header">Settings</div>
              <div class="setting-body"><p> You can set theme and full view page to hide left Nav section</p></div>
              <div *ngIf="isAuthenticated">
                <div class="setting-body-sub-heading" >Full Page view Setting</div>
                <div class="setting-body">
                    <div class="custom-panel" >
                        <div class="checkbox_wrapper" data-toggle="tooltip" title="Page Expand">
                        <input type="checkbox" [checked]="isFullView" class="pull-right" (change)="toggleView()"  />
                        <label></label>
                        </div>
                    </div>
                </div>
              </div>
              <div class="setting-body-sub-heading">Theme Setting</div>
              <div class="setting-body"> 
                  <div  class="form-group grid-dropdown" id="divshow">
                        <select class="form-control select-css" #themeElement [value]="theme" (change)="changeTheme(themeElement.value)">                    
                            <option value="theme1"> <span class="themes color1"></span> Theme 1</option>
                            <option value="theme2"> <span class="themes color2"></span> Theme 2</option>
                            <option value="theme3"> <span class="themes color3"></span> Theme 3</option>
                        </select>
                    </div>
                </div>
                <div class="setting-body-sub-heading" >Border Setting</div>
                <div class="setting-body"> 
                    <div  class="form-group grid-dropdown" id="divshow">
                          <select class="form-control select-css" #radiusElement [value]="radius" (change)="changeRadius(radiusElement.value)">                    
                              <option value="radius1"> <span class="themes color1"></span> Curved </option>
                              <option value="radius2"> <span class="themes color2"></span> Normal </option>
                              <option value="radius3"> <span class="themes color3"></span> Square </option>
                          </select>
                      </div>
                  </div>
            </div>
        </div>

    </div>     -->
</div>