<div>
    <div class="claim-filter-section">

        <div class="gq-topBanner goal-status-manager">
            <div class="row">
                <div class="col-md-4 col-sm-4">
                    <div class="goal-status" [class]="{'current-status': activeStage === 1}">
                        {{'GoalQuestPage.Manager.GoalQuestSelection.GoalSelection' | translate}}</div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <div class="goal-status" [class]="{'current-status': activeStage === 2}">
                        {{'GoalQuestPage.Manager.GoalQuestSelection.GoalProgress' | translate}}</div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <div class="goal-status" [class]="{'current-status': activeStage === 3}">
                        {{'GoalQuestPage.Manager.GoalQuestSelection.GoalResult' | translate}}</div>
                </div>
            </div>
        </div>

        <h2 class="gqHeadline">{{'GoalQuestPage.Manager.GoalQuestSelection.header' | translate}}</h2>
        <!-- <p class="gqParagraph">{{'GoalQuestPage.Manager.GoalQuestSelection.Description' | translate}}</p> -->

        <div class="gq-goalStatus gq-badge" *ngIf="activeStage === 2 || activeStage === 3">
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="goal"
                        [class]="{'completed': activeStage === 3 && goalQuest.participantsAchievedTargetCount >0}">
                        <div class="iconHolder">
                            <span class="icon-gq-badge"></span>
                        </div>
                        <div class="divider"></div>
                        <p><span class="level">{{goalQuest.managerEarnedPoints}}</span>
                            {{'GoalQuestPage.Manager.GoalQuestProgress.RewardPoints' | translate}}</p>
                        <cs-redeem-now class="btn-redeem" *ngIf="activeStage === 3" buttonTheme="theme2">
                        </cs-redeem-now>
                    </div>
                </div>
            </div>
        </div>

        <app-goal-selection-manager *ngIf="activeStage === 1" [userCount]="goalQuest.countOfUsers"
            [goalSelectedUsers]="goalQuest.goalsSelectedUsersCounts"
            [goalSelectionDetails]="goalQuest.participantGoalSelection"
            (downloadGoalSelectionEvent)="downloadGoalSelection()">
        </app-goal-selection-manager>
        <app-goal-progress-manager *ngIf="activeStage === 2" [isMinimumQualifierEnabled]="isMinimumQualifierEnabled"
            [goalAchievedUsers]="goalQuest.goalsAchievedUsersCounts"
            [goalAchievedDetails]="goalQuest.participantGoalAchievement"
            (downloadGoalProgressEvent)="downloadGoalProgress()">
        </app-goal-progress-manager>
        <app-goal-result-manager *ngIf="activeStage === 3" [goalAchievedUsers]="goalQuest.goalsAchievedUsersCounts"
            [mostRedeemedProducts]="mostRedeemedProducts"></app-goal-result-manager>
        <div class="custom-rule-bottom"></div>
        <!-- Redeem Section -->
        <div class="gq-rewards" *ngIf="activeStage === 3">
            <div class="">
                <div class="reward-images-container">
                    <cs-most-redeem-products [singleView]="false"></cs-most-redeem-products>
                    <button type="button"
                        class="btn btn-primary btn-redeem custombtn">{{'GoalQuestPage.Manager.GoalQuestSelection.BtnRedeemNow'
                        | translate}}</button>
                </div>
            </div>
        </div>

    </div>
</div>