<div class="prelogin-body">
    <div class="content-width">
        <div class="self-registration-page">
            <div class="row">
                <div *ngIf="isSuccess == 1" class="col-md-12 alert alert-success">
                    Successfully logged in
                </div>
                <div *ngIf="isSuccess == -1" class="col-md-12 alert alert-danger">
                    Loggedin failed
                </div>
            </div>
        </div>
    </div>
</div>