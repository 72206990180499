import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '@core/shared/services/shared.service';

@Component({
    selector: 'cs-shared-league-profile-image',
    template: `<img class="profile-pic" *ngIf="imageSrc" [src]="imageSrc" alt="profilepic"/>
    <div class="profile-image profile-name" *ngIf="!imageSrc && shortName"><span>{{shortName}}</span></div>`,
    styles: [`
        .profile-pic{
            width: 30px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 10px;
            height: 30px;
            object-fit: cover;
        }
        .profile-name{
            background-color: #000;
            color: #fff;           
            width: 30px;
            height: 30px;           
            text-align: center;            
            cursor: default;
            font-size: 14px;
        }
        .profile-image {           
            border-radius: var(--app-body-input-border-curved-50P);
            display: inline-block;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;           
            border: 5px solid #fff;
        }
    `]
})
export class LeagueProfileImageComponent implements OnInit {
    @Input() id;
    @Input() fullname;

    shortName;
    imageSrc;
    constructor(private sanitizer: DomSanitizer, private sharedService: SharedService) { }

    ngOnInit() {
        this.getImage();
    }

    getImage() {
        this.sharedService.getUserImage(this.id).subscribe((response) => {
            if (response && response.profileImage)
                this.imageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${response.profileImage}`);
            else
                this.getShortName();
        })
    }

    getShortName() {        
        this.shortName = this.fullname.toUpperCase().split(' ').map(n => n[0]).join('');
    }
}