
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'evaluate-quiz-questionnaire',
  templateUrl: './evaluate-quiz-questionnaire.component.html'
})
export class EvaluateQuizQuestionnaireComponent {

  @Input() promotionId;
  @Input() currentUser;
  @Input() attemptUserId;
  
  enableContent: boolean = false;  
  isAdmin: boolean = true;
  culture:string="en-GB";

  constructor( private activeModal: NgbActiveModal) {
  }
  cancel() {
    this.activeModal.dismiss();
  }
}