<div class="remove-button">
  <span class="close-icon" (click)="cancel()">&#x2715;</span>
</div>
<div>
  <label><strong>{{ notification.title }}</strong></label>
</div>
<div class="notification-date-time">
  <label><fa-icon [icon]="faClock"></fa-icon>  {{ notification.createdDate | localizedDate }}</label>
</div>
<div>  
  <div [innerHTML]="notification.message"></div>
</div>