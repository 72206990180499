import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PreloginService } from '@core/prelogin/services/prelogin.service';
import { AuthenticationService } from '@core/startup';
import { LoginType, RequestType } from '@core/common/constants.service';
import { MessageService } from '@core/shared/services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { SystemSettings } from '@core/models'

@Component({
    selector: 'app-activate-user',
    templateUrl: './activate-user.component.html'
})
export class ActivateUserComponent implements OnInit {
    public formActivationCheck: FormGroup;
    public referenceID: any;
    public isOTPEnabled: boolean = false;
    public isResendOTP: boolean = false
    public loginType: any = LoginType.loginTypes;
    public label: string = 'ParticipantActivation.Username';
    public validationError: string = 'ParticipantActivation.UsernameRequired';
    public passwordLable: string = 'ParticipantActivation.ActivationCode'
    public passwordErrorMessage: string = 'ParticipantActivation.ActivationCodeRequired';
    public expiryTime: string;
    public userName: string;
    isSubmitted: boolean;
    @Output() showCompleteProfile = new EventEmitter();

    constructor(public formBuilder: FormBuilder, public PreloginService: PreloginService, public authenticationService: AuthenticationService
        , private messageService: MessageService, public translate: TranslateService, public systemSettings: SystemSettings) { }

    ngOnInit() {
        this.formActivationCheck = this.formBuilder.group({
            username: [null, [Validators.required]],
            email: [null, [Validators.required]],
            activationCode: [null, [Validators.required]],
            login: ['username'],
            otp: [''],
        });
    }

    validate(formActivationCheck) {
        this.isSubmitted = true;
        if (formActivationCheck.value.username !== "" && formActivationCheck.value.username !== null
            && formActivationCheck.value.activationCode !== "" && formActivationCheck.value.activationCode !== null) {
            let rawActivation = this.formActivationCheck.getRawValue();
            if (this.isOTPEnabled === false) {
                this.PreloginService.validate(rawActivation.username, rawActivation.activationCode, rawActivation.login.toString())
                    .subscribe({
                        next: (result) => {
                            result.activationCode = formActivationCheck.value.activationCode;
                            result.type = formActivationCheck.value.login;
                            this.showCompleteProfile.emit(result);
                        }
                    });
            }
            else if (this.isOTPEnabled === true) {
                this.PreloginService.validateOTP(this.userName, rawActivation.activationCode, this.referenceID).subscribe(result => {
                    result.user.activationCode = result.activationCode;
                    result.user.type = formActivationCheck.value.login;
                    this.showCompleteProfile.emit(result.user);
                });
            }
        }
    }
    useOTPasPassword(event) {
        if (this.formActivationCheck.value.username === null || this.formActivationCheck.value.username === '') {
            event.target.checked = false;
            this.messageService.showWarning(this.translate.instant(this.validationError));
        }
        else if (event.target.checked) {
            this.isOTPEnabled = true;
            this.formActivationCheck.get('activationCode').setValue("");
            this.sendOTP();
            this.passwordLable = 'ParticipantActivation.OTP'
            this.passwordErrorMessage = 'ParticipantActivation.OTPRequired'
        }
        else {
            this.isOTPEnabled = false;
            this.expiryTime = null;
            this.passwordLable = 'ParticipantActivation.ActivationCode';
            this.passwordErrorMessage = 'ParticipantActivation.ActivationCodeRequired';
            this.formActivationCheck.controls['username'].enable();
        }
    }
    sendOTP() {
        let rawActivationDetails = this.formActivationCheck.getRawValue();
        this.authenticationService.getUserAccount(rawActivationDetails.username, rawActivationDetails.login, 'Activation')
            .subscribe(reponse => {
                this.isOTPEnabled = true;
                this.userName = reponse.username;
                this.authenticationService.sendOTP(RequestType.CS_Activation, reponse.id).subscribe(response => {
                    this.referenceID = response.referenceID;
                    this.expiryTime = response.expiryTime;
                    this.isResendOTP = true;
                    this.formActivationCheck.controls['username'].disable();
                    this.messageService.showSuccess(this.translate.instant('ParticipantLogin.OTPSentSuccessfully'));
                });
            });

    }
    changeLable() {
        if(this.formActivationCheck.value.login === LoginType.mobile){
            this.label = 'ParticipantActivation.Mobile';
            this.validationError = 'ParticipantLogin.MobileNoRequired';
        }
        else if(this.formActivationCheck.value.login === LoginType.email){
            this.label = 'ParticipantActivation.Email';
            this.validationError = 'ParticipantLogin.EmailIdRequired';
        }
        else{
            this.label = 'ParticipantActivation.Username';
            this.validationError = 'ParticipantLogin.UsernameRequired';
        }

        this.formActivationCheck.get('otp').setValue(false);
        this.formActivationCheck.controls['username'].enable();
        this.passwordLable = 'ParticipantActivation.ActivationCode';
        this.isResendOTP = false;
        this.isOTPEnabled = false;
    }

    show: boolean = false;
    password() {
        this.show = !this.show;
    }
}