import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'cs-performance-details',
    templateUrl: 'performance-details.component.html',
    styleUrls: ['performance-details.component.scss']
})
export class PerformanceDetailsComponent implements OnChanges {
    @Input() title;
    @Input() achieved: number;
    @Input() startDate;
    @Input() endDate;
    @Input() currentLevel: number;
    @Input() targets: any;
    @Input() minTarget: number;
    @Input() maxTarget: number;
    @Input() isPromotionStarted: boolean;
    @Input() isPromotionEnded: boolean;
    @Input() isMultiple: boolean;
    @Input() units;
    @Input() promotionName: string;
    @Input() isManualReward: boolean;
    @Input() redirectionLink: string;

    remaining: number;
    timeRemaining: number;
    achievedPercentage: number;
    fixedPercentage: number;
    message: string;
    progressTargets: any[] = [];

    constructor(private translateService: TranslateService, private router: Router) { }

    ngOnChanges() {
        this.remaining = this.maxTarget - this.achieved;
        this.achievedPercentage = ((this.achieved / this.maxTarget) * 100) >= 100 ? 100 : (this.achieved / this.maxTarget) * 100;
        this.fixedPercentage = this.achievedPercentage % 1 != 0 ? parseFloat(this.achievedPercentage.toFixed(2)) : this.achievedPercentage;
        this.findProgressLevels();
        this.displayMessage();
    }

    displayMessage() {
        if (this.isPromotionEnded) {
            if (this.achieved >= this.minTarget) {
                this.translateService.get('MyPerformance.PromotionEndedAndTargetAchieved', { achieved: this.fixedPercentage }).subscribe(res => {
                    this.message = res;
                });
            }
            else {
                this.message = this.translateService.instant('MyPerformance.TargetNotAchieved');
            }
        }
        else {
            if (this.achieved >= this.maxTarget) {
                this.translateService.get('MyPerformance.PromotionIsLiveAndTargetAchieved', { achieved: this.fixedPercentage }).subscribe(res => {
                    this.message = res;
                });
            }
            else {
                this.translateService.get('MyPerformance.PromotionIsLiveAndTargetNotAchieved', { achieved: this.fixedPercentage, sales: (this.maxTarget - this.achieved) }).subscribe(res => {
                    this.message = res;
                });
            }
        }
    }

    findProgressLevels() {
        this.progressTargets = [];
        if (this.isMultiple) {
            this.targets.forEach(target => {
                let progressTarget = { 'level': `Level ${target.level}`, 'left': `${(target.target / this.maxTarget) * 100}%`, 'isAchieved': target.target <= this.achieved };
                this.progressTargets.push(progressTarget);
            });
        }
    }

    redirectTo() {
        if (this.redirectionLink)
            this.router.navigateByUrl(this.redirectionLink)        
    }
}