import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@core/shared/shared.module';
import { CommunicationService } from './services/communication.service';
import { CommunicationComponent } from './communication.component';
import { GoalQuestComponent } from './goalquest/goalquest.component';
import { AotComponent } from './aot/aot.component';
import { SellxyComponent } from './sellxy/sellxy.component';
import { HubPageModule } from '../hubpage';

@NgModule({
    declarations: [
        CommunicationComponent,
        GoalQuestComponent,
        AotComponent,
        SellxyComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        CommunicationService
    ],
    imports: [
        CommonModule,
        TranslateModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HubPageModule
    ]
})
export class CommunicationModule { }
