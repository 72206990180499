<div class="gq-goals-selected">
    <div class="grid grid-gap-10 grid-col-md-33 grid-col-hd-33 grid-col-xs-100 grid-col-sm-100 grid-col-lt-100">
        <div class="goal-selected-outer-wrapper" *ngFor="let achieved of goalAchievedUsers">
            <div class="percentage-achieved">
                <h5>{{'GoalQuestPage.Manager.GoalQuestProgress.Goal' | translate}} {{achieved.selectedLevel}}</h5>
                <h5>{{calculateAchievedPercentage(achieved.noOfUsersSelectedGoal, achieved.noOfUsersAchievedGoal)}}%
                </h5>
            </div>
            <div class="goalPercentage">
                <div class="totalPer"></div>
                <div class="goalAchieved"
                    [style.width.%]="calculateAchievedPercentage(achieved.noOfUsersSelectedGoal, achieved.noOfUsersAchievedGoal)">
                </div>
            </div>
            <div class="total-selection">
                <p>{{'GoalQuestPage.Manager.GoalQuestProgress.TotalAchieved' | translate}}</p>
                <p class="count"><span>{{achieved.noOfUsersAchievedGoal}}</span>/{{achieved.noOfUsersSelectedGoal}}
                </p>
            </div>
        </div>
    </div>
</div>