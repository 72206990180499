import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@core/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PointSummaryService } from './services/pointsummary.service';
import { PointSummaryComponent } from './points-summary/points-summary.component';
import { PointsStatementChartViewComponent } from './points-statement-chart-view/points-statement-chart-view.component';
import { PointsStatementTableViewComponent } from './points-statement-table-view/points-statement-table-view.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        SharedModule,
        NgbModule,
        FormsModule,
        NgChartsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [PointSummaryService],
    declarations: [
        PointSummaryComponent,        
        PointsStatementChartViewComponent,
        PointsStatementTableViewComponent
    ]
})
export class PointsummaryModule { }