<div class="modal-header">
    <h4 class="modal-title">{{'UserRegistrationApproval.Upload.Title' | translate}}</h4>
</div>
<form>
    <div class="modal-body">
        <div [innerHTML]="('UserRegistrationApproval.Upload.UploadColumns' | translate)"></div>
        <br />
        <cs-file-uploader-without-mapping *ngIf="contextId" [lastUploadId]="lastUploadId" uploadType="UserRegistrationApproval"
            [contextId]="contextId" [importBehaviors]="importBehaviors" showPicker="true" uploadButtonEnabled="true"
            [userId]="currentUser.Id" [header]="('UserRegistrationApproval.Upload.UploadHeader' | translate)"
            [popoverContent]="('UserRegistrationApproval.Upload.PopoverContent' | translate)"
            (onImportComplete)="importCompleted($event)">
        </cs-file-uploader-without-mapping>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default" type="button"
            (click)="cancel()">{{'UserRegistrationApproval.Upload.Cancel' | translate}}</button>
    </div>
</form>