import { Routes } from "@angular/router";
import { AuthGuard } from "@app/core/shared";
import { ContactUsComponent } from "./contact-us/contact-us.component";

export const ContactUsPageRoutes: Routes = [
  {
    path: "contactus-public",
    component: ContactUsComponent,
    pathMatch: "full",
    data: { title: "Contact Us", isPreLogin: true },
  },
  {
    path: "contactus-private",
    canActivate: [AuthGuard],
    component: ContactUsComponent,
    pathMatch: "full",
    data: { title: "Contact Us", isPreLogin: false },
  },
];
