import { Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { LoadingService } from '@core/shared';
import { HubPageService } from '@core/private/hubpage/services/hub-page.service';

@UntilDestroy()
@Component({
    selector: 'app-hub-performance-page',
    templateUrl: 'my-performance.component.html'
})
export class MyPerformanceComponent implements OnChanges {
    @Input() componentId: string;
    @Input() payload: any;
    @Input() culture: string;

    mytarget: any;         
    minTarget: number;
    maxTarget: number;
    isPromotionStarted: boolean;
    isPromotionEnded: boolean;
    isMultiple: boolean;    

    constructor(private hubPageService: HubPageService, public loadingService: LoadingService) { }

    ngOnChanges() {
        let data = { item: JSON.stringify(this.payload) };
        this.loadingService.doLoading(this.hubPageService.getHubComponent(this.componentId, this.culture, data), this, "performance")
            .pipe(untilDestroyed(this)).subscribe(response => {                
                this.mytarget = response;
                if (this.mytarget.targets.length > 0) {
                    this.isPromotionStarted = this.calculateDateDifference(new Date(), new Date(this.mytarget.startDate)) < 0;
                    this.isPromotionEnded = this.calculateDateDifference(new Date(), new Date(this.mytarget.endDate)) < 0;                
                    this.minTarget = this.mytarget.targets[0].target;
                    this.maxTarget = this.mytarget.targets[this.mytarget.targets.length - 1].target;
                    this.isMultiple = this.mytarget.targets.length > 1;                
                }

            });
    }   

    calculateDateDifference(startDate, endDate) {
        var startDateUtc = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
        var endDateUtc = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());

        return (endDateUtc - startDateUtc) / 1000;
    }
}