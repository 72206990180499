import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@core/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { ApprovalComponent } from './approval.component';
import { UserRegistrationApprovalComponent } from './user-registration-approval/user-registration-approval.component';
import { UserRegistrationApprovalService } from './services/user-registration-approval.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserRegistrationRejectReasonComponent } from './reject-reason/reject-reason.component';
import { BulkApprovalComponent } from './bulk-approval/bulk-approval.component';
import { UserRegistrationViewUserComponent } from './view-user/view-user.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        SharedModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        UserRegistrationApprovalService
    ],
    declarations: [
        ApprovalComponent,
        UserRegistrationApprovalComponent,
        UserRegistrationRejectReasonComponent,
        BulkApprovalComponent,
        UserRegistrationViewUserComponent
    ]
})
export class RegistrationApprovalModule { }