import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@core/shared/shared.module';
import { HubPageModule } from '../hubpage';
import { HomeService } from './services/home.service';
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
    imports: [
        CommonModule,        
        ReactiveFormsModule,
        NgbModule,
        SlickCarouselModule,
        TranslateModule,
        SharedModule,
        HubPageModule      
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [HomeService],
    declarations: [
        DashboardComponent
    ]
})
export class HomeModule { }