import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms'

import { SharedModule } from '@core/shared';
import { MyAwardsService } from './services/my-awards.service';
import { MyAwardsComponent } from './my-awards/my-awards.component';
import { AwardsComponent } from './awards.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        TranslateModule,
        NgbModule,
        FormsModule
    ],
    declarations: [
        AwardsComponent,
        MyAwardsComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    providers: [
        MyAwardsService
    ],
})
export class MyAwardsModule { }
