import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: "[subMenuHover]"
})
export class SubMenuHoverDirective {
    constructor(public el: ElementRef, public renderer: Renderer2) { }

    @HostListener('mouseover') onMouseOver() {
        let hasChild = this.el.nativeElement.querySelector('ul.dropdown-menu');        
        if (hasChild) {
            this.renderer.addClass(this.el.nativeElement, 'submenu-hover');
            this.renderer.addClass(hasChild, 'submenu-open');
        }
    }

    @HostListener('mouseout') onMouseOut() {
        let hasChild = this.el.nativeElement.querySelector('ul.dropdown-menu');        
        if (hasChild) {
            this.renderer.removeClass(this.el.nativeElement, 'submenu-hover');
            this.renderer.removeClass(hasChild, 'submenu-open');
        }
    }
}