import { Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ChartConfiguration, ChartOptions } from 'chart.js';

import { LoadingService } from '@core/shared';
import { HubPageService } from '@core/private/hubpage/services/hub-page.service';

@UntilDestroy()
@Component({
    selector: 'app-hub-monthly-sales',
    templateUrl: 'monthly-sales.component.html'
})

export class MonthlySalesComponent implements OnChanges {
    @Input() componentId: string;
    @Input() payload: any;
    @Input() culture: string;
    @Input() promotion: any;
    attributeJson: any;
    monthlySales: any[];
    brandColor: string;

    chartLabels: any[];
    chartColors: any[];
    public lineChartData: ChartConfiguration<'line'>['data'] = { datasets: [] };
    public lineChartOptions: ChartOptions<'line'> = {
        responsive: true,
        elements: { line: { tension: 0 } },
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'bottom' }
        }
    };

    public barChartData: ChartConfiguration<'bar'>['data'] = { datasets: [] };
    public barChartOptions: ChartOptions<'bar'> = {
        responsive: true,
        elements: { line: { tension: 0 } },
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'bottom' },
        }
    };

    constructor(private hubPageService: HubPageService, public loadingService: LoadingService) {
        let bodyStyles = window.getComputedStyle(document.body);
        this.brandColor = bodyStyles.getPropertyValue('--app-brand-color').trim();
        this.chartColors = [{ borderColor: this.brandColor, backgroundColor: this.brandColor }];
    }

    ngOnChanges() {
        let promotionType = this.promotion.promotionType === 'AchievementOfTargetPromotion' ? 'aot' : 'sellxy';
        this.loadingService.doLoading(this.hubPageService.getMontlySales(this.promotion.id, promotionType), this, "monthlySales")
            .pipe(untilDestroyed(this)).subscribe(response => {
                this.monthlySales = response;
                let attributeJson = JSON.parse(this.promotion.attributesJson);
                let unit = attributeJson.runPromotionMetricsOn;
                this.chartLabels = this.monthlySales.map(m => m.month);
                let data = unit === 'Quantity' ? this.monthlySales.map(m => m.quantity) : this.monthlySales.map(m => m.value);
                if (this.payload.selectedItem.id === 'line')
                    this.buildLineChart(data, unit);
                else
                    this.buildBarChart(data, unit);
            });
    }

    buildLineChart(data, unit) {
        let dataSets: ChartConfiguration<'line'>['data'] = {
            labels: this.chartLabels,
            datasets: [],
        };

        let monthlyDataSet: any = {};
        monthlyDataSet.data = data;
        monthlyDataSet.label = `Monthly Sales(${unit})`;
        monthlyDataSet.backgroundColor = this.brandColor;
        monthlyDataSet.borderColor = this.brandColor;
        monthlyDataSet.fill = false;
        monthlyDataSet.pointBackgroundColor = this.brandColor;
        dataSets.datasets.push(monthlyDataSet);

        this.lineChartData = dataSets;
    }

    buildBarChart(data, unit) {
        let dataSets: ChartConfiguration<'bar'>['data'] = {
            labels: this.chartLabels,
            datasets: [],
        };

        let monthlyDataSet: any = {};
        monthlyDataSet.data = data;
        monthlyDataSet.label = `Monthly Sales(${unit})`;
        monthlyDataSet.backgroundColor = this.brandColor;
        monthlyDataSet.borderColor = this.brandColor;
        monthlyDataSet.hoverBackgroundColor = this.brandColor;
        dataSets.datasets.push(monthlyDataSet);

        this.barChartData = dataSets;
    }
}