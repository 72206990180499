import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import { SharedService } from '@core/shared/services/shared.service'
import { AuthenticationService } from '@core/startup';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PlatformSystemSettings } from '@core/models';
import { Authentication } from '@core/models/authentication.model';
import { AccountsService } from '@core/private/accounts/services/accounts.service';

@Component({
    selector: 'cs-profile-point-card',
    templateUrl: './profile-point-card.component.html',
    styleUrls: ['./profile-point-card.component.scss']
})
export class ProfilePointCardComponent implements OnInit {
    @Input() theme: string = 'theme1';
    @Input() buttonTheme: string = 'theme3';
    pointsBalance: any = {};
    point: number | null = null;
    //profileImage: string = 'assets/images/white_male.jpg';
    profileImage: string;
    isAuthenticated: boolean;
    showBalance: boolean;
    user: Authentication;
    tierName = "";
    isLoading = false;
    menuAccess: any;
    private onDestroy$ = new Subject<void>();
    public onchangeFunction = this.onChange.bind(this);
    systemSettings: PlatformSystemSettings;

    constructor(private authenticationService: AuthenticationService, private router: Router,
        private sharedService: SharedService, private store: Store<fromStore.PlatformState>,
        private accountsService: AccountsService) { }

    ngOnInit() {
        if (localStorage.getItem('platformSettings'))
            this.systemSettings = JSON.parse(localStorage.getItem('platformSettings'));

        this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            if (state.user) {
                this.user = state.user;
                this.tierName = state.user.tier ? state.user.tier + " Member" : "";
            }
        });
        this.store.select(fromStore.globalMenuState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.menuAccess = state.menuAccess;
            if (this.systemSettings.showPointBalanceInPlatform && this.menuAccess && this.menuAccess.points)
                this.getPointsBalance();
        });
        this.showBalance = this.systemSettings.showPointBalanceInPlatform;
        this.GetProfileImage();
    }

    getPointsBalance() {
        this.authenticationService.getPointsBalance().subscribe({
            next: (response) => {
                this.point = response.points;
                this.pointsBalance.showError = false;
            },
            error: () => {
                this.point = 0;
            }
        });
    }

    pointSummary() {
        this.router.navigate([`pointsstatement`]);
    }

    GetProfileImage() {
        this.sharedService.getProfile().subscribe(result => {
            if (result.profileImage !== null) {
                this.profileImage = result.profileImage;
                this.isLoading = false;
            }
        })
    }

    onChange(event) {
        if (event.target.files.length > 0) {
            this.isLoading = true;
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            this.accountsService.updateProfileImage(formData).subscribe(result => {
                this.GetProfileImage();
            });
        }
    }
}