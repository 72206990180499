<nav class="navbar navbar-expand-lg navbar-light bg-light nav-item-sticky">
    <div class="container-fluid menu-header">
        <div class="content-width menu-bg">
            <a class="navbar-brand" href="#">Home</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                (click)="toggleCollapsed()">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div id="navbarSupportedContent" [ngClass]="{'collapse': isCollapsed, 'navbar-collapse': true}">
                <ul class="navbar-nav">
                    
                    <li class="nav-item" menuClick [ngClass]="{'dropdown': item.children.length > 0}"
                        *ngFor="let item of menu">
                       
                        <a *ngIf="item.menuType !== 'expand' && item.menuType !== 'externalLink' && item.menuType !== 'dashboard' && 
                        item.name !== 'messageArticle' && item.menuType !== 'merchandise' && item.menuType !== 'experience' && item.menuType !== 'mastery'"
                            class="nav-link" [routerLink]="item.url | lowercase"
                            [ngClass]="{'dropdown-toggle': item.children.length > 0}">
                            {{item.translations[selectedCulture]? item.translations[selectedCulture]:
                            item.translations[defaultCulture]}}
                        </a>
                        <a *ngIf="item.menuType === 'merchandise' || item.menuType === 'experience' || item.menuType === 'mastery'" class="nav-link" (click)="rewardCatalogue(item.menuType)">
                            {{item.translations[selectedCulture]? item.translations[selectedCulture]:
                                item.translations[defaultCulture]}}
                        </a>
                        <a target="blank" *ngIf="item.menuType === 'externalLink'" class="nav-link" [href]="item.url">
                            {{item.translations[selectedCulture]? item.translations[selectedCulture]:
                            item.translations[defaultCulture]}}
                        </a>
                        <a *ngIf="item.menuType ==='expand' && !item.isForTabView" class="nav-link" [href]=""
                            [ngClass]="{'dropdown-toggle': item.children.length > 0}">
                            {{item.translations[selectedCulture]? item.translations[selectedCulture]:
                            item.translations[defaultCulture]}}
                        </a>
                        <a *ngIf="item.menuType ==='expand' && item.isForTabView" class="nav-link" [routerLink]="item.url | lowercase">
                            {{item.translations[selectedCulture]? item.translations[selectedCulture]:
                            item.translations[defaultCulture]}}
                        </a>
                        <sub-menu-component *ngIf="!item.isForTabView && item.children.length > 0" [subMenu]="item.children"
                            [selectedCulture]="selectedCulture" [defaultCulture]="defaultCulture">
                        </sub-menu-component>
                    </li>
                    <li *ngIf="isEnabledReport" class="nav-item" (click)="reportImpersonate()">
                        <a class="nav-link">
                            Report
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>