<div class="modal-header">
    <a class="icon-reject">
        <fa-icon [icon]="faRemoveIcon" (click)="cancel()"></fa-icon>
    </a>
</div>
<div class="modal-body userRegistrationApproval">
    <div class="both">
        <div id="selfRegistrationRoot">
            <div class="both"></div>
        </div>
    </div>
</div>