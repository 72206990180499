<div
  *ngIf="routeData"
  [class]="{ main: !routeData.isPreLogin, prelogin: routeData.isPreLogin }"
>
  <div class="globalHeader" [ngClass]="{'webInMobileView': isWebInMobileView}">
    <cs-logo-bar-component
      [isAuthenticated]="isAuthenticated"
      [isPreLogin]="routeData.isPreLogin"
    >
    </cs-logo-bar-component>
  </div>
  <menu-component
    *ngIf="isAuthenticated && !routeData.isPreLogin && !isWebInMobileView"
    [userId]="userId"
  ></menu-component>
  <!--  <cs-page-header *ngIf="isAuthenticated && !routeData.isPreLogin && !routeData.isDashboard" [title]="title" [isFullView]="isFullView">
  </cs-page-header> -->
  <cs-custom-theme [theme]="theme" [radius]="radius" *ngIf="!isWebInMobileView"></cs-custom-theme>
  <div [class]="{ themeContent: isFullView }">
    <router-outlet></router-outlet>
  </div>
  <div class="globalFooter" [ngClass]="{'webInMobileView': isWebInMobileView}">
    <cs-prelogin-footer-component [isAuthenticated]="isAuthenticated"></cs-prelogin-footer-component>
    <div class="cookie-position-absolute">
      <app-cookie-component></app-cookie-component>
    </div>
      </div>
</div>
