import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@core/shared/shared.module';

import { ChangePasswordComponent } from './change-password/change-password.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EditProfileSelfRegistrationComponent } from './edit-profile-selfregistration/edit-profile-selfregistration.component';
import { SecurityQuestionsComponent } from './security-questions/security-questions.component';
import { NotificationSubscriptionComponent } from './notification-subscription/notification-subscription.component';
import { AccountsService } from './services/accounts.service';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        RouterModule,
        TranslateModule,
        SharedModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AccountsService,
        { provide: Window, useValue: window }
    ],    
    declarations: [        
        ChangePasswordComponent,
        EditProfileComponent,      
        EditProfileSelfRegistrationComponent,
        SecurityQuestionsComponent,
        NotificationSubscriptionComponent
    ]
})
export class AccountsModule { }