<div class="point-messages">
    <div class="summaryPointSeactionEarned">
        <div class="summaryReportEarned">
            <div class="textEarned">
                <p class="pointHeaderText">
                    <body-text [textContent]="content"></body-text>
                </p>
                <p class="points">{{value | localizednumber}}</p>
            </div>
            <div class="imageEarned">
                <img [src]="icon" width="76" height="76" alt="earned">
            </div>
        </div>
    </div>
</div>