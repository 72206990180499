import { Component, Input } from '@angular/core';
import { PlatformSystemSettings, SystemSettings } from '@app/core/models';

@Component({
  selector: 'app-point-summary',
  templateUrl: './point-summary.component.html'
})
export class PointSummaryComponent {
  @Input() summaryDetails: any;
  @Input() summary: any;
  isFIFOEnabled: boolean;

  constructor(public systemSettings: SystemSettings) {
    if (localStorage.getItem('platformSettings')) {
      let settings: PlatformSystemSettings = JSON.parse(localStorage.getItem('platformSettings'));
      this.isFIFOEnabled = settings.isFIFOEnabled;
    }
  }
}