import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "cs-category-card",
    templateUrl: "./category-card.component.html",
    styleUrls: ['./category-card.component.scss']
})
export class CategoryCardComponent implements OnInit {
    @Input() theme: string;
    @Input() ratio: string;
    @Input() bgUrl: string;
    @Input() fgUrl: string;
    @Input() text1: string;
    @Input() text2: string;
    @Input() text3: string;
    @Input() btnText: string;
    @Input() isRedeem: boolean;
    @Input() showButton: boolean = true;

    public template: string;

    ngOnInit() {
        this.template = this.theme ? this.theme : 'theme1';
    }
}