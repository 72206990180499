import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoadingService } from '@core/shared';

import { AuthenticationService } from '@core/startup'
import { SystemSettings } from '@core/models';

import { SearchCriteria } from '@core/shared/models/search-criteria';
import { Pagination } from '@core/shared/models/pagination';
import { Sorter } from '@core/shared/models/sorter'

import { ParticipantsQuizAttemptsFilter } from '../view-models/quiz-evaluation.filter';
import { QuizQuestionnaireService } from '../services/quiz-questionnaire.service';
import { EvaluateQuizQuestionnaireComponent } from '../quiz-modal/evaluate/evaluate-quiz-questionnaire.component';


@UntilDestroy()
@Component({
  selector: 'participants-quiz-attempts',
  templateUrl: './participants-quiz-attempts.component.html'
})
export class ParticipantsQuizAttemptsComponent {  
  searchCriteria: SearchCriteria = new SearchCriteria('userName');
  paginationDetails: Pagination = new Pagination();
  sorter: Sorter = new Sorter('userName');
  couponHistoryDetails: any;
  filter: ParticipantsQuizAttemptsFilter;
  quizAttemptDetails : any;

  quizPromotion: any;
  promotionId: string;
  currentUser: any;
  enableContent: boolean = false; 

  constructor(private activeRoute: ActivatedRoute, public authService: AuthenticationService
    , private quizQuestionnaireService: QuizQuestionnaireService,public loadingService: LoadingService, private modalService: NgbModal) {

    this.promotionId = this.activeRoute.snapshot.params.id;   

    this.authService.getCurrentUser().subscribe(response => {
      this.currentUser = response;
    });
  }

  ngOnInit(): void {
    this.filter = new ParticipantsQuizAttemptsFilter();
    this.getQuizAttemptDetails();
  }

  getQuizAttemptDetails() {    
    this.quizQuestionnaireService.getQuizPromotion(this.promotionId).subscribe(response => {
      this.quizPromotion = JSON.parse(response.attributesJson);
      this.searchCriteria.itemsPerPage = this.paginationDetails.itemsPerPage;
      this.searchCriteria.pageNumber = this.paginationDetails.pageNumber;
      this.searchCriteria.sortField = this.sorter.sortColumn;
      this.searchCriteria.sortAscending = this.sorter.sortDirection;
      this.filter.type = 'promotion';
      this.filter.promotionId = this.promotionId;
      this.filter.quizId = this.quizPromotion.quizId;
      this.searchCriteria.additionalData = this.filter;

      this.quizQuestionnaireService.getQuizAttemptDetails(this.searchCriteria).subscribe(res => {        
        this.paginationDetails.totalNumberOfItems = res.totalNumberOfItems;
        this.quizAttemptDetails = res.items;
      });
    });
  }

  onSearch() {
    this.getQuizAttemptDetails();
  }
  onClear() {
    this.filter.firstName = '';
    this.filter.userName = '';
    this.filter.lastName = '';    
    this.getQuizAttemptDetails();
  }

  onChange(pagination: Pagination) {    
    this.paginationDetails = pagination;
    this.getQuizAttemptDetails();
  }

  onSort(sortField) {
    this.sorter.sort(sortField);
    this.getQuizAttemptDetails();
  }

  evaluate(quizAttempt){    
    const modalRef = this.modalService.open(EvaluateQuizQuestionnaireComponent, { backdrop: 'static' });
    modalRef.componentInstance.promotionId = this.promotionId;
    modalRef.componentInstance.currentUser = this.currentUser;
    modalRef.componentInstance.attemptUserId = quizAttempt.userId;
    modalRef.result.then((result) => {
    });
  }
}