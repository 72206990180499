<div class="modal-header">
    <h4 class="modal-title">{{'SalesSearch.RejectModal.Title' | translate}}</h4>
</div>
<form [formGroup]="formRejectClaim" (ngSubmit)="onSubmit()"> 
    <div class="modal-body">
        <div class="form-group">
            <label class="col-sm-4 control-label">{{'SalesSearch.RejectModal.ClaimedBy' | translate}}</label>
            <div class="col-sm-8 col-md-6">
                <div class="static-field">
                    {{rejectDto.claimedByUsername}}
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="rejectionReason" class="col-sm-4 control-label">{{'SalesSearch.RejectModal.RejectionReason' | translate}}</label>
            <div class="col-sm-8 col-md-6">
                <textarea class="form-control" rows="5" id="rejectionReason" name="rejectionReason" formControlName="rejectionReason"></textarea>
            </div>
        </div>
    </div>
  
    <div class="modal-footer">
        <button type="submit" class="btn btn-light" [disabled]="!formRejectClaim.controls.rejectionReason.valid">{{ 'SalesSearch.RejectModal.Save' | translate }}</button>
        <button class="btn btn-default" type="button" (click)="cancel()">{{ 'Shared.Confirmation.Cancel' | translate }}</button>                
    </div>
</form>