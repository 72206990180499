import { Component, Input } from '@angular/core';

@Component({
    selector: 'cs-file-uploader-validation-result',
    templateUrl: 'file-uploader-validation-result.component.html',
    styles: [`.hasError {
        font-weight: bold;
    }
    .validation-msg {
        background: #d3d3d4;
        max-width: 400px;
    }`]
})

export class FileUploaderValidationResultComponent {
    @Input() validationResultSummary: any;

    constructor() { }
}