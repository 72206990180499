import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router, RouterEvent, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromStore from '@core/store';
import { StyleManagerService } from '@core/shared/services/style-manager.service';
import * as fromThemeAction from '@core/store/actions/theme.action';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;
  userId: string;
  title;
  routeData: any;
  // isFullView: boolean;
  theme: string;
  radius: string;
  @Input() isFullView: boolean;
  private onDestroy$ = new Subject<void>();
  navigationStartSubscription: Subscription;
  public destroyed = new Subject<any>();
  public isWebInMobileView: boolean = false;

  constructor(private router: Router, private store: Store<fromStore.PlatformState>, private translateService: TranslateService,
    private titleService: Title, private themeService: StyleManagerService, private modalService: NgbModal) {

    this.store.select(fromStore.themeState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
      this.isFullView = state.isExpand;
      this.theme = state.theme;
      this.radius = state.radius;
      this.changeTheme();
      this.changeRadius();
    });

    this.isWebInMobileView = localStorage.getItem('token') && JSON.parse(localStorage.getItem('token')).hideHeaderFooter ? true : false;
    this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
      this.isAuthenticated = state.status;
      if (state.user)
        this.userId = state.user.Id;
    });

    this.navigationStartSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.routeData = data.state.root.firstChild.data;
        if (this.routeData && this.routeData.title) {
          this.translateService.get(this.routeData.title).subscribe((res: string) => {
            this.title = res;
            this.titleService.setTitle(res);
          });
        }
      }
    });
  }

  changeTheme() {
    this.themeService.setStyle("theme", `assets/themes/${this.theme}.css`);
  }

  changeRadius() {
    this.themeService.setStyle("radius", `assets/radius/${this.radius}.css`);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }

  toggleView() {
    this.store.dispatch({ type: fromThemeAction.TOGGLE_PANEL, payload: !this.isFullView });
  }

}
