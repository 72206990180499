<div class="home-page">
    <div class="bg-color3 home-page-bg">
        <div class="content-width home-point-gallery-section">
            <div class="flex-1 home-page-slide rightPane bg-color2">
                <div class="catalogueaccess-container">
                    <h2>Rewards Login with OTP</h2>
                    <div class="otp-buttons">
                        <button class="btn send-otp" (click)="sendOtp()" [disabled]="sendingOtp">Send OTP</button>
                        <!-- <button class="btn resend-otp" (click)="sendOtp()">Resend OTP</button> -->
                    </div>
                    <div class="otp-input">
                        <label for="otp">Enter OTP</label>
                        <input type="text" id="otp" name="otp" [(ngModel)]="otp" />
                        <button class="btn submit" (click)="rewardCatalogue()" [disabled]="!otp">Submit</button>
                    </div>
                </div>
            </div>            
            <div class="home-left leftPane">
                <cs-profile-point-card theme="theme-white" buttonTheme="theme2"></cs-profile-point-card>
                <cs-most-redeem-products [singleView]="true"></cs-most-redeem-products>
            </div>
        </div>
    </div>
</div>