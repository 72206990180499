import { Authentication } from "@core/models/authentication.model";
import * as fromAuthentication from '../actions/authentication.action';

export interface AuthenticationState {
    user: Authentication,
    status: boolean,
    isImpersonate: boolean
}

export const initialState: AuthenticationState = {
    user: null,
    status: localStorage.getItem('token') ? true : false,
    isImpersonate: localStorage.getItem('token') && JSON.parse(localStorage.getItem('token'))['impersonatorId'] ? true : false
}

export function reducer(state = initialState, action: fromAuthentication.AuthenticationAction): AuthenticationState {   
    switch (action.type) {
        case fromAuthentication.CHANGE_AUTHENTICATION_STATUS: {
            return { ...state, status: action.payload };
        }
        case fromAuthentication.UPDATE_USER: {
            return { ...state, user: action.payload };
        }
        case fromAuthentication.UPDATE_NAME: {
            return { ...state, user: { ...state.user, Firstname: action.payload.firstname, Lastname: action.payload.lastname } };
        }
        case fromAuthentication.CHANGE_IMPERSONATE_STATUS: {
            return { ...state, isImpersonate: action.payload };
        }
        default:
            return state;
    }
}

export const getAuthentication = (state: AuthenticationState) => state;