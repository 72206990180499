import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@core/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { EmbeddedReportService } from './services/embedded-report.service';
import { ReportsComponent } from './reports.component';
import { SupersetReportComponent } from './embedded-reports/superset-report/superset-report.component';
import { PowerBIReportComponent } from './embedded-reports/power-bi-report/power-bi-report.component';
import { EmbeddedReportsComponent } from './embedded-reports/embedded-reports.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        SharedModule,
        NgbModule,
        FormsModule,
        RouterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [EmbeddedReportService],
    declarations: [
        ReportsComponent,
        EmbeddedReportsComponent,
        PowerBIReportComponent,
        SupersetReportComponent  
    ]
})
export class EmbeddedReportModule { }