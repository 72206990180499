<div>
  <div class="claim-filter-section">
    <div ngbAccordion>
      <div
        ngbAccordionItem="claimfilter"
        #claimfilter="ngbAccordionItem"
        [collapsed]="false"
        class="padding-15"
      >
        <div ngbAccordionHeader>
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="accordion-header">
              {{ "MyAwardsPage.Title" | translate }}
            </h5>
            <button ngbAccordionToggle class="btn btn-link accordion">
              {{ !claimfilter.collapsed ? "-" : "+" }}
            </button>
          </div>
        </div>
        <div ngbAccordionCollapse>
          <div  class="padding-left-0" ngbAccordionBody>
            <ng-template>
              <div
                class="grid grid-gap-10 grid-col-sm-100 grid-col-xs-100 grid-col-hd-50"
              >
                <div class="margin-bottom-10">
                  <label>{{ "MyAwardsPage.DateOfSales" | translate }}</label>
                  <div class="form-group item">
                    <div class="input-group">
                      <label for="fromdate" class="datePlacement lbl-date">{{
                        "MyAwardsPage.DateFrom" | translate
                      }}</label>
                      <input
                        class="form-control datePickerCalander"
                        placeholder="mm-dd-yyyy"
                        name="salesStartDate"
                        [(ngModel)]="filter.salesStartDate"
                        [maxDate]="maxFromDate"
                        (ngModelChange)="restrictSalesFromToDate('from')"
                        ngbDatepicker
                        #salesStartDate="ngbDatepicker"
                        readonly
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          type="button"
                          (click)="salesStartDate.toggle()"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="margin-bottom-10 no-label">
                  <div class="form-group item">
                    <div class="input-group">
                      <label for="todate" class="datePlacement lbl-date">{{
                        "MyAwardsPage.DateTo" | translate
                      }}</label>
                      <input
                        class="form-control datePickerCalander"
                        placeholder="mm-dd-yyyy"
                        name="salesEndDate"
                        [(ngModel)]="filter.salesEndDate"
                        ngbDatepicker
                        [minDate]="minToDate"
                        (ngModelChange)="restrictSalesFromToDate('to')"
                        #salesEndDate="ngbDatepicker"
                        readonly
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          type="button"
                          (click)="salesEndDate.toggle()"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="grid grid-gap-10 grid-col-sm-100 grid-col-xs-100 grid-col-hd-50"
              >
                <div class="margin-bottom-10">
                  <label>{{ "MyAwardsPage.DateofClaim" | translate }}</label>
                  <div class="form-group item">
                    <div class="input-group">
                      <label for="fromdate" class="datePlacement lbl-date">{{
                        "MyAwardsPage.DateFrom" | translate
                      }}</label>
                      <input
                        class="form-control datePickerCalander"
                        placeholder="mm-dd-yyyy"
                        name="claimsStartDate"
                        [(ngModel)]="filter.claimsStartDate"
                        [maxDate]="maxClaimFromDate"
                        (ngModelChange)="restrictClaimFromToDate('from')"
                        ngbDatepicker
                        #claimsStartDate="ngbDatepicker"
                        readonly
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          type="button"
                          (click)="claimsStartDate.toggle()"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="margin-bottom-10 no-label">
                  <div class="form-group item">
                    <div class="input-group">
                      <label for="todate" class="datePlacement lbl-date">{{
                        "MyAwardsPage.DateTo" | translate
                      }}</label>
                      <input
                        class="form-control datePickerCalander"
                        placeholder="mm-dd-yyyy"
                        name="claimsEndDate"
                        [(ngModel)]="filter.claimsEndDate"
                        [minDate]="minClaimToDate"
                        (ngModelChange)="restrictClaimFromToDate('to')"
                        ngbDatepicker
                        #claimsEndDate="ngbDatepicker"
                        readonly
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          type="button"
                          (click)="claimsEndDate.toggle()"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="grid grid-gap-10 grid-col-sm-100 grid-col-xs-100 grid-col-hd-50"
              >
                <div class="margin-bottom-10">
                  <p class="myawards-para">
                    {{ "MyAwardsPage.FilterResults" | translate }}
                  </p>
                  <div class="form-group item">
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="Search"
                        name="search"
                        [(ngModel)]="filter.searchTerm"
                      />
                    </div>
                  </div>
                </div>
                <div class="margin-bottom-10"></div>
              </div>

              <div class="row">
                <div class="col-md-12 text-center btn-align">
                  <button
                    class="btn btn-primary customBtnSearch"
                    (click)="onSearch()"
                  >
                    {{ "MyAwardsPage.BtnSearch" | translate }}
                  </button>
                  <button
                    class="btn btn-primary customBtnReset"
                    (click)="onClear()"
                  >
                    {{ "MyAwardsPage.BtnReset" | translate }}
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-width">
    <h1 class="sub-pageHeader">{{ "MyAwardsPage.TableHeader" | translate }}</h1>
  </div>

  <div class="claim-filter-section">
    <div class="row" *ngIf="myAwardsDetails && myAwardsDetails.length > 0">
      <div class="col-6 col-md-6">
        <div *ngIf="paginationDetails.totalNumberOfItems > 0">
          <p>
            {{ "TableRecords.FoundTotalOf" | translate
            }}{{ paginationDetails.totalNumberOfItems
            }}{{ "TableRecords.matches" | translate }}
          </p>
        </div>
      </div>
      <div class="col-6 col-md-6 text-right">
        <div class="page-align">
          <cs-items-per-page
            class="col-sm-4"
            [paginationDetails]="paginationDetails"
            (onChangeEvent)="onChange($event)"
          ></cs-items-per-page>
        </div>
      </div>
    </div>

    <div
      class="table-width"
      #dataLoading="appIsLoading"
      [appIsLoading]="loadingService.isLoading(this)"
    >
      <table
        class="table table-striped"
        aria-describedby="myTable"
        *ngIf="paginationDetails.totalNumberOfItems !== 0"
      >
        <thead>
          <tr>
            <th
              *ngFor="let attributeHeader of attributeHeaders"
              role="button"
              (click)="onSort(attributeHeader)"
              scope="col"
            >
              <span>{{ attributeHeader }}</span>
              <cs-sort-icons
                *ngIf="sorter.showSortIcons(attributeHeader)"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              scope="col"
              (click)="onSort('ClaimDate')"
            >
              {{ "MyAwardsPage.MyAwardsTable.ClaimDate" | translate }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('ClaimDate')"
                [sortAscending]="sorter.sortDirection"
              ></cs-sort-icons>
            </th>
            <th
              class="cm_12 cm_bold"
              role="button"
              scope="col"
              (click)="onSort('Award')"
            >
              {{ "MyAwardsPage.MyAwardsTable.Award" | translate }}
              <cs-sort-icons
                *ngIf="sorter.showSortIcons('Award')"
                [sortAscending]="sorter.sortDirection"
              >
              </cs-sort-icons>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let award of myAwardsDetails">
            <td *ngFor="let attribute of award.attributes">
              <span *ngIf="attribute.dataType.name === 'Integer'">{{
                attribute.valueAsString
              }}</span>
              <span *ngIf="attribute.dataType.name === 'Date'">{{
                attribute.valueAsDate | localizedDate
              }}</span>
              <span *ngIf="attribute.dataType.name === 'Decimal'">{{
                attribute.valueAsString
              }}</span>
              <span
                *ngIf="
                  attribute.dataType.name === 'Text' ||
                  attribute.dataType.name == 'email'
                "
                >{{ attribute.valueAsString }}</span
              >
            </td>
            <td>{{ award.dateOfClaim | localizedDate }}</td>
            <td>{{ award.points }}</td>
          </tr>
        </tbody>
      </table>
      <div
        class="awardsNoData alert alert-danger"
        *ngIf="
          !dataLoading.appIsLoading &&
          paginationDetails.totalNumberOfItems === 0
        "
      >
        {{ "MyAwardsPage.RecordNotFound" | translate }}
      </div>
    </div>
    <div class="row" *ngIf="myAwardsDetails && myAwardsDetails.length > 0">
      <div class="col-lg-12 pagination-center-Bottom">
        <cs-custom-pagination
          [paginationDetails]="paginationDetails"
          (onChangeEvent)="onChange($event)"
        >
        </cs-custom-pagination>
      </div>
    </div>
  </div>
</div>
