<div class="performanceDetails text-center">
    <body-text class="super-heading4" *ngIf="promotionName"> {{promotionName}} </body-text> <!-- Promotion name-->
    <body-text class="block medium-size margin-top-10 text-center">
        <span [innerHtml]="message | sanitizeHtml">
        </span>
    </body-text>

    <cs-goal-progress-bar-chart class="block margin-top-20" [progressPercentage]="fixedPercentage" trackColor="#f0f0f0"
        progressedTrackColor="linear-gradient(to right, #541aa2 , #fc501d)"
        progressIcon="/assets/images/target_goal_rank_car_icon.png" goalIcon="/assets/images/profile_target.png"
        [loadOnInit]="true" [targets]="progressTargets" [remainingPoints]="remaining" [units]="units"></cs-goal-progress-bar-chart>

    <div class='target-text-dashboard block-align-center margin-top-40' *ngIf="!isMultiple">
        <div>
            <body-text class="block medium-size color1">{{'MyPerformance.Target' | translate}} ({{units}}) </body-text>
            <body-text class="block super-heading4 color4">{{maxTarget | number:'1.0':'en-US'}}</body-text>
        </div>
        <div>
            <body-text class="block medium-size color1">{{'MyPerformance.Achievd' | translate}} ({{units}})</body-text>
            <body-text class="block super-heading4 color1">
                <cs-animated-digit [digit]="achieved" [duration]="1000"></cs-animated-digit>
            </body-text>
        </div>
        <div *ngIf="remaining>0">
            <body-text class="block medium-size color1">{{'MyPerformance.Remaining' | translate}} </body-text>
            <body-text class="block super-heading4 color1">{{remaining | number:'1.0':'en-US'}}</body-text>
        </div>
        <div>
            <body-text class="block medium-size color1">{{'MyPerformance.TimeRemaining' | translate}} </body-text>
            <body-text class="block medium-size">
                <cs-timer [endDate]="endDate"></cs-timer>
            </body-text>
        </div>
        <div class="points-with-badge flex flex-align-center">
            <div>
                <img class="badge" src="/assets/images/rewards_badge.png" alt="badge">
            </div>
            <div class="padding-left-10">
                <body-text class="block medium-size">{{'MyPerformance.RewardsPoints' | translate}} </body-text>
                <body-text class="block super-heading4 color1">{{targets[targets.length -1].reward |
                    number:'1.0':'en-US' }}</body-text>
            </div>
        </div>
    </div>

    <div class="filter-section-chart" *ngIf="isMultiple">
        <div class="timeLeft">
            <span class="icon-gq-calendar"></span>
            <div class="goalWrapper">
                <span class="daysLeft"><cs-timer [endDate]="endDate"></cs-timer>
                </span><p class="duration">  <span class="divider-date-time"> | </span> {{startDate| localizedDate}} - {{endDate | localizedDate}}</p>
            </div>
        </div>

        <div class="gq-goal-container">
            <div class="">
                <div
                    class="grid grid-gap-10 grid-col-md-33 grid-col-hd-33 grid-col-xs-100 grid-col-sm-100 grid-col-lt-50 .grid-col-md-100">
                    <div class="contest-goal ng-scope" *ngFor="let target of targets; let i = index">
                        <div class="goal-star">
                          <div>
                            <img alt="No Img" src="/assets/images/1star.png">
                          </div>
                        </div>
                        <div class="goal-display">
                          <div>
                            <img alt="goal" class="target-icon" src="/assets/images/rewards_badge.png">
                          </div>
                          <div class="pull-right">
                            <body-text class="block text-uppercase color4">goal target</body-text>
                            <body-text class="block super-heading6 bold color4 ng-binding" ng-bind="eachGoal.target | number">{{target.target}}</body-text>
                          </div>
                        </div>
                        <div class="rewards-display" *ngIf="!isManualReward">
                          <div>
                            <img alt="reward" src="/assets/images/star_1.png">
                          </div>
                          <div class="pull-right">
                            <body-text class="block text-uppercase medium-size-plus-2">reward points</body-text>
                            <body-text class="block super-heading4 bold ng-binding" ng-bind="eachGoal.rewards | number">{{target.reward}}</body-text>
                          </div>
                        </div>
                        <div class="action-btn">
                          <!-- <button class="select-btn active" ng-class="{'active' : eachGoal.active}">
                            <i class="checkmark ion-checkmark"></i>
                            <span class="text-uppercase ng-binding" ng-bind="eachGoal.btnText">Selected</span>
                          </button> -->
                        </div>
                      </div>
                </div>

            </div>
        </div>

    </div>

    <div class="btn-group margin-top-10 homepage-tab-body-view">
      <button type="button" class="btn btn-primary btn-WhiteBg-BrandColor" (click)="redirectTo()">
        <strong>{{'Shared.Buttons.KnowMore' | translate}}</strong>
        <span class="glyphicon glyphicon-menu-right pl-1"></span>
      </button>
    </div>

</div>