import { ActionReducerMap, createSelector } from '@ngrx/store';
import * as fromTheme from './theme.reducer';
import * as fromAuthentication from './authentication.reducer';
import * as fromGlobalMenu from './globalmenu.reducer';

export interface PlatformState {
    platformTheme: fromTheme.ThemeState
    authentication: fromAuthentication.AuthenticationState,
    globalMenu: fromGlobalMenu.GlobalMenuState
}

export const reducers: ActionReducerMap<PlatformState> = {
    platformTheme: fromTheme.reducer,
    authentication: fromAuthentication.reducer,
    globalMenu: fromGlobalMenu.reducer
}

export const themeState = (state: PlatformState) => state.platformTheme;
export const getView = createSelector(themeState, fromTheme.getView);
export const getTheme = createSelector(themeState, fromTheme.getTheme);
export const getRadius = createSelector(themeState, fromTheme.getRadius);

export const authenticationState = (state: PlatformState) => state.authentication;
export const getAuthenticationUser = createSelector(authenticationState, fromAuthentication.getAuthentication);

export const globalMenuState = (state: PlatformState) => state.globalMenu;
export const getGlobalMenuAccess = createSelector(globalMenuState, fromGlobalMenu.getGlobalMenuState);