import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';
import { ContestComponent } from './contest.component';
import { LeagueComponent } from './league/league.component';

export const LeagueRoutes: Routes = [

    {
        path: 'contest',
        component: ContestComponent,
        children: [
            {
                path: 'league/view/:id',
                canActivate: [AuthGuard],
                component: LeagueComponent,
                pathMatch: 'full',
                data: { title: 'League', isPreLogin: false }
            }
        ]
    }
];