import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'cs-resolve-challenge-modal',
    templateUrl: 'resolve-challenge.component.html'
})

export class ResolveChallengeModalComponent implements OnInit {
    @Input() resolveDto;
    formResolveChallenge: FormGroup;

    constructor(private activeModal: NgbActiveModal, private fb: FormBuilder) { }

    ngOnInit() {
        this.formResolveChallenge = this.fb.group({
            claimedByUsername: [this.resolveDto.claimedByUsername],
            challengedByUsername: [this.resolveDto.challengedByUsername],
            challengeReason: [this.resolveDto.challengeReason],
            winnerId: ['', [Validators.required]],
            resolveReason: [null, [Validators.required]]
        });
    }

    onSubmit() {
        this.activeModal.close(this.formResolveChallenge.getRawValue());
    }

    cancel() {
        this.activeModal.dismiss();
    }
}