import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@core/shared/shared.module';
import { TabViewComponent } from './tabview.component';

@NgModule({
    declarations: [
        TabViewComponent
    ],
    providers: [],
    imports: [
        CommonModule,
        TranslateModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ]
})
export class TabViewModule { }
