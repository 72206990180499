import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgChartsModule } from 'ng2-charts';

import { SharedModule } from '@core/shared';
import { HubPageService } from './services/hub-page.service';
import { HubPageComponent } from './hub-page/hub-page.component';
import {
    CmsComponent, MessagePageComponent, MyAwardComponent, LeagueComponent, ParticipantGoalQuestComponent, PointsSummaryComponent,
    ManagerGoalQuestComponent, ClaimComponent, MyPerformanceComponent, MyRankComponent, GameArcadeComponent, MonthlySalesComponent,
    MessageQuicktipComponent
} from './components';
import { MessagePageBannerComponent } from './components/message-page-banner/message-page-banner.component';
import { MyThresholdAwardsComponent } from './components/my-threshold-awards/my-threshold-awards.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicMessageArticlePageComponent } from './components/dynamic-message-article/dynamic-message-article-page.component';
import { StaticMessageArticlePageComponent } from './components/static-message-article/static-message-article-page.component';
import { BenefitsPageBannerComponent } from './components/benefits-page/benefits-page-banner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HubComponent } from './hub.component';
import { ClaimHomeComponent } from './components/claim-home-page/claim-home.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModalModule,
        SlickCarouselModule,
        TranslateModule,
        SharedModule,
        ReactiveFormsModule,
        NgbModule,
        FontAwesomeModule,
        NgChartsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        HubComponent,
        HubPageComponent,
        CmsComponent,
        MessagePageComponent,
        MyAwardComponent,
        LeagueComponent,
        ParticipantGoalQuestComponent,
        PointsSummaryComponent,
        ManagerGoalQuestComponent,
        ClaimComponent,
        MyPerformanceComponent,
        MyRankComponent,
        MessagePageBannerComponent,
        MyThresholdAwardsComponent,
        GameArcadeComponent,
        DynamicMessageArticlePageComponent,
        StaticMessageArticlePageComponent,
        BenefitsPageBannerComponent,
        MonthlySalesComponent,
        ClaimHomeComponent,
        MessageQuicktipComponent
    ],
    providers: [
        HubPageService
    ],
    exports: [
        CmsComponent,
        MessagePageComponent,
        MyAwardComponent,
        LeagueComponent,
        ParticipantGoalQuestComponent,
        PointsSummaryComponent,
        ManagerGoalQuestComponent,
        ClaimComponent,
        MyPerformanceComponent,
        MyRankComponent,
        MessagePageBannerComponent,
        GameArcadeComponent,
        DynamicMessageArticlePageComponent,
        StaticMessageArticlePageComponent,
        BenefitsPageBannerComponent,
        MonthlySalesComponent,
        ClaimHomeComponent,
        MessageQuicktipComponent
    ]
})
export class HubPageModule { }
