import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { UrlBuilder, GET_MY_TARGET } from '@core/core-api-variables';
import { HttpService } from '@core/shared';

@Injectable()
export class MyPerformanceService {
    constructor(public http: HttpService) { }

    getMyTarget(promotionId) {
        let data = { promotionId }
        let url = UrlBuilder(GET_MY_TARGET, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }
}