import { Component, Input, OnChanges } from '@angular/core';
import { SystemSettings } from '@core/models';
import * as fromStore from '@core/store';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'cs-prelogin-footer-component',
    templateUrl: './prelogin-footer.component.html',
    styleUrls: ['./prelogin-footer.component.scss']
})
export class PreloginFooterComponent implements OnChanges {
    @Input() isAuthenticated: boolean;
    platformSystemSettings: SystemSettings = null;
    private onDestroy$ = new Subject<void>();
    menuAccess: any;
    footerAccess: any = { accessAdvice: false, cookies: false, privacyPolicy: false, termsOfUse: false, contactUs: false };

    constructor(private systemSettings: SystemSettings, private store: Store<fromStore.PlatformState>) {
        this.store.select(fromStore.globalMenuState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.menuAccess = state.menuAccess;
            if (this.menuAccess)
                this.changeAccess(this.menuAccess.accessAdvice, this.menuAccess.cookies, this.menuAccess.privacyPolicy,
                    this.menuAccess.termsOfUse, this.menuAccess.contactUs);
        });
    }

    ngOnInit() {
        this.platformSystemSettings = this.systemSettings;
        this.changeAccess(this.platformSystemSettings.preloginAccess.preLoginAccessAdvice,
            this.platformSystemSettings.preloginAccess.preLoginCookies, this.platformSystemSettings.preloginAccess.preLoginPrivacyPolicy,
            this.platformSystemSettings.preloginAccess.preLoginTermsOfUse, this.platformSystemSettings.preloginAccess.preLoginContactUs);
    }

    ngOnChanges() {
        if (this.isAuthenticated && this.menuAccess) {
            this.changeAccess(this.menuAccess.accessAdvice, this.menuAccess.cookies, this.menuAccess.privacyPolicy,
                this.menuAccess.termsOfUse, this.menuAccess.contactUs);
        }
        else {
            if (this.platformSystemSettings)
                this.changeAccess(this.platformSystemSettings.preloginAccess.preLoginAccessAdvice,
                    this.platformSystemSettings.preloginAccess.preLoginCookies, this.platformSystemSettings.preloginAccess.preLoginPrivacyPolicy,
                    this.platformSystemSettings.preloginAccess.preLoginTermsOfUse, this.platformSystemSettings.preloginAccess.preLoginContactUs);
        }
    }

    changeAccess(accessAdvice, cookies, privacyPolicy, termsOfUse, contactUs) {
        this.footerAccess.accessAdvice = accessAdvice;
        this.footerAccess.cookies = cookies;
        this.footerAccess.privacyPolicy = privacyPolicy;
        this.footerAccess.termsOfUse = termsOfUse;
        this.footerAccess.contactUs = contactUs;
    }
}