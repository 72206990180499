import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ImportBehaviors } from '@core/shared/models/import-behaviors';

@Component({
  selector: 'cs-file-uploader-without-mapping',
  templateUrl: './file-uploader-without-mapping.component.html',
  styleUrls: ['./file-uploader-without-mapping.component.scss']
})
export class FileUploaderWithoutMappingComponent implements OnInit {
  @Input() lastUploadId: string;
  @Input() uploadType: string;
  @Input() contextId: string;
  @Input() userId: string;
  @Input() importBehaviors: ImportBehaviors;
  @Input() showPicker: boolean;
  @Input() uploadButtonEnabled: boolean;
  @Input() header: string;
  @Input() popoverContent: string;
  @Output() onImportComplete = new EventEmitter();

  uploadId: string = null;
  uploadStatus: number = 1;

  ngOnInit() {
    this.uploadId = this.lastUploadId;
  }

  changeUploadId(newUploadId) {
    this.uploadId = newUploadId;
  }

  changeUploadStatus(status) {
    this.uploadStatus = status;
  }

  isUploadButtonEnabled() {
    return this.uploadButtonEnabled && this.uploadStatus < 4
  }

  handleImportComplete() {    
    this.onImportComplete.emit(this.uploadId);
  }
}
