import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICustomWindow, WindowReferenceService } from '@core/shared';
import { UserRegistrationApprovalService } from '../services/user-registration-approval.service';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
let windowObject = window as any;

@Component({
    selector: 'cs-user-registration-view-user',
    templateUrl: 'view-user.component.html',
    styleUrls: ['view-user.component.scss']
})

export class UserRegistrationViewUserComponent implements OnInit {
    @Input() userId;

    selectedCulture: string;
    private _window: ICustomWindow;
    public faRemoveIcon = faTimesCircle;

    constructor(private activeModal: NgbActiveModal, private windowRef: WindowReferenceService, private approvalService: UserRegistrationApprovalService) {
        this._window = windowRef.nativeWindow;
    }

    ngOnInit() {
        windowObject.parent.preRegistrationCallBack = function (userDetails): Promise<any> {
            let success = {
                "success": true,
                "errorCode": "",
                "responseMessage": ""
            };
            return Promise.resolve(success);
        };

        windowObject.parent.postRegistrationCallBack = (userDetails, message) => {
            location.reload();
        };
        this.getSelfRegistrationConfiguration();
    }

    getSelfRegistrationConfiguration() {
        this.selectedCulture = localStorage.getItem('culture');
        this.approvalService.selfRegistrationConfig().subscribe(data => {
            this.renderForm(data);
        })
    }

    renderForm(configs) {
        this.approvalService.editProfileSelfRegistration(configs, this.userId, this.selectedCulture).subscribe(data => {
            this._window.__selfRegistrationAppData__ = data;
            this._window.loadSelfRegistrationInDom('selfRegistrationRoot');
        });
    }

    cancel() {
        this.activeModal.dismiss();
    }
}