import { Action } from '@ngrx/store';

export const CHANGE_GLOBAL_MENU_ACCESS = 'Change Global Menu Access';

export class ChangeGlobalMenuAccess implements Action {
    readonly type = CHANGE_GLOBAL_MENU_ACCESS;

    constructor(public payload: any) { }
}

export type GlobalMenuAccessAction =  ChangeGlobalMenuAccess;