import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/startup';
import { environment } from '@env/environment';

@Component({
    selector: 'app-test',
    templateUrl: 'site-monitoring.component.html'
})

export class SiteMonitoringComponent implements OnInit {
    isSuccess: number = 0;
    constructor(private authenticationService: AuthenticationService) { }

    ngOnInit() {
        this.authenticationService.login(environment.username, environment.password, 'username', 'password', null, null).subscribe({
            next: () => {
                this.isSuccess = 1;
            },
            error: () => {
                this.isSuccess = -1;
            }
        });
    }
}