import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'localizedDate', pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const selectedCulture = localStorage.getItem('culture');
    var culture = selectedCulture.split('-');
    const formatedDate: DatePipe = new DatePipe(culture[1]);
    return formatedDate.transform(value, 'short');
  }
}
