import { Routes } from '@angular/router';
import { AuthGuard } from '@core/shared';
import { ClaimFormsComponent } from './claimForms.component';
import { SalesClaimComponent } from './sales-claim/sales-claim.component';

export const SalesClaimRoutes: Routes = [
    {
        path: 'claimforms',
        component: ClaimFormsComponent,
        children: [
            {
                path: 'claims/sales/:id',
                canActivate: [AuthGuard],
                component: SalesClaimComponent,
                pathMatch: 'full',
                data: { title: 'PageTitles.Sales', isPreLogin: false }
            }
        ]
    }
];