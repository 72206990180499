<div>
    <div class="pull-right per-page-align">
        <label for="options" class="pull-right pad-eight">{{ 'Shared.Lists.ItemsPerPage' | translate }}</label>
        <select class="form-control" name="options" id="options" [value]="paginationDetails.itemsPerPage" #itemsPerPage
            (change)="onItemsChange(itemsPerPage.value)">           
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
    </div>
</div>

